import React, {useEffect, useState} from "react";
import { Form, Col, Row, OverlayTrigger, Button, Popover } from "react-bootstrap";
import { NumberFormatInput } from "../../../../helpers";


//buy form
const SwitchForm = ({formik, activeAcountList, fundList, error, error2, accountAllList}) => {
    const [fundlist, setFundList] = useState([]);
    const [tofundlist, setToFundList] = useState([]);
    const [accountList, setAccountList] = useState([]);
    const [accountAll, setAccountAll] = useState([]);
    const [available, setAvailabale] = useState(0);
    const [account, setAccount] = useState([]);


    useEffect(() => {
        setFundList(fundList);
    }, [fundList])

    useEffect(() => {
        if(formik.values.tofund != ''){
            let list = activeAcountList.filter((value) => value.FUND_CODE == formik.values.tofund);
            setAccountAll(list);
        }else{
            setAccountAll([]); 
        }
    }, [formik.values.tofund])
    
    useEffect(() => {
        if(formik.values.fund != ''){
            let list = activeAcountList.filter((value) => value.FUND_CODE == formik.values.fund);
            setAccountList(list);

            let tofund = fundlist.filter((value) => value.FUND_CODE != formik.values.fund);
            setToFundList(tofund);
        }else{
            setAccountList([]); 
        }
    }, [formik.values.fund])

    //hanlde amount
    const handleavalue = (e) => {
        const re = /^[0-9]*\.?[0-9]*$/;
    
        if (e.target.value === '' || re.test(e.target.value)) {
           formik.setFieldValue('value', e.target.value);
        }
    }

    //hanlde amount
    const handleunits = (e) => {
        const re = /^[0-9]*\.?[0-9]*$/;
    
        if (e.target.value === '' || re.test(e.target.value)) {
           formik.setFieldValue('units', e.target.value);
        }
    }

    const popover = (block, SubAmount, SubUnits, available, type, unitprice, Total) => {
        return(
          <Popover id="popover-basic" style={{width:350}}>
          <Popover.Header as="h3">Asset summary</Popover.Header>
          <Popover.Body>
          <Row>
              <Col md={8}>
                  <label><b>Statement Balance</b></label>
              </Col>
              <Col md={4} className="popover-number">
                  <label>{type == 1?(Number(Total) * Number(unitprice)).toFixed(2):Number(Total).toFixed(2)}</label>
              </Col>
            </Row>
            <Row>
              <Col md={8}>
                  <label><b>Blocked Value</b></label>
              </Col>
              <Col md={4} className="popover-number">
                  <label>{type == 1?(Number(block) * Number(unitprice)).toFixed(2):Number(block).toFixed(2)}</label>
              </Col>
            </Row>
            {
              type == 1?
              <Row>
              <Col md={8}>
                  <label><b>Pending Value (LKR)</b></label>
              </Col>
              <Col md={4} className="popover-number">
                  <label>{Number(Number(SubAmount) +((SubUnits != null && Number(SubUnits) > 0)?Number(SubUnits) * Number(unitprice):0)).toFixed(2)}</label>
              </Col>
            </Row>:
            <Row>
              <Col md={8}>
                  <label><b>Pending Units</b></label>
              </Col>
              <Col md={4} className="popover-number">
                  <label>{Number(Number(SubUnits) + ((SubAmount != null && Number(SubAmount) > 0)?Number(SubAmount)/Number(unitprice):0)).toFixed(2)}</label>
              </Col>
            </Row>
            }
          </Popover.Body>
        </Popover>
        )
      }

      const handleAccountChange = (e) => {
        let passValFrom = accountList.filter((value) => value.ACCOUNT_NO == e.target.value)[0];
        let subUnits = (passValFrom.sub_units != '' && passValFrom.sub_units != null) ?passValFrom.sub_units:0;
        let subAmount = (passValFrom.sub_amount != '' && passValFrom.sub_amount != null) ?passValFrom.sub_amount:0;
        let totalUnits = 0;
        let blockUnits = passValFrom.BLOCK_NO_OF_UNIT != '' && passValFrom.BLOCK_NO_OF_UNIT != null?passValFrom.BLOCK_NO_OF_UNIT:0
    
        if(Number(subAmount) > 0){
          subAmount = Number(subAmount) / Number(passValFrom.REDIM_PRICE);
        }
        totalUnits = Number(subUnits) + Number(subAmount)+Number(blockUnits);

        formik.setFieldValue('totalunits', totalUnits)
        formik.setFieldValue('numberofunits', passValFrom.NO_OF_UNITS)
        formik.setFieldValue('account', e.target.value);
      }

      const handleRedeemMethod = (e) =>{
        let passValFrom = e.target;
        let account = accountList.filter((value) => value.ACCOUNT_NO == formik.values.account)[0];
        let avail = 0;
        let availabale = (passValFrom.value==1?((Number(account.NO_OF_UNITS) - Number(account.BLOCK_NO_OF_UNIT)) * Number(account.REDIM_PRICE)):(Number(account.NO_OF_UNITS) - Number(account.BLOCK_NO_OF_UNIT)));
        if(account.sub_amount != null && Number(account.sub_amount) > 0){
          if(passValFrom.value==1){ //if value
           availabale =  Number(availabale) - Number(account.sub_amount);
          }else{ //if units
           availabale = Number(availabale) - (Number(account.sub_amount) / Number(account.REDIM_PRICE));
          }
        }

        if(account.sub_units != null && Number(account.sub_units) > 0){ //value for unit
           if(passValFrom.value==2){ //if value
             availabale =  Number(availabale) - Number(account.sub_units);
           }else{ //if units
             availabale = Number(availabale) - (Number(account.sub_units) * Number(account.REDIM_PRICE));
           }
        }
        formik.setFieldValue('redeemmethod', e.target.value)
        formik.setFieldValue('availabale', availabale)
        setAccount(account);
      }

    return(
        <Form>
        <Form.Group as={Row} className="mb-3" controlId="fund">
        <Form.Label column sm="3">
           From Fund
        </Form.Label>
        <Col sm="8">
        <Form.Select 
            className="text-small" 
            name="fund"
            autoComplete="off"
            isInvalid={Boolean(formik.errors.fund)}
            onChange={formik.handleChange}
            value={formik.values.fund}
            size="sm">
                 <option value="">Select Fund</option>
            {
                fundlist.length > 0 && fundlist.map((value) => {
                    return(
                        <option value={value.FUND_CODE}>{value.FUND_NAME}</option>
                    )
                })
            }
           
        </Form.Select>
        {formik.errors.fund && 
        <div className="col-md-12">
        <span className="srv-validation-message"><span className="error-palate">*&nbsp;{formik.errors.fund}</span></span>
        </div>
        }  
        </Col>
        </Form.Group>
        <Form.Group as={Row}  className="mb-3" controlId="fund">
        <Form.Label column sm="3">
          From Account
        </Form.Label>
        <Col sm="8">
        <Form.Select 
            name="account" 
            className="text-small" 
            autoComplete="off"
            isInvalid={Boolean(formik.errors.account)}
            onChange={handleAccountChange}
            value={formik.values.account} 
            size="sm">
            <option value={''}>Select Account</option>
            {
                accountList.length > 0 && accountList.map((value) => {
                    return(
                        <option value={value.ACCOUNT_NO}>{value.ACCOUNT_NO}</option> 
                    )
                })
            }
        </Form.Select>
        {formik.errors.account && 
        <div className="col-md-12">
        <span className="srv-validation-message"><span className="error-palate">*&nbsp;{formik.errors.account}</span></span>
        </div>
        } 
        </Col>
  
        </Form.Group>

        <Form.Group as={Row} className="mb-3" controlId="fund">
        <Form.Label column sm="3">
           To Fund
        </Form.Label>
        <Col sm="8">
        <Form.Select 
            className="text-small" 
            name="tofund"
            autoComplete="off"
            isInvalid={Boolean(formik.errors.tofund)}
            onChange={formik.handleChange}
            value={formik.values.tofund}
            size="sm">
                 <option value="">Select Fund</option>
            {
                tofundlist.length > 0 && tofundlist.map((value) => {
                    return(
                        <option value={value.FUND_CODE}>{value.FUND_NAME}</option>
                    )
                })
            }
           
        </Form.Select>
        {formik.errors.tofund && 
        <div className="col-md-12">
        <span className="srv-validation-message"><span className="error-palate">*&nbsp;{formik.errors.tofund}</span></span>
        </div>
        }  
        </Col>
        </Form.Group>

        <Form.Group as={Row}  className="mb-3" controlId="fund">
        <Form.Label column sm="3">
          To Account
        </Form.Label>
        <Col sm="8">
        <Form.Select 
            name="toaccount" 
            className="text-small" 
            autoComplete="off"
            isInvalid={Boolean(formik.errors.toaccount)}
            onChange={formik.handleChange}
            value={formik.values.toaccount} 
            size="sm">
            <option value={''}>Select Account</option>
            <option value={'1'}>New</option>
            {
                accountAll.length > 0 && accountAll.map((value) => {
                    return(
                        <option value={value.ACCOUNT_NO}>{value.ACCOUNT_NO}</option> 
                    )
                })
            }
        </Form.Select>
        {formik.errors.toaccount && 
        <div className="col-md-12">
        <span className="srv-validation-message"><span className="error-palate">*&nbsp;{formik.errors.toaccount}</span></span>
        </div>
        } 
        </Col>
  
        </Form.Group>

        <Form.Group as={Row}  className="mb-3" controlId="redeemtype">
        <Form.Label column sm="3">
            Redeem Type
        </Form.Label>
        <Col sm="8">
        <Form.Select 
            name="redeemtype" 
            className="text-small" 
            autoComplete="off"
            isInvalid={Boolean(formik.errors.redeemtype)}
            onChange={formik.handleChange}
            value={formik.values.redeemtype} 
            size="sm">
            <option value="">Select Redeem Type</option>
            <option value="1">Full</option>
            <option value="2">Partial</option>
        </Form.Select>
        {formik.errors.redeemtype && 
        <div className="col-md-12">
        <span className="srv-validation-message"><span className="error-palate">*&nbsp;{formik.errors.redeemtype}</span></span>
        </div>
        } 

        {
         error == true?
        <div className="col-md-12" style={{marginTop:10}}>
        <span className="srv-validation-message"><span className="error-palate">*&nbsp; insufficient amount of units&nbsp;&nbsp;&nbsp;
        <OverlayTrigger trigger="hover" style={{marginRight:30}} placement="bottom" overlay={popover(0, 0, 0, 0, 0, 0, 0)}>
            <Button variant="default" className="popover-button">?</Button>
        </OverlayTrigger>
        </span></span>
        </div>
        :''
        }
        
        </Col>
  
        </Form.Group>

        {
            formik.values.redeemtype == 2?
                  <>
                <Form.Group as={Row}  className="mb-3" controlId="redeemmethod">
                <Form.Label column sm="3">
                    Redeem Method
                </Form.Label>
                <Col sm="8">
                <Form.Select 
                    name="redeemmethod" 
                    className="text-small" 
                    autoComplete="off"
                    isInvalid={Boolean(formik.errors.redeemmethod)}
                    onChange={handleRedeemMethod}
                    value={formik.values.redeemmethod} 
                    size="sm">
                    <option value="">Select Redeem Method</option>
                    <option value="1">By Value</option>
                    <option value="2">By Units</option>
                </Form.Select>
                {formik.errors.redeemmethod && 
                <div className="col-md-12">
                <span className="srv-validation-message"><span className="error-palate">*&nbsp;{formik.errors.redeemmethod}</span></span>
                </div>
                } 
                </Col>
        
                </Form.Group>
                {
                    formik.values.redeemmethod == 1?
                        <>
                        <Form.Group as={Row} className="mb-3" controlId="fund">
                        <Form.Label column sm="3">
                            Value
                        </Form.Label>
                        <Col sm="8">
                            {/* <Form.Control 
                                name="value" 
                                className="text-small"
                                autoComplete="off"
                                isInvalid={Boolean(formik.errors.value)}
                                onChange={handleavalue}
                                value={formik.values.value}  
                                size="sm" 
                                type="text" /> */}
                        <NumberFormatInput
                            formik={formik}
                            name="value"
                            onValueChange={handleavalue}
                            thousandSeparator={true}
                        />
                        {formik.errors.value && 
                        <div className="col-md-12">
                        <span className="srv-validation-message"><span className="error-palate">*&nbsp;{formik.errors.value}</span></span>
                        </div>
                        } 
                        {
                        error2 == true?
                        <div className="col-md-12" style={{marginTop:10}}>
                        <span className="srv-validation-message"><span className="error-palate">*&nbsp; Value must be less than available Value
                        </span></span>
                        </div>
                        :''
                        }
                        </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3" controlId="fund">
                        <Form.Label column sm="3">Available Value</Form.Label>
                            <Form.Label column sm="3">
                                <span style={{marginRight:15}}>{parseFloat((formik.values.availabale))>0?parseFloat(formik.values.availabale).toFixed(1).replace(/\d(?=(\d{3})+\.)/g, '$&,'):"0.00"}</span> 
                                <OverlayTrigger trigger="hover" style={{marginRight:30}} placement="bottom" overlay={popover(account.BLOCK_NO_OF_UNIT, account.sub_amount, account.sub_units, formik.values.availabale, formik.values.redeemmethod, account.REDIM_PRICE, account.NO_OF_UNITS)}>
                              <Button variant="default" className="popover-button">?</Button>
                            </OverlayTrigger>
                            </Form.Label>
                        </Form.Group>
                        </>
                        :
                        <>
                        <Form.Group as={Row} className="mb-3" controlId="fund">
                        <Form.Label column sm="3">
                            Units
                        </Form.Label>
                        <Col sm="8">
                            {/* <Form.Control 
                                name="units" 
                                className="text-small"
                                autoComplete="off"
                                isInvalid={Boolean(formik.errors.units)}
                                onChange={handleunits}
                                value={formik.values.units}  
                                size="sm" 
                                type="text" /> */}
                        <NumberFormatInput
                            formik={formik}
                            name="units"
                            onValueChange={handleunits}
                            thousandSeparator={true}
                        />
                        {formik.errors.units && 
                        <div className="col-md-12">
                        <span className="srv-validation-message"><span className="error-palate">*&nbsp;{formik.errors.units}</span></span>
                        </div>
                        } 
                          {
                        error2 == true?
                        <div className="col-md-12" style={{marginTop:10}}>
                        <span className="srv-validation-message"><span className="error-palate">*&nbsp; Units must be less than available Units
                        </span></span>
                        </div>
                        :''
                        }
                        </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3" controlId="fund">
                            <Form.Label column sm="3">Available Value</Form.Label>
                            <Form.Label column sm="3">
                            <span style={{marginRight:15}}>{parseFloat((formik.values.availabale))>0?parseFloat(formik.values.availabale).toFixed(1).replace(/\d(?=(\d{3})+\.)/g, '$&,'):"0.00"}</span>  
                            <OverlayTrigger trigger="hover" style={{marginRight:30}} placement="bottom" overlay={popover(account.BLOCK_NO_OF_UNIT, account.sub_amount, account.sub_units, formik.values.availabale, formik.values.redeemmethod, account.REDIM_PRICE, account.NO_OF_UNITS)}>
                              <Button variant="default" className="popover-button">?</Button>
                            </OverlayTrigger>
                            </Form.Label>
                        </Form.Group>
                        </>
                }
           
                </>  
        :
        ''
        }
        </Form>
    )
}

export default SwitchForm;
