import React, {useState, useEffect} from 'react'
import { Button, Form, FormControl, ListGroup } from 'react-bootstrap'
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload, faTrash } from '@fortawesome/free-solid-svg-icons';
import api from '../../../services/api';

export default function BankAccount({bankAccount, setBankAccount, bankAccountError, resident, nationality_type,bankDeclare,bankDeclareInvalid,bankScrollRef,setBankDeclare,setBankDeclareInvalid, name}) {
    const [bank, setBank] = useState([]);
    const [branch, setBranch] = useState([]);
    const [customMsg, setCustomMsg] = useState('');
    const [branchFilterd, setBranchFilterd] = useState([]);

    const validation = Yup.object({
        bank:Yup.string().required("Bank is required"),
        branch:Yup.string().required("Branch is required"),
        account:Yup.string().required("Account Number is required"),
        passbook:Yup.string().required("Bank Passbook is required"),
        account_type:Yup.string().required("Account Type is required"),
    });

    const handleDeclareEach = (e) => {
        if(e.target.checked == true){
            setBankDeclare(true)
        }else{
            setBankDeclare(false)
        }
    }

    const fileToBase64 = (file) => {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onloadend = () => {
            resolve(reader.result.split(',')[1]);
          };
          reader.onerror = (error) => {
            reject(error);
          };
          reader.readAsDataURL(file);
        });
    };

        //formkin form submition Invidual existsting
    const formik = useFormik({
        initialValues: {
            bank:'',
            branch:'',
            account:'',
            passbook:'',
            account_type:'',
            id:1,
        },
        validateOnChange:false,
        validateOnBlur:false,
        validationSchema: validation,
        // Login Request
        onSubmit: async(values) => {
            let arr = bankAccount;
            let passbook = await fileToBase64(values.passbook);
            arr.push({
                "bank":bank.filter((val) => val.id == values.bank)?.[0],
                "branch":branch.filter((val) => val.id == values.branch && val.bank_id == values.bank)?.[0],
                "account":values.account,
                "passbook": values.passbook,
                "account_type":values.account_type,
                "bank_passbook_scanned":passbook,
                "id":1,
            })
            formik.handleReset();
            document.getElementById('passbook').value= null;
            setBankAccount(arr);
        },
    });

    const handleDeleteBank = (index) => {
        let arr = bankAccount;
        arr.splice(index, 1);
        setBankAccount([...arr]);
    }

    useEffect(() => {
        try{
            const list = branch.filter((value) => value.bank_id == formik.values.bank)
            setBranchFilterd(list);
        }catch(error) {
            setCustomMsg('Something went wrong');
        }
    }, [formik.values.bank])

    useEffect(async () => {
        try{
            const bank_list = await api.getRequest('bank_list');
            if(bank_list.status == 200){
                if(bank_list.data.status == 'success'){
                    setBank(bank_list.data.bank_list);
                    setBranch(bank_list.data.bank_branch_list);
                }else{
                  setCustomMsg('Something went wrong');
                }
            }
        }catch(error){
          setCustomMsg('Something went wrong');
        }
    }, [])

    function downloadFile(file) {
        const blob = new Blob([file], { type: file.type });
        const a = document.createElement('a');
        a.href = window.URL.createObjectURL(blob);
        a.download = file.name;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    }

    const handleDownloadPassbook = (passbook) => {
        downloadFile(passbook);
    }

  return (
    <div className='col-md-12 animate__animated animate__fadeIn'>
        <p style={{fontWeight:700}}>Bank Account Details</p>
        <hr/>
        <div className='row' style={{minHeight:'60vh'}}>
            <div className='col-md-5'>
                <Form.Group className="mb-2" controlId="exampleForm.ControlInput1">
                    <Form.Label className="input-label">Bank <span class="required-field"></span></Form.Label>
                    <Form.Select       
                        isInvalid={Boolean(formik.errors.bank)}
                        onChange={formik.handleChange}
                        value={formik.values.bank}
                        name="bank" 
                        autoComplete="off"
                        size="sm" 
                        className='select2'
                        aria-label="Default select example"
                    >
                        <option value="">Select Bank</option>
                        {
                            bank.length > 0 && bank.map((value) => {
                                return(
                                    <option value={value.id}>{value.name}</option> 
                                )
                            })
                        }
                    </Form.Select>
                    {formik.errors.bank && 
                        <div className="col-md-12">
                        <span className="srv-validation-message"><span className="error-palate">*&nbsp;{formik.errors.bank}</span></span>
                        </div>
                    } 
                </Form.Group>
                <Form.Group className="mb-2" controlId="exampleForm.ControlInput1">
                    <Form.Label><strong>Branch</strong> <span class="required-field"></span></Form.Label>
                    <Form.Select 
                        size="sm" 
                        aria-label="Default select example"
                        value={formik.values.branch}
                        onChange={(e) => formik.setFieldValue('branch', e.target.value)}
                        isInvalid={Boolean(formik.errors.branch)}
                    >
                        <option value="">Select Branch</option>
                        {
                            branchFilterd.length > 0 && branchFilterd.map((value) => {
                                return(
                                    <option value={value.id}>{value.name}</option> 
                                )
                            })
                        }
                    </Form.Select>
                    {formik.errors.branch && 
                        <div className="col-md-12">
                        <span className="srv-validation-message"><span className="error-palate">*&nbsp;{formik.errors.branch}</span></span>
                        </div>
                    } 
                </Form.Group>
                <Form.Group className="mb-2" controlId="exampleForm.ControlInput1">
                    <Form.Label className="input-label">Account Type <span class="required-field"></span></Form.Label>
                    <Form.Select       
                        isInvalid={Boolean(formik.errors.account_type)}
                        onChange={formik.handleChange}
                        value={formik.values.account_type}
                        name="account_type" 
                        autoComplete="off"
                        size="sm" 
                        className='select2'
                        aria-label="Default select example"
                    >
                        <option value="">Select Account Type</option>
                        <option value="1" disabled={resident == 2 && nationality_type ==2}>Saving Account</option>
                        <option value="2" disabled={resident == 2 && nationality_type ==2}>Current Account</option>
                        <option value="3">IIA Account</option>
                    </Form.Select>
                    {formik.errors.account_type && 
                        <div className="col-md-12">
                        <span className="srv-validation-message"><span className="error-palate">*&nbsp;{formik.errors.account_type}</span></span>
                        </div>
                    } 
                </Form.Group>
                <Form.Group className="mb-2" controlId="exampleForm.ControlInput1">
                    <Form.Label>Account Number <span class="required-field"></span></Form.Label>
                    <FormControl
                        size="sm"
                        aria-label="account"
                        name="account"
                        autoComplete="off"
                        isInvalid={Boolean(formik.errors.account)}
                        onChange={formik.handleChange}
                        value={formik.values.account}
                        aria-describedby="basic-addon1"
                    />
                    {formik.errors.account && 
                        <div className="col-md-12">
                        <span className="srv-validation-message"><span className="error-palate">*&nbsp;{formik.errors.account}</span></span>
                        </div>
                    }  
                </Form.Group>
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                    <Form.Label>Bank Passbook / Statement <span class="required-field"></span></Form.Label>
                    <FormControl
                        size="sm"
                        aria-label="passbook"
                        type='file'
                        id="passbook"
                        name="passbook"
                        autoComplete="off"
                        isInvalid={Boolean(formik.errors.passbook)}
                        onChange={(e) => formik.setFieldValue('passbook', e.target.files[0])}
                        aria-describedby="basic-addon1"
                    />
                    {formik.errors.passbook && 
                        <div className="col-md-12">
                        <span className="srv-validation-message"><span className="error-palate">*&nbsp;{formik.errors.passbook}</span></span>
                        </div>
                    }  
                </Form.Group>
                <Form.Group className="mb-2" style={{display:'flex', flexDirection:'row', justifyContent:'end'}} controlId="exampleForm.ControlInput1">
                    <Button onClick={formik.handleSubmit} variant='success' size='sm' style={{width:150}}>Add Account</Button>
                </Form.Group>
            </div>
            <div className='col-md-7 mt-3'>
                {
                    bankAccountError != null?
                    <div className="col-md-12">
                        <span className="srv-validation-message"><span className="error-palate">*&nbsp;{bankAccountError}</span></span>
                    </div>
                    :''
                }
                    
                <ListGroup as="ol" style={{fontSize:15}} numbered>
                    {
                        bankAccount?.length > 0 && bankAccount?.map((val, index) => {
                            return (
                                <ListGroup.Item>
                                    <div style={{display:'flex', flexDirection:'row', justifyContent:'space-between'}}>
                                        <div style={{display:'flex', flexDirection:'column'}}>
                                            <span style={{fontSize:15, fontWeight:600}}>{val.bank.name}</span>
                                            <span style={{fontSize:12, fontWeight:600}}>{val.branch.name}</span>
                                            <span className='text-success' style={{fontSize:12, fontWeight:800}}>{val.account}{` (${val.account_type == 1?'Saving':(val.account_type == 2?'Current':'IIA')})`}</span>
                                        </div>
                                        <div style={{display:'flex', alignItems:'center'}}>
                                            <FontAwesomeIcon title='Download Passbook' onClick={() => handleDownloadPassbook(val.passbook)} style={{cursor:'pointer', marginRight:15}} className='text-primary' icon={faDownload}/>
                                            <FontAwesomeIcon title='Delete Bank' onClick={() => handleDeleteBank(index)} style={{cursor:'pointer'}} className='text-danger' icon={faTrash}/>
                                        </div>
                                    </div>
                                </ListGroup.Item>
                            )
                        })
                    }
                </ListGroup>
            </div>
        </div>
        <div className='row'>
            <div className='col-md-12' style={{display:'flex', flexDirection:'column', justifyContent:'center', marginTop:10}}>
                <Form.Check
                    style={{fontSize:13, marginRight:20}}
                    size="md"
                    isInvalid={bankDeclareInvalid}
                    label={`I, ${name} hereby declare that the information given above is true and accurate to the best of my knowledge and confirm submitting same to Senfin Asset Management for the purposes of investing in one or more funds managed by same`}
                    name="declare"
                    type={'checkbox'}
                    onClick={handleDeclareEach}
                    id={`inline-declare`}
                    checked={bankDeclare}
                />
            </div>
        </div>
        <div ref={bankScrollRef}></div>
    </div>
  )
}
