import React, {useState} from "react";
import { Form, FormControl } from "react-bootstrap";
import PasswordStrengthBar from 'react-password-strength-bar';
import { faLock, faCheckCircle, faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const eye = <FontAwesomeIcon icon={faEye} />;
const eyeSlash = <FontAwesomeIcon icon={faEyeSlash} />;
const PasswordResetForm = ({formik, passvalid, handleScore}) => {
    const [showNewPass, setShowNewPass] = useState(false);
    const [showRePass, setShowRePass] = useState(false);

        //handle password show icon
        const handlePasswordShow = (e) => {
            setShowNewPass(true);
            setTimeout(() => {
                setShowNewPass(false);
            }, 1500);
        }

        //handle re password show icon
        const handleRePasswordShow = (e) => {
            setShowRePass(true);
            setTimeout(() => {
                setShowRePass(false);
            }, 1500);
        }

        //handle enter key
        const handleEnterKey = (e) => {
            if(e.key == 'Enter'){
                formik.handleSubmit();
            }
        }
        return(
        <div className="animate__animated animate__fadeIn">
        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label className="input-label">New Password</Form.Label>
            <FormControl
            placeholder="Password"
            name="password"
            type={showNewPass == false?"password":"text"}
            autoComplete="off"
            isInvalid={Boolean(formik.errors.password) || passvalid}
            value={formik.values.password}
            onChange={formik.handleChange}
            aria-label="password"
            aria-describedby="basic-addon1"
            />
            <is style={{cursor:'pointer'}} onClick={handlePasswordShow}>{showNewPass == false?eye:eyeSlash}</is>
            { formik.values.password.length > 0 && <div className="col-md-12 strength-container" style={{margin:0, padding:0}}>
                <PasswordStrengthBar  className="strength-bar" style={{width:'100%'}} onChangeScore={handleScore} password={formik.values.password} />
            </div>}
            {formik.errors.password && 
                <div className="col-md-12">
                <span className="srv-validation-message"><span className="error-palate">*&nbsp;{formik.errors.password}</span></span>
                </div>
            }  
  
         </Form.Group>
         <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label className="input-label">Password Confirm</Form.Label>
            <FormControl
            placeholder="Confirm Password"
            name="repassword"
            type={showRePass == false?"password":"text"}
            autoComplete="off"
            isInvalid={Boolean(formik.errors.repassword)}
            value={formik.values.repassword}
            onChange={formik.handleChange}
            aria-label="Confirm password"
            onKeyDown={handleEnterKey}
            aria-describedby="basic-addon1"
            />
            <ir style={{cursor:'pointer'}} onClick={handleRePasswordShow}>{showRePass == false?eye:eyeSlash}</ir>
            {formik.errors.repassword && 
                <div className="col-md-12">
                <span className="srv-validation-message"><span className="error-palate">*&nbsp;{formik.errors.repassword}</span></span>
                </div>
            } 
       </Form.Group>
        </div>
        )
}

export default PasswordResetForm;