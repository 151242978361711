import { createStore, combineReducers } from "redux";

import initialData from "./initialData";
import { profileReducer } from "./reducers";

const reducers = combineReducers({
    profile:profileReducer
});

export default createStore(
    (state, action) => reducers(state, action),
    initialData
);