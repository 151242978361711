import React, {useState, useEffect} from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBuilding } from "@fortawesome/free-regular-svg-icons";
import { faDollar, faBank, faExchange, faSliders, faLock } from "@fortawesome/free-solid-svg-icons";
import ActionModel from "./models/actionModel";
import SellModel from "./models/sellModel";
import Transfer from "./models/transferModel";
import Switch from "./models/switchModel";
import LockModel from "./models/lockModel";
import Chart from "../chart";
import BannerSmall from '../banner/small';
import * as animationData from '../../../assets/images/locked.json';
import Lottie from 'react-lottie';
import RegisterInfo from '../../../components/home/actions/models/registerInfo';
import RegistarComplete from "../banner/registarComplete";

const Actions = ({tableList, all, fundList, activeList, acountList, refreshList, setRefresh, userFund, fixedFundChart, equityFundChart, allFundChart, setTableActive, userUpdate}) => {
    const [buttonID, setButtonID] = useState(0);
    const [actionModal, setActionModal] = useState(false);
    const [modeID, setModeID] = useState(0);
    const [sellModal, setSellModal] = useState(false);
    const [transModal, setTransModal] = useState(false);
    const [switchModal, setSwitchModal] = useState(false);
    const [lockModal, setLockModal] = useState(false);
    const [buydefault, setBuyDefault] = useState(null);
    const [locked, setLoacked] = useState(true);
    const [bannerLocked, setBannerLocked] = useState(true);
    const [registerinfo, setRegisterInfo] = useState(false);
    const [formType, setFromType] = useState(0);

    useEffect(async() => {
        let user = JSON.parse(userUpdate);
        if(user != undefined && user != null){
            if(user?.kyc != null && user?.application != null){
                setBannerLocked(false);
                if(user?.profile != null){
                    setLoacked(false);
                }
            }else{
                setBannerLocked(true);
            }
        }
        animateOnload();
    },[userUpdate]);

    const animateOnload = () => {
        const arr = [1,2,3,4,5];
        setTimeout(
            function() {
            buttonAnimate(1);
            setTimeout(
                function() {
                buttonAnimate(2);
                setTimeout(
                    function() {
                    buttonAnimate(3);
                    setTimeout(
                        function() {
                        buttonAnimate(4);
                        setTimeout(
                            function() {
                            buttonAnimate(5);
                        },310);
                    },310);
                },310);
            },310);
        },310);
    }

    const buttonAnimate = (id) => {
        setTimeout(
            function() {
            setButtonID('');
        },300);
        setButtonID(id);
    }

    const handleBuy = (e, id) => {
        buttonAnimate(id);
        setModeID(id)
        setActionModal(true);
    }

    const handleSell = (e, id) => {
        buttonAnimate(id);
        setModeID(id)
        setSellModal(true);
    }

    const handleTransfer = (id) => {
        setFromType(0);
        buttonAnimate(id);
        setModeID(id)
        setTransModal(true);
    }

    const handleSwitch = (id) => {
        buttonAnimate(id);
        setModeID(id)
        setSwitchModal(true);
    }

    const handleLock = (id) => {
        buttonAnimate(id);
        setModeID(id)
        setLockModal(true);
    }

    const handleBuyButton = (code) => {
        setModeID(1)
        setBuyDefault(code);
        setActionModal(true);
    }

    const AnimatedLock = ({name})=>{
        return(
            <div className="tray-item" onClick={handleOnClickLocked}>
                <Lottie options={defaultOptions}
                height={65}
                width={65}
                isStopped={false}
                isPaused={false}/>
                <span className="text-center dark-blue"><p><strong>{name}</strong></p></span>
            </div>
        )
    }

    const handleOnClickLocked = () => {
        setRegisterInfo(true);
    }

    const handleRegisterInfoHide = () => {
        setRegisterInfo(false);
    }

    const defaultOptions = {
        loop: true,
        autoplay: true, 
        animationData: animationData,
        rendererSettings: {
          preserveAspectRatio: 'xMidYMid slice'
        }
    };

    return (
        <div className="col-md-4 tray">
            <div className="button-tray">
                {
                     locked == false?
                     <div className="tray-item">
                        <button type="button" 
                            onClick={(e) => {
                                setButtonID('');
                                handleBuy(e, 1)
                            }} class="btn btn-circle buy-glow"><FontAwesomeIcon className={buttonID == 1?"icon-vib":''} icon={faBank} />
                        </button>
                        <span className="text-center dark-blue"><p><strong>INVEST</strong></p></span>
                    </div>
                     :
                     <AnimatedLock name="INVEST"/>
                }
                {
                    locked == false?
                    <div className="tray-item">
                        <button type="button" onClick={(e) => handleSell(e, 2)} class="btn btn-circle sell-glow">
                            <FontAwesomeIcon className={buttonID == 2?"icon-vib":''} icon={faDollar} />
                        </button>
                        <span className="text-center dark-blue"><p><strong>REDEEM</strong></p></span>
                    </div>
                    :
                    <AnimatedLock name="REDEEM"/>
                }
                {
                    locked == false?
                    <div className="tray-item">
                        <button type="button" onClick={(e) => handleTransfer(e, 3)} class="btn btn-circle transfer-glow"><FontAwesomeIcon className={buttonID == 3?"icon-vib":''} icon={faExchange} />
                        </button>
                        <span className="text-center dark-blue"><p><strong>SWITCH</strong></p></span>
                    </div>
                    :
                    <AnimatedLock name="SWITCH"/>
                }
                {/* {
                    locked == false?
                    <div className="tray-item">
                        <button type="button" onClick={(e) => handleSwitch(e, 4)} class="btn btn-circle switch-glow"><FontAwesomeIcon className={buttonID == 4?"icon-vib":''} icon={faSliders} />
                        </button>
                        <span className="text-center dark-blue"><p><strong>SWITCH</strong></p></span>
                    </div>
                    :
                    <AnimatedLock name="SWITCH"/>
                } */}
               {
                locked == false?
                <div className="tray-item">
                    <button type="button" onClick={(e) => handleLock(e, 5)} class="btn btn-circle lock-glow"><FontAwesomeIcon className={buttonID == 5?"icon-vib":''} icon={faLock} />
                    </button>
                    <span className="text-center dark-blue"><p><strong>LOCK</strong></p></span>
                </div>
                :
                <AnimatedLock name="LOCK"/>
               }
            </div>
            <div className="suggest-invest">
                {/* <div className="invest-tray curve-top curve-bottom">
                    {
                        all == true?
                        <h3>Invest more in,</h3>
                        :
                        <h3>You can also invest in,</h3>
                    }
                 
                    <div className="invest-table">
                        <div className="it-head">
                            <div className="it-col-1">Funds</div>
                            <div className="it-col-2">Buying Price</div>
                            <div className="it-col-3"></div>
                        </div>
                        {
                            tableList.length > 0 && tableList.map((value) => {
                            return(
                            <div className="it-row">
                                <div className="it-col-1">{value.FUND_NAME}</div>
                                <div className="it-col-2">{value.CREATION_PRICE}</div>
                                <div className="it-col-3 center">
                                <button type="button" onClick={(e) => handleBuyButton(value.FUND_CODE)} class="btn btn-primary buy-btn btn-sm">Buy</button>
                                </div>
                            </div>
                            )
                            })
                        }
                    </div>
                </div> */}
                {/* {
                    userFund.length > 1?
                    <Chart userFund={userFund} allFundChart={allFundChart} fixedFundChart={fixedFundChart} equityFundChart={equityFundChart} setTableActive={setTableActive}/>
                    :
                    <BannerSmall/>
                } */}
                {/* <BannerSmall/> */}
                {
                    bannerLocked == false?
                    <BannerSmall/>
                    :
                    <RegistarComplete/>
                }
                
            </div>
             <ActionModel show={actionModal} mode={modeID} onHide={() => setActionModal(false)} fundList={fundList} acountList={acountList} defaultFund={buydefault}/>
             <SellModel show={sellModal} mode={modeID} onHide={() => setSellModal(false)} fundList={fundList} activeAcountList={activeList}
                   accountList={acountList} refreshList={refreshList} setRefresh={setRefresh}
                />

            <Transfer formType={formType} setFromType={setFromType} show={transModal} mode={modeID} onHide={() => setTransModal(false)} fundList={fundList} activeAcountList={activeList}
                   accountList={acountList} refreshList={refreshList} setRefresh={setRefresh} />

            {/* <Switch show={switchModal} mode={modeID} onHide={() => setSwitchModal(false)} fundList={fundList} activeAcountList={activeList}
                   accountList={acountList} refreshList={refreshList} setRefresh={setRefresh} /> */}

            <LockModel show={lockModal} mode={modeID} onHide={() => setLockModal(false)} fundList={fundList} activeAcountList={activeList}
                   accountList={acountList} refreshList={refreshList} setRefresh={setRefresh}
                />
            <RegisterInfo show={registerinfo} onHide={handleRegisterInfoHide}/>
        </div>
    )
}

export default Actions;