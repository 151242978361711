import React, { useEffect, useState } from "react";
import { Button, Modal } from 'react-bootstrap';
import { faGears, faLock } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import 'animate.css';
import Success from "../../common/alerts/success";
import Error from "../../common/alerts/error";
import CreateNicnameForm from "./createNicknameForm";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import api from "../../../services/api";

const CreateNickname = ({onHide, show, account, nickname, state, setActiveList, activeList}) =>  {
  const [step, setStep] = useState(0);
  
  //validations
  const validationNickname = Yup.object({
    nickname : Yup.string().required("Required"),
  });

  const formik = useFormik({
  initialValues: {
    nickname: '',
    },
    validateOnChange:false,
    validateOnBlur:false,
    validationSchema: validationNickname,
    // Login Request
    onSubmit: async(values) => {
      try{
        if(state == 1){
          let dataSet = await api.postRequest('acc/name',{'name':values.nickname, 'ACCOUNT_NO':account});
          if(dataSet.status == 200){
            if(dataSet.data.status == 'success'){
              let ac_list = activeList;
              let check = ac_list?.filter((val) => val.ACCOUNT_NO == account)?.length > 0;
              if(check == true){
                ac_list.filter((val) => val.ACCOUNT_NO == account)[0].name = values.nickname;
              }
              setActiveList(ac_list);
              setStep(1);
            }else{
              setStep(2);
            }
          }else{
            setStep(2);
          }
        }else{
          let dataSet = await api.postRequest(`acc/name/${account}`,{'name':values.nickname, 'ACCOUNT_NO':account});
          if(dataSet.status == 200){
            if(dataSet.data.status == 'success'){
              let ac_list = activeList;
              let check = ac_list?.filter((val) => val.ACCOUNT_NO == account)?.length > 0;
              if(check == true){
                ac_list.filter((val) => val.ACCOUNT_NO == account)[0].name = values.nickname;
              }
              setActiveList(ac_list);
              setStep(1);
            }else{
              setStep(2);
            }
          }else{
            setStep(2);
          }
        }
        formik.resetForm();
      }catch(error){
        formik.resetForm();
        setStep(2);
      }
    },
  });

    return (
      <Modal
        show={show}
        onHide={onHide}
        backdrop="static"
        keyboard={false}
        size="md"
        style={{fontSize:13}}
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header style={{padding:'0.3rem 0.3rem'}}>
          <Modal.Title id="contained-modal-title-vcenter" >
            <FontAwesomeIcon icon={faGears} style={{paddingLeft:'8px'}}/>
            &nbsp;
            {state == 1?'Add Nickname':'Change Nickname'}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ paddingBottom:0}}>
          {
            step == 0?
              <div className="animate__animated animate__fadeIn">
                <CreateNicnameForm formik={formik} account={account} nickname={nickname} state={state}/>
              </div>
            : (
                step == 1?
                <div className="animate__animated animate__fadeIn">
                  {
                    state == 1?
                    <Success closeTime={10} message="Nickname Added Successfully!" close={onHide} step={setStep} nextStep={false}/>
                    :
                    <Success closeTime={10} message="Nickname Updated Successfully!" close={onHide} step={setStep} nextStep={false}/>
                  }
                    
                </div>
                :
                <div className="animate__animated animate__fadeIn">
                    <Error closeTime={10} close={onHide} step={setStep}  nextStep={false}/>
                </div>
            )
          }
              
        </Modal.Body>
        <Modal.Footer style={{padding:'0.3rem 0.3rem', paddingLeft:'8px',paddingRight:'8px', paddingBottom:8, display:'flex', justifyContent:step > 0?'end':'space-between', borderTop:'none'}}>
          {
            <Button  className="btn btn-danger btn-red" style={{width:80}} onClick={(e) => {setStep(0);formik.resetForm();onHide()}}>Cancel</Button>
            
          }
          <Button className="btn-btn-success btn-green" style={{width:80, display:step > 0?'none':''}}  onClick={formik.handleSubmit}>Submit</Button>
        </Modal.Footer>
      </Modal>
    );
  }

  export default CreateNickname;