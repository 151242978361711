import React from 'react'
import { Accordion, ListGroup } from 'react-bootstrap'

export default function Complete({instruction, accountType}) {
  const successMsg = () =>{
    return(
      <p style={{fontSize:15, fontWeight:500}}>Your account has been successfully <span className='text-success'>Verified</span>. We provide customized solutions to suit your individual investment needs through our Wealth Management Services.</p>
    )
  }

  const pendingMsg = () => {
    return(
      <p style={{fontSize:15, fontWeight:500}}>We're currently <span className='text-warning'>awaiting</span> the information from the second applicant to complete the process. Your patience is appreciated.</p>
    )
  }

  return (
    <div className='row'>
      <div className='col-md-12'>
        {
          accountType == 1?
          successMsg()
          :(
            instruction == 1?
            successMsg()
            :
            pendingMsg()
          )

        }
        <p style={{fontSize:14}}>For more information please navigate to below fund details. If you need assistance, feel free to reach out to our support team.</p>
        <ListGroup>
          <ListGroup.Item className='complete_list_item' action target='_blank' href="http://senfinassetmanagement.com/fund/senfin-growth-fund/"><span style={{fontSize:15, fontWeight:600, color:'#0f974b'}}>Senfin Growth Fund</span></ListGroup.Item>
          <ListGroup.Item className='complete_list_item' action target='_blank' href="http://senfinassetmanagement.com/fund/senfin-money-market-fund/"><span style={{fontSize:15, fontWeight:600, color:'#0f974b'}}>Senfin Money Market Fund</span></ListGroup.Item>
          <ListGroup.Item className='complete_list_item' action target='_blank' href="http://senfinassetmanagement.com/fund/senfin-shariah-balanced-fund/"><span style={{fontSize:15, fontWeight:600, color:'#0f974b'}}>Senfin Shariah Balanced Fund</span></ListGroup.Item>
          <ListGroup.Item className='complete_list_item' action target='_blank' href="http://senfinassetmanagement.com/fund/senfin-shariah-income-fund/"><span style={{fontSize:15, fontWeight:600, color:'#0f974b'}}>Senfin Shariah Income Fund</span></ListGroup.Item>
          <ListGroup.Item className='complete_list_item' action target='_blank' href="http://senfinassetmanagement.com/fund/senfin-dynamic-income-fund/"><span style={{fontSize:15, fontWeight:600, color:'#0f974b'}}>Senfin Dynamic Income Fund</span></ListGroup.Item>
          <ListGroup.Item className='complete_list_item' action target='_blank' href="http://senfinassetmanagement.com/fund/senfin-dividend-fund/"><span style={{fontSize:15, fontWeight:600, color:'#0f974b'}}>Senfin Dividned Fund</span></ListGroup.Item>
          <ListGroup.Item className='complete_list_item' action target='_blank' href="http://senfinassetmanagement.com/fund/senfin-insurance-sector-fund/"><span style={{fontSize:15, fontWeight:600, color:'#0f974b'}}>Senfin Insurance Sector Fund</span></ListGroup.Item>
          <ListGroup.Item className='complete_list_item' action target='_blank' href="http://senfinassetmanagement.com/fund/senfin-financial-services-fund/"><span style={{fontSize:15, fontWeight:600, color:'#0f974b'}}>Senfin Financial Services Fund</span></ListGroup.Item>
          <ListGroup.Item className='complete_list_item' action target='_blank' href="http://senfinassetmanagement.com/fund/senfin-consumer-staples-fund/"><span style={{fontSize:15, fontWeight:600, color:'#0f974b'}}>Senfin Consumer Staples Fund</span></ListGroup.Item>
        </ListGroup>
      </div>
    </div>
  )
}
