import React, { useState, useEffect} from "react";
import '../../../css/alert.css';
import { useHistory, Redirect } from "react-router-dom";

const Warning = ({message="Transaction Successful!"}) => {
    return(
    <>
    <div className="swal2-icon swal2-warning swal2-icon-show" style={{display:'flex'}}>
        <div className="swal2-icon-content" style={{fontSize:'4em'}}>!</div>
    </div>
    </>
    )
}

export default Warning;