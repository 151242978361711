import React from 'react';
import { Switch, HashRouter } from 'react-router-dom';
import { ProtectedRoute } from './services/ProtectedRoute';
import ScrollToTop from './utils/ScrollToTop';
import Home from './pages/home';
import Login from './pages/Login';
import Register from './pages/Register';
import Application from './pages/Application';
import Existing from './pages/Application/existing';
import BankDetails from './pages/bank';
import KYC from './pages/kyc';
import ProfileShow from './pages/Profile';
import Reset from './pages/reset';
import StatementView from './components/transaction/pdf/statementview';
import Ask from './pages/Ask';
import Regcomplete from './pages/Regcomplete';
import RegisterOtp from './pages/Register/secondApplicationOtp';

const Routes = () => {
  return (
      <HashRouter>
        <ScrollToTop>
          <Switch>
            <ProtectedRoute exact path='/home'>
              <Home />
            </ProtectedRoute>
            <ProtectedRoute exact path='/profile'>
              <ProfileShow />
            </ProtectedRoute>
            <ProtectedRoute exact path='/profile/pre'>
              <ProfileShow />
            </ProtectedRoute>
            <ProtectedRoute exact path='/'>
              <Login />
            </ProtectedRoute>
            <ProtectedRoute exact path='/checkpoint'>
              <Ask />
            </ProtectedRoute>
            <ProtectedRoute exact path='/existing'>
              <Existing />
            </ProtectedRoute>
            <ProtectedRoute exact path='/register'>
              <Register />
            </ProtectedRoute>
            <ProtectedRoute exact path='/complete'>
              <Regcomplete />
            </ProtectedRoute>
            <ProtectedRoute exact path='/reset'>
              <Reset />
            </ProtectedRoute>
            <ProtectedRoute exact path='/application'>
              <Application />
            </ProtectedRoute>
            <ProtectedRoute exact path='/bankdetails'>
              <BankDetails />
            </ProtectedRoute>
            <ProtectedRoute exact path='/uploads'>
              < KYC/>
            </ProtectedRoute>
            <ProtectedRoute exact path='/statement'>
              < StatementView/>
            </ProtectedRoute>
            <ProtectedRoute exact path='/user/register/:token'>
              < RegisterOtp/>
            </ProtectedRoute>
          </Switch>
        </ScrollToTop>
      </HashRouter>
  );
};
export default Routes;
