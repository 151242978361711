import React, { useEffect, useState } from "react";

const BannerSmall = () =>{
    const [fundName, setFundName] = useState('');
    const [day, setDay] = useState('');
    const [month, setMonth] = useState('');
    const [year, setYear] = useState('');
    const [fullDate, setFullDate] = useState('');
    const [precentage, setPrecentage] = useState('');
    const [text1, setText1] = useState('');
    const [text2, setText2] = useState('');
    const [display, setDisplay] = useState('');

    const monthNames = ["January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
    ];


    useEffect(() => {
        try{
            let data = sessionStorage.getItem('advertisement');
            data = JSON.parse(data);
            if(data != undefined && data != null){
                setFundName(data.fund.FUND_NAME);
                // setDay(new Date(data.as_at).getDate());
                // setMonth(monthNames[new Date(data.as_at).getMonth()]);
                // setYear(new Date(data.as_at).getFullYear());
                setFullDate(data.as_at);
                setPrecentage(data.value)
                setText1(data.text.split('*')[0])
                setText2(data.text.split('*')[1])
            }else{
                setDisplay('none');
            }
        }catch(error){
            setDisplay('none');
        }
    }, [])

    return (
        <>
            <div className="row" style={{paddingLeft:35, display:display}}>
                <div className="col-md-12" style={{marginTop:10, marginBottom:0,paddingBottom:0}}>
                    <h5 style={{margin:0, fontSize:15}}>{fundName}</h5>
                    <p className="banner-color" style={{fontSize:12}}>Managed by Senfin Asset Management</p>
                </div>
                <div className="col-md-6 banner">
                    <h1 className="text-right banner-color banner-precentage-small">{precentage}</h1>
                    <h6 className="text-right banner-color">CURRENT YIELD</h6>
                </div>
                <div className="col-md-6 center">
                <h1 className="banner-color banner-quote-small" >
                    <div  className="animate__animated animate__fadeInUp animate__delay-1s">{text1}</div>
                    <div className="animate__animated animate__fadeInUp animate__delay-2s">{text2}</div> 
                </h1>
                </div>
                <div className="col-md-12">
                <p className="banner-color banner-date-small"><strong>* {fullDate}</strong></p>
                </div>
            </div>
        </>
     
    )
}

export default BannerSmall;