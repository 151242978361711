import React, {useState, useEffect} from "react";
import { Button, Modal, Form, Col, Row, InputGroup, FormControl } from 'react-bootstrap';
import { faBank, faKey } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import 'animate.css';
import { useHistory } from "react-router-dom";
import { useFormik } from 'formik';
import * as animationData from '../../../assets/images/otp_animate.json';
import * as securityAnimationData from '../../../assets/images/security.json';
import * as Yup from 'yup';
import api from '../../../services/api';
import Lottie from 'react-lottie';
import OTPInput from "otp-input-react";
import Success from "../../common/alerts/success";
import PhoneInput from "react-phone-input-2";

const setAuthData = (auth) => {
    sessionStorage.setItem('loggedBy', 2); //form login
    sessionStorage.setItem('token', auth.access_token);
    sessionStorage.setItem('user',  JSON.stringify(auth.user));
    sessionStorage.setItem('advertisement', JSON.stringify(auth.advertisement));
    sessionStorage.setItem('news', JSON.stringify(auth.news));
    sessionStorage.setItem('profile', auth.user.profile);
    sessionStorage.setItem('maintance', auth.maintenance_notification);
  } 

const OtpConfirmCop = ({data, setData, onHide, show, dataset}) =>  {
    const history = useHistory();
    const [customMsg, setCustomMsg] = useState('');
    const [otp, setOtp] = useState('');
    const [step, setStep] = useState(4);
    const [check, setCheck] = useState(1);
    const [stared, setStared] = useState([]);
    const [authentication, setAuthentication] = useState([]);
    const [manualRender, setManualRender] = useState(false);
 

        const defaultOptions = {
          loop: true,
          autoplay: true, 
          animationData: animationData,
          rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
          }
        };

        const defaultOptionsSecurity = {
          loop: true,
          autoplay: true, 
          animationData: securityAnimationData,
          rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
          }
        };

        useEffect(() => {
          setStep(4);
          setOtp('');
          formik.setFieldValue('mobile', '');
          formik.setFieldValue('email', '');
        }, [show])

        const hideEmail = (email) => {
          return email.replace(/(.{3})(.*)(?=@)/,
            function(gp1, gp2, gp3) { 
              for(let i = 0; i < gp3.length; i++) { 
                gp2+= "*"; 
              } return gp2; 
            });
        };

        const hideMobile = (mobile) => {
          return mobile.slice(0, 2) + mobile.slice(2).replace(/.(?=...)/g, '*');
        }

        const handleContinue = async() => {
          let formData = new FormData();
          if(check == 1){
            formData.append('email', data?.EMAIL);
          }else{
            formData.append('mobile', data?.MOBILE_NO);
          }
          const registered = await api.postRequest('register/request', formData)
          if(registered?.status == 200){
            setStep(2);
            sessionStorage.setItem('token', registered?.data?.token)
            setAuthentication(registered?.data);
          }
        }

        const handleSubmit = async() => {
          let formData = new FormData();
          formData.append('password_confirmation', dataset.repassword);
          formData.append('password', dataset.password);
          if(check == 1){
            formData.append('email', data?.EMAIL);
          }else{
            formData.append('mobile', data?.MOBILE_NO);
          }
          formData.append('otp', otp);
          formData.append('UNIT_HOLDER_REG_NO', dataset.registration);
          formData.append('company_name', dataset.companyname);
          formData.append('company_registration_no', dataset.companyregistration);
          const request = await api.postRequest('existing/register/corporate', formData)
          if(request.status == 200){
              if(request.data.status == 'fail'){
                  var erros = request.data.errors;
                  var first = Object.keys(erros)[0]
                  setCustomMsg(erros[first][0]);
              }else{
                setAuthData(request.data);
                setStep(3);
              }
          }else{
              setCustomMsg('Something went wrong');
          }
        }

        const handleBack = () => {
          history.push("/home");
        }

        const validation = Yup.object({
          email:Yup.string().test('is-email', 'Email address is required', (value, context) => {
            if((data?.MOBILE_NO == undefined || data?.MOBILE_NO == '')){
                if(formik.values.email != ''){
                  return true;
                }else{
                  return false;
                }
            }else{
                return true;
            }
          }).email('Invalid email address'),
          mobile:Yup.string().test('is-mobile', 'Mobile is required', (value, context) => {
            if((data?.MOBILE_NO != undefined && data?.MOBILE_NO != '')){
                if(formik.values.mobile != ''){
                  return true;
                }else{
                  return false;
                }
            }else{
                return true;
            }
          })
        });

        //formkin form submition Invidual existsting
        const formik = useFormik({
          initialValues: {
              email:'',
              mobile:'',
          },
          validateOnChange:false,
          validateOnBlur:false,
          validationSchema: validation,
          // Login Request
          onSubmit: async(values) => {
              if(values.mobile != ''){
                let lastfour = values.mobile.slice(-4);
                let lastfourexist = data?.MOBILE_NO.slice(-4);
                if(lastfour != lastfourexist){
                  formik.setFieldError('mobile', 'Mobile number does not match');
                  return false;
                }else{
                  let dataDump = data;
                  dataDump.MOBILE_NO = '+'+values.mobile;
                  setData(dataDump);
                  setManualRender(!manualRender);
                  setStep(1);
                }
              }else if(values.email != ''){
                let splidedNew = values.email.split('@');
                let splidedCurrent = data.EMAIL.split('@');
                if(splidedNew[0].toLocaleLowerCase() != splidedCurrent[0].toLocaleLowerCase()){
                  formik.setFieldError('email', 'Email address does not match');
                  return false;
                }else{
                  let dataDump = data;
                  dataDump.EMAIL = values.email;
                  setData(dataDump);
                  setManualRender(!manualRender);
                  setStep(1);
                }
              }
          },
      });

    return (
      <Modal
        show={show}
        onHide={onHide}
        backdrop="static"
        size="sm"
        style={{fontSize:13}}
        dialogClassName="otp-confirm"
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header style={{padding:'0.3rem 0.3rem'}}>
          <Modal.Title style={{fontSize:18}} id="contained-modal-title-vcenter">
            <FontAwesomeIcon icon={faKey} style={{paddingLeft:'8px'}}/>
            &nbsp;
            CONFIRMATION
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ padding:0}}>
          {
           step == 4?
              <>
                <div style={{display:'flex', flexDirection:'row', justifyContent:'center'}}>
                    <Lottie options={defaultOptionsSecurity}
                      height={130}
                      width={130}
                      isStopped={false}
                      isPaused={false}/>
                </div>
                <div style={{paddingLeft:20, paddingRight:20, marginTop:10}}>
                  <p style={{padding:0, margin:0}}>Verify your {(data?.MOBILE_NO != undefined && data?.MOBILE_NO != '')?'mobile number':'email address'} for enhanced security.</p>
                  {
                    data?.MOBILE_NO != undefined && data?.MOBILE_NO != ''?
                    <div className="row" style={{marginBottom:10, paddingLeft:10, paddingRight:10, marginTop:10}}>
                      <div className="col-md-4" style={{fontSize:14, fontWeight:500, paddingTop:5, paddingBottom:5, background:'#e9ecef'}}>
                            Mobile No:
                      </div>
                      <div className="col-md-6" style={{fontSize:14, fontWeight:500, background:'#e9ecef', color:'#212529', paddingTop:5, paddingBottom:5}}>
                      {hideMobile(data?.MOBILE_NO)}
                      </div>
                    </div>
                    :
                    data?.EMAIL != undefined && data?.EMAIL != ''?
                    <div className="row" style={{marginBottom:10 , paddingLeft:10, paddingRight:10, marginTop:10}}>
                      <div className="col-md-4" style={{fontSize:14, fontWeight:500, paddingTop:5, paddingBottom:5, background:'#e9ecef'}}>
                            Email:
                      </div>
                      <div className="col-md-8" style={{fontSize:14, fontWeight:500, background:'#e9ecef', color:'#212529', paddingTop:5, paddingBottom:5}}>
                        {hideEmail(data?.EMAIL)}
                      </div>
                    </div>
                    :null
                  }
                  {
                    data?.MOBILE_NO != undefined && data?.MOBILE_NO != ''?
                    <div className='col-md-10'>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                          <Form.Label className="input-label">Mobile <span class="required-field"></span></Form.Label>
                            <InputGroup style={{height:'30px', fontSize:'13px'}} className="mb-3">
                                    <PhoneInput
                                        country={'lk'}
                                        name="mobile"
                                        className="phone-register-mobile"
                                        placeholder="mobile"
                                        autoComplete="off"
                                        isInvalid={Boolean(formik.errors.mobile)}
                                        onChange={phone => formik.setFieldValue('mobile', phone)}
                                        value={formik.values.mobile}
                                        style={{width:'100%'}}
                                    />
                                        {formik.errors.mobile && <div className="col-md-12">
                                        <span className="srv-validation-message"><span className="error-palate">*&nbsp;{formik.errors.mobile}</span></span>
                                    </div>}
                            </InputGroup>
                    </Form.Group>
                  </div>
                  :
                  <div className='col-md-10'>
                  <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <Form.Label className="input-label">Email <span class="required-field"></span></Form.Label>
                        <FormControl
                            size="sm"
                            aria-label="email"
                            aria-describedby="basic-addon1"
                            name="email"
                            autoComplete="off"
                            isInvalid={Boolean(formik.errors.email)}
                            onChange={formik.handleChange}
                            value={formik.values.email}
                        />
                        {formik.errors.email && 
                            <div className="col-md-12">
                            <span className="srv-validation-message"><span className="error-palate">*&nbsp;{formik.errors.email}</span></span>
                            </div>
                        }  
                  </Form.Group>
                  </div> 

                  }
                </div>

              </>
           :
            (step == 3?
            <Success message="Registration Successful!" closeTime={10} redirect="/home"/>
            :
            <>
            <div style={{display:'flex', flexDirection:'row', justifyContent:'center'}}>
                <Lottie options={defaultOptions}
                  height={130}
                  width={130}
                  isStopped={false}
                  isPaused={false}/>
            </div>
            {
              step == 1?
              <div style={{paddingLeft:20, paddingRight:20, marginTop:15}}>
              <p style={{padding:0, margin:0}}>How do you want to receive OTP to confirmation?</p>
              <div style={{marginTop:20, marginBottom:20}}>

                  {
                    data?.EMAIL != undefined && data?.EMAIL != ''?
                    <Form.Check type={'radio'} id={`check-api-radio`} >
                      <Form.Check.Input type={'radio'} onClick={() => setCheck(1)}  isValid checked={check==1}/>
                      <Form.Check.Label >{`Send otp to email`}</Form.Check.Label>
                      <Form.Control.Feedback type="valid">
                        {hideEmail(data?.EMAIL)}
                      </Form.Control.Feedback>
                    </Form.Check>
                    :null
                  }
                
                  {
                    data?.MOBILE_NO != undefined && data?.MOBILE_NO != ''?
                    <Form.Check type={'radio'} id={`check-api-radio2`} style={{marginTop:10}}>
                      <Form.Check.Input type={'radio'} onClick={() => setCheck(2)} isValid checked={check==2}/>
                      <Form.Check.Label >{`Send otp to mobile`}</Form.Check.Label>
                      <Form.Control.Feedback type="valid">
                      {hideMobile(data?.MOBILE_NO)}
                      </Form.Control.Feedback>
                    </Form.Check>
                    :null
                  }
                    
              </div>
            </div>
              :
              <div style={{paddingLeft:20, paddingRight:20, marginTop:15}}>
              <p style={{padding:0, margin:0}}>Your one time password (OTP) has been sent via SMS to your registered mobile number.</p>
              <div style={{display:'flex', flexDirection:'row', marginTop:20}}>
                    <Form.Label sm={6} lg={6} style={{marginRight:20}}><strong>Sended To : </strong></Form.Label>
                    <div>
                      {
                        check == 1?
                        <p> {hideEmail(data?.EMAIL)}</p>
                        :
                        <p> {hideMobile(data?.MOBILE_NO)}</p>
                      }
                    
                    </div>
              </div>
              <div style={{display:'flex', flexDirection:'row', justifyContent:'center', marginTop:10, marginBottom:15}}>
                  <OTPInput
                  style={{ display: "block"}}
                  value={otp}
                  onChange={setOtp}
                  autoFocus
                  OTPLength={5}
                  otpType="number"
                  disabled={false}
                  />
                </div>
            </div>
            }
            </>
            )
          }
     
        </Modal.Body>
        <Modal.Footer style={{padding:'0.3rem 0.3rem', paddingLeft:'8px',paddingRight:'8px', paddingBottom:8, display:'flex', justifyContent:step == 3?'end':'space-between', borderTop:'none'}}>
          {
            step == 1?
            <>
            <Button className="btn btn-danger btn-red" style={{width:80}} onClick={onHide}>Cancel</Button>
            <Button className="btn-btn-success btn-green" style={{width:80}} onClick={handleContinue}>Continue</Button>
            </>
            :
            step == 2?
            <>
            <Button className="btn btn-danger btn-red" style={{width:80}} onClick={onHide}>Cancel</Button>
            <Button className="btn-btn-success btn-green" style={{width:80}} onClick={handleSubmit}>Submit</Button>
            </>
            :
            step == 4?
            <>
            <Button className="btn btn-danger btn-red" style={{width:80}} onClick={onHide}>Cancel</Button>
            <Button className="btn-btn-success btn-green" style={{width:80}} onClick={formik.handleSubmit}>Submit</Button>
            </>
            :
            <>
            <Button className="btn-btn-success btn-green" style={{width:80}} onClick={handleBack}>Back</Button>
            </>
          }

        </Modal.Footer>
      </Modal>
    );
  }

  export default OtpConfirmCop;