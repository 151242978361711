
import { NumericFormat } from 'react-number-format';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';

export function NumberFormat(amount) {
	var value = (amount).toLocaleString(
        undefined, // leave undefined to use the visitor's browser 
                   // locale or a string like 'en-US' to override it.
        { minimumFractionDigits: 2,  maximumFractionDigits:2}
      );
     return value;
}

export function Logout () {
  sessionStorage.removeItem('token');
  window.location.reload();
}


export function NumberFormatInput(props) {
  const [separate, setSeparate] = useState(""); 
  const { formik, name, onValueChange, thousandSeparator, decimalSeparator, ...rest } = props;

  function handleChange(event) {
    if (onValueChange) {
      const unformattedValue = event.value.replace(new RegExp(`\\${thousandSeparator}`, 'g'), '');
      onValueChange({
        target:{
          value:event.value == '' || event.value == null ?'':parseFloat(unformattedValue)
        }
      });
    }
  }

  useEffect(() => {
      setSeparate(formik?.values?.[name]);
  },[formik?.values?.[name]])

  return (
      <NumericFormat
        value={separate}
        className='text-small'
        onValueChange={handleChange}
        thousandSeparator={thousandSeparator}
        decimalSeparator={decimalSeparator}
        allowNegative={false}
        {...rest}
        isInvalid={Boolean(formik.errors[name])}
        customInput={Form.Control}
        autoComplete='off'
        style={{padding:'.25rem .5rem'}}
      />
  );
}

export function NumberFormatInputWithSuffix(props) {
  const [separate, setSeparate] = useState(""); 
  const { formik, name, onValueChange, thousandSeparator, decimalSeparator, suffix, ...rest } = props;

  function handleChange(event) {
    if (onValueChange) {
      const unformattedValue = event.value.replace(new RegExp(`\\${thousandSeparator}`, 'g'), '');
      onValueChange({
        target:{
          value:event.value == '' || event.value == null ?'':parseFloat(unformattedValue)
        }
      });
    }
  }

  useEffect(() => {
      setSeparate(formik?.values?.[name]);
  },[formik?.values?.[name]])

  return (
      <NumericFormat
        value={separate}
        className='text-small'
        onValueChange={handleChange}
        thousandSeparator={thousandSeparator}
        decimalSeparator={decimalSeparator}
        allowNegative={false}
        {...rest}
        isInvalid={Boolean(formik.errors[name])}
        customInput={Form.Control}
        autoComplete='off'
        style={{padding:'.25rem .5rem'}}
        suffix={suffix}
      />
  );
}

NumberFormatInput.propTypes = {
  value: PropTypes.number,
  onValueChange: PropTypes.func,
  thousandSeparator: PropTypes.string,
  decimalSeparator: PropTypes.string,
};

NumberFormatInput.defaultProps = {
  thousandSeparator: ',',
  decimalSeparator: '.',
};
