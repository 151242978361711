import React from "react";
import { Row, Col } from 'react-bootstrap';

const Footer = () => {
    return (
        <footer>  
        <div className="container footer-styles">
        <div className="footer">
            <div className="footer-left">
                <h6 style={{margin:0}}>SENFIN ASSET MANAGEMENT (PVT) LTD</h6>
                <h6 >Your Premium Investment Partner</h6>
            </div>
            <div className="footer-right">
                <h6 style={{margin:0}}>Hotline 074-179-0202</h6>
                <h6 >Email : investor.relations@senfin.com</h6>
            </div>
        </div>
        </div>
        </footer>
    )
}

export default Footer;