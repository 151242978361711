"use strict";

import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Routes from "./Routes";
import axios from "axios";
import { baseUrl } from "./Config";
import Login from "./pages/Login";

const App = () => {

  const setAuthData = (auth) => {
    sessionStorage.setItem("token", auth.access_token);
    sessionStorage.setItem('user',  JSON.stringify(auth.user));
  };
  

  const noInterceptAxios = axios.create();
  axios.interceptors.response.use(
    function async(response) {
      // Do something with response data
      return response;
    },
    function (error) {
      if (error.response.status == 401) {
        return new Promise(async (resolve) => {
          const originalRequest = error.config;
          let refreshToken = sessionStorage.getItem("token");
          if (
            error.response &&
            error.response.status === 401 &&
            error.config &&
            !error.config.__isRetryRequest &&
            refreshToken != undefined
          ) {
            originalRequest._retry = false;
            const response = await fetch(baseUrl + "refresh", {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${refreshToken}`,
              },
            })
              .then((res) => res.json())
              .then(async (res) => {
                setAuthData(res);
                originalRequest.headers[
                  "Authorization"
                ] = `bearer ${res.access_token}`;
                return noInterceptAxios(originalRequest);
              })
              .catch((error) => {
                sessionStorage.removeItem('token');
                window.location.reload();
              });
            resolve(response);
          }
        });
      } else {
        return Promise.reject(error);
      }
    }
  );
  return (
    <div className="App">
      <Routes />
    </div>
  );
};

export default App;
