import moment from 'moment';
import React from 'react'
import { Form, FormControl } from 'react-bootstrap';
import DatePicker from 'react-datepicker';

export default function Kyc({formik, isAddressPremenent, type, name, kycDeclare, kycDeclareInvalid, setKycDeclareInvalid, setKycDeclare, kycRef}) {

    const filterPassedTime = (time) => {
    const currentDate = new Date();
    const selectedDate = new Date(time);

    return currentDate.getTime() < selectedDate.getTime();
    };

    
    const handleDeclareEach = (e) => {
        if(e.target.checked == true){
            setKycDeclare(true)
        }else{
            setKycDeclare(false)
        }
    }

  return (
    <div className='col-md-12 animate__animated animate__fadeIn'>
        <p style={{fontWeight:700}}>KYC</p>
        <hr/>
        {
            type == 'nic'?
            <div className='row'>
                <p style={{fontSize:14}}>Upload scanned copies of the front and back sides of your National Identity Card (NIC)</p>
                <div className='col-md-4'>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <Form.Label className="input-label">Front Side of NIC <span class="required-field"></span></Form.Label>
                        <FormControl
                            size="sm"
                            type='file'
                            aria-label="nic_front"
                            aria-describedby="basic-addon1"
                            name="nic_front"
                            autoComplete="off"
                            isInvalid={Boolean(formik.errors.nic_front)}
                            onChange={(e) => formik.setFieldValue('nic_front', e.target.files[0])}
                        />
                        {formik.errors.nic_front && 
                            <div className="col-md-12">
                            <span className="srv-validation-message"><span className="error-palate">*&nbsp;{formik.errors.nic_front}</span></span>
                            </div>
                        }  
                    </Form.Group>
                </div>
                <div className='col-md-4'>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <Form.Label className="input-label">Back Side of NIC <span class="required-field"></span></Form.Label>
                        <FormControl
                            size="sm"
                            type='file'
                            aria-label="nic_back"
                            aria-describedby="basic-addon1"
                            name="nic_back"
                            autoComplete="off"
                            isInvalid={Boolean(formik.errors.nic_back)}
                            onChange={(e) => formik.setFieldValue('nic_back', e.target.files[0])}
                        />
                        {formik.errors.nic_back && 
                            <div className="col-md-12">
                            <span className="srv-validation-message"><span className="error-palate">*&nbsp;{formik.errors.nic_back}</span></span>
                            </div>
                        }  
                    </Form.Group>
                </div>
            </div>
            :(
                type == 'passport'?
                <div className='row'>
                    <p style={{fontSize:14}}>Upload scanned copy of the Passport</p>
                    <div className='col-md-4'>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label className="input-label">Passport <span class="required-field"></span></Form.Label>
                            <FormControl
                                size="sm"
                                type='file'
                                aria-label="passport"
                                aria-describedby="basic-addon1"
                                name="passport"
                                autoComplete="off"
                                isInvalid={Boolean(formik.errors.passport)}
                                onChange={(e) => formik.setFieldValue('passport', e.target.files[0])}
                            />
                            {formik.errors.passport && 
                                <div className="col-md-12">
                                <span className="srv-validation-message"><span className="error-palate">*&nbsp;{formik.errors.passport}</span></span>
                                </div>
                            }  
                        </Form.Group>
                    </div>
                </div>
            :
            <div className='row'>
                <p style={{fontSize:14}}>Upload scanned copy of Identification document</p>
                <div className='col-md-4'>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <Form.Label className="input-label">Other Document <span class="required-field"></span></Form.Label>
                        <FormControl
                            size="sm"
                            type='file'
                            aria-label="other_doc"
                            aria-describedby="basic-addon1"
                            name="other_doc"
                            autoComplete="off"
                            isInvalid={Boolean(formik.errors.other_doc)}
                            onChange={(e) => formik.setFieldValue('other_doc', e.target.files[0])}
                        />
                        {formik.errors.other_doc && 
                            <div className="col-md-12">
                            <span className="srv-validation-message"><span className="error-palate">*&nbsp;{formik.errors.other_doc}</span></span>
                            </div>
                        }  
                    </Form.Group>
                </div>
            </div>
            )
        }
        <div className='row'>
            <p style={{fontSize:14}}>Upload the signature, clearly written on white paper</p>
            <div className='col-md-4'>
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                    <Form.Label className="input-label">Signature <span class="required-field"></span></Form.Label>
                    <FormControl
                        size="sm"
                        type='file'
                        aria-label="signature"
                        aria-describedby="basic-addon1"
                        name="signature"
                        autoComplete="off"
                        isInvalid={Boolean(formik.errors.signature)}
                        onChange={(e) => formik.setFieldValue('signature', e.target.files[0])}
                    />
                    {formik.errors.signature && 
                        <div className="col-md-12">
                        <span className="srv-validation-message"><span className="error-palate">*&nbsp;{formik.errors.signature}</span></span>
                        </div>
                    }  
                </Form.Group>
            </div>
        </div>
        {
            isAddressPremenent == true?
            <div className='row'>
                <div className='col-md-4'>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <Form.Label className="input-label">Billing Proof <span class="required-field"></span></Form.Label>
                        <FormControl
                            size="sm"
                            type='file'
                            aria-label="billing_proof"
                            aria-describedby="basic-addon1"
                            name="billing_proof"
                            autoComplete="off"
                            isInvalid={Boolean(formik.errors.billing_proof)}
                            onChange={(e) => formik.setFieldValue('billing_proof', e.target.files[0])}
                        />
                        {formik.errors.billing_proof && 
                            <div className="col-md-12">
                            <span className="srv-validation-message"><span className="error-palate">*&nbsp;{formik.errors.billing_proof}</span></span>
                            </div>
                        }  
                    </Form.Group>
                </div>
            </div>
            :null
        }
        <div className="row">
        <p style={{fontSize:14}}>Please fill the details for the kyc process </p>
            <div className="col-md-6">
                    <Form.Group 
                        className="mb-3" 
                        controlId="exampleForm.ControlInput1"
                    >
                        <Form.Label className="input-label">Verification Method <span class="required-field"></span></Form.Label>
                        <Form.Select 
                            size="sm"
                            name="method"
                            value={formik.values.method}
                            onChange={formik.handleChange}
                            isInvalid={Boolean(formik.errors.method)}
                        >
                            <option value="">Select Method</option>
                            <option value="skype">Skype</option>
                            <option value="whatsapp">WhatsApp</option>
                            <option value="zoom">Zoom</option>
                            <option value="viber">Viber</option>
                            <option value="physical">Physical</option>
                            <option value="videocall">Video Call</option>
                        </Form.Select>
                        {formik.errors.method && 
                        <div className="col-md-12">
                        <span className="srv-validation-message"><span className="error-palate">*&nbsp;{formik.errors.method}</span></span>
                        </div>
                        }  
                    </Form.Group>
                </div>
                <div className="col-md-6">
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <Form.Label className="input-label">Contact ID <span class="required-field"></span></Form.Label>
                        <FormControl
                            size="sm"
                            aria-label="contact_id"
                            aria-describedby="basic-addon1"
                            autoComplete="off"
                            name="contact_id"
                            value={formik.values.contact_id}
                            onChange={formik.handleChange}
                            isInvalid={Boolean(formik.errors.contact_id)}
                        />
                        {formik.errors.contact_id && 
                        <div className="col-md-12">
                        <span className="srv-validation-message"><span className="error-palate">*&nbsp;{formik.errors.contact_id}</span></span>
                        </div>
                        }  
                    </Form.Group>
                </div>
                <div className="col-md-6">
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <Form.Label className="input-label">Contact At <span class="required-field"></span></Form.Label>
                        {/* <FormControl
                            size="sm"
                            type="date-time"
                            aria-label="conactid"
                            aria-describedby="basic-addon1"
                            autoComplete="off"
                            value={formik.values.contactat}
                            onChange={formik.handleChange}
                            isInvalid={Boolean(formik.errors.contactat)}
                        /> */}
                           <DatePicker
                            className="form-control"
                            selected={formik.values.contact_at}
                            onChange={(date) => {
                                formik.setFieldValue('contact_at', date)
                            }}
                            name="contact_at"
                            showTimeSelect
                            autoComplete="off"
                            minDate={moment().toDate()}
                            filterTime={filterPassedTime}
                            filterDate={date => {
                                return date.getDay() !== 0 && date.getDay() !== 6;
                            }}
                            minTime={
                                new Date().getDate() ===
                                new Date(formik.values.contact_at ?? new Date().getTime()).getDate()
                                  ? new Date()
                                  : new Date().setHours(8, 45)
                            }
                            maxTime={new Date().setHours(16, 45)}
                            timeFormat="HH:mm"
                            timeIntervals={15}
                            timeCaption="time"
                            dateFormat="MMMM d, yyyy h:mm aa"
                            onKeyDown={(e) => {
                                e.preventDefault();
                            }}
                        />
                        {formik.errors.contact_at && 
                        <div className="col-md-12">
                        <span className="srv-validation-message"><span className="error-palate">*&nbsp;{formik.errors.contact_at}</span></span>
                        </div>
                        }  
                    </Form.Group>
                </div>
                <div className="col-md-6">
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <Form.Label className="input-label">Remark</Form.Label>
                        <Form.Control as="textarea" 
                        size="sm"
                        rows={3} 
                        autoComplete="off"
                        name="remark"
                        value={formik.values.remark}
                        onChange={formik.handleChange}
                        />
                        {formik.errors.remark && 
                        <div className="col-md-12">
                        <span className="srv-validation-message"><span className="error-palate">*&nbsp;{formik.errors.remark}</span></span>
                        </div>
                        }  
                    </Form.Group>
                </div>
            </div>
            <div className='row'>
                <div className='col-md-12' style={{display:'flex', flexDirection:'column', justifyContent:'center'}}>
                    <Form.Check
                        style={{fontSize:13, marginRight:20}}
                        size="md"
                        isInvalid={kycDeclareInvalid}
                        label={`I, ${name} hereby declare that the information given above is true and accurate to the best of my knowledge and confirm submitting same to Senfin Asset Management for the purposes of investing in one or more funds managed by same`}
                        name="declare"
                        type={'checkbox'}
                        onClick={handleDeclareEach}
                        id={`inline-declare`}
                        checked={kycDeclare}
                    />
                </div>
            </div>
            <div ref={kycRef}></div>
    </div>
  )
}
