import React, { useEffect, useState } from "react";
import Moment from "react-moment";
import Clock from 'react-live-clock';
import { NumberFormat } from "../../../helpers/index";
import ActionModel from "../actions/models/actionModel";
import SellModel from "../actions/models/sellModel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faAngleUp, faDownload, faLock, faSortAlphaAsc, faSortAsc } from "@fortawesome/free-solid-svg-icons";
import CreateNickname from "../nickname/createNickname";

const Portfolio = ({fundlist, userFunds, modelFundList, tableList, all, fundList, activeList, acountList, refreshList, setRefresh, fundCost, setActiveList}) => {
    const [actionModal, setActionModal] = useState(false);
    const [sellModal, setSellModal] = useState(false);
    const [defaultcode, setDefaultCode] = useState(null);
    const [selldefaultcode, setSellDefaultCode] = useState(null);
    const [clickToOpen, setClickToOpen] = useState([]);
    const [nicknameModel, setNicknameModel] = useState(false);
    const [nicknameAccount, setNicknameAccount] = useState(null);
    const [nickname, setNickname] = useState(null);
    const [nicknameState, setNicknameState] = useState(null);

    //open buy modal
    const handleOpenBuy = (code) => {
        setDefaultCode(code);
        setActionModal(true);
    }

    const handleOpenSell= (code) => {
        setSellDefaultCode(code);
        setSellModal(true);
    }

    const handleOnClickRow = (code) => {
        let open = clickToOpen;
        if(open.filter((val) => val == code).length > 0){
            let index = open.indexOf(code);
            if (index > -1) {
                open.splice(index, 1);
            }
        }else{
            open.push(code);
        }
        setClickToOpen([...open]);
    }

    const handleAddNickname = (accno, state, nickname) => {
        setNickname(nickname);
        setNicknameAccount(accno)
        setNicknameState(state)
        setNicknameModel(true);
    }

    const handleNicknameHide = () => {
        setNicknameModel(false);
    }

    const customRow = (value, color, cost, center, fund, state) => {
        return(
            activeList?.filter((active) => active.FUND_CODE == value.FUND_CODE).length > 0 
            && clickToOpen.filter((op) => op == value.FUND_CODE).length > 0?
            <>
            <div className={`${color} animate__animated animate__fadeIn`}>
            <p style={{fontSize:12, margin:0, fontWeight:700, paddingLeft:10}}>{value.FUND_NAME} Breakdown</p>
            </div>
            {
                activeList?.filter((active) => active.FUND_CODE == value.FUND_CODE)?.map((subAcc) => {
                    let account_cost = fundCost.filter((fv) => fv[1] === subAcc.ACCOUNT_NO)?.[0];
                    return(
                    <div className={`${color} animate__animated animate__fadeIn`} style={{paddingBottom:4,paddingTop:4}}>
                        <div className="cl-1">{subAcc.ACCOUNT_NO}{subAcc?.name == undefined || subAcc?.name == null ?'':' ('+subAcc?.name+')'}</div>
                        <div className="cl-2">{(Number(value.CREATION_PRICE)).toFixed(4)}</div>
                        <div className="cl-3">{(Number(value.REDIM_PRICE)).toFixed(4)}</div>
                        <div className="cl-3">{account_cost != undefined && account_cost != null?NumberFormat((Number(account_cost[2]))):'N/A'}</div>
                        <div className="cl-4">{state == true?NumberFormat((Number(fund.REDIM_PRICE) * Number(subAcc.NO_OF_UNITS))):'N/A'}</div>
                        <div className={`cl-5 center ${center}`} style={{display:'flex', flexDirection:'row', justifyContent:'center', paddingLeft:15}}>
                            {
                                subAcc?.name == undefined || subAcc?.name == null ?
                                <span className="text-center nickname" onClick={() => handleAddNickname(subAcc.ACCOUNT_NO, 1, subAcc?.name)} >Add Nickname</span>
                                :
                                <span className="text-center nickname" onClick={() => handleAddNickname(subAcc.ACCOUNT_NO, 2, subAcc?.name)} >Change Nickname</span>
                            }
                         
                        </div>
                    </div>
                    )
                })
            }
            </>
            :<></>
        )
    }

    return(
        <div className="col-md-8 portfolio">
            <div className="head-align">
                <h3 className="table-heading">My Portfolio</h3>
                <p style={{margin:0}}><Clock format={'YYYY MMM DD HH:mm:ss A'} ticking={true} timezone={'Asia/Colombo'} /></p>
            </div>
            <div className="horiz-scroll">
            <div className="table-header">
                <div className="cl-1">Holdings</div>
                <div className="cl-2">Buying Price</div>
                <div className="cl-3">Selling Price</div>
                <div className="cl-3">Cost</div>
                <div className="cl-4">Market Value</div>
                <div className="cl-5"></div>
            </div>
            {
                fundlist.length > 0 && fundlist.map((value, index) => {
                    const color = index % 2 == 0?'table-row-blue':'table-row-default';
                    let state = false;
                    let center = '';
                    let fund = [];
                    if(userFunds.length > 0){
                        let legth = userFunds.filter((values) => values.FUND_CODE == value.FUND_CODE).length;
                        fund = userFunds.filter((values) => values.FUND_CODE == value.FUND_CODE)[0];
                        if(legth > 0){
                            state = true;
                        }else{
                            state = false;
                        }
                    }else{
                        center ='just-center';
                    }

                    let cost = 0;
                    if(fundCost.length > 0){
                        let fund_with_cost = fundCost.filter((fv) => fv[0] === value.FUND_CODE);
                        if(fund_with_cost.length > 0){
                            fund_with_cost.map((costval) => {
                                cost += costval[2];
                            })
                        }
                    }

                    if(index == 0){
                        return(
                            <>
                            <div className={`curve-top ${color}`}>
                                <div className="cl-1" onClick={() => handleOnClickRow(value.FUND_CODE)} style={{paddingLeft:activeList?.filter((active) => active.FUND_CODE == value.FUND_CODE).length > 0 
                                          ?0:10}}>
                                    {
                                            activeList?.filter((active) => active.FUND_CODE == value.FUND_CODE).length > 0?
                                            (
                                                clickToOpen.filter((cf) => cf == value.FUND_CODE).length > 0?
                                                <FontAwesomeIcon style={{marginRight:3}} icon={faAngleUp}/>
                                                :
                                                <FontAwesomeIcon style={{marginRight:3}} icon={faAngleDown}/>
                                            )
                                           :null
                                    }
                                    <span className="row-dot" style={{backgroundColor:value.color}}>
                                    </span>{value.FUND_NAME}</div>
                                <div className="cl-2" onClick={() => handleOnClickRow(value.FUND_CODE)}>{(Number(value.CREATION_PRICE)).toFixed(4)}</div>
                                <div className="cl-3" onClick={() => handleOnClickRow(value.FUND_CODE)}>{(Number(value.REDIM_PRICE)).toFixed(4)}</div>
                                <div className="cl-3" onClick={() => handleOnClickRow(value.FUND_CODE)}>{NumberFormat((Number(cost)))}</div>
                                <div className="cl-4" onClick={() => handleOnClickRow(value.FUND_CODE)}>{state == true?NumberFormat((Number(fund.REDIM_PRICE) * Number(fund.NO_OF_UNITS))):'N/A'}</div>
                                <div className={`cl-5 center ${center}`}>
                                    <button type="button" onClick={(e) =>{handleOpenBuy(value.FUND_CODE)}} class="btn btn-primary buy-btn btn-sm">Invest</button>
                                    {
                                    state == true?
                                            <button onClick={(e) => handleOpenSell(value.FUND_CODE)}  type="button" class="btn btn-danger sell-btn btn-sm">Redeem</button>
                                        :
                                        <div className="sell-btn-overlay">
                                            <button type="button" onClick={null} class="btn btn-danger sell-btn btn-sm">Redeem</button>
                                            <div className="btn-overlay">
                                                <FontAwesomeIcon style={{fontSize:10, color:'#ffff'}} icon={faLock}/>
                                            </div>
                                        </div>
                                    } 
                                </div>
                            </div>
                            {customRow(value, color, cost, center, fund, state)}
                            </>
                        )
                    }else if((index+1) == fundlist.length){
                        return(
                        <>
                        <div className={`curve-bottom ${color}`}>
                                <div className="cl-1" onClick={() => handleOnClickRow(value.FUND_CODE)} style={{paddingLeft:activeList?.filter((active) => active.FUND_CODE == value.FUND_CODE).length > 0 
                                          ?0:10}}>
                                    {
                                            activeList?.filter((active) => active.FUND_CODE == value.FUND_CODE).length > 0?
                                            (
                                                clickToOpen.filter((cf) => cf == value.FUND_CODE).length > 0?
                                                <FontAwesomeIcon style={{marginRight:3}} icon={faAngleUp}/>
                                                :
                                                <FontAwesomeIcon style={{marginRight:3}} icon={faAngleDown}/>
                                            )
                                           :null
                                    }
                                    <span className="row-dot" style={{backgroundColor:value.color}}>
                                    </span>{value.FUND_NAME}</div>
                                <div className="cl-2" onClick={() => handleOnClickRow(value.FUND_CODE)}>{(Number(value.CREATION_PRICE)).toFixed(4)}</div>
                                <div className="cl-3" onClick={() => handleOnClickRow(value.FUND_CODE)}>{(Number(value.REDIM_PRICE)).toFixed(4)}</div>
                                <div className="cl-3" onClick={() => handleOnClickRow(value.FUND_CODE)}>{NumberFormat((Number(cost)))}</div>
                                <div className="cl-4" onClick={() => handleOnClickRow(value.FUND_CODE)}>{state == true?NumberFormat((Number(fund.REDIM_PRICE) * Number(fund.NO_OF_UNITS))):'N/A'}</div>
                                <div className={`cl-5 center ${center}`}>
                                    <button type="button" onClick={(e) => handleOpenBuy(value.FUND_CODE)} class="btn btn-primary buy-btn btn-sm">Invest</button>
                                    {
                                       state == true?
                                            <button type="button"  onClick={(e) => handleOpenSell(value.FUND_CODE)} class="btn btn-danger sell-btn btn-sm">Redeem</button>
                                        :
                                        <div className="sell-btn-overlay">
                                            <button type="button" onClick={null} class="btn btn-danger sell-btn btn-sm">Redeem</button>
                                            <div className="btn-overlay">
                                                <FontAwesomeIcon style={{fontSize:10, color:'#ffff'}} icon={faLock}/>
                                            </div>
                                        </div>
                                    }
                                    
                                </div>
                        </div>
                        {customRow(value, color, cost, center, fund, state)}
                        </>
                        )
                    }else{
                        if(index % 2 == 0){
                            return(
                                <>
                                <div className={color}>
                                <div className="cl-1" onClick={() => handleOnClickRow(value.FUND_CODE)} style={{paddingLeft:activeList?.filter((active) => active.FUND_CODE == value.FUND_CODE).length > 0 
                                          ?0:10}}>
                                    {
                                            activeList?.filter((active) => active.FUND_CODE == value.FUND_CODE).length > 0?
                                            (
                                                clickToOpen.filter((cf) => cf == value.FUND_CODE).length > 0?
                                                <FontAwesomeIcon style={{marginRight:3}} icon={faAngleUp}/>
                                                :
                                                <FontAwesomeIcon style={{marginRight:3}} icon={faAngleDown}/>
                                            )
                                           :null
                                    }
                                    <span className="row-dot" style={{backgroundColor:value.color}}>
                                    </span>{value.FUND_NAME}</div>
                                <div className="cl-2" onClick={() => handleOnClickRow(value.FUND_CODE)}>{(Number(value.CREATION_PRICE)).toFixed(4)}</div>
                                <div className="cl-3" onClick={() => handleOnClickRow(value.FUND_CODE)}>{(Number(value.REDIM_PRICE)).toFixed(4)}</div>
                                <div className="cl-3" onClick={() => handleOnClickRow(value.FUND_CODE)}>{NumberFormat((Number(cost)))}</div>
                                <div className="cl-4" onClick={() => handleOnClickRow(value.FUND_CODE)}>{state == true?NumberFormat((Number(fund.REDIM_PRICE) * Number(fund.NO_OF_UNITS))):'N/A'}</div>
                                <div className={`cl-5 center ${center}`}>
                                    <button type="button" onClick={(e) => handleOpenBuy(value.FUND_CODE)} class="btn btn-primary buy-btn btn-sm">Invest</button>
                                    {
                                       state == true?
                                            <button type="button"  onClick={(e) => handleOpenSell(value.FUND_CODE)} class="btn btn-danger sell-btn btn-sm">Redeem</button>
                                        :
                                        <div className="sell-btn-overlay">
                                            <button type="button" onClick={null} class="btn btn-danger sell-btn btn-sm">Redeem</button>
                                            <div className="btn-overlay">
                                                <FontAwesomeIcon style={{fontSize:10, color:'#ffff'}} icon={faLock}/>
                                            </div>
                                        </div>
                                    }
                                    
                                </div>
                                </div>
                                {customRow(value, color, cost, center, fund, state)}
                            </>
                            )
                        }else{
                            return(
                            <>
                            <div className={color}>
                                <div className="cl-1" onClick={() => handleOnClickRow(value.FUND_CODE)} style={{paddingLeft:activeList?.filter((active) => active.FUND_CODE == value.FUND_CODE).length > 0 
                                          ?0:10}}>
                                    {
                                            activeList?.filter((active) => active.FUND_CODE == value.FUND_CODE).length > 0?
                                            (
                                                clickToOpen.filter((cf) => cf == value.FUND_CODE).length > 0?
                                                <FontAwesomeIcon style={{marginRight:3}} icon={faAngleUp}/>
                                                :
                                                <FontAwesomeIcon style={{marginRight:3}} icon={faAngleDown}/>
                                            )
                                          
                                           :null
                                    }
                                    <span className="row-dot" style={{backgroundColor:value.color}}>
                                </span>{value.FUND_NAME}</div>
                                <div className="cl-2" onClick={() => handleOnClickRow(value.FUND_CODE)}>{(Number(value.CREATION_PRICE)).toFixed(4)}</div>
                                <div className="cl-3" onClick={() => handleOnClickRow(value.FUND_CODE)}>{(Number(value.REDIM_PRICE)).toFixed(4)}</div>
                                <div className="cl-3" onClick={() => handleOnClickRow(value.FUND_CODE)}>{NumberFormat((Number(cost)))}</div>
                                <div className="cl-4" onClick={() => handleOnClickRow(value.FUND_CODE)}>{state == true?NumberFormat((Number(fund.REDIM_PRICE) * Number(fund.NO_OF_UNITS))):'N/A'}</div>
                                <div className={`cl-5 center ${center}`}>
                                    <button type="button" onClick={(e) => handleOpenBuy(value.FUND_CODE)} class="btn btn-primary buy-btn btn-sm">Invest</button>
                                    {
                                       state == true?
                                            <button type="button"  onClick={(e) => handleOpenSell(value.FUND_CODE)} class="btn btn-danger sell-btn btn-sm">Redeem</button>
                                        :
                                        <div className="sell-btn-overlay">
                                            <button type="button" onClick={null} class="btn btn-danger sell-btn btn-sm">Redeem</button>
                                            <div className="btn-overlay">
                                                <FontAwesomeIcon style={{fontSize:10, color:'#ffff'}} icon={faLock}/>
                                            </div>
                                        </div>
                                    }
                                    
                                </div>
                            </div>
                            {customRow(value, color, cost, center, fund, state)}
                            </>
                            )
                        }
                    }
                })
            }
            </div>
            <div className="price-note">
                * Above prices are last published prices. Actual purchase / sale prices may differ according to time of instruction and prices applied for type of fund <br/> i.e : equity or fixed income
            </div>
            <ActionModel show={actionModal} mode={1} onHide={() => setActionModal(false)} fundList={modelFundList} acountList={acountList} defaultFund={defaultcode}/>
             <SellModel show={sellModal} mode={2} onHide={() => setSellModal(false)} fundList={modelFundList} activeAcountList={activeList}
                   accountList={acountList} refreshList={refreshList} setRefresh={setRefresh} defaultFund={selldefaultcode}
                />
            <CreateNickname activeList={activeList} setActiveList={setActiveList} account={nicknameAccount} nickname={nickname} state={nicknameState} show={nicknameModel} onHide={handleNicknameHide}/>
        </div>
    )
}

export default Portfolio;