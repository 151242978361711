import React, {useState} from "react";
import Form from '../../components/bank/form';
import Loader from '../../components/common/loader';
import TopNav from "../../components/common/topnav";

const BankDetails = () => {
    document.body.classList.add('body-register')

    const [loading, setLoading] = useState(false);

    const handleLoading = (e) => {
        setLoading(e);
    }

    return(
        <>
         <Loader active={loading}>
            <div className="container">
                <TopNav counter={false} downer={false} signOutMobile={false}/>
                <div className='main-wrap' style={{minHeight:'100vh'}}>
                    <div className='row justify-content-center'>
                        <Form loading={handleLoading}/>
                    </div>
                </div>
            </div>
        </Loader>
        </>
    )
}

export default BankDetails;