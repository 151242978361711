import React, { useEffect } from "react";
import { Form, FormControl } from "react-bootstrap"; 
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Terms from "../../common/terms/terms";


const NewCustomer = ({ formik, state, setState, msg, setMsg }) => {

    useEffect(() => {
      setState(1);
    }, [])

    //get birthday fun
    const getbdy = (nicn) => {
        var dayText = 0;
        var year = "";
        var month = "";
        var day = "";
        var gender = "";
        // Year
        if (nicn.length == 10) {
            year = "19" + nicn.substr(0, 2);
            dayText = parseInt(nicn.substr(2, 3));
        } else {
            year = nicn.substr(0, 4);
            dayText = parseInt(nicn.substr(4, 3));
        }
        if ( (dayText>0 && dayText<367) || (dayText>500 && dayText<867) ){
    
            if(dayText>500){
                dayText=dayText-500;
            }
        //Month
            if (dayText > 335) {
                day = dayText - 335;
                month = '12';
            }
            else if (dayText > 305) {
                day = dayText - 305;
                month = '11';
            }
            else if (dayText > 274) {
                day = dayText - 274;
                month = '10';
            }
            else if (dayText > 244) {
                day = dayText - 244;
                month = '09';
            }
            else if (dayText > 213) {
                day = dayText - 213;
                month = '08';
            }
            else if (dayText > 182) {
                day = dayText - 182;
                month = '07';
            }
            else if (dayText > 152) {
                day = dayText - 152;
                month = '06';
            }
            else if (dayText > 121) {
                day = dayText - 121;
                month = '05';
            }
            else if (dayText > 91) {
                day = dayText - 91;
                month = '04';
            }
            else if (dayText > 60) {
                day = dayText - 60;
                month = '03';
            }
            else if (dayText < 32) {
                month = '01';
                day = dayText;
            }
            else if (dayText > 31) {
                day = dayText - 31;
                month = '02';
            }
            if(day<10){
                    day="0"+day;
            }
                let date = year+'-'+month+'-'+day;
               return date;
            }
        }
    
        //handle identification blur
        const handleAutoBirthday = (e) => {
            if(formik.values.type == 'nic'){
                let id = e.target.value;
                var bday = getbdy(id);
                formik.setFieldValue('dob', bday);
            }else{
                formik.setFieldValue('dob', '');
            }
        } 
    

    return(
        <form>
        <div className="row">
            <div className="col-md-6">
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                    <Form.Label className="input-label">Type</Form.Label>
                    <Form.Select 
                        name="type" 
                        size="sm" 
                        autoComplete="off"
                        aria-label="Default select example"
                        isInvalid={Boolean(formik.errors.type)}
                        onChange={formik.handleChange}
                        value={formik.values.type}
                    >
                        <option value="nic">NIC</option>
                        <option value="passport">Passport</option>
                    </Form.Select>
                    {formik.errors.type && 
                        <div className="col-md-12">
                        <span className="srv-validation-message"><span className="error-palate">*&nbsp;{formik.errors.type}</span></span>
                        </div>
                    }   
                </Form.Group>
            </div>
            <div className="col-md-6">
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                    <Form.Label className="input-label">Identification Number</Form.Label>
                    <FormControl
                        name="idnumber"
                        size="sm"
                        className="text-uppercase"
                        autoComplete="off"
                        isInvalid={Boolean(formik.errors.idnumber)}
                        onChange={formik.handleChange}
                        value={formik.values.idnumber}
                        onBlur={handleAutoBirthday}
                        aria-label="idnumber"
                        aria-describedby="basic-addon1"
                    />
                    {formik.errors.idnumber && 
                        <div className="col-md-12">
                        <span className="srv-validation-message"><span className="error-palate">*&nbsp;{formik.errors.idnumber}</span></span>
                        </div>
                    }   
                </Form.Group>
            </div>
        </div>
        <div className="row">
            <div className="col-md-6">
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                <Form.Label className="input-label">Date of birth</Form.Label>
                <FormControl
                    size="sm"
                    name="dob"
                    isInvalid={Boolean(formik.errors.dob)}
                    autoComplete="off"
                    onChange={formik.handleChange}
                    value={formik.values.dob}
                    type="date"
                    aria-label="dateofbirth"
                    aria-describedby="basic-addon1"
                />
                 {formik.errors.dob && 
                    <div className="col-md-12">
                    <span className="srv-validation-message"><span className="error-palate">*&nbsp;{formik.errors.dob}</span></span>
                    </div>
                } 
            </Form.Group>
            </div>
            <div className="col-md-6">
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                <Form.Label className="input-label">Title</Form.Label>
                <Form.Select       
                     isInvalid={Boolean(formik.errors.title)}
                     onChange={formik.handleChange}
                     value={formik.values.title}
                     name="title" 
                     autoComplete="off"
                     size="sm" 
                     aria-label="Default select example"
                >
                    <option value="Mr:">Mr</option>
                    <option value="Mrs:">Mrs</option>
                    <option value="Miss:">Miss</option>
                    <option value="Rev:">Rev</option>
                    <option value="Other:">Other</option>
                </Form.Select>
                {formik.errors.title && 
                    <div className="col-md-12">
                    <span className="srv-validation-message"><span className="error-palate">*&nbsp;{formik.errors.title}</span></span>
                    </div>
                } 
            </Form.Group>
            </div>
        </div>
        <div className="row">
            <div className="col-md-6">
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                <Form.Label className="input-label">Initials</Form.Label>
                <FormControl
                    size="sm"
                    isInvalid={Boolean(formik.errors.initials)}
                    onChange={formik.handleChange}
                    value={formik.values.initials}
                    autoComplete="off"
                    name="initials"
                    aria-label="dateofbirth"
                    aria-describedby="basic-addon1"
                />
                {formik.errors.initials && 
                    <div className="col-md-12">
                    <span className="srv-validation-message"><span className="error-palate">*&nbsp;{formik.errors.initials}</span></span>
                    </div>
                } 
            </Form.Group>
            </div>
            <div className="col-md-6">
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                <Form.Label className="input-label">First Name</Form.Label>
                <FormControl
                    size="sm"
                    name="firstname"
                    isInvalid={Boolean(formik.errors.firstname)}
                    onChange={formik.handleChange}
                    autoComplete="off"
                    value={formik.values.firstname}
                    aria-label="dateofbirth"
                    aria-describedby="basic-addon1"
                />
                {formik.errors.firstname && 
                    <div className="col-md-12">
                    <span className="srv-validation-message"><span className="error-palate">*&nbsp;{formik.errors.firstname}</span></span>
                    </div>
                } 
            </Form.Group>
            </div>
        </div>
        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label className="input-label">Last Name</Form.Label>
            <FormControl
                size="sm"
                name="lastname"
                isInvalid={Boolean(formik.errors.lastname)}
                onChange={formik.handleChange}
                value={formik.values.lastname}
                autoComplete="off"
                aria-label="dateofbirth"
                aria-describedby="basic-addon1"
            />
            {formik.errors.lastname && 
                <div className="col-md-12">
                <span className="srv-validation-message"><span className="error-palate">*&nbsp;{formik.errors.lastname}</span></span>
                </div>
            } 
        </Form.Group>
        <Terms formik={formik}/>
        </form>
    )
}

export default NewCustomer;