import React, { useState, useEffect} from "react";
import '../../../css/alert.css';
import { useHistory, Redirect } from "react-router-dom";

const Error = ({message="Transaction Unsuccessful!", closeTime, close, currentStep=0, step, nextStep=false, redirect=null, leftResponce=null}) => {
    const history = useHistory();
    const [timeLeft, setTimeLeft] = useState(closeTime);

    useEffect(() => {
        if(timeLeft===0){
            setTimeLeft(0)
            if(redirect != null){
                history.push(redirect);
                window.location.reload();
            }

            if(leftResponce != null){
                leftResponce(0)
            }
           if(nextStep == false){
                close(true);
                step(0);
           }else{
                step(currentStep+1);
           }
        }
        if (!timeLeft) return;
        const intervalId = setInterval(() => {
          setTimeLeft(timeLeft - 1);
        }, 1000);
        return () => clearInterval(intervalId);
    }, [timeLeft]);

    return(
    <>
    <div className="swal2-icon swal2-error swal2-animate-error-icon" style={{display:'flex'}}>
        <span className="swal2-x-mark">
            <span className="swal2-x-mark-line-left"></span>
            <span className="swal2-x-mark-line-right"></span>
        </span>
    </div>
    <h2 className="swal2-title">{message}</h2>
        {
            nextStep == false?
                <div className="swal2-text">This window will close in {timeLeft} seconds</div>
            :
                <div className="swal2-text">This window will move to next step in {timeLeft} seconds</div>
        }
            
    </>
    )
}

export default Error;