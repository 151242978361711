import React, {useEffect, useState} from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';

ChartJS.register(ArcElement, Tooltip, Legend);

export const dataset = {
  
  datasets: [
    {
      label: '# of Votes',
      data: [20, 30],
      backgroundColor: [
        'rgba(220,115,68,255)',
        'rgba(148,72,191,255)',
      ],
      borderColor: [
        'rgba(220,115,68,255)',
        'rgba(148,72,191,255)',
      ],
      hoverBackgroundColor:[
        'rgba(220,115,68,255)',
        'rgba(148,72,191,255)',
      ],
      borderWidth: 1,
    },
  ],
};

export function Chart3({data}) {
  const [dataList, setDataList] = useState(dataset);

  useEffect(() => {
    if(data != null && data.length != 0 && data != undefined){
      setDataList(data);
    }
  }, [data])

  return <Doughnut options={{
    cutout: '65%'
  }} data={dataList} />;
}
