import React, { useState, useEffect } from "react";
import { InputGroup, FormControl, Button, Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import 'bootstrap/dist/css/bootstrap.min.css';
import { faUser, faLock, faUserAlt, faUsers, faBookBookmark, faIdBadge, faCalendar } from "@fortawesome/free-solid-svg-icons";
import 'react-phone-number-input/style.css';
import PhoneInput from "react-phone-number-input";
import {getCountryCallingCode} from 'react-phone-number-input';
import { useHistory } from "react-router-dom";
import NewCustomer from "../splits/NewCustomer";
import IndividualCustomer from "../splits/InvidualCustomer";
import CorporateCustomer from "../splits/CorporateCustomer";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import api from '../../../services/api';
import Success from "../../common/alerts/success";
import OtpConfirm from "./otpConfirm";
import OtpConfirmCop from "./otpConfirmCop";


const ApplicationForm = ({loading}) => {
    const history = useHistory();
    const [DivSwift, setDivSwift] = useState(2);
    const [existsState, setExistsState] = useState(0);
    const [formState, setFormState] = useState(1);
    const [customMsg, setCustomMsg] = useState('');
    const [openOtpModel, setOpenOtpModel] = useState(false);
    const [openOtpModelCop, setOpenOtpModelCop] = useState(false);
    const [otpModelDataCop, setOtpModelDataCop] = useState([]);
    const [otpModelData, setOtpModelData] = useState([]);
    const [strength, setStrength] = useState(0);

    //validation
    const validation = Yup.object({
        type : Yup.string().required("Required"),
        idnumber : Yup.string().required("Required") 
        .when(['type'], (type, schema) => {
            return type == 'nic'?schema.max(12, 'Invalid Identification Number').min(10, 'Invalid Identification Number'):schema.max(20)
        }),
        dob : Yup.string().required("Required"),
        title : Yup.string().required("Required"),
        initials : Yup.string().required("Required"),
        firstname : Yup.string().required("Required"),
        lastname : Yup.string().required("Required"),
        terms:Yup.string().required("Required"),
    });

    //validation invidula form
    const validationInvi = Yup.object({
        type : Yup.string().required("Required"),
        idnumber : Yup.string().required("Required") 
        .when(['type'], (type, schema) => {
            return type == 'nic'?schema.max(12, 'Invalid Identification Number').min(10, 'Invalid Identification Number'):(type == 'passport'?schema.max(20):schema.max(50))
        }),
        fullname : Yup.string().required("Required"),
        registration : Yup.string().required("Required"),
        terms:Yup.string().required("Required"),
        password:Yup.string().required("Required"),
        repassword: Yup.string().required('Required').oneOf([Yup.ref('password'), null], 'Passwords must match')
    });

     //validation coporate form
     const validationCop = Yup.object({
        registration: Yup.string().required("Required"),
        companyname : Yup.string().required("Required"),
        companyregistration : Yup.string().required("Required"),
        // personname : Yup.string().required("Required"),
        // designation : Yup.string().required("Required"),
        // email : Yup.string().required("Required").email('Invalid Email Address'),
        // mobile : Yup.string().required("Required"),
        // terms : Yup.string().required("Required"),
        password:Yup.string().required("Required"),
        repassword: Yup.string().required('Required').oneOf([Yup.ref('password'), null], 'Passwords must match')
    });

    //formkin form submition
    const formikApplication = useFormik({
        initialValues: {
          type: 'nic',
          idnumber: '',
          dob: '',
          title:'Mr:',
          initials:'',
          firstname:'',
          lastname:'',
          terms:'',
        },
        validateOnChange:false,
        validateOnBlur:false,
        validationSchema: validation,
        // Login Request
        onSubmit: async(values) => {
            var formData ={
                type :  values.type,
                ID : values.idnumber,
                TITLE :values.title,
                INITIALS:values.initials,
                FIRST_NAME : values.firstname, 
                SURNAME :values.lastname,
                DATE_OF_BIRTH:values.dob,
            }
            try{
                const data = await api.postRequest('application/validate', formData)
                if(data.status == 200){
                    if(data.data.status == 'success'){
                        setCustomMsg('');
                        history.push({
                            pathname: '/bankdetails',
                            state: { formData: formData }
                        });
                    }else{
                        var erros = data.data.errors;
                        var first = Object.keys(erros)[0]
                        setCustomMsg(erros[first][0]);
                    }
                }else{
                    setCustomMsg('Something Went Wrong');
                }
            }catch(error){
                setCustomMsg('Something Went Wrong');
            }
        },
    });

      //formkin form submition Invidual existsting
      const formikApplicationInvi = useFormik({
        initialValues: {
          type: 'nic',
          idnumber: '',
          fullname:'',
          registration:'',
          remark:'',
          terms:'',
          password:'',
          repassword:'',
        },
        validateOnChange:false,
        validateOnBlur:false,
        validationSchema: validationInvi,
        // Login Request
        onSubmit: async(values) => {
            var formData ={
                type :  values.type,
                ID : values.idnumber,
                UNIT_HOLDER_REG_NO : values.registration,
              }
            try{
                if(strength > 1){
                    const data = await api.postRequest('existing/individual/validation', formData)
                    if(data.status == 200){
                        if(data.data.status == 'success'){
                            setOtpModelData(data?.data?.profile);
                            setOpenOtpModel(true);
                            setCustomMsg('');
                            // setExistsState(3);
                        }else{
                            var erros = data.data.errors;
                            var first = Object.keys(erros)[0]
                            setCustomMsg(erros[first][0]);
                        }
                    }else{
                        setCustomMsg('Something Went Wrong');
                    }
                }
            }catch(error){
                setCustomMsg('Something Went Wrong');
            }
        },
    });

     //formkin form submition Invidual existsting
     const formikApplicationCop = useFormik({
        initialValues: {
          registration:'',
          companyname: '',
          companyregistration: '',
          accountnumber:'',
          personname:'',
          designation:'',
          email:'',
          mobile:'',
          terms:'',
          password:'',
          repassword:'',
        },
        validateOnChange:false,
        validateOnBlur:false,
        validationSchema: validationCop,
        // Login Request
        onSubmit: async(values) => {

            var formData ={
                UNIT_HOLDER_REG_NO : values.registration,
                COMP_REG_NO:values.companyregistration
                // company_name:values.companyname,
                // company_registration_no :  values.companyregistration,
                // contact_person_name : values.personname,
                // contact_person_designation: values.designation,
                // contact_person_email : values.email,
                // contact_person_mobile: values.mobile
              }
            try{
                loading(true);
                if(strength > 1){
                    const data = await api.postRequest('existing/corporate/validation', formData)
                    if(data.status == 200){
                        if(data.data.status == 'success'){
                            setOtpModelDataCop(data?.data?.profile);
                            setOpenOtpModelCop(true);
                            setCustomMsg('');
                            // setExistsState(3);
                        }else{
                            var erros = data.data.errors;
                            var first = Object.keys(erros)[0]
                            setCustomMsg(erros[first][0]);
                        }
                    }else{
                        setCustomMsg('Something Went Wrong');
                    }
                }
                loading(false);
            }catch(error){
                loading(false);
                setCustomMsg('Something Went Wrong');
            }
        },
    });

    //hande Custom Error Message
    const setErrorMessage = (e) => {
        setCustomMsg(e);
    }

    //when page load
    useEffect(() => {
        setExistsState(0);
    }, [])

    //hanlde form tab state
    const handleFormState = (e) => {
        setFormState(e);
    }
    
    //handle cusotmer exists or new
    const handleCustomerSelect = (id) => {
        setCustomMsg('');
        setExistsState(id);
    }

    //confirm button click
    const handleApplication = async() => {
        if(DivSwift == 1){
            formikApplication.handleSubmit();
        }else if(DivSwift == 2){
            if(existsState == 1){
                formikApplicationInvi.handleSubmit();
            }else if(existsState == 2){
                formikApplicationCop.handleSubmit();
            }else{
                history.push('/');
            }
        }
    }

    const handleOnHideModel = () => {
        setOpenOtpModel(false);
    }

    const handleOnHideCopModel = () => {
        setOpenOtpModelCop(false);
    }

    return(
        <>
        <div className="col-md-7 animate__animated animate__fadeIn">
            <div className="login-card">
                <div className="panel-head">
                    <h4>Application</h4>
                    <ul class="animated_tab">
                        {/* <li className={DivSwift == 1?'active':''} onClick={(e) => {
                            setExistsState(1); 
                            setDivSwift(1);
                            setCustomMsg('');
                        }}><a className="tab-item" data-toggle="tab"><FontAwesomeIcon className="login-icon" icon={faUserAlt}/>New Customer</a></li> */}
                        <li className={DivSwift == 2?'active':''} onClick={(e) =>{
                            setDivSwift(2)
                            setExistsState(0);
                            setCustomMsg('');
                        }}><a className="tab-item" data-toggle="tab"><FontAwesomeIcon className="login-icon" icon={faUsers}/>Existing Customer</a></li>
                    </ul>
                </div>
                {DivSwift == 1 || existsState > 0?
                <hr style={{paddingTop: 0, marginTop:0}}/>
                :''
                }
                <div style={{marginTop: DivSwift == 1 || existsState > 0?20:0}}>
                {
                
                    DivSwift == 1?
                        <NewCustomer formik={formikApplication} state={formState} setState={handleFormState} msg={customMsg} setMsg={setErrorMessage}/>
                    :
                    (existsState == 0?
                    <div className="">
                            <div className="overlay-quo">
                                <h1>Are you</h1>
                                <h1 className="animate__animated animate__heartBeat">?</h1>
                            </div>
                            <div className="overlay-selection" style={{marginTop:80}}> 
                                <span onClick={(e) => handleCustomerSelect(1)} className="overlay-link" style={{marginRight:50}}>An individual customer</span>
                                <span onClick={(e) => handleCustomerSelect(2)} className="overlay-link" style={{marginLeft:50}}>A corporate customer</span>
                            </div>
                    </div>
                    :(existsState == 1?
                        <IndividualCustomer 
                            formik={formikApplicationInvi} 
                            state={formState} 
                            setState={handleFormState} 
                            msg={customMsg} 
                            setMsg={setErrorMessage}
                            strength={strength}
                            setStrength={setStrength}
                        />
                        :
                        (
                            existsState == 3?
                                <div style={{marginTop:80}}>
                                    <Success message="Registration Successful!" closeTime={10} redirect="/"/>
                                </div>
                            :
                            <CorporateCustomer 
                            formik={formikApplicationCop} 
                            state={formState} 
                            setState={handleFormState} 
                            msg={customMsg} 
                            strength={strength}
                            setStrength={setStrength}
                            setMsg={setErrorMessage}/>
                            
                        )
                        
                    )
                    )}
                </div>
                {customMsg != '' && 
                    <div className="col-md-12">
                    <span className="srv-validation-message"><span className="error-palate">*&nbsp;{customMsg}</span></span>
                    </div>
                }  
                <div className="text-right" style={{display:'flex', flexDirection:'row', justifyContent:'end', alignItems:'center', marginTop:20}}>
                    <button type="submit" className="btn btn-primary card-button login-button" onClick={handleApplication} disabled={existsState == 0}>
                       {existsState == 3?'Login':'Continue'} 
                    </button>
                </div>
            </div>
        </div>
        <OtpConfirm show={openOtpModel} onHide={handleOnHideModel} data={otpModelData} setData={setOtpModelData} dataset={formikApplicationInvi.values}/>
        <OtpConfirmCop show={openOtpModelCop} onHide={handleOnHideCopModel} data={otpModelDataCop} setData={setOtpModelData} dataset={formikApplicationCop.values}/>
        </>
    )
}

export default ApplicationForm;