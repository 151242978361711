import React, { useEffect } from 'react';
import TopNav from '../../components/common/topnav';
import Form from '../../components/reset/form';
import Image from '../../assets/images/login-front.png';

const Reset = () => {
    document.body.classList.add('body-login')
    useEffect(() => {
        document.body.classList.remove('body-register')
        document.body.classList.add('body-login')
    }, [])
    

    return (
        <>
        <div className="container">
            <TopNav counter={false} downer={false} signOutMobile={false}/>
            <div className='main-wrap' style={{minHeight:'100vh'}}>
                <div className='row'>
                    <div className='col-md-6 mbrs'>
                        <div className='row justify-content-center'>
                            <img src={Image} className='login-front-img'/>
                        </div>
                    </div>
                    <div className='col-md-6'>
                        <div className='row justify-content-end'>
                            <Form/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}

export default Reset;