import React, {useState,useEffect} from "react";
import { Button, Modal, Form, Col, Row } from 'react-bootstrap';
import { faBank } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Stepper from 'react-stepper-horizontal';
import 'animate.css';
import Success from "../../../common/alerts/success";
import BankMethod from "../contents/method";
import BuyForm from "../contents/buyForm";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import api from "../../../../services/api";
import Error from "../../../common/alerts/error";


const ActionModel = ({onHide, show, fundList, acountList, defaultFund=null}) =>  {
    const steps = 3; //steps without success window
    const [step, setStep] = useState(0);
    const [buyValues, setBuyValues] = useState([]);
    const [file, setFile] = useState([]);
    const [details, setDetails] = useState({});
    const [manual, setManual] = useState(false);
    const [customError, setCustomError] = useState(null);
    
    
    useEffect(() => {
     if(defaultFund != null){
        formikBuy.setFieldValue('fund', defaultFund);
     }
    }, [defaultFund])
    
    //validations
  const validationBuy = Yup.object({
      fund : Yup.string().required("Required"),
      account : Yup.string().required("Required"),
      amount : Yup.string().required("Required").test('min-amount', 'The minimum amount required is 1000 LKR.', (value, context) => {
        if(Number(formikBuy.values.amount) < 1000){
          return false;
        }else{
          return true;
        }
      }),
  });

  const formikBuy = useFormik({
      initialValues: {
        fund: defaultFund == null?'':defaultFund,
        account: '',
        amount: '',
      },
      validateOnChange:false,
      validateOnBlur:false,
      validationSchema: validationBuy,
      // Login Request
      onSubmit: async(values) => {
        setBuyValues(values);
        let detail = {
          fund:fundList.filter((value) => value.FUND_CODE == values.fund)[0].FUND_NAME,
          account:values.account == '1'?'New':values.account,
          amount:values.amount
        }
        setDetails(detail);
        setStep(step+1);
      },
  });

  
  //validations
  const validationBank = Yup.object({
      bankslip : Yup.array().min(1, "Required"),
      terms : Yup.string().required("Required"),
  });

  const formikBank = useFormik({
      initialValues: {
        bankslip: [],
        terms: '',
      },
      validateOnChange:false,
      validateOnBlur:false,
      validationSchema: validationBank,
      // Login Request
      onSubmit: async(values, {resetForm}) => {
        setCustomError(null);
        setFile(values.bankslip);
        try{
          const fd = new FormData();
          fd.append('FUND_CODE', buyValues.fund);
          if(buyValues.account != '1'){
            fd.append('ACCOUNT_NO', buyValues.account);
          }          
          fd.append('AMOUNT', buyValues.amount);
          fd.append('payment_type', 1);
          let docs = [];
          values.bankslip.length > 0 && Array.from(values.bankslip).forEach(file => {
            fd.append('documents[]', file);
          })
          fd.append('reference_code', "");

          let data = await api.postRequest('buy', fd);
          if(data.status == 200){
            formikBank.values.bankslip.splice(0, formikBank.values.bankslip.length);
            if(data.data.status == 'success'){
              setStep(2);
            }else{
              if(data?.errors != undefined && data?.errors?.Amount?.[0] != undefined 
                && data?.errors?.Amount?.[0] != null){
                  setCustomError(data?.errors?.Amount?.[0]);
                  return false;
                }
              setStep(3);
            }
          }else{
            if(data?.errors != undefined && data?.errors?.Amount?.[0] != undefined 
              && data?.errors?.Amount?.[0] != null){
                setCustomError(data?.errors?.Amount?.[0]);
                return false;
              }
            setStep(3);
          }
          formikBuy.resetForm();
          resetForm();
        }catch(error){
        if(error.response?.data?.errors != undefined && error.response?.data?.errors?.Amount?.[0] != undefined 
          && error.response?.data?.errors?.Amount?.[0] != null){
            setCustomError(error.response?.data?.errors?.Amount?.[0]);
            return false;
          }
          formikBuy.resetForm();
          resetForm();
          setStep(3);
        }
      },
  });

    //modal previois button action
    const onchnagePrevious = () => {
    if(step > 0){
      const set = step-1;
      setStep(set);
    }
  }


  //change step and submit
  const onChangeStep = () => {
    if(step == 0){
        formikBuy.handleSubmit();
    }else if(step == 1){
        formikBank.handleSubmit();
    }else{
      setStep(0);
      onHide(true);
    }
  }

  useEffect(() => {
    setManual(!manual);
  }, [])


    return (
      <Modal
        show={show}
        onHide={onHide}
        backdrop="static"
        keyboard={false}
        size="md"
        style={{fontSize:13}}
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header style={{padding:'0.3rem 0.3rem'}}>
          <Modal.Title id="contained-modal-title-vcenter">
            <FontAwesomeIcon icon={faBank} style={{paddingLeft:'8px'}}/>
            &nbsp;
            INVEST
          </Modal.Title>
          <div className="steps">
            <Stepper 
              steps={[
                {title: ''}, 
                {title: ''}, 
                {title: ''}, 
              ]} 
              activeStep={step}
              size={15}
              circleFontSize={0}
              completeColor='#5cb85c'
              activeColor={1 == 1?'#5096FF':'#d9534f'}
              defaultBorderWidth={2}
              />
          </div>
        </Modal.Header>
        <Modal.Body style={{ paddingBottom:0}}>
          {
            step == 0?
              <BuyForm formik={formikBuy} fundList={fundList} acountList={acountList}/>
            :(
              step == 1?
              <div className="animate__animated animate__fadeIn">
                <BankMethod formik={formikBank} details={details} customError={customError}/>
              </div>
              : (
                step == 2?
                <div className="animate__animated animate__fadeIn">
                  <Success closeTime={10} close={onHide} step={setStep}/>
                </div>
                :
                <div className="animate__animated animate__fadeIn">
                  <Error closeTime={10} close={onHide} step={setStep}/>
                </div>
              )
            )
          }
              
        </Modal.Body>
        <Modal.Footer style={{padding:'0.3rem 0.3rem', paddingLeft:'8px',paddingRight:'8px', paddingBottom:8, display:'flex', justifyContent:step > 1?'end':'space-between', borderTop:'none'}}>
          {
            (
            step == 0?
              <Button className="btn btn-danger btn-red" style={{width:80, display:step > 1?'none':''}} 
              onClick={(e) =>{
                onHide();
                formikBuy.resetForm();
                formikBank.resetForm();
                formikBank.values.bankslip.splice(0, formikBank.values.bankslip.length);
              }
              }>Cancel</Button>
              :
              <Button className="btn btn-danger btn-red" style={{width:80, display:step > 1?'none':''}} onClick={onchnagePrevious}>Previous</Button>
            )
          }
        
          <Button className="btn-btn-success btn-green" style={{width:80}} onClick={onChangeStep}>Continue</Button>
        </Modal.Footer>
      </Modal>
    );
  }

  export default ActionModel;