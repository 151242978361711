import React from 'react';
import { Form, FormControl } from 'react-bootstrap';

const Document = ({ type, formik }) => {

    return(
        <div className="uploads-content">
        <div className="col-md-12 animate__animated animate__fadeIn">
            <div className="row">
                <div className="col-md-4">
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <Form.Label className="input-label">Application</Form.Label>
                        <FormControl
                            type="file"
                            size="sm"
                            name="application"
                            isInvalid={Boolean(formik.errors.application)}
                            onChange={(e) => formik.setFieldValue('application', e.target.files[0])}
                            aria-label="application"
                            aria-describedby="basic-addon1"
                        />
                        {formik.errors.application && 
                        <div className="col-md-12">
                        <span className="srv-validation-message"><span className="error-palate">*&nbsp;{formik.errors.application}</span></span>
                        </div>
                        }  
                    </Form.Group>
                </div>
            </div>
            <div className="row">
                {/* <div className="col-md-4">
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                    <Form.Label className="input-label">Identification</Form.Label>
                    <Form.Select size="sm" aria-label="Default select example">
                        <option value="1">NIC</option>
                        <option value="2">Passport</option>
                        <option value="3">Driving Licence</option>
                    </Form.Select>
                </Form.Group>
                </div> */}
                {
                    type == 'nic'?
                    <>
                    <div className="col-md-4">
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <Form.Label className="input-label">Nic Front</Form.Label>
                        <FormControl
                            type="file"
                            size="sm"
                            name="nicf"
                            onChange={(e) => formik.setFieldValue('nicf', e.target.files[0])}
                            isInvalid={Boolean(formik.errors.nicf)}
                            aria-label="application"
                            aria-describedby="basic-addon1"
                        />
                        {formik.errors.nicf && 
                        <div className="col-md-12">
                        <span className="srv-validation-message"><span className="error-palate">*&nbsp;{formik.errors.nicf}</span></span>
                        </div>
                        }  
                    </Form.Group>
                </div>
                <div className="col-md-4">
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <Form.Label className="input-label">Nic Back</Form.Label>
                        <FormControl
                            type="file"
                            size="sm"
                            name='nicb'
                            onChange={(e) => formik.setFieldValue('nicb', e.target.files[0])}
                            isInvalid={Boolean(formik.errors.nicb)}
                            aria-label="application"
                            aria-describedby="basic-addon1"
                        />
                        {formik.errors.nicb && 
                        <div className="col-md-12">
                        <span className="srv-validation-message"><span className="error-palate">*&nbsp;{formik.errors.nicb}</span></span>
                        </div>
                        }  
                    </Form.Group>
                </div>
                </>
                :
                <div className="col-md-4">
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                    <Form.Label className="input-label">Passport</Form.Label>
                    <FormControl
                        type="file"
                        size="sm"
                        name="passport"
                        onChange={(e) => formik.setFieldValue('passport', e.target.files[0])}
                        isInvalid={Boolean(formik.errors.passport)}
                        aria-label="application"
                        aria-describedby="basic-addon1"
                    />
                    {formik.errors.passport && 
                    <div className="col-md-12">
                    <span className="srv-validation-message"><span className="error-palate">*&nbsp;{formik.errors.passport}</span></span>
                    </div>
                    }  
                </Form.Group>
                </div>
                    
                }
               
            </div>
            <div className="row">
                <div className="col-md-4">
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <Form.Label className="input-label">Address Verification</Form.Label>
                        <FormControl
                            type="file"
                            size="sm"
                            name="verification"
                            onChange={(e) => formik.setFieldValue('verification', e.target.files[0])}
                            isInvalid={Boolean(formik.errors.verification)}
                            aria-label="addressverify"
                            aria-describedby="basic-addon1"
                        />
                        {formik.errors.verification && 
                        <div className="col-md-12">
                        <span className="srv-validation-message"><span className="error-palate">*&nbsp;{formik.errors.verification}</span></span>
                        </div>
                        } 
                    </Form.Group>
                </div>
                <div className="col-md-4">
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <Form.Label className="input-label">Investment Form</Form.Label>
                        <FormControl
                            type="file"
                            size="sm"
                            name='investment'
                            onChange={(e) => formik.setFieldValue('investment', e.target.files[0])}
                            isInvalid={Boolean(formik.errors.investment)}
                            aria-label="investment"
                            aria-describedby="basic-addon1"
                        />
                        {formik.errors.investment && 
                        <div className="col-md-12">
                        <span className="srv-validation-message"><span className="error-palate">*&nbsp;{formik.errors.investment}</span></span>
                        </div>
                        } 
                    </Form.Group>
                </div>
                <div className="col-md-4">
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <Form.Label className="input-label">Nomination Form</Form.Label>
                        <FormControl
                            type="file"
                            name='nomination'
                            size="sm"
                            aria-label="nomination"
                            onChange={(e) => formik.setFieldValue('nomination', e.target.files[0])}
                            isInvalid={Boolean(formik.errors.nomination)}
                            aria-describedby="basic-addon1"
                        />
                        {formik.errors.nomination && 
                        <div className="col-md-12">
                        <span className="srv-validation-message"><span className="error-palate">*&nbsp;{formik.errors.nomination}</span></span>
                        </div>
                        } 
                    </Form.Group>
                </div>
            </div>
        </div>
    </div>
    )
}

export default Document;