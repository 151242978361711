import React, {useState, useEffect} from 'react'
import { Button, Form, FormControl, InputGroup, ListGroup } from 'react-bootstrap'
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload, faTrash } from '@fortawesome/free-solid-svg-icons';
import api from '../../../services/api';
import {NumberFormatInputWithSuffix} from '../../../helpers';
import TermsAndCon from '../models/termsAndCon';

export default function Nomination({nominees, setNominees, nomineesError, nominationDeclare, setNominationDeclare, nominationDeclareInvalid, setNominationDeclareInvalid, nominationRef, name, ownerData, nomineeTerms, nomineeTermsError, setNomineeTerms, setNomineeTermsError}) {

    const [openTermsAndCondition, setOpenTermsAndCondition] = useState(false);
    const [address, setAddress] = useState(null);

    const validation = Yup.object({
        nominee_name:Yup.string().required("Name of the nominee is required"),
        nominee_address:Yup.string().required("Address of nominee is required"),
        percentage:Yup.string().required("Percentage is required"),
    });

    const fileToBase64 = (file) => {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onloadend = () => {
            resolve(reader.result.split(',')[1]);
          };
          reader.onerror = (error) => {
            reject(error);
          };
          reader.readAsDataURL(file);
        });
    };

    //open termsa and condition model
    const handleTermsAndCon = () => {
        setOpenTermsAndCondition(true);
    }

    useEffect(() => {
        makeAddress(ownerData);
    }, [ownerData])

    const makeAddress = (ownerData) => {
        let address = null;
        address = ownerData?.street;
        if(ownerData?.town != null && ownerData?.town != ''){
            address+=', '+ownerData?.town;
        }
        if(ownerData?.country != null && ownerData?.country != ''){
            address+=', '+ownerData?.country;
        }
        setAddress(address);
    }

    //formkin form submition Invidual existsting
    const formik = useFormik({
        initialValues: {
            nominee_name:'',
            nominee_address:'',
            nominee_id_type:'nic',
            nominee_id_number:'',
            nominee_nic_front:'',
            nominee_nic_back:'',
            nominee_passport:'',
            nominee_other_doc:'',
            percentage:'',
        },
        validateOnChange:false,
        validateOnBlur:false,
        validationSchema: validation,
        // Login Request
        onSubmit: async(values) => {
            let arr = nominees;
            if(Number(values.percentage) > 100){
              formik.setFieldError('percentage', 'Invalid Percentage');
              return false;
            }
            if(arr.length > 0){
              let previous_pres = arr[0].percentage;
              if((Number(previous_pres) + Number(values.percentage)) > 100){
                formik.setFieldError('percentage', 'Invalid Percentage');
                return false;
              }
            }
            let nominee_nic_front = '';
            let nominee_nic_back = '';
            let nominee_passport = '';
            let nominee_other_doc = '';
            if(values.nominee_id_type == 'nic'){
              if(values.nominee_nic_front != ''){
                nominee_nic_front = await fileToBase64(values.nominee_nic_front);
              }
              if(values.nominee_nic_back != ''){
                nominee_nic_back = await fileToBase64(values.nominee_nic_back);
              }
            }else if(values.nominee_id_type == 'passport' && values.nominee_passport != ''){
                nominee_passport = await fileToBase64(values.nominee_passport);
            }else if(values.nominee_id_type == 'other_no' && values.nominee_other_doc != ''){
                nominee_other_doc = await fileToBase64(values.nominee_other_doc);
            }
            arr.push({
                "nominee_name":values.nominee_name,
                "nominee_address":values.nominee_address,
                "nominee_id_type":values.nominee_id_type,
                "nominee_id_number":values.nominee_id_number,
                "nominee_nic_front":nominee_nic_front,
                "nominee_nic_back":nominee_nic_back,
                "nominee_passport":nominee_passport,
                "nominee_other_doc":nominee_other_doc,
                "percentage":values.percentage
            })
            formik.handleReset();
            document.getElementById('nominee_nic_front').value= null;
            document.getElementById('nominee_nic_back').value= null;
            document.getElementById('nominee_passport').value= null;
            document.getElementById('nominee_other_doc').value= null;
            setNominees(arr);
        },
    });


    const handleDeleteNominee = (index) => {
      let arr = nominees;
      arr.splice(index, 1);
      setNominees([...arr]);
  }

    function downloadFile(file) {
        const blob = new Blob([file], { type: file.type });
        const a = document.createElement('a');
        a.href = window.URL.createObjectURL(blob);
        a.download = file.name;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    }

    const handleDownloadPassbook = (passbook) => {
        downloadFile(passbook);
    }

    const handleDeclareEach = (e) => {
        if(e.target.checked == true){
            // setNominationDeclare(true)
            setOpenTermsAndCondition(true);
        }else{
            // setNominationDeclare(false)
            setNominationDeclare(false);
            setOpenTermsAndCondition(false);
        }
    }

    const handleNomineeTandC = () => {
        setNominationDeclare(true);
        setOpenTermsAndCondition(false);
    }

    const handleCancelNomineeTandC = () => {
        setNominationDeclare(false);
        setOpenTermsAndCondition(false);
    }

  return (
    <div className='col-md-12 animate__animated animate__fadeIn'>
        <p style={{fontWeight:700}}>Nomination Details</p>
        <hr/>
        <div className='row' style={{minHeight:'60vh'}}>
            <div className='col-md-6'>
                  <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                      <Form.Label className="input-label">Full Name of the nominee <span class="required-field"></span></Form.Label>
                      <FormControl
                          size="sm"
                          aria-label="nominee_name"
                          aria-describedby="basic-addon1"
                          name="nominee_name"
                          autoComplete="off"
                          isInvalid={Boolean(formik.errors.nominee_name)}
                          onChange={formik.handleChange}
                          value={formik.values.nominee_name}
                      />
                      {formik.errors.nominee_name && 
                          <div className="col-md-12">
                          <span className="srv-validation-message"><span className="error-palate">*&nbsp;{formik.errors.nominee_name}</span></span>
                          </div>
                      }  
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                      <Form.Label className="input-label">Address of nominee <span class="required-field"></span></Form.Label>
                      <FormControl
                          size="sm"
                          aria-label="nominee_address"
                          aria-describedby="basic-addon1"
                          name="nominee_address"
                          as='textarea'
                          autoComplete="off"
                          rows={3}
                          style={{resize:'none'}}
                          isInvalid={Boolean(formik.errors.nominee_address)}
                          onChange={formik.handleChange}
                          value={formik.values.nominee_address}
                      />
                      {formik.errors.nominee_address && 
                          <div className="col-md-12">
                          <span className="srv-validation-message"><span className="error-palate">*&nbsp;{formik.errors.nominee_address}</span></span>
                          </div>
                      }  
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                      <Form.Label className="input-label">NIC / Passport No.</Form.Label>
                      <InputGroup>
                        <Form.Select       
                            isInvalid={Boolean(formik.errors.nominee_id_type)}
                            onChange={formik.handleChange}
                            value={formik.values.nominee_id_type}
                            name="nominee_id_type" 
                            style={{maxWidth:'30%'}}
                            autoComplete="off"
                            size="sm" 
                            aria-label="Default select example"
                        >
                            <option value="nic">NIC</option>
                            <option value="passport">Passport</option>
                            <option value="other_no">Other</option>
                        </Form.Select>
                      <FormControl
                          size="sm"
                          aria-label="nominee_id_number"
                          aria-describedby="basic-addon1"
                          name="nominee_id_number"
                          autoComplete="off"
                          className='text-uppercase'
                          isInvalid={Boolean(formik.errors.nominee_id_number)}
                          onChange={formik.handleChange}
                          value={formik.values.nominee_id_number}
                      />
                      </InputGroup>
                      {formik.errors.nominee_id_number && 
                          <div className="col-md-12">
                          <span className="srv-validation-message"><span className="error-palate">*&nbsp;{formik.errors.nominee_id_number}</span></span>
                          </div>
                      }  
                  </Form.Group>
                  {
                      formik.values.nominee_id_type == 'nic'?
                          <div className='row'>
                              <div className='col-md-6'>
                                  <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                      <Form.Label className="input-label">Front Side of NIC</Form.Label>
                                      <FormControl
                                          size="sm"
                                          type='file'
                                          aria-label="nominee_nic_front"
                                          aria-describedby="basic-addon1"
                                          id="nominee_nic_front"
                                          name="nominee_nic_front"
                                          autoComplete="off"
                                          isInvalid={Boolean(formik.errors.nominee_nic_front)}
                                          onChange={(e) => formik.setFieldValue('nominee_nic_front', e.target.files[0])}
                                      />
                                      {formik.errors.nominee_nic_front && 
                                          <div className="col-md-12">
                                          <span className="srv-validation-message"><span className="error-palate">*&nbsp;{formik.errors.nominee_nic_front}</span></span>
                                          </div>
                                      }  
                                  </Form.Group>
                              </div>
                              <div className='col-md-6'>
                                  <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                      <Form.Label className="input-label">Back Side of NIC</Form.Label>
                                      <FormControl
                                          size="sm"
                                          type='file'
                                          aria-label="nominee_nic_back"
                                          aria-describedby="basic-addon1"
                                          id="nominee_nic_back"
                                          name="nominee_nic_back"
                                          autoComplete="off"
                                          isInvalid={Boolean(formik.errors.nominee_nic_back)}
                                          onChange={(e) => formik.setFieldValue('nominee_nic_back', e.target.files[0])}
                                      />
                                      {formik.errors.nominee_nic_back && 
                                          <div className="col-md-12">
                                          <span className="srv-validation-message"><span className="error-palate">*&nbsp;{formik.errors.nominee_nic_back}</span></span>
                                          </div>
                                      }  
                                  </Form.Group>
                              </div>
                          </div>
                          :(
                              formik.values.nominee_id_type == 'passport'?
                              <div className='row'>
                                  <div className='col-md-6'>
                                      <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                          <Form.Label className="input-label">Passport</Form.Label>
                                          <FormControl
                                              size="sm"
                                              type='file'
                                              aria-label="nominee_passport"
                                              aria-describedby="basic-addon1"
                                              id="nominee_passport"
                                              name="nominee_passport"
                                              autoComplete="off"
                                              isInvalid={Boolean(formik.errors.nominee_passport)}
                                              onChange={(e) => formik.setFieldValue('nominee_passport', e.target.files[0])}
                                          />
                                          {formik.errors.nominee_passport && 
                                              <div className="col-md-12">
                                              <span className="srv-validation-message"><span className="error-palate">*&nbsp;{formik.errors.nominee_passport}</span></span>
                                              </div>
                                          }  
                                      </Form.Group>
                                  </div>
                              </div>
                          :
                          <div className='row'>
                              <div className='col-md-6'>
                                  <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                      <Form.Label className="input-label">Other Document</Form.Label>
                                      <FormControl
                                          size="sm"
                                          type='file'
                                          aria-label="nominee_other_doc"
                                          aria-describedby="basic-addon1"
                                          id="nominee_other_doc"
                                          name="nominee_other_doc"
                                          autoComplete="off"
                                          isInvalid={Boolean(formik.errors.nominee_other_doc)}
                                          onChange={(e) => formik.setFieldValue('nominee_other_doc', e.target.files[0])}
                                      />
                                      {formik.errors.nominee_other_doc && 
                                          <div className="col-md-12">
                                          <span className="srv-validation-message"><span className="error-palate">*&nbsp;{formik.errors.nominee_other_doc}</span></span>
                                          </div>
                                      }  
                                  </Form.Group>
                              </div>
                          </div>
                          )
                    }
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                      <Form.Label className="input-label">Payment percentage <span class="required-field"></span></Form.Label>
                      <NumberFormatInputWithSuffix
                          formik={formik}
                          name="percentage"
                          onValueChange={(e) => formik.setFieldValue('percentage', e.target.value)}
                          thousandSeparator={false}
                          suffix="%"
                      />
                      {formik.errors.percentage && 
                          <div className="col-md-12">
                          <span className="srv-validation-message"><span className="error-palate">*&nbsp;{formik.errors.percentage}</span></span>
                          </div>
                      }  
                    </Form.Group>
                    <Form.Group className="mb-2" style={{display:'flex', flexDirection:'row', justifyContent:'end'}} controlId="exampleForm.ControlInput1">
                      <Button disabled={nominees.length >= 2} onClick={formik.handleSubmit} variant='success' size='sm' style={{width:150}}>Add Nominee</Button>
                    </Form.Group>
            </div>
            <div className='col-md-6 mt-3'>
                <div className="col-md-12">
                    <span style={{fontSize:12, fontWeight:600}}>You are only permitted to add a maximum of two nominees.</span>
                </div>
                {
                  nomineesError != null?
                  <div className="col-md-12">
                  <span className="srv-validation-message"><span className="error-palate">*&nbsp;{nomineesError}</span></span>
                  </div>
                  :null
                }
                    
                <ListGroup as="ol" style={{fontSize:15}} numbered>
                    {
                        nominees?.length > 0 && nominees?.map((val, index) => {
                            return (
                                <ListGroup.Item>
                                    <div style={{display:'flex', flexDirection:'row', justifyContent:'space-between'}}>
                                        <div style={{display:'flex', flexDirection:'column'}}>
                                            <span style={{fontSize:15, fontWeight:600}}>{val.nominee_name+' ('+val.percentage+'%)'}</span>
                                            <span style={{fontSize:12, fontWeight:600}}>{val.nominee_address}</span>
                                            {
                                              val?.nominee_id_type == 'nic' && val.nominee_id_number != ''?
                                              <span className='text-success' style={{fontSize:12, fontWeight:800}}>NIC No: {val.nominee_id_number}</span>
                                              :(
                                                val?.nominee_id_type == 'passport' && val.nominee_id_number != ''?
                                                <span className='text-success' style={{fontSize:12, fontWeight:800}}>Passport No: {val.nominee_id_number}</span>
                                                :
                                                (
                                                  val?.nominee_id_type == 'other_no' && val.nominee_id_number != ''?
                                                  <span className='text-success' style={{fontSize:12, fontWeight:800}}>Other No: {val.nominee_id_number}</span>
                                                  :null
                                                )
                                              )
                                            }
                                           
                                        </div>
                                        <div style={{display:'flex', alignItems:'center'}}>
                                            {
                                              val?.nominee_id_type == 'nic'?
                                                (val?.nominee_nic_front != ''?
                                                  <>
                                                  {
                                                    val?.nominee_nic_front != ''?
                                                      <FontAwesomeIcon title='Download NIC Front' onClick={() => handleDownloadPassbook(val?.nominee_nic_front)} style={{cursor:'pointer', marginRight:15}} className='text-primary' icon={faDownload}/>
                                                    :null
                                                  }
                                                  {
                                                     val?.nominee_nic_back != ''?
                                                     <FontAwesomeIcon title='Download NIC Back' onClick={() => handleDownloadPassbook(val?.nominee_nic_back)} style={{cursor:'pointer', marginRight:15}} className='text-primary' icon={faDownload}/>
                                                    :null
                                                  }
                                                  </>
                                                :null)
                                              :(
                                                val?.nominee_id_type == 'passport'?
                                                  <FontAwesomeIcon title='Download Passport' onClick={() => handleDownloadPassbook(val?.nominee_passport)} style={{cursor:'pointer', marginRight:15}} className='text-primary' icon={faDownload}/>
                                                :
                                                (val?.nominee_id_type == 'other_no'?
                                                 <FontAwesomeIcon title='Download Other Doc' onClick={() => handleDownloadPassbook(val?.nominee_other_doc)} style={{cursor:'pointer', marginRight:15}} className='text-primary' icon={faDownload}/>
                                                :
                                                null
                                                )

                                              )

                                            }
                                            {/* <FontAwesomeIcon title='Download Passbook' onClick={() => handleDownloadPassbook(val?.nominee_id_type == 'nic'?)} style={{cursor:'pointer', marginRight:15}} className='text-primary' icon={faDownload}/> */}
                                            <FontAwesomeIcon title='Delete Bank' onClick={() => handleDeleteNominee(index)} style={{cursor:'pointer'}} className='text-danger' icon={faTrash}/>
                                        </div>
                                    </div>
                                </ListGroup.Item>
                            )
                        })
                    }
                </ListGroup>
                {/* {
                    nominees.length > 0 ?
                    <div className='row'>
                    <div className='col-md-12' style={{display:'flex', flexDirection:'column', justifyContent:'center', marginTop:10}}>
                        <Form.Check
                            style={{fontSize:13, marginRight:20}}
                            size="md"
                            isInvalid={nomineeTermsError}
                            label={<span>Please carefully read and acknowledge the <span onClick={handleTermsAndCon} style={{color:'#187182', textDecoration:'underline', cursor:'pointer'}}>terms and conditions</span> for nominees</span>}
                            name="declare"
                            type={'checkbox'}
                            onClick={handleTermsAndCon}
                            id={`inline-terms-con`}
                            checked={nomineeTerms == true}
                        />
                    </div>
                </div>
                :null
                } */}
             
            </div>
        </div>
        {nominees.length > 0 ?
        <div className='row'>
            <div className='col-md-12' style={{display:'flex', flexDirection:'column', justifyContent:'center', marginTop:10}}>
                <Form.Check
                    style={{fontSize:13, marginRight:20}}
                    size="md"
                    isInvalid={nominationDeclareInvalid}
                    label={`I, ${name} hereby declare that the information given above is true and accurate to the best of my knowledge and confirm submitting same to Senfin Asset Management for the purposes of investing in one or more funds managed by same`}
                    name="declare"
                    type={'checkbox'}
                    onClick={handleDeclareEach}
                    id={`inline-declare`}
                    checked={nominationDeclare}
                />
            </div>
        </div>
        :null}
        <div ref={nominationRef}></div>
        <TermsAndCon show={openTermsAndCondition} onHide={handleCancelNomineeTandC} address={address} handleCancel={handleCancelNomineeTandC} handleAgree={handleNomineeTandC}/>
    </div>
  )
}
