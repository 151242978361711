import React, {useState} from 'react';
import TopNav from '../../components/common/topnav';
import ApplicationForm from '../../components/application/existing';
import Loader from '../../components/common/loader';

const Existing = () => {
    document.body.classList.add('body-register')

    const [loading, setLoading] = useState(false);

    const handleLoading = (e) => {
        setLoading(e);
    }

    return (
        <>
        <Loader active={loading}>
            <div className="container">
                <TopNav counter={false} downer={false} signOutMobile={false}/>
                <div className='main-wrap' style={{minHeight:'100vh'}}>
                    <div className='row justify-content-center'>
                        <ApplicationForm loading={handleLoading}/>
                    </div>
                </div>
            </div>
        </Loader>
        </>
    );
}

export default Existing;