import React, {useState, useEffect} from "react";
import { Button, Modal, Form, Col, Row } from 'react-bootstrap';
import { faUpload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import 'animate.css';
import { useHistory } from "react-router-dom";
import Error from "../alerts/error";
import Success from "../alerts/success";
import api from "../../../services/api";
import AvatarEditor from 'react-avatar-editor';
import { useRef } from "react";
import { Range } from 'react-range';
import { assetUrl } from "../../../Config";

const UploadModel = ({onHide, show, image, setImage}) =>  {
    const avatarRef = useRef(null);

    const [uploadedUrl, setUploadedUrl] = useState('');
    const [crop, setCrop] = useState();
    const [iscroped, setIsCroped] = useState(false);
    const [keepSelection, setKeepSelection] = useState(false);
    const [scale, setScale] = useState([1.2]);

    const hanldeUploaded = (e) => {
        let image = e.target.files[0];
        if(image != undefined && image != null){
            setUploadedUrl(URL.createObjectURL(e.target.files[0]));
        }else{
            setUploadedUrl('');
        }
    }

    const blobToFile = (theBlob, fileName) =>{
        theBlob.lastModifiedDate = new Date();
        theBlob.name = fileName;
        return theBlob;
    }

    const handleCrop = async() => {
        const canvas = avatarRef.current.getImage()
        canvas.toBlob(async function(blob) {
          const newImg = document.createElement('img');
          const file = blobToFile(blob, 'croped.jpg');
          let formData = new FormData();
          formData.append('image', file);
          const result = await api.postRequest('update/profile/image', formData);
            if(result.data.status == 'success'){
                let image = result.data.image;
                let user = JSON.parse(sessionStorage.getItem('user'));
                user.image = image;
                setImage(image);
                onHide();
            }
        });
    }

    return (
      <Modal
        show={show}
        onHide={onHide}
        backdrop="static"
        keyboard={false}
        size="md"
        style={{fontSize:13}}
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header style={{padding:'0.3rem 0.3rem'}}>
          <Modal.Title style={{fontSize:18}} id="contained-modal-title-vcenter">
            <FontAwesomeIcon icon={faUpload} style={{paddingLeft:'8px'}}/>
            &nbsp;
           UPLOAD IMAGE
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ paddingBottom:0, minHeight:250, textAlign:'center'}}>
        <Form.Group controlId="formFileSm" className="mb-3">
            <Form.Label>Upload your image here</Form.Label>
            <Form.Control type="file" onChange={hanldeUploaded} accept="image/*" size="sm" />
       </Form.Group>
       {
        uploadedUrl != '' && uploadedUrl != null?
        <div style={{textAlign:'center'}}>
        <AvatarEditor
            image={uploadedUrl}
            ref={avatarRef}
            width={150}
            height={150}
            border={50}
            color={[100, 100, 100, 0.3]} // RGBA
            scale={scale}
            rotate={0}
        />
        <Range
            className="center"
            step={0.1}
            min={0}
            max={5}
            values={scale}
            onChange={(values) => setScale(values)}
            renderTrack={({ props, children }) => (
            <div
                {...props}
                style={{
                ...props.style,
                height: '6px',
                width: '100%',
                backgroundColor: '#ccc'
                }}
            >
                {children}
            </div>
            )}
            renderThumb={({ props }) => (
            <div
                {...props}
                style={{
                ...props.style,
                height: '12px',
                width: '12px',
                backgroundColor: '#999'
                }}
            />
            )}
        />
        </div>
        :''
       }
        </Modal.Body>
        <Modal.Footer style={{padding:'0.3rem 0.3rem', paddingLeft:'8px',paddingRight:'8px', paddingBottom:8, display:'flex', justifyContent:'space-between', borderTop:'none'}}>
          <Button className="btn btn-danger btn-red" style={{width:80}} onClick={()=>onHide()}>Cancel</Button>
          <Button className="btn-btn-success btn-green" onClick={handleCrop}>{iscroped == true?'Finish Crop':'Upload'}</Button>
        </Modal.Footer>
      </Modal>
    );
  }

  export default UploadModel;