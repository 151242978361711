import React from "react";
import { Form, FormControl } from "react-bootstrap";

const Bank = ({formik, bank}) => {
    return (
        <div className="uploads-content">
        <div className="col-md-12">
            <div className="row animate__animated animate__fadeIn">
                {
                    bank.length > 0 && bank.map((value, index) => {
                        let name = 'bank'+index;
                        return(
                        <div className="col-md-6">
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Label className="input-label">{value.bankName} (AC: {value.account})</Form.Label>
                                <FormControl
                                    type="file"
                                    size="sm"
                                    name={name}
                                    onChange={(e) => formik.setFieldValue(name, e.target.files[0])}
                                    isInvalid={Boolean(formik.errors[name])}
                                    aria-label="application"
                                    aria-describedby="basic-addon1"
                                />
                                {formik.errors[name] && 
                                <div className="col-md-12">
                                <span className="srv-validation-message"><span className="error-palate">*&nbsp;{formik.errors[name]}</span></span>
                                </div>
                                }  
                            </Form.Group>
                        </div>
                        )
                    })
                }
              
            </div>
        </div>
        </div>
    )
}

export default Bank;