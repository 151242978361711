import { faInfo, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react'
import { Alert, Form, FormControl, InputGroup } from 'react-bootstrap'
import PhoneInput from 'react-phone-input-2';

export default function SecoundApplicantContact({formik,name,secondContactDeclare,setSecondContactDeclare,secondContactDeclareInvalid,setSecondContactDeclareInvalid,scrollref}) {

    const handleDeclareEach = (e) => {
        if(e.target.checked == true){
            setSecondContactDeclare(true)
        }else{
            setSecondContactDeclare(false)
        }
    }

  return (
    <div className='col-md-12 animate__animated animate__fadeIn'>
    <p style={{fontWeight:700}}>Second Applicant Contact Details</p>
    <hr/>
    <div className='row' style={{minHeight:'60vh'}}>
        <div className='col-md-6'>
            <Form.Group className="mb-2" controlId="exampleForm.ControlInput1">
                <Form.Label className="input-label">Second Applicant's Name <span class="required-field"></span></Form.Label>
                <FormControl
                    size="sm"
                    aria-label="name"
                    name="name"
                    autoComplete="off"
                    isInvalid={Boolean(formik.errors.name)}
                    onChange={formik.handleChange}
                    value={formik.values.name}
                    aria-describedby="basic-addon1"
                />
                {formik.errors.name && 
                    <div className="col-md-12">
                    <span className="srv-validation-message"><span className="error-palate">*&nbsp;{formik.errors.name}</span></span>
                    </div>
                } 
            </Form.Group>
            <Form.Group className="mb-2" controlId="exampleForm.ControlInput1">
                <Form.Label className="input-label">Second Applicant's Email <span class="required-field"></span></Form.Label>
                <FormControl
                    size="sm"
                    aria-label="email"
                    name="email"
                    autoComplete="off"
                    isInvalid={Boolean(formik.errors.email)}
                    onChange={formik.handleChange}
                    value={formik.values.email}
                    aria-describedby="basic-addon1"
                />
                {formik.errors.email && 
                    <div className="col-md-12">
                    <span className="srv-validation-message"><span className="error-palate">*&nbsp;{formik.errors.email}</span></span>
                    </div>
                } 
            </Form.Group>
            <Form.Group className="mb-2" controlId="exampleForm.ControlInput1">
                    <Form.Label className="input-label">Second Applicant's Mobile <span class="required-field"></span></Form.Label>
                    <InputGroup style={{height:'30px', fontSize:'13px'}} className="mb-3">
                            <PhoneInput
                                country={'lk'}
                                name="mobile"
                                className="phone-register-mobile"
                                placeholder="mobile"
                                autoComplete="off"
                                isInvalid={Boolean(formik.errors.mobile)}
                                onChange={phone => formik.setFieldValue('mobile', phone)}
                                value={formik.values.mobile}
                                style={{width:'100%'}}
                            />
                                {formik.errors.mobile && <div className="col-md-12">
                                <span className="srv-validation-message"><span className="error-palate">*&nbsp;{formik.errors.mobile}</span></span>
                            </div>}
                    </InputGroup>
            </Form.Group>
            <Form.Group className="mb-2" controlId="exampleForm.ControlInput1">
                <Form.Label className="input-label">Remark</Form.Label>
                <FormControl
                    size="sm"
                    as={'textarea'}
                    aria-label="remark"
                    name="remark"
                    rows={3}
                    autoComplete="off"
                    isInvalid={Boolean(formik.errors.remark)}
                    onChange={formik.handleChange}
                    style={{resize:'none'}}
                    value={formik.values.remark}
                    aria-describedby="basic-addon1"
                />
                {formik.errors.remark && 
                    <div className="col-md-12">
                    <span className="srv-validation-message"><span className="error-palate">*&nbsp;{formik.errors.remark}</span></span>
                    </div>
                } 
            </Form.Group>
        </div>
        <div className='col-md-6'>
            <Alert variant="info" style={{marginTop:15, marginLeft:15}}>
                <Alert.Heading style={{fontSize:12, fontWeight:600}}><FontAwesomeIcon icon={faInfoCircle}/>&nbsp;&nbsp;Notice</Alert.Heading>
                <p style={{fontSize:12}}>
                    System will send Second applicant's login information to the above email address and mobile number. Please double-check before proceeding.
                </p>
            </Alert>
        </div>
    </div>
    <div className='row'>
        <div className='col-md-12' style={{display:'flex', flexDirection:'column', justifyContent:'center', marginTop:10}}>
            <Form.Check
                style={{fontSize:13, marginRight:20}}
                size="md"
                isInvalid={secondContactDeclareInvalid}
                label={`I, ${name} hereby declare that the information given above is true and accurate to the best of my knowledge and confirm submitting same to Senfin Asset Management for the purposes of investing in one or more funds managed by same`}
                name="declare"
                type={'checkbox'}
                onClick={handleDeclareEach}
                id={`inline-declare`}
                checked={secondContactDeclare}
            />
        </div>
    </div>
    <div ref={scrollref}></div>
</div>
  )
}
