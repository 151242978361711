import React, { useEffect, useState } from "react";
import { Form, FormControl } from "react-bootstrap";

const ModelForm = ({formik,msg,setMsg,bank,branch}) => {
    const [branchFilterd, setBranchFilterd] = useState([]);
    

    useEffect(() => {
        try{
            const list = branch.filter((value) => value.bank_id == formik.values.bank)
            setBranchFilterd(list);
        }catch(error) {
            setMsg('Something went wrong');
        }
    }, [formik.values.bank])

    

    return (
        <>
        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label><strong>Bank</strong></Form.Label>
            <Form.Select size="sm" 
                aria-label="Default select example"
                value={formik.values.bank}
                onChange={(e) => formik.setFieldValue('bank', e.target.value)}
                isInvalid={Boolean(formik.errors.bank)}
            >
                <option value="">Select Bank</option>
                {
                    bank.length > 0 && bank.map((value) => {
                        return(
                            <option value={value.id}>{value.name}</option> 
                        )
                    })
                }
            </Form.Select>
            {formik.errors.bank && 
                <div className="col-md-12">
                <span className="srv-validation-message"><span className="error-palate">*&nbsp;{formik.errors.bank}</span></span>
                </div>
            } 
        </Form.Group>
        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label><strong>Branch</strong></Form.Label>
            <Form.Select 
                size="sm" 
                aria-label="Default select example"
                value={formik.values.branch}
                onChange={(e) => formik.setFieldValue('branch', e.target.value)}
                isInvalid={Boolean(formik.errors.branch)}
            >
                <option value="">Select Branch</option>
                {
                    branchFilterd.length > 0 && branchFilterd.map((value) => {
                        return(
                            <option value={value.id}>{value.name}</option> 
                        )
                    })
                }
            </Form.Select>
            {formik.errors.branch && 
                <div className="col-md-12">
                <span className="srv-validation-message"><span className="error-palate">*&nbsp;{formik.errors.branch}</span></span>
                </div>
            } 
        </Form.Group>
        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label><strong>Account Number</strong></Form.Label>
            <FormControl
                size="sm"
                aria-label="accno"
                aria-describedby="basic-addon1"
                value={formik.values.account}
                onChange={(e) => {
                    const re = /^[0-9\b]+$/;
                    if (e.target.value === '' || re.test(e.target.value)) {
                        formik.setFieldValue('account', e.target.value)
                     }
                }}
                isInvalid={Boolean(formik.errors.account)}
                autoComplete="off"
            />
            {formik.errors.account && 
                <div className="col-md-12">
                <span className="srv-validation-message"><span className="error-palate">*&nbsp;{formik.errors.account}</span></span>
                </div>
            } 
        </Form.Group>
        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label><strong>Nick Name</strong></Form.Label>
            <FormControl
                size="sm"
                aria-label="nickname"
                aria-describedby="basic-addon1"
                value={formik.values.nickname}
                autoComplete="off"
                onChange={(e) => formik.setFieldValue('nickname', e.target.value)}
                isInvalid={Boolean(formik.errors.nickname)}
            />
            {formik.errors.nickname && 
                <div className="col-md-12">
                <span className="srv-validation-message"><span className="error-palate">*&nbsp;{formik.errors.nickname}</span></span>
                </div>
            }  
        </Form.Group>
        </>
    )
}

export default ModelForm;