import React, {useEffect, useState} from 'react';
import Loader from '../../components/common/loader';
import TopNav from '../../components/common/topnav';
import Profile from '../../components/common/profile';
import Footer from '../../components/common/footer';
import { useHistory } from 'react-router-dom';

const ProfileShow = () => {
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        document.body.classList.remove('body-register')
        document.body.classList.remove('body-login')
    }, [])

    const handleLoading = (e) => {
        setLoading(e);
    }

    const LogoutHome = () => {
        if(sessionStorage.getItem('token') == undefined || sessionStorage.getItem('token') == '' || sessionStorage.getItem('token') == null){
            history.push('/');
        }
    }

    useEffect(() => {
        LogoutHome();
    }, [])
    

    return (
        <>
        <Loader active={loading}>
            <div className="container container-line">
            <TopNav counter={false} sticky={false} downer={true} signOutMobile={false}/>
            <div className='main-wrap'>
                <div className='row justify-content-end'>
                    <Profile loading={handleLoading}/>
                </div>
            </div>
            <div className='main-wrap'>
                <div className='row'>
                    <Footer/>
                </div>
            </div>
            </div>
        </Loader>
        </>
    )
}

export default ProfileShow;