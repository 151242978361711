import React, {useState, useEffect} from "react";
import { Button, Modal, Form, Col, Row } from 'react-bootstrap';
import { faCheckCircle, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import 'animate.css';
import { useHistory } from "react-router-dom";
import Error from "../alerts/error";
import Success from "../alerts/success";

const Message = ({onHide, show, state}) =>  {
    const steps = 2; //steps without success window
    const history = useHistory();
    const [onState, setOnState] = useState(1);

    useEffect(() => {
        setOnState(state);
    }, [state])
    

    const onConfirm = () => {
        history.push('/'); 
    }

    const closeFunc = () => {

    }

    
    const stepslog = () => {

    }

    return (
      <Modal
        show={show}
        onHide={onHide}
        backdrop="static"
        keyboard={false}
        size="md"
        style={{fontSize:13}}
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header style={{padding:'0.3rem 0.3rem'}}>
          <Modal.Title style={{fontSize:18}} id="contained-modal-title-vcenter">
            <FontAwesomeIcon icon={onState == 2?faCheckCircle:faTimesCircle} style={{paddingLeft:'8px'}}/>
            &nbsp;
           {onState == 2?'Update Successful':'Update Unsuccessful!'}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ paddingBottom:0, minHeight:250}}>
          {
                onState == 2?
                  <Success closeTime={10} close={onHide}  step={stepslog} redirect="/"  message="Profile Update Successful!" />
                :
                  <Error closeTime={10} close={onHide} step={stepslog} message="Profile Update Unsuccessful!"/>
          }
           
        </Modal.Body>
        <Modal.Footer style={{padding:'0.3rem 0.3rem', paddingLeft:'8px',paddingRight:'8px', paddingBottom:8, display:'flex', justifyContent:onState == 2?'space-between':'end', borderTop:'none'}}>
          {<Button className="btn btn-danger btn-red" style={{width:80}} onClick={onHide}>Cancel</Button>}
          {onState == 2?<Button className="btn-btn-success btn-green" style={{width:80}} onClick={onConfirm}>{'Login'}</Button>:''}
        </Modal.Footer>
      </Modal>
    );
  }

  export default Message;