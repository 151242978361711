import React from "react";
import { Button } from "react-bootstrap";

const Statement = ({formik}) => {
    return(
        <div className='statement-container'>
        <div className='statement-wrap'>
            <div className='row' style={{display:'flex', flexDirection:'row'}}>
                <div className='col-6 text-right'
                    style={{
                        padding:15,
                    }}
                >
                    <p style={{padding:0, margin:0, color:'#4f8aaf'}}>Verified</p>
                    <h2 style={{padding:0, margin:0, color:'#5fc69e'}}>Statement</h2>
                </div>
                <div className='col-6'
                    style={{
                        display:'flex',
                        flexDirection:'row',
                        justifyContent:'center',
                        padding:15,
                    }}
                >
                    <Button style={{width:130, fontSize:20}} onClick={(e) => formik.handleSubmit()} className="btn btn-success btn-blue">Request</Button>
                </div>
            </div>
        </div>
        </div>
    )
}

export default Statement;