import React, {useState} from "react";
import { InputGroup, FormControl, Button, Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import 'bootstrap/dist/css/bootstrap.min.css';
import { faAdd, faTrash, faEdit } from "@fortawesome/free-solid-svg-icons";
import 'react-phone-number-input/style.css';
import { useHistory, useLocation } from "react-router-dom";
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import BankModel from "../model";
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import api from '../../../services/api';


const BankForm = ({loading}) => {
    const history = useHistory();
    const location = useLocation();
    const [bankModel, setBankModel] = useState(false)
    const [bankarray, setBankArray] = useState([]);
    const [errorMsg, setErrorMsg] = useState('');

    const handleBankModel = () => {
        setBankModel(false);
    }
    

    //continue bank form
    const handleBankConfirm = async() =>{
        if(bankarray.length == 0){
            setErrorMsg('Atleast one bank account Required');
            return false;
        }
        loading(true);
        let form = location.state.formData;
        form.ACCOUNTS = [...bankarray];
        const data = await api.postRequest('application', form);
        if(data.status == 200){
            if(data.data.status == 'success'){
                history.push({
                    pathname: '/uploads',
                    state: { type: form.type, bank:form.ACCOUNTS}
                });
            }else{
                var erros = data.data.errors;
                var first = Object.keys(erros)[0]
                setErrorMsg(erros[first][0]);
            }
            loading(false);
        }else{
            setErrorMsg('Something went wrong');
            loading(false);
        }
    }

    //handle Bank array
    const handleBankArray = (e) => {
        setBankArray(e)
    }

    // handle edit bank
    const hanldeEditBank = async(index) => {
        let arr = [...bankarray];
        arr[index].editable = 1;
        setBankArray(arr);
        setBankModel(true);
    }

    //handle delete bank 
    const hanldeDeleteBank = (index) => {
        let arr = [...bankarray];
        arr.splice(index, 1);
        setBankArray([...arr]);
    }

    return(
        <>
        <div className="col-md-7 animate__animated animate__fadeIn">
            <div className="login-card">
                <div className="panel-head">
                    <h4>Bank Details</h4>
                    <button onClick={(e) => setBankModel(true)} className="btn btn-sm btn-success"><FontAwesomeIcon icon={faAdd}/></button>
                </div>
                <hr style={{paddingTop: 0, marginTop:0}}/>
                <BankModel show={bankModel} onHide={handleBankModel} bankarray={bankarray} setBankArray={handleBankArray}/>
                <Table className="table-style" style={{marginBottom:40}}>
                <Thead style={{fontSize:14}}>
                    <Tr>
                    <Th>Nickname</Th>
                    <Th>Bank</Th>
                    <Th>Branch</Th>
                    <Th>Account No</Th>
                    <Th></Th>
                    </Tr>
                </Thead>
                <Tbody style={{fontSize:14}}>
                    {
                     bankarray.length > 0 &&  bankarray.map((values, index) => {
                         return(
                            <Tr key={index}>
                            <Td>{values.nickname}</Td>
                            <Td>{values.bankName}</Td>
                            <Td>{values.branchName}</Td>
                            <Td>{values.account}</Td>
                            <Td>
                                <div className="table-xsm-btn">
                                    <button onClick={(e) => hanldeEditBank(index)} className="btn btn-primary">
                                        <FontAwesomeIcon  icon={faEdit}/>
                                    </button>
                                    <button onClick={(e) => hanldeDeleteBank(index)} className="btn btn-danger">
                                        <FontAwesomeIcon  icon={faTrash}/>
                                    </button>
                                </div>
                            </Td>
                            </Tr>
                         )
                     })  
                    }
                </Tbody>
                </Table>
                {errorMsg && 
                    <div className="col-md-12"  style={{position:'absolute', bottom:'60px'}}>
                    <span className="srv-validation-message"><span className="error-palate">*&nbsp;{errorMsg}</span></span>
                    </div>
                }  
                <div className="text-right" style={{display:'flex', flexDirection:'row', justifyContent:'end', alignItems:'center', marginTop:20}}>
                    <button onClick={handleBankConfirm} className="btn btn-primary card-button login-button" >
                        Continue
                    </button>
                </div>
            </div>
        </div>

        </>
    )
}

export default BankForm;