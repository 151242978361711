import React, { useState } from "react"
import { Form } from "react-bootstrap";
import moment from "moment";

 //bank form
 const Details = ({formik, acountList, fundList, error }) => {

    return(
      <Form>
        <div className="payslip-upload mt-4">
            <div className="row">
                <div className="col-md-4">
                  Fund
                </div>
                <div className="col-md-8">: {formik.values.fund == ''?'All':fundList.filter((value) => value.FUND_CODE == formik.values.fund)[0].FUND_NAME} </div>
            </div>
            <div className="row">
                <div className="col-md-4">
                  Account
                </div>
                <div className="col-md-5">: {formik.values.account == ''?'All':formik.values.account} </div>
            </div>
            <div className="row">
                <div className="col-md-4">
                From Date
                </div>
                <div className="col-md-5">: {formik.values.from?moment(formik.values.from).format("YYYY/MM/DD"):" - "}</div>
            </div>
            <div className="row">
                <div className="col-md-4">
                To Date
                </div>
                <div className="col-md-5">: {formik.values.to?moment(formik.values.to).format("YYYY/MM/DD"):" - "}</div>
            </div>
            <div className="row">
                <div className="col-md-4">
                Description
                </div>
                <div className="col-md-8">
                   <Form.Control 
                      as="textarea" 
                      rows={3} 
                      onChange={(e) => formik.setFieldValue('disacription', e.target.value)}
                    />
                </div>
            </div>
            {
              error != ''?
                <div className="col-md-12">
                <span className="srv-validation-message"><span className="error-palate">*&nbsp;{error}</span></span>
                </div>
              :''
            }
        </div>
      </Form>
    )
  }

  export default Details;