import React, {useState, useEffect, useRef} from "react";
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import logo from '../../../assets/images/banner1.png';
import moment from "moment";
import { useLocation } from "react-router-dom";
import Pdf from "react-to-pdf";

const StatementView = ({transaction, unitColumn, fromDate, toDate, account, TransTable, fundName}) => {
  const [unitColumnres, setUnitColumnres]=useState(false);

    useEffect(() => {
      setUnitColumnres(unitColumn);
    }, [unitColumn])

  return(
    <>
    <div ref={TransTable} className="p-2">
    <div className="p-3 report">
          <div className="report-top">
                  <img alt="Senfin Asset Management" src={logo} />
                  <h5>Transaction Report</h5>
                  {
                    (fromDate != '' || toDate != '') ? 
                    <h6>{fromDate} to {toDate}</h6>
                    :''
                  }
                 
          </div>
        <div style={{marginTop:30}}>
        <div style={{display:'flex', flexDirection:'row', justifyContent:'space-between', fontSize:11}}>
         {
           fundName != ''?
            <p><b>Fund:</b> {fundName}</p>
          :''
         }
         {
          account != ''?
          <p><b>Account:</b> {account}</p>
          :''
         }
        </div>
        <Table>
        <Thead className="pdf-table" style={{fontSize:14}}>
            <Tr>
            <Th>Date</Th>
            <Th>Type</Th>
            <Th>TR.No</Th>
            <Th>Account No</Th>
            <Th>TR.Units</Th>
            <Th>Price</Th>
            <Th className="text-right">Amount</Th>
            {
                unitColumnres == true?
                <Th className="text-right">Unit Balance</Th>
                :''
            }
          
            </Tr>
        </Thead>
        <Tbody style={{fontSize:14}}>
            {
                transaction.length > 0 && transaction.map((value) => {
                    return(
                        <Tr>
                            <Td>{"  " + moment(value.TRANSACTION_DATE).format("DD/MM/YYYY")}</Td>
                            <Td>{value.NAME}</Td>
                            <Td>{value.TRANS_NO}</Td>
                            <Td>{value.ACCOUNT_NO}</Td>
                            <Td>{parseFloat(value.TransactedUnits).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</Td>
                            <Td>{(Number(value.OFFER_BID_PRICE)).toFixed(4)}</Td>
                            <Td className="text-right">{parseFloat(value.AMOUNT_APPLIED).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</Td>
                             {
                            unitColumnres == true?
                                <Td className="text-right">{value.unitPrice != undefined && value.unitPrice != null? (parseFloat(value.unitPrice).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")):0}</Td>
                                :''
                            }
                        </Tr>
                    )
                })
            }

        </Tbody>
        </Table>
        </div>
    </div>
    <div className="foot-pdf">
      <hr/>
      <p className="disclaimer"><b>Disclaimer:-</b> This is a Transaction Report being generated through the ONLINE PORTAL of the web application and not a Verified Statement of the Client's account. For a Statement of your account, please send a 'Statement Request' to Registrar- Senfin Asset Management (Pvt) Ltd. This request can be sent through the ONLINE PORTAL or through an email to sfam.info@senfin.com</p>
      </div>
    </div>

    </>
  )
}

export default StatementView;