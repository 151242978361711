import React, {useEffect, useState} from "react";
import { Button, Modal, Form, Col, Row } from 'react-bootstrap';
import { faLockOpen, faDownload, faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import 'animate.css';
import { useHistory } from "react-router-dom";
import Warning from "../../common/alerts/warning";
import SupportContent from "../../support/content";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import api from "../../../services/api";
import Success from '../../common/alerts/success';

const Maintance = ({onHide, show}) =>  {
    const history = useHistory();

    useEffect(() => {

    },[])

    
    return (
      <Modal
        show={show}
        onHide={onHide}
        backdrop="static"
        keyboard={false}
        size="md"
        style={{fontSize:13}}
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header style={{padding:'0.3rem 0.3rem', paddingRight:'1rem'}} closeButton>
          <Modal.Title style={{fontSize:18}} id="contained-modal-title-vcenter">
            <FontAwesomeIcon icon={faExclamationCircle} style={{paddingLeft:'8px'}}/>
            &nbsp;
            NOTICE
          </Modal.Title>
        </Modal.Header>
        {
        <div className="animate__animated animate__fadeIn">
            <Modal.Body style={{ paddingBottom:0, minHeight:200}}>
                <p style={{fontWeight:700}}>Dear Value Unit Holder,</p>

                <p><strong>Due to system maintenance</strong>, 
                Senfin Online Balances will not be available for the moment. The online platform will be back online shortly.</p>

                <p style={{padding:0, margin:0}}>For any urgent inquiries, please call our hotline at 0741790202 or email us at</p>
                <a href="investor.relations@senfin.com">investor.relations@senfin.com</a>
                <p style={{marginTop:15}}>We regret the inconvenience caused.</p>
            </Modal.Body>
        </div>
        }
      </Modal>
    );
  }

  export default Maintance;