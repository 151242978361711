import React, {useEffect, useState} from "react";
import { Button, Modal, Form, Col, Row } from 'react-bootstrap';
import { faLockOpen, faDownload, faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import 'animate.css';
import { useHistory } from "react-router-dom";
import Warning from "../../common/alerts/warning";
import SupportContent from "../../support/content";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import api from "../../../services/api";
import Success from '../../common/alerts/success';

const InProgress = ({onHide, show}) =>  {
    const history = useHistory();
    const [step, setStep] = useState(0);
    const [customMsg, setCustomMsg] = useState('');
    const [modelHide, setModelHide] = useState('');
    

    const validation = Yup.object({
        type :Yup.string().required("Required"),
        inquiry : Yup.string().required("Required"),
    });
    

    const formik = useFormik({
      initialValues: {
        type: '',
        inquiry: '',
        attch: '',
      },
      validateOnChange:false,
      validateOnBlur:false,
      validationSchema: validation,
      // Login Request
      onSubmit: async(values) => {
          try{
              const fd = new FormData();
              if(values.attch){
                fd.append('description', values.inquiry);           
                fd.append('TRANS_CODE', values.type);           
                fd.append('image', values.attch);           
              }else{
                fd.append('description', values.inquiry);           
                fd.append('TRANS_CODE', values.type);           
              }
              const data = await api.postRequest('support', fd)
              if(data.status == 200){
                  if(data.data.status == 'fail'){
                      var erros = data.data.errors;
                      var first = Object.keys(erros)[0]
                      setCustomMsg(erros[first][0]);
                  }else{
                    setStep(2);  
                  }
              }else{
                  setCustomMsg('Something went wrong');
              }
          }catch(error){
              setCustomMsg('Something went wrong');
          }
      },
  });

    useEffect(() => {
      if(step == 0){
        formik.resetForm();
      }
    },[step])
  

    const logOut = () => {
      sessionStorage.removeItem('token');
      history.push('/')
    }

    const handleSupport = () => {
        setModelHide('none');
    }

    const onHideSupport = () => {
      setStep(0);
    }

    const handleCloseSuccess = () => {

    }

    const handleEdit = () => {
      history.push('/profile/pre');
    }
    
    return (
      <Modal
        show={show}
        onHide={onHide}
        backdrop="static"
        keyboard={false}
        size="md"
        style={{fontSize:13, display:modelHide}}
        aria-labelledby="contained-modal-title-vcenter"
        className="model-hide-class"
      >
        <Modal.Header style={{padding:'0.3rem 0.3rem'}}>
          <Modal.Title style={{fontSize:18}} id="contained-modal-title-vcenter">
            <FontAwesomeIcon icon={faExclamationCircle} style={{paddingLeft:'8px'}}/>
            &nbsp;
            WARNING
          </Modal.Title>
        </Modal.Header>
        {
          step ==0?
          <Modal.Body style={{ paddingBottom:0, minHeight:250}}>
            <div className="row animate__animated animate__fadeIn">
              <div className="warning-icon">
                <div className="col-md-12">
                  <Warning/>
                </div>
                <div className="col-md-12">
                  <h5 className="text-center" style={{padding:0, margin:0}}>Your registration is being processed</h5>
                  <p className="text-center" style={{padding:0, margin:0}}>Please wait until the KYC process is completed.</p>
                </div>
                <div className="col-md-12" style={{marginTop:20}}>
                    <div className="row">
                      <div className="col-md-6 text-right">
                        <Button className="btn btn-danger btn-red" style={{width:80}} onClick={logOut}>Sign Out</Button>
                      </div>
                      <div className="col-md-6">
                        <Button className="btn-btn-success btn-green" style={{width:80}} onClick={handleSupport}>Okay</Button>
                      </div>  
                    </div> 
                    {/* <div className="row" style={{marginTop:10, marginBottom:10}}>
                      <div className="col-md-12 text-center">
                        <Button className="btn-btn-default btn-blue" style={{width:160}} onClick={handleEdit}>Edit Profile</Button>
                      </div>
                    </div>    */}
                </div>
              </div>
            </div>
          </Modal.Body>
          :(
            step == 1?
              <SupportContent onHide={onHideSupport} formik={formik} setError={setCustomMsg} error={customMsg}/>
            :
            <div className="animate__animated animate__fadeIn">
            <Modal.Body style={{ paddingBottom:0, minHeight:250}}>
              <Success closeTime={10} currentStep={step} close={handleCloseSuccess} nextStep={false} message="Support Requesting Successful!" step={setStep}/>
            </Modal.Body>
              <Modal.Footer style={{padding:'0.3rem 0.3rem', paddingLeft:'8px',paddingRight:'8px', paddingBottom:8, display:'flex', justifyContent:'end', borderTop:'none'}}>
                <Button className="btn-btn-success btn-green" style={{width:80}} onClick={(e) => setStep(0)}>Confirm</Button>
            </Modal.Footer>
          </div>
          )
          
        }
      </Modal>
    );
  }

  export default InProgress;