import React from 'react'
import { Form } from 'react-bootstrap'

export default function TermsCondition({checked, setChecked, checkedError}) {

    const handleCheckTerms = (e) => {
        setChecked(e.target.checked);
    }

  return (
    <div className='col-md-12 animate__animated animate__fadeIn'>
        <p style={{fontWeight:700}}>Declaration</p>
        <hr/>
        <div className='row'>
            <p className='text-danger' style={{fontSize:14, fontWeight:600}}>Please read the declaration carefully.</p>

            <ol type='I' style={{fontSize:13, marginLeft:25, fontWeight:600, width:'95%'}}>
                <li style={{marginBottom:8}}><span style={{fontWeight:400}}>I/We declare that all information in this Unit Trust application form and in all documents provided in connection with this investments are true and accurate and form the basis of this Agreement.</span></li>
                <li style={{marginBottom:8}}><span style={{fontWeight:400}}>I/We have read and fully understood the terms and conditions in the Explanatory Memorandum pertaining to the units that may be issued pursuant to this application form and agree to be bound by the aforementioned notes.</span></li>
                <li style={{marginBottom:8}}><span style={{fontWeight:400}}>I/We hereby agree to accept and to be bound by the provisions of the Trust Deed as amended from time to time.</span></li>
                <li style={{marginBottom:8}}><span style={{fontWeight:400}}>I/We confirm that this investment is made on the understanding that prices of Units could move up as well as down from time to time due to market fluctuations as explained in the Explanatory Memorandum.</span></li>
                <li style={{marginBottom:8}}><span style={{fontWeight:400}}>I/We am/are aware of the fees and charges that will be incurred directly or indirectly when investing in the Fund.</span></li>
                <li style={{marginBottom:8}}><span style={{fontWeight:400}}>I/We authorize Senfin Asset Management (Pvt) Ltd (Fund Manager) to accept instructions by facsimile or registered email and hereby waive any claim that I/we may have against the Fund Manager and indemnify the Fund Manager against any loss incurred as a result of the Fund Manager receiving and/or acting upon such facsimile/email which have been originated by us or purported to have been originated by me/us. </span></li>
                <li style={{marginBottom:8}}><span style={{fontWeight:400}}>I/We agree that the Fund Manager reserves the right to reject, withdraw or terminate forthwith without notice my/our application without assigning any reasons thereto and recover related costs and /or other expenses pertaining to this account and under no circumstances shall Fund Manager be liable for any loss or damages for such action.</span></li>
                <li style={{marginBottom:8}}><span style={{fontWeight:700}}>For Non-Resident Applicants</span><br/><span style={{fontWeight:400}}>I/We declare that I/we am/are resident outside Sri Lanka at the time of opening of this account and further declare that the funds to be invested for the purchase of Units in any issue or transactions with the Fund Manager shall be made only out of or into an Inward Remittance Account (IIA) or an account maintained in a licensed commercial bank in Sri Lanka.</span></li>
            </ol>
            <div key={'checkbox'} className="mb-3">
                <Form.Check type={'checkbox'} id={`check-api-checkbox`}>
                    <Form.Check.Input onClick={handleCheckTerms} type={'checkbox'} checked={checked} isInvalid={checkedError}/>
                    <Form.Check.Label style={{fontSize:13, fontWeight:600}}>I hereby acknowledge that I have read and understood the declaration mentioned above, and I agree to the terms stated therein.</Form.Check.Label>
                    <Form.Control.Feedback type="invalid">
                    * Required 
                    </Form.Control.Feedback>
                </Form.Check>
            </div>
        </div>
    </div>
  )
}
