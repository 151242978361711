import React, {useState, useEffect, useRef} from "react";
import { Button, Modal, Form, Col, Row } from 'react-bootstrap';
import 'animate.css';
import { faFilePdf } from "@fortawesome/free-solid-svg-icons";
import StatementView from "./statementview";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Pdf from "react-to-pdf";

const PdfViewr = ({onHide, show, transaction, unitColumn, fromDate, toDate, account, fund, fundList}) =>  {
    const [fundName, setFundName] = useState('');
    const [transactions, setTransactions] = useState([]);

    const TransTable = useRef();

      useEffect(() => {
        if(fundList != '' && fundList != undefined && fundList.length > 0 && fund != ''){
          setFundName(fundList.filter((e) => e.FUND_CODE == fund)[0].FUND_NAME);
        }else{
          setFundName('');
        }
    }, [fundList])

    useEffect(() => {
      setTransactions(transaction);
    }, [transaction])
    

    return (
      <Modal
        show={show}
        onHide={onHide}
        backdrop="static"
        keyboard={false}
        size="lg"
        style={{fontSize:13}}
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header style={{padding:'0.3rem 0.3rem'}}>
          <Modal.Title style={{fontSize:18}} id="contained-modal-title-vcenter">
            <FontAwesomeIcon icon={faFilePdf} style={{paddingLeft:'8px'}}/>
            &nbsp;
            {'TRANSACTION REPORT'}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ paddingBottom:0, minHeight:250}}>
            <StatementView TransTable={TransTable} transaction={transactions} unitColumn={unitColumn} fromDate={fromDate} toDate={toDate} account={account}fund={fund} fundList={fundList} fundName={fundName}/>
        </Modal.Body>
        <Modal.Footer style={{padding:'0.3rem 0.3rem', paddingLeft:'8px',paddingRight:'8px', paddingBottom:8, display:'flex', justifyContent:'space-between', borderTop:'none'}}>
          {<Button className="btn btn-danger btn-red" style={{width:80}} onClick={onHide}>Cancel</Button>}
          
          <Pdf targetRef={TransTable} filename="transactions.pdf"  x={3} y={.5}>
            {({ toPdf }) => <Button className="btn-btn-success btn-green" onClick={toPdf} style={{width:80}}>Download</Button>}
          </Pdf>
        </Modal.Footer>
      </Modal>
    );
  }

  export default PdfViewr;