import React, { useState, useEffect, useRef } from "react"
import { Form, Button } from "react-bootstrap";
import { SegmentedControl } from 'segmented-control';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Terms from "../../../common/terms/terms";
import { faFileImage, faFilePdf } from "@fortawesome/free-solid-svg-icons";

 //bank form
 const BankMethod = ({formik, details, customError}) => {
    const [segmantValue, setSegmantValue] = useState('one');
    const uploadFiles = useRef();

    const SegmentChange = (value) => {
        setSegmantValue('two')
    }

    const handleBankSlip = (e) => {
      let files = formik.values.bankslip;
      files.push(e.target.files[0]);
      formik.setFieldValue('bankslip', files);
      e.target.value = null;
    }

    const hanleCloseDoc = (id) => {
      let files = formik.values.bankslip;
      files.splice(id, 1);
      formik.setFieldValue('bankslip', files);
    }

    const downloadFile = (content) => {
      var dl = document.createElement('a');
      dl.setAttribute('href', URL.createObjectURL(content));
      dl.setAttribute('download', content.name);
      dl.click();
    }

    const uploadButtonClick = () => {
      uploadFiles.current.click();
    }
    

    return(
      <Form>
          <p style={{margin:0, padding:0}}>Please select your preferred payment method to proceed.</p>
          <SegmentedControl
          name="oneDisabled"
          options={[
            { label: "Bank Deposit / Interbank Transfers" , value: "one", default: true },
            { label: "Online Debit Card Payment", value: "two" },
          ]}
          setValue={newValue => SegmentChange(newValue)}
        />
        <p style={{margin:0, padding:0}}>Please upload your payment slip here</p>
        <div className="payslip-upload">
            <div className="row">
                <div className="col-md-3">
                  Fund
                </div>
                <div className="col-md-8">: {details.fund}</div>
            </div>
            <div className="row">
                <div className="col-md-3">
                  Account
                </div>
                <div className="col-md-5">: {details.account}</div>
            </div>
            <div className="row">
                <div className="col-md-3">
                  Amount (LKR)
                </div>
                <div className="col-md-5">: {details.amount}</div>
            </div>
            <div className="row" style={{marginTop:10, marginBottom:10}}>
              <div className="col-md-3">
                <Button style={{fontSize:12}} onClick={uploadButtonClick} variant="outline-secondary" size="sm">
                    Upload Files
                </Button>
                {formik.errors.bankslip && 
                  <div className="col-md-12">
                  <span className="srv-validation-message"><span className="error-palate">*&nbsp;{formik.errors.bankslip}</span></span>
                  </div>
                }  
              </div>
              <div className="col-md-6">
                  <span>{formik.values.bankslip.length == 0?': No file chosen':': '+formik.values.bankslip.length+' files chosen'}</span> 
              </div>
              <Form.Group style={{display:'none'}} className="mb-3">
                <Form.Control 
                    autoComplete="off"
                    isInvalid={Boolean(formik.errors.bankslip)}
                    onChange={(e) => handleBankSlip(e)}
                    type="file" 
                    ref={uploadFiles}
                    accept="application/pdf, image/jpeg, image/png, image/jpg, image/gif"
                    size="sm" />
              </Form.Group>
            </div>
            <div className="row">
             {
              formik.values.bankslip.length > 0?
              formik.values.bankslip.map((value, index) => {
                if(/[^.]+$/.exec(value.name)[0] == 'pdf'){
                  return(
                  <div className="col-md-4 doc-container">
                       <span class='close-x' onClick={() => hanleCloseDoc(index)}>x</span>
                       <FontAwesomeIcon onClick={() => downloadFile(value)} style={{fontSize:35, cursor:"pointer"}} icon={faFilePdf}/>
                       <p>{value.name}</p>
                  </div>
                  )
     
                }else{
                  return(
                    <div className="col-md-4 doc-container">
                         <span class='close-x' onClick={() => hanleCloseDoc(index)}>x</span>
                        <FontAwesomeIcon onClick={() => downloadFile(value)} style={{fontSize:35, cursor:"pointer"}} icon={faFileImage}/>
                        <p>{value.name}</p>
                    </div>
                  )
                }
              
              })
              :
              ''
             }     
        </div>
        </div>
        <Terms formik={formik}/>
        {
          customError != null?
          <div className="col-md-12">
              <span className="srv-validation-message"><span className="error-palate">*&nbsp;{customError}</span></span>
          </div>
          :null
        }
      </Form>
    )
  }

  export default BankMethod;