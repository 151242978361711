import React, {useState,useEffect} from "react";
import { Button, Modal, Form, Col, Row } from 'react-bootstrap';
import { faLock } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Stepper from 'react-stepper-horizontal';
import 'animate.css';
import Success from "../../../common/alerts/success";
import BankMethod from "../contents/method";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import api from "../../../../services/api";
import Error from "../../../common/alerts/error";
import OTP from "../../../common/contents/otp";
import LockForm from "../contents/lockForm";
import LockMethod from "../contents/lockMethod";


const LockModel = ({onHide, show, fundList, activeAcountList, refreshList, setRefresh, accountList}) =>  {
    const steps = 3; //steps without success window
    const [step, setStep] = useState(0);
    const [buyValues, setBuyValues] = useState([]);
    const [file, setFile] = useState([]);
    const [sellsection, setSellSection] = useState([])
    const [details, setDetails] = useState({});
    const [unitError, setUnitError] = useState(false);
    const [valueError, setValueError] = useState(false);
    const [coporate, setCoporate] = useState(false);
    const [customMsg, setCustomMsg] = useState('');
    const [loggedType, setLoggedType] = useState('');
    const [loggedMedia, setloggedMedia] = useState('');
    
    //modal previois button action
    const onchnagePrevious = () => {
      if(step > 0){
        const set = step-1;
        setStep(set);
      }
    }

    //when load page
    useEffect(() => {
      formikMethod.setFieldValue('bankslip', [])
        try{
            let user = JSON.parse(sessionStorage.getItem('user'));
            if(user.corporate != null && user.corporate == 1){
                setCoporate(true);
            }else{
                setCoporate(false);
            }

            if (user['mobile'] !== "undefined") {
                setLoggedType(2); // 2 = Mobile
                setloggedMedia(user['mobile']);
            } else {
                setLoggedType(1); // 1 = email 
                setloggedMedia(user['email']);
            }
        }catch(error){
            setCoporate(false);
        }
    }, [])
    


    //validations
  const validationLock = Yup.object({
      fund : Yup.string().required("Required"),
      account : Yup.string().required("Required"),
      redeemtype : Yup.string().required("Required"),
      redeemmethod : Yup.string().when(['redeemtype'], (redeemtype, schema) => {
        return redeemtype == 2? schema.required('Required') : schema.notRequired();
      }),
      value:Yup.string().when(['redeemmethod'], (redeemmethod, schema) => {
        return redeemmethod == 1? schema.required('Required'): schema.notRequired();
      }),
      units:Yup.string().min(1, 'Unit must be grater than zero').when(['redeemmethod'], (redeemmethod, schema) => {
        return redeemmethod == 2? schema.required('Required') : schema.notRequired();
      }),
  });

  const formikLock = useFormik({
    initialValues: {
        fund: '',
        account: '',
        redeemtype: '',
        redeemmethod: '',
        value: '',
        units: '',
        totalunits:0,
        numberofunits:0,
        availabale:0,
      },
      validateOnChange:false,
      validateOnBlur:false,
      validationSchema: validationLock,
      // Login Request
      onSubmit: async(values) => {
          if(values.totalunits >= values.numberofunits && values.redeemtype == 1){
            setUnitError(true)
            return false;
          }else{
            setUnitError(false)  
          }

          //check avaialble and enterd
          if(values.redeemmethod == 1){
                if(Number(values.value) > Number(values.availabale)){
                    setValueError(true);
                    return false;
                }else{
                    setValueError(false); 
                }
          }else{
                if(Number(values.units) > Number(values.availabale)){
                    setValueError(true);
                    return false;
                }else{
                    setValueError(false); 
                }
          }
            let detail = {
                fund:fundList.filter((value) => value.FUND_CODE == values.fund)[0].FUND_NAME,
                account:values.account,
                amount:values.redeemmethod == '1'?values.value:values.units,
                redeemtype:values.redeemtype == '1'?'Full':'Partial',
                redeemmethod:values.redeemmethod == '1'?'By Value':'By Units'
            }
          setSellSection(values);
          setDetails(detail);
          setStep(1);
      },
  });

  
  useEffect(() => {
    setValueError('');
  }, [formikLock.values.redeemmethod])

  //validations
  const validationMethod = Yup.object({
      bankslip : coporate== true?Yup.array().min(1, "Required"):Yup.array().notRequired(),
      terms : Yup.string().required("Required"),
  });

  const formikMethod = useFormik({
      initialValues: {
        bankslip: [],
        terms: '',
      },
      validateOnChange:false,
      validateOnBlur:false,
      validationSchema: validationMethod,
      // Login Request
      onSubmit: async(values, {resetForm}) => {
        setFile(values.bankslip);
        try{
            handleLockRequest(values);
        //  formikLock.resetForm();
          // resetForm();
        }catch(error){
        // formikLock.resetForm();
          resetForm();
          setStep(3);
        }
      },
  });

  //handle sell request
  const handleLockRequest = async(values) => {
    try{
        const fd = new FormData();
        if(sellsection.redeemtype==1){
        let numofunit = activeAcountList.filter((value) => value.FUND_CODE == sellsection.fund)[0];
          fd.append('FUND_CODE', sellsection.fund);
          fd.append('ACCOUNT_NO', sellsection.account);
          fd.append('redeem_type', sellsection.redeemtype);
          fd.append('redeem_method', 2);
          fd.append('NO_OF_UNITS', numofunit.NO_OF_UNITS);
          values.bankslip.length > 0 && Array.from(values.bankslip).forEach(files => {
              fd.append('documents[]', files);
          })
        }else if(sellsection.redeemtype==2 && sellsection.redeemmethod == 1){
          fd.append('FUND_CODE', sellsection.fund);
          fd.append('ACCOUNT_NO', sellsection.account);
          fd.append('redeem_type', sellsection.redeemtype);
          fd.append('redeem_method', sellsection.redeemmethod);
          fd.append('AMOUNT', sellsection.value);
          values.bankslip.length > 0 && Array.from(values.bankslip).forEach(files => {
              fd.append('documents[]', files);
          })     
        }else{
          fd.append('FUND_CODE', sellsection.fund);
          fd.append('ACCOUNT_NO', sellsection.account);
          fd.append('redeem_type', sellsection.redeemtype);
          fd.append('redeem_method', sellsection.redeemmethod);
          fd.append('NO_OF_UNITS', sellsection.units);
          values.bankslip.length > 0 && Array.from(values.bankslip).forEach(files => {
              fd.append('documents[]', files);
          })     
        }

        let data = await api.postRequest('lock', fd);
        if(data.status == 200){
          if(data.data.status == 'success'){
            setStep(2);
          }else{
            setStep(3);
          }
        }else{
          setCustomMsg('Incorrect Otp Enterd!');
        }
        formikLock.resetForm();
        formikMethod.resetForm();
        formikMethod.setFieldValue('bankslip', [])
        setRefresh(!refreshList);
    }catch(error){
      formikLock.resetForm();
      formikMethod.resetForm();
      formikMethod.setFieldValue('bankslip', [])
      setCustomMsg('Incorrect Otp Enterd!');
    }
  }


  //change step and submit
  const onChangeStep = () => {
    if(step == 0){
        formikLock.handleSubmit();
    }else if(step == 1){
        formikMethod.handleSubmit();
    }else{
      setStep(0);
      onHide(true);
    }
  }

  //error msg set
  const handleSetError = (e) =>{
    setCustomMsg(e)
  }

    return (
      <Modal
        show={show}
        onHide={onHide}
        backdrop="static"
        keyboard={false}
        size="md"
        style={{fontSize:13}}
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header style={{padding:'0.3rem 0.3rem'}}>
          <Modal.Title id="contained-modal-title-vcenter">
            <FontAwesomeIcon icon={faLock} style={{paddingLeft:'8px'}}/>
            &nbsp;
            LOCK
          </Modal.Title>
          <div className="steps">
            <Stepper 
              steps={[
                {title: ''}, 
                {title: ''}, 
                {title: ''}, 
              ]} 
              activeStep={step}
              size={15}
              circleFontSize={0}
              completeColor='#5cb85c'
              activeColor={1 == 1?'#5096FF':'#d9534f'}
              defaultBorderWidth={2}
              />
          </div>
        </Modal.Header>
        <Modal.Body style={{ paddingBottom:0}}>
          {
            step == 0?
              <LockForm formik={formikLock} fundList={fundList} activeAcountList={activeAcountList} error={unitError} error2={valueError}/>
            :(
              step == 1?
              <div className="animate__animated animate__fadeIn">
                <LockMethod formik={formikMethod} details={details} coporate={coporate}/>
              </div>
              : (
                    step == 2?
                    <div className="animate__animated animate__fadeIn">
                        <Success closeTime={10} close={onHide} step={setStep}/>
                    </div>
                    :
                    <div className="animate__animated animate__fadeIn">
                        <Error closeTime={10} close={onHide} step={setStep}/>
                    </div>
                )
            )
          }
              
        </Modal.Body>
        <Modal.Footer style={{padding:'0.3rem 0.3rem', paddingLeft:'8px',paddingRight:'8px', paddingBottom:8, display:'flex', justifyContent:step > 1?'end':'space-between', borderTop:'none'}}>
          {
            (
            step == 0?
              <Button  className="btn btn-danger btn-red" style={{width:80, display:step > 1?'none':''}} onClick={(e) => {
                onHide();
                formikLock.resetForm();
                formikMethod.resetForm();
                formikMethod.values.bankslip.splice(0, formikMethod.values.bankslip.length);
              }}>Cancel</Button>
              :
              <Button className="btn btn-danger btn-red" style={{width:80, display:step > 1?'none':''}} onClick={onchnagePrevious}>Previous</Button>
            )
          }
        
          <Button className="btn-btn-success btn-green" style={{width:80}}  onClick={onChangeStep}>Continue</Button>
        </Modal.Footer>
      </Modal>
    );
  }

  export default LockModel;