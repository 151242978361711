import React, {useState,useEffect} from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Chart3 } from "./charts/chart.3";
import { Chart1 } from "./charts/chart1";
import { Chart2 } from "./charts/chart2";

const Chart = ({userFund, fixedFundChart, equityFundChart, allFundChart, setTableActive}) => {
    const [chartZoom, setChartZoom] = useState('');
    const [chartPlace, setChartPlace] = useState(0);
    const [chart1, setChart1] = useState([]);
    const [chart2, setChart2] = useState([]);
    const [chart3, setChart3] = useState([]);


    useEffect(() => {
        handleChartZoom(0);
        setTableActive(2);
    }, [])

    useEffect(() => {
        let fundValues = [];
        let colors = [];

        fixedFundChart.length > 0 && fixedFundChart.map((value) => {
          fundValues.push(value.precen)
          colors.push(value.color)
        })

        let data1 = {
            datasets: [
                {
                  label: '# of funds',
                  data: fundValues,
                  backgroundColor:colors,
                  borderColor:colors,
                  hoverBackgroundColor:colors,
                  borderWidth: 1,
                },
              ]
        }
        setChart1(data1)
    }, [fixedFundChart])

    useEffect(() => {
      let fundValues = [];
      let colors = [];

      equityFundChart.length > 0 && equityFundChart.map((value) => {
        fundValues.push(value.precen)
        colors.push(value.color)
      })

      let data1 = {
          datasets: [
              {
                label: '# of funds',
                data: fundValues,
                backgroundColor:colors,
                borderColor:colors,
                hoverBackgroundColor:colors,
                borderWidth: 1,
              },
            ]
      }
      setChart3(data1)
  }, [equityFundChart])

  useEffect(() => {
    let fundValues = [];
    let colors = [];

    allFundChart.length > 0 && allFundChart.map((value) => {
      fundValues.push(value.precen)
      colors.push(value.color)
    })

    let data1 = {
        datasets: [
            {
              label: '# of funds',
              data: fundValues,
              backgroundColor:colors,
              borderColor:colors,
              hoverBackgroundColor:colors,
              borderWidth: 1,
            },
          ]
    }
    setChart2(data1)
}, [allFundChart])
    

    const handleChartZoom = (num) => {
            setChartPlace(num);
            setTableActive(num);
            setChartZoom('lg-chart animate__animated animate__zoomIn animate__faster');
    }

    return(
        <div className="dougnet">
            <div className="row" style={{alignItems:'center'}}>
              {
                fixedFundChart.length > 0 ?
                  <div className={`text-center ${chartPlace == 1?chartZoom:'sm-chart'}`} onClick={(e) => handleChartZoom(1)} style={{cursor:'pointer'}}>
                      <Chart1 data={chart1}/>
                      <p style={{fontSize:13, marginTop:10}}><strong>Fixed Income Fund</strong></p>
                  </div>
                :''
              }
                
              {
               allFundChart.length > 0 ?
                <div onClick={(e) => handleChartZoom(2)} className={`text-center ${chartPlace == 2?chartZoom:(chartPlace == 0?'lg-chart':'sm-chart')}`} style={{cursor:'pointer'}}>
                  <Chart2 data={chart2}/>
                  <p style={{fontSize:13, marginTop:10}}><strong>Acquired funds</strong></p>
                </div>
                :''
              }
               
               {
                 equityFundChart.length > 0 ?
                  <div onClick={(e) => handleChartZoom(3)} className={`text-center ${chartPlace == 3?chartZoom:'sm-chart'}`} style={{cursor:'pointer'}}>
                      <Chart3 data={chart3}/>
                      <p style={{fontSize:13, marginTop:10}}><strong>Equity Fund</strong></p>
                  </div>
                  :''
               }
               
            </div>
        
        </div>
    )
}

export default Chart;