import React, {useEffect, useState} from "react";
import { Form, Col, Row } from "react-bootstrap";
import { NumericFormat } from 'react-number-format';
import { NumberFormatInput } from "../../../../helpers";


//buy form
const BuyForm = ({formik, acountList, fundList}) => {
    const [fundlist, setFundList] = useState([]);
    const [accountList, setAccountList] = useState([]);

    useEffect(() => {
        setFundList(fundList);
    }, [fundList])
    
    useEffect(() => {
        if(formik.values.fund != ''){
            let list = acountList.filter((value) => value.FUND_CODE == formik.values.fund);
            setAccountList(list);
        }else{
            setAccountList([]); 
        }
    }, [formik.values.fund])

    //hanlde amount
    const handleamount = (e) => {
        const re = /^[0-9]*\.?[0-9]*$/;
    
        if (e.target.value === '' || re.test(e.target.value)) {
           formik.setFieldValue('amount', e.target.value);
        }
    }

    return(
        <Form>
        <Form.Group as={Row} className="mb-3" controlId="fund">
        <Form.Label column sm="3">
            Fund
        </Form.Label>
        <Col sm="8">
        <Form.Select 
            className="text-small" 
            name="fund"
            autoComplete="off"
            isInvalid={Boolean(formik.errors.fund)}
            onChange={formik.handleChange}
            value={formik.values.fund}
            size="sm">
                 <option value="">Select Fund</option>
            {
                fundlist.length > 0 && fundlist.map((value) => {
                    return(
                        <option value={value.FUND_CODE}>{value.FUND_NAME}</option>
                    )
                })
            }
           
        </Form.Select>
        {formik.errors.fund && 
        <div className="col-md-12">
        <span className="srv-validation-message"><span className="error-palate">*&nbsp;{formik.errors.fund}</span></span>
        </div>
        }  
        </Col>
        </Form.Group>
        <Form.Group as={Row}  className="mb-3" controlId="fund">
        <Form.Label column sm="3">
            Account
        </Form.Label>
        <Col sm="8">
        <Form.Select 
            name="account" 
            className="text-small" 
            autoComplete="off"
            isInvalid={Boolean(formik.errors.account)}
            onChange={formik.handleChange}
            value={formik.values.account} 
            size="sm">
            <option value={''}>Select Account</option>
            <option value={'1'}>New</option>
            {
                accountList.length > 0 && accountList.map((value) => {
                    return(
                        <option value={value.ACCOUNT_NO}>{value.ACCOUNT_NO}</option> 
                    )
                })
            }
        </Form.Select>
        {formik.errors.account && 
        <div className="col-md-12">
        <span className="srv-validation-message"><span className="error-palate">*&nbsp;{formik.errors.account}</span></span>
        </div>
        } 
        </Col>
  
        </Form.Group>
        <Form.Group as={Row} className="mb-3" controlId="fund">
        <Form.Label column sm="3">
            Amount (LKR)
        </Form.Label>
        <Col sm="8">
            {/* <Form.Control 
                as={NumberFormatInput}
                name="amount" 
                className="text-small"
                autoComplete="off"
                isInvalid={Boolean(formik.errors.amount)}
                onChange={handleamount}
                value={formik.values.amount}  
                size="sm" 
                type="text" 
                
                /> */}

            <NumberFormatInput
                formik={formik}
                name="amount"
                onValueChange={handleamount}
                thousandSeparator={true}
            />
        {formik.errors.amount && 
        <div className="col-md-12">
        <span className="srv-validation-message"><span className="error-palate">*&nbsp;{formik.errors.amount}</span></span>
        </div>
        } 
        </Col>
        </Form.Group>
        </Form>
    )
}

export default BuyForm;
