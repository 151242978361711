import React, {useEffect, useRef, useState} from 'react';
import Loader from '../../components/common/loader';
import TopNav from '../../components/common/topnav';
import Profile from '../../components/common/profile';
import Footer from '../../components/common/footer';
import { useHistory } from 'react-router-dom';
import '../../css/stepper-vertical.css';
import Stepper from '../../components/regcomplete/stepper';
import FirstApplicant from '../../components/regcomplete/form/firstApplicant';
import SecoundApplication from '../../components/regcomplete/form/secoundApplication';
import BankAccount from '../../components/regcomplete/form/bankAccount';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import TermsCondition from '../../components/regcomplete/form/termsCondition';
import Kyc from '../../components/regcomplete/form/kyc';
import Complete from '../../components/regcomplete/form/complete';
import SecoundApplicatBrake from '../../components/regcomplete/form/secoundApplicatBrake';
import Nomination from '../../components/regcomplete/form/nomination';
import api from '../../services/api';
import moment from 'moment';

const Regcomplete = () => {
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const [completed, setCompleted] = useState(0);
    const [step, setStep] = useState(1);
    const [firstApplicant, setFirstApplicant] = useState({});
    const [secondApplicant, setSecondApplicant] = useState({});
    const [bankAccount, setBankAccount] = useState([]);
    const [term, setTerm] = useState(false);
    const [kyc, setKyc] = useState({});
    const [cacheDataSet, setCacheDataSet] = useState({});
    const [userId, setUserId] = useState(null);
    const [bankAccountError, setBankAccountError] = useState(null);
    const [checked, setChecked] = useState(false);
    const [checkedError, setCheckedError] = useState(false);
    const [timeLine, setTimeLine] = useState([]);
    const [ submitting, setSubmitting] = useState(false);
    const [secondApplicantOnly, setSecondApplicantOnly] = useState(false);
    const [customError, setCustomError] = useState(null);
    const [fundError, setFundError] = useState(null);
    const [nominees, setNominees] = useState([]);
    const [nomineesError, setNomineesError] = useState(null);
    const [name, setName] = useState(null);
    const [manualRender, setManualRender] = useState(false);

    //agree
    const [firstDeclare, setFirstDeclare] = useState(false);
    const [firstDeclareInvalid, setFirstDeclareInvalid] = useState(false);
    const [secondDeclare, setSecondDeclare] = useState(false);
    const [secondDeclareInvalid, setSecondDeclareInvalid] = useState(false);
    const [secondContactDeclare, setSecondContactDeclare] = useState(false);
    const [secondContactDeclareInvalid, setSecondContactDeclareInvalid] = useState(false);
    const [bankDeclare, setBankDeclare] = useState(false);
    const [bankDeclareInvalid, setBankDeclareInvalid] = useState(false);
    const [nominationDeclare, setNominationDeclare] = useState(false);
    const [nominationDeclareInvalid, setNominationDeclareInvalid] = useState(false);
    const [kycDeclare, setKycDeclare] = useState(false);
    const [kycDeclareInvalid, setKycDeclareInvalid] = useState(false);
    const [nomineeTerms, setNomineeTerms] = useState(false);
    const [nomineeTermsError, setNomineeTermsError] = useState(false);


    const firstScrollref = useRef();
    const secondScrollRef = useRef();
    const secondContactScrollRef = useRef();
    const bankScrollRef = useRef();
    const nominationRef = useRef();
    const kycRef = useRef();


    useEffect(() => {
        document.body.classList.remove('body-register')
        document.body.classList.remove('body-login')
    }, [])

    const LogoutHome = () => {
        if(sessionStorage.getItem('token') == undefined || sessionStorage.getItem('token') == '' || sessionStorage.getItem('token') == null){
            history.push('/');
        }
    }

    const getbdy = (nicn) => {
        var dayText = 0;
        var year = "";
        var month = "";
        var day = "";
        var gender = "";
        // Year
        if (nicn.length == 10) {
            year = "19" + nicn.substr(0, 2);
            dayText = parseInt(nicn.substr(2, 3));
        } else {
            year = nicn.substr(0, 4);
            dayText = parseInt(nicn.substr(4, 3));
        }
        if ( (dayText>0 && dayText<367) || (dayText>500 && dayText<867) ){
    
            if(dayText>500){
                dayText=dayText-500;
            }
        //Month
            if (dayText > 335) {
                day = dayText - 335;
                month = '12';
            }
            else if (dayText > 305) {
                day = dayText - 305;
                month = '11';
            }
            else if (dayText > 274) {
                day = dayText - 274;
                month = '10';
            }
            else if (dayText > 244) {
                day = dayText - 244;
                month = '09';
            }
            else if (dayText > 213) {
                day = dayText - 213;
                month = '08';
            }
            else if (dayText > 182) {
                day = dayText - 182;
                month = '07';
            }
            else if (dayText > 152) {
                day = dayText - 152;
                month = '06';
            }
            else if (dayText > 121) {
                day = dayText - 121;
                month = '05';
            }
            else if (dayText > 91) {
                day = dayText - 91;
                month = '04';
            }
            else if (dayText > 60) {
                day = dayText - 60;
                month = '03';
            }
            else if (dayText < 32) {
                month = '01';
                day = dayText;
            }
            else if (dayText > 31) {
                day = dayText - 31;
                month = '02';
            }
            if(day<10){
                    day="0"+day;
            }
            let date = year+'-'+month+'-'+day;
               return date;
            }
    }

    useEffect(() => {
        let user = sessionStorage.getItem('user');
        let decoded = JSON.parse(user);
        let unique_id = null;
        if(user != null){
            if(decoded?.mobile != undefined && decoded?.mobile != null){
                unique_id = decoded?.mobile;
            }else{
                unique_id = decoded?.email;
            }
            setUserId(unique_id);
        } 
        //check previous data
        let cache_id = 'senfin_asset_'+unique_id;
        let cached_data = localStorage.getItem(cache_id);
        if(cached_data !== null){
            let data_decoded = JSON.parse(cached_data);
            if(data_decoded?.firstApplicant != undefined && data_decoded?.firstApplicant != null){
                formikFirstApplicant.setValues(data_decoded?.firstApplicant);
            }
            if(data_decoded?.secondApplicant != undefined && data_decoded?.secondApplicant != null){
                data_decoded.secondApplicant.nic_back='';
                data_decoded.secondApplicant.nic_front='';
                data_decoded.secondApplicant.passport='';
                data_decoded.secondApplicant.other_doc='';
                formikSecondApplicant.setValues(data_decoded?.secondApplicant);
            }
            if(data_decoded?.secondApplicantContact != undefined && data_decoded?.secondApplicantContact != null){
                formikSecondApplicantContact.setValues(data_decoded?.secondApplicantContact);
            }
            if(data_decoded?.bankAccount != undefined && data_decoded?.bankAccount != null){
                setBankAccount(data_decoded?.bankAccount);
            }
            if(data_decoded?.nominees != undefined && data_decoded?.nominees != null){
                setNominees(data_decoded?.nominees);
            }
        }
        LogoutHome();
    }, [])


    const validationFirstApplicant = Yup.object({
        title:Yup.string().required("Title is required"),
        firstname:Yup.string().required("First Name is required"),
        nic:Yup.string().required("Required") 
        .when(['type'], (type, schema) => {
            return type == 'nic'?schema.max(12, 'Invalid Identification Number').min(10, 'Invalid Identification Number'):schema.max(20)
        }),
        dob:Yup.string().required("Date of birth is required"),
        nationality_type:Yup.string().required("Nationality is required"),
        resident:Yup.string().required("Resident is required"),
        street:Yup.string().required("Address is required"),
        mobile:Yup.string().required("Mobile is required"),
        email:Yup.string().required("Email is required").email('Invalid email address'),
        occupation:Yup.string().required("Occupation is required"),
        employerName:Yup.string().required("Employer Name is required"),
        employerAddress:Yup.string().required("Employer Address is required"),
        currency:Yup.string().required("Currency is required"),
        soi:Yup.string().required("Source of income is required"),
        investment:Yup.string().required("Investment is required"),
        nationality:Yup.string().test('is-sri-lankan', 'Nationality Required', (value, context) => {
            if(formikFirstApplicant.values.nationality_type == 2 && formikFirstApplicant.values.nationality == ''){
                return false;
            }else{
                return true;
            }
        }),
        pep_relationship:Yup.string().test('is-pep-relative', 'Relationship is Required', (value, context) => {
            if(formikFirstApplicant.values.pep == 1 && formikFirstApplicant.values.pep_relationship == ''){
                return false;
            }else{
                return true;
            }
        }),
        other_income:Yup.string().test('is-other-income-is-required', 'Other Income is Required', (value, context) => {
            if(formikFirstApplicant.values.soi.includes(4) == true && formikFirstApplicant.values.other_income == ''){
                return false;
            }else{
                return true;
            }
        })
    });

    //formkin form submition Invidual existsting
    const formikFirstApplicant = useFormik({
        initialValues: {
            funds:[],
            title:"Mr:",
            firstname:'',
            nic:'',
            dob:'',
            nationality_type:1,
            resident:1,
            street:'',
            type:'nic',
            town:'',
            country:'',
            corres_street:'',
            corres_town:'',
            corres_country:'',
            mobile:'',
            business:'',
            home:'',
            email:'',
            occupation:'',
            employerName:'',
            employerAddress:'',
            soi:'11',
            investment:'10',
            account_type:1,
            instruction:1,
            nationality:'',
            isAddressPremenent:false,
            pep:0,
            pep_relationship:'',
            other_income:'',
            currency:'',
        },
        validateOnChange:false,
        validateOnBlur:false,
        validationSchema: validationFirstApplicant,
        
        // Login Request
        onSubmit: async(values) => {
            let count = completed;
            let stepNum = step;
            let timeline_obj = timeLine;

            if(firstDeclare == false){
                setFirstDeclareInvalid(true);
                firstScrollref.current?.scrollIntoView({ behavior: "smooth" })
                return false;
            }else{
                setFirstDeclareInvalid(false);
            }

            if(values.funds.length == 0){
                setFundError('At least one fund is required');
                return false;
            }else{
                setFundError(null);
            }

            let server_send = {
                accountType:values.account_type,
                instruction:values.instruction,
                TITLE:values.title,
                FIRST_NAME:values.firstname,
                type:values.type,
                DATE_OF_BIRTH:values.dob,
                NATIONALITY:values.nationality_type == 1?'Sri Lankan':values.nationality,
                PER_ADD_STREET:values.street,
                PER_ADD_TOWN:values.town,
                COUNTRY:values.country,
                PER_ADD_POSTAL_CODE:'',
                MOBILE_NO:'+'+values.mobile,
                LAND_NO:values.home != ''?'+'+values.home:'',
                EMAIL:values.email,
                OCCUPATION:values.occupation,
                INCOME_SOURCE:values.soi,
                SEC_ADD_STREET:values.corres_street,
                INCOME_ANUAL:values.investment,
                SEC_ADD_TOWN:values.corres_town,
                SEC_ADD_COUNTRY:values.corres_country,
                SEC_ADD_POSTAL_CODE:'',
                SEC_POSTAL_AREA:'',
                CURRENCY_TYPE:values.currency,
                employerName:values.employerName,
                employerAddress:values.employerAddress,
                pep:values.pep,
                pep_relationship:values.pep_relationship,
                funds:values.funds,
                other_income:values.other_income,
                isAddressPremenent:values.isAddressPremenent,
            }

            let validate_find = {
                TITLE:'title',
                FIRST_NAME:'firstname',
                type:'type',
                DATE_OF_BIRTH:'dob',
                NATIONALITY:'nationality',
                PER_ADD_STREET:'street',
                PER_ADD_TOWN:'town',
                COUNTRY:'country',
                PER_ADD_POSTAL_CODE:'',
                MOBILE_NO:'mobile',
                LAND_NO:'home',
                EMAIL:'email',
                INCOME_SOURCE:'soi',
                OCCUPATION:'occupation',
                CURRENCY_TYPE:'currency',
                INCOME_ANUAL:'investment',
                funds:'funds',
                other_income:'other_income',
                employerName:'employerName',
                employerAddress:'employerAddress',
                pep:'pep',
                pep_relationship:'pep_relationship',
                isAddressPremenent:'isAddressPremenent'
            }
            if(values.type == 'nic'){
                server_send.NIC = values.nic;
                validate_find.NIC = 'nic';
                let birthday = getbdy(values.nic);
                if(birthday != values.dob){
                    formikFirstApplicant.setFieldError('dob', 'Invalid Date of Birth');
                    return false;
                }
            }else if(values.type == 'passport'){
                server_send.PASSPORT = values.nic;
                validate_find.PASSPORT = 'nic';
            }else{
                server_send.OTHER_NO = values.nic;
                validate_find.OTHER_NO = 'nic';
            }

            let validate = await api.postRequest('application/validate', server_send)
            .then((response) =>{
                timeline_obj.filter((val) => val.id == 1)[0].status = 'complete';
                if(values.account_type == 1){
                    if(completed <= 4){
                        count+=2;
                    }
                    if(step < 5){
                        stepNum+=2;
                    }
                }else{
                    if(completed <= 5){
                        count+=1;
                    }
                    if(step < 6){
                        stepNum+=1;
                    }
                }
                setFirstApplicant(server_send);
                setStep(stepNum);
                setCompleted(count);
    
                //cache data
                let cache = cacheDataSet;
                cache.firstApplicant = values;
                localStorage.setItem(`senfin_asset_${userId}`, JSON.stringify(cache));
                setCacheDataSet(cache);
                setTimeLine(timeline_obj);
            })
            .catch((e) => {
                if(e.response){
                    let errors =e?.response?.data?.errors;
                    if(errors != undefined && errors != null){
                        Object.keys(errors).map((keyName, i) => {
                            let field = validate_find[keyName];
                            formikFirstApplicant.setFieldError(field, errors[keyName]);
                        })
                    }
                }
            })
        },
    });

    useEffect(() => {
        console.log('formikFirs', formikFirstApplicant.values);
    }, [])

    const validationSecondApplicant = Yup.object({
        title:Yup.string().required("Title is required"),
        firstname:Yup.string().required("First Name is required"),
        nic:Yup.string().required("Required") 
        .when(['type'], (type, schema) => {
            return type == 'nic'?schema.max(12, 'Invalid Identification Number').min(10, 'Invalid Identification Number'):schema.max(20)
        }),
        dob:Yup.string().required("Date of birth is required"),
        nationality_type:Yup.string().required("Nationality is required"),
        resident:Yup.string().required("Resident is required"),
        street:Yup.string().required("Address is required"),
        mobile:Yup.string().required("Mobile is required"),
        email:Yup.string().required("Email is required").email('Invalid email address'),
        occupation:Yup.string().required("Occupation is required"),
        employerName:Yup.string().required("Employer Name is required"),
        employerAddress:Yup.string().required("Employer Address is required"),
        currency:Yup.string().required("Currency is required"),
        soi:Yup.string().required("Source of income is required"),
        investment:Yup.string().required("Investment is required"),
        nationality:Yup.string().test('is-sri-lankan', 'Nationality Required', (value, context) => {
            if(formikFirstApplicant.values.nationality_type == 2 && formikFirstApplicant.values.nationality == ''){
                return false;
            }else{
                return true;
            }
        }),
        pep_relationship:Yup.string().test('is-pep-relative', 'Relationship is Required', (value, context) => {
            if(formikFirstApplicant.values.pep == 1 && formikFirstApplicant.values.pep_relationship == ''){
                return false;
            }else{
                return true;
            }
        }),
        nic_back:Yup.string().test('is-nic-back-is-required', 'NIC Back is required', (value, context) => {
            if(formikSecondApplicant.values.type == 'nic' && formikSecondApplicant.values.nic_back == ''){
                return false;
            }else{
                return true;
            }
        }),
        nic_front:Yup.string().test('is-nic-is-required', 'NIC Front is required', (value, context) => {
            if(formikSecondApplicant.values.type == 'nic' && formikSecondApplicant.values.nic_front == ''){
                return false;
            }else{
                return true;
            }
        }),
        passport:Yup.string().test('is-passport-is-required', 'Passport is required', (value, context) => {
            if(formikSecondApplicant.values.type == 'passport' && formikSecondApplicant.values.passport == ''){
                return false;
            }else{
                return true;
            }
        }),
        other_doc:Yup.string().test('is-other-doc-is-required', 'Identification Document is required', (value, context) => {
            if(formikSecondApplicant.values.type == 'other_no' && formikSecondApplicant.values.other_doc == ''){
                return false;
            }else{
                return true;
            }
        }),
        other_income:Yup.string().test('is-other-income-is-required', 'Other Income is Required', (value, context) => {
            if(formikSecondApplicant.values.soi.includes(4) == true && formikSecondApplicant.values.other_income == ''){
                return false;
            }else{
                return true;
            }
        }),
        pep_relationship:Yup.string().test('is-pep-relative', 'Relationship is Required', (value, context) => {
            if(formikSecondApplicant.values.pep == 1 && formikSecondApplicant.values.pep_relationship == ''){
                return false;
            }else{
                return true;
            }
        })
    });

    const submitSecondApplicantData = async(data) => {
        setSubmitting(true);
        let response = await api.postRequest('application', {
            firstApplicant:data,
            secondApplicant:{},
            bankAccount:{},
            kyc:{}
        }).catch(() => {
            setCustomError('Something went wrong');
        });
        if(response.status == 200){
            setCustomError(null);
            let count = completed;
            let stepNum = step;
            let timelineCount = timeLine.length;
            let timeline_obj = timeLine;
            timeline_obj.filter((val) => val.id == 7)[0].status = 'complete';
            if(completed <= timelineCount){
                count+=1;
            }
            if(step < timelineCount+1){
                stepNum=7;
            }
            setStep(stepNum);
            setCompleted(count);
            setTimeLine(timeline_obj);
        }else{
            setCustomError('Something went wrong');
        }
        setSubmitting(false);
    }

    //formkin form submition second existsting
    const formikSecondApplicant = useFormik({
        initialValues: {
            title:"Mr:",
            firstname:'',
            type:'nic',
            nic:'',
            dob:'',
            nationality_type:1,
            resident:1,
            street:'',
            town:'',
            country:'',
            corres_street:'',
            corres_town:'',
            corres_country:'',
            mobile:'',
            business:'',
            home:'',
            email:'',
            occupation:'',
            employerName:'',
            employerAddress:'',
            soi:'11',
            investment:'10',
            nationality:'',
            isAddressPremenent:false,
            pep:0,
            pep_relationship:'',
            nic_front:'',
            nic_back:'',
            passport:'',
            other_doc:'',
            other_income:'',
            currency:'',
        },
        validateOnChange:false,
        validateOnBlur:false,
        validationSchema: validationSecondApplicant,
        // Login Request
        onSubmit: async(values) => {
            let count = completed;
            let stepNum = step;
            let timeline_obj = timeLine;
            
                if(secondDeclare == false){
                    setSecondDeclareInvalid(true);
                    secondScrollRef.current?.scrollIntoView({ behavior: "smooth" })
                    return false;
                }else{
                    setSecondDeclareInvalid(false);
                }

               let nic_back ='';
               let nic_front ='';
               let passport = '';
               let other_doc = '';
               try{
                   if(values.nic_back != ''){
                       nic_back = await fileToBase64(values.nic_back);
                   }
                   if(values.nic_front != ''){
                       nic_front = await fileToBase64(values.nic_front);
                   }
                   if(values.passport != ''){
                       passport = await fileToBase64(values.passport);
                   }
                   if(values.other_doc != ''){
                       other_doc = await fileToBase64(values.other_doc);
                   }
               }catch(error){
                   console.log('file convert error: '+error)
               }
            let server_send = {
                accountType:1,
                instruction:1,
                TITLE:values.title,
                FIRST_NAME:values.firstname,
                type:values.type,
                DATE_OF_BIRTH:values.dob,
                NATIONALITY:values.nationality_type == 1?'Sri Lankan':values.nationality,
                PER_ADD_STREET:values.street,
                PER_ADD_TOWN:values.town,
                COUNTRY:values.country,
                PER_ADD_POSTAL_CODE:'',
                MOBILE_NO:'+'+values.mobile,
                LAND_NO:values.home != ''?'+'+values.home:'',
                EMAIL:values.email,
                OCCUPATION:values.occupation,
                INCOME_SOURCE:values.soi,
                SEC_ADD_STREET:values.corres_street,
                SEC_ADD_TOWN:values.corres_town,
                SEC_ADD_COUNTRY:values.corres_country,
                SEC_ADD_POSTAL_CODE:'',
                CURRENCY_TYPE:values.currency,
                INCOME_ANUAL:values.investment,
                SEC_POSTAL_AREA:'',
                nic_sec_back:nic_back,
                nic_sec_front:nic_front,
                passport_sec:passport,
                other_doc_sec:other_doc,
                other_income:values.other_income,
                employerName:values.employerName,
                employerAddress:values.employerAddress,
                pep:values.pep,
                pep_relationship:values.pep_relationship,
                isAddressPremenent:values.isAddressPremenent,
            }
           
            let validate_find = {
                TITLE:'title',
                FIRST_NAME:'firstname',
                type:'type',
                DATE_OF_BIRTH:'dob',
                NATIONALITY:'nationality',
                PER_ADD_STREET:'street',
                PER_ADD_TOWN:'town',
                COUNTRY:'country',
                PER_ADD_POSTAL_CODE:'',
                MOBILE_NO:'mobile',
                LAND_NO:'home',
                EMAIL:'email',
                INCOME_SOURCE:'soi',
                OCCUPATION:'occupation',
                nic_sec_back:'nic_back',
                nic_sec_front:'nic_front',
                passport_sec:'passport',
                INCOME_ANUAL:'investment',
                other_doc_sec:'other_doc',
                other_income:'other_income',
                CURRENCY_TYPE:'currency',
                employerName:'employerName',
                employerAddress:'employerAddress',
                pep:'pep',
                pep_relationship:'pep_relationship',
                isAddressPremenent:'isAddressPremenent',
            }
            if(values.type == 'nic'){
                server_send.NIC = values.nic;
                validate_find.NIC = 'nic';
                let birthday = getbdy(values.nic);
                if(birthday != values.dob){
                    formikSecondApplicant.setFieldError('dob', 'Invalid Date of Birth');
                    return false;
                }
            }else if(values.type == 'passport'){
                server_send.PASSPORT = values.nic;
                validate_find.PASSPORT = 'nic';
            }else{
                server_send.OTHER_NO = values.nic;
                validate_find.OTHER_NO = 'nic';
            }

            let validate = await api.postRequest('application/validate', server_send)
            .then((response) =>{
                let timelineCount = timeLine.length;
                timeline_obj.filter((val) => val.id == 2)[0].status = 'complete';
    
                if(secondApplicantOnly == true){
                    setTimeLine(timeline_obj);
                    submitSecondApplicantData(server_send);
                }else{
                    if(completed <= timelineCount){
                        count+=1;
                    }
                    if(step < timelineCount+1){
                        stepNum+=1;
                    }
                    setSecondApplicant(server_send);
        
                    setStep(stepNum);
                    setCompleted(count);
        
                    let cache = cacheDataSet;
                    cache.secondApplicant = values;
                    localStorage.setItem(`senfin_asset_${userId}`, JSON.stringify(cache));
                    setCacheDataSet(cache);
                    setTimeLine(timeline_obj);
                }
            })
            .catch((e) => {
                if(e.response){
                    let errors =e?.response?.data?.errors;
                    if(errors != undefined && errors != null){
                        Object.keys(errors).map((keyName, i) => {
                            let field = validate_find[keyName];
                            formikSecondApplicant.setFieldError(field, errors[keyName]);
                        })
                    }
                }
            })
        },
    });

    const validationSecondApplicantContact = Yup.object({
        name:Yup.string().required("Name is required"),
        mobile:Yup.string().required("Mobile is required"),
        email:Yup.string().required("Email is required").email('Invalid email address'),
    });

      //formkin form submition second existsting
      const formikSecondApplicantContact = useFormik({
        initialValues: {
            name:'',
            mobile:'',
            email:'',
            remark:'',
        },
        validateOnChange:false,
        validateOnBlur:false,
        validationSchema: validationSecondApplicantContact,
        // Login Request
        onSubmit: async(values) => {
            if(secondContactDeclare == false){
                setSecondContactDeclareInvalid(true);
                secondContactScrollRef.current?.scrollIntoView({ behavior: "smooth" })
                return false;
            }else{
                setSecondContactDeclareInvalid(false);
            }
            let formData = new FormData();
            formData.append('name', values.name);
            formData.append('mobile', '+'+values.mobile);
            formData.append('email', values.email);
            formData.append('remark', values.remark);
            let validate = await api.postRequest('application/joint/validate', formData)
            .then(() => {
                let count = completed;
                let stepNum = step;
                let timeline_obj = timeLine;
                let timelineCount = timeLine.length;

                timeline_obj.filter((val) => val.id == 2)[0].status = 'complete';
                if(completed <= timelineCount){
                    count+=1;
                }
                if(step < timelineCount+1){
                    stepNum+=1;
                }

                let server_send = {
                    name:values.name,
                    mobile:'+'+values.mobile,
                    email:values.email,
                    remark:values.remark,
                }
                setSecondApplicant(server_send);
    
                setStep(stepNum);
                setCompleted(count);
    
                let cache = cacheDataSet;
                cache.secondApplicantContact = values;
                localStorage.setItem(`senfin_asset_${userId}`, JSON.stringify(cache));
                setCacheDataSet(cache);
                setTimeLine(timeline_obj);
            })
            .catch((e) => {
                if(e.response){
                    let errors =e?.response?.data?.errors;
                    if(errors != undefined && errors != null){
                        Object.keys(errors).map((keyName, i) => {
                            formikSecondApplicantContact.setFieldError(keyName, errors[keyName]);
                        })
                    }
                }
            })
        },
    });

    const validationKyc = Yup.object({
        nic_back:Yup.string().test('is-nic-back-is-required', 'NIC Back is required', (value, context) => {
            if(formikFirstApplicant.values.type == 'nic' && formikKyc.values.nic_back == ''){
                return false;
            }else{
                return true;
            }
        }),
        nic_front:Yup.string().test('is-nic-is-required', 'NIC Front is required', (value, context) => {
            if(formikFirstApplicant.values.type == 'nic' && formikKyc.values.nic_front == ''){
                return false;
            }else{
                return true;
            }
        }),
        passport:Yup.string().test('is-passport-is-required', 'Passport is required', (value, context) => {
            if(formikFirstApplicant.values.type == 'passport' && formikKyc.values.passport == ''){
                return false;
            }else{
                return true;
            }
        }),
        other_doc:Yup.string().test('is-other-doc-is-required', 'Identification Document is required', (value, context) => {
            if(formikFirstApplicant.values.type == 'other_no' && formikKyc.values.other_doc == ''){
                return false;
            }else{
                return true;
            }
        }),
        signature:Yup.string().required("Signature Document is required"),
        method:Yup.string().required("Method is required"),
        contact_id:Yup.string().required("Contact ID is required"),
        contact_at:Yup.string().required("Contact At is required").test('is-time-is-valid', 'Invalid Time. Please select the time between 8.45am and 04.45pm', (value, context) => {
            if(formikKyc.values.contact_at != ''){
                let time = formikKyc.values.contact_at.toLocaleTimeString('it-IT');
                let startDate = new Date('October 13, 2014 08:45:00');
                let endDate = new Date('October 13, 2014 16:45:00');
                let startTime = startDate.toLocaleTimeString('it-IT');
                let endTime = endDate.toLocaleTimeString('it-IT');
                if(endTime >= time && startTime <=time){
                    return true;
                }else{
                    return false;
                }
            }else{
                return false;
            }
        }),
        billing_proof:Yup.string().test('is-address-is-permement', 'Billing Proof is required', (value, context) => {
            if(formikFirstApplicant.values.isAddressPremenent == false){
                return true;
            }else{
                if(formikKyc.values.billing_proof == ''){
                    return false;
                }else{
                    return true;
                }
              
            }
        }),
    });

    const fileToBase64 = (file) => {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onloadend = () => {
            resolve(reader.result.split(',')[1]);
          };
          reader.onerror = (error) => {
            reject(error);
          };
          reader.readAsDataURL(file);
        });
    };
      

       //formkin form submition second existsting
       const formikKyc = useFormik({
        initialValues: {
            nic_back:'',
            nic_front:'',
            passport:'',
            other_doc:'',
            method:'',
            contact_id:'',
            contact_at:'',
            remark:'',
            billing_proof:'',
            signature:'',
        },
        validateOnChange:false,
        validateOnBlur:false,
        validationSchema: validationKyc,
        // Login Request
        onSubmit: async(values) => {

            if(kycDeclare == false){
                setKycDeclareInvalid(true);
                kycRef.current?.scrollIntoView({ behavior: "smooth" })
                return false;
            }else{
                setKycDeclareInvalid(false);
            }
            setSubmitting(true);
            //kyc files
            let billing_proof='';
            let nic_back ='';
            let nic_front ='';
            let passport = '';
            let other_doc = '';
            let signature = '';
            try{
                if(values.nic_back != ''){
                    nic_back = await fileToBase64(values.nic_back);
                }
                if(values.nic_front != ''){
                    nic_front = await fileToBase64(values.nic_front);
                }
                if(values.passport != ''){
                    passport = await fileToBase64(values.passport);
                }
                if(values.other_doc != ''){
                    other_doc = await fileToBase64(values.other_doc);
                }
                if(values.billing_proof != ''){
                    billing_proof = await fileToBase64(values.billing_proof);
                }
                if(values.signature != ''){
                    signature = await fileToBase64(values.signature);
                }
            }catch(error){
                console.log('file convert error: '+error)
            }
            let kyc_object = {
                method:values.method, 
                contact_id:values.contact_id,
                contact_at:moment(values.contact_at).format('YYYY-MM-DD hh:mm:ss A'),
                remarks:values.remark,
                nic_back:nic_back,
                nic_front:nic_front,
                other_doc:other_doc,
                passport:passport,
                billing_proof:billing_proof,
                signature:signature
            }

            bankAccount.length > 0 && bankAccount.map((val, index) => {
                kyc_object['bank_passbook_'+index] = val.bank_passbook_scanned
            })

            nominees.length > 0 && nominees.map((val, index) => {
                if(val.nominee_nic_front != ''){
                    kyc_object['nominee_nic_front'+index] = val.nominee_nic_front
                }
                if(val.nominee_nic_back != ''){
                    kyc_object['nominee_nic_back'+index] = val.nominee_nic_back
                }
                if(val.nominee_passport != ''){
                    kyc_object['nominee_passport'+index] = val.nominee_passport
                }
                if(val.nominee_other_doc != ''){
                    kyc_object['nominee_other_doc'+index] = val.nominee_other_doc
                }
            })

            let response = await api.postRequest('application', {
                firstApplicant:firstApplicant,
                secondApplicant:secondApplicant,
                bankAccount:bankAccount,
                nominees:nominees,
                kyc:kyc_object
            }).catch(() => {
                setCustomError('Something went wrong');
            });
            if(response.status == 200){
                setCustomError(null)
                let count = completed;
                let stepNum = step;
                let timelineCount = timeLine.length;
                let timeline_obj = timeLine;
                timeline_obj.filter((val) => val.id == 6)[0].status = 'complete';
                timeline_obj.filter((val) => val.id == 7)[0].status = 'complete';
                if(completed <= timelineCount){
                    count+=1;
                }
                if(step < timelineCount+1){
                    stepNum+=1;
                }
                setKyc(values);
                setStep(stepNum);
                setCompleted(count);
                setTimeLine(timeline_obj);
                localStorage.removeItem(`senfin_asset_${userId}`);
            }else{
                setCustomError("Something went wrong")
            }
            setSubmitting(false);
        },
    });

    //add bank account
    const nextStep = () => {
        let count = completed;
        let stepNum = step;
        let timelineCount = timeLine.length;
        if(completed <= timelineCount){
            count+=1;
        }
        if(step < timelineCount+1){
            stepNum+=1;
        }
        setStep(stepNum);
        setCompleted(count);
    }

    const handleCompleted = () => {
        let timeline_obj = timeLine;
        if(step == 1){
            formikFirstApplicant.handleSubmit();
        }else if(step == 2){
            if(secondApplicantOnly == true){
                formikSecondApplicant.handleSubmit();
            }else{
                if(formikFirstApplicant.values.instruction == 1){
                    formikSecondApplicant.handleSubmit();
                }else{
                    formikSecondApplicantContact.handleSubmit();
                }
            }
        }else if(step == 3){
            if(bankAccount.length > 0){
                if(bankDeclare == false){
                    setBankDeclareInvalid(true);
                    bankScrollRef.current?.scrollIntoView({ behavior: "smooth" })
                    return false;
                }else{
                    setBankDeclareInvalid(false);
                }

                setBankAccountError(null);
                let cache = cacheDataSet;
                cache.bankAccount = bankAccount;
                localStorage.setItem(`senfin_asset_${userId}`, JSON.stringify(cache));
                setCacheDataSet(cache);
                timeline_obj.filter((val) => val.id == 3)[0].status = 'complete';
                nextStep();
            }else{
                setBankAccountError('Atleast one bank account required.');
            }
        }else if(step == 4){
            if(nominees.length > 0){
            let full_amt = 0;
            nominees.map((val) => {
                full_amt+=Number(val.percentage);
            })   
            if(full_amt < 100){
                setNomineesError("The sum of the percentages for all the nominees must equal 100%");
            }else{
                if(nominationDeclare == false){
                    setNominationDeclareInvalid(true);
                    nominationRef.current?.scrollIntoView({ behavior: "smooth" })
                    return false;
                }else{
                    setNominationDeclareInvalid(false);
                }

                setNomineesError(null);
                let cache = cacheDataSet;
                cache.nominees = nominees;
                localStorage.setItem(`senfin_asset_${userId}`, JSON.stringify(cache));
                setCacheDataSet(cache);
                timeline_obj.filter((val) => val.id == 4)[0].status = 'complete';
                nextStep();
            }
            }else{
                nextStep();
            }
        }else if(step == 5){
            if(checked == true){
                setCheckedError(false);
                setTerm(true);
                timeline_obj.filter((val) => val.id == 5)[0].status = 'complete';
                nextStep();
            }else{
                setCheckedError(true);
            }
        }else if(step == 6){
            formikKyc.handleSubmit();
        }else if(step == 7){
            handleSubmitData();
        }
    }

    const handleStepCheck = async(check) => {
        if(check == 1){
            let fav = await formikFirstApplicant.validateForm();
            if(Object.keys(fav).length > 0){
                return fav;
            }else{
                if(await unFirstApplicationValid(formikFirstApplicant.values) == true){
                    return {};
                }else{
                    return {error:'validation Error in new data'};
                }
            }
        }else if(check == 2){
            if(secondApplicantOnly == true){
                let sav = await formikSecondApplicant.validateForm();
                if(Object.keys(sav).length > 0){
                    return sav;
                }else{
                    if(await unSeconApplicationValid(formikSecondApplicant.values) == true){
                        return {};
                    }else{
                        return {error:'validation Error in new data'};
                    }
                }
            }else{
                if(formikFirstApplicant.values.instruction == 1){
                    let sav = await formikSecondApplicant.validateForm();
                    if(Object.keys(sav).length > 0){
                        return sav;
                    }else{
                        if(await unSeconApplicationValid(formikSecondApplicant.values) == true){
                            return {};
                        }else{
                            return {error:'validation Error in new data'};
                        }
                    }
                }else{
                    let sacv = await formikSecondApplicantContact.validateForm();
                    if(Object.keys(sacv).length > 0){
                        return sacv;
                    }else{
                        if(await unSecondApplicatContactValid(formikSecondApplicantContact.values) == true){
                            return {};
                        }else{
                            return {error:'validation Error in new data'};
                        }
                    }
                }
            }
        }else if(check == 3){
            if(bankAccount.length > 0){
                setBankAccountError(null);
                return {};

            }else{
                setBankAccountError('Atleast one bank account required.');
                return {error:'bank account required'};
            }
        }else if(check == 4){
            return {};
        }else if(check == 5){
            if(checked == true){
                setCheckedError(false);
                return {};
            }else{
                setCheckedError(true);
                return {error:'check required'};
            }
        }else if(check == 6){
            return formikKyc.validateForm();
        }
    }

    /**UNNECESSARY VALIDATIONS */
    const unFirstApplicationValid = async(values) => {
        let server_send = {
            accountType:values.account_type,
            instruction:values.instruction,
            TITLE:values.title,
            FIRST_NAME:values.firstname,
            type:values.type,
            DATE_OF_BIRTH:values.dob,
            NATIONALITY:values.nationality_type == 1?'Sri Lankan':values.nationality,
            PER_ADD_STREET:values.street,
            PER_ADD_TOWN:values.town,
            COUNTRY:values.country,
            PER_ADD_POSTAL_CODE:'',
            MOBILE_NO:'+'+values.mobile,
            LAND_NO:values.home != ''?'+'+values.home:'',
            EMAIL:values.email,
            OCCUPATION:values.occupation,
            INCOME_SOURCE:values.soi,
            SEC_ADD_STREET:values.corres_street,
            INCOME_ANUAL:values.investment,
            SEC_ADD_TOWN:values.corres_town,
            SEC_ADD_COUNTRY:values.corres_country,
            SEC_ADD_POSTAL_CODE:'',
            SEC_POSTAL_AREA:'',
            CURRENCY_TYPE:values.currency,
            funds:values.funds,
            other_income:values.other_income,
        }

        let validate_find = {
            TITLE:'title',
            FIRST_NAME:'firstname',
            type:'type',
            DATE_OF_BIRTH:'dob',
            NATIONALITY:'nationality',
            PER_ADD_STREET:'street',
            PER_ADD_TOWN:'town',
            COUNTRY:'country',
            PER_ADD_POSTAL_CODE:'',
            MOBILE_NO:'mobile',
            LAND_NO:'home',
            INCOME_SOURCE:'soi',
            EMAIL:'email',
            OCCUPATION:'occupation',
            CURRENCY_TYPE:'currency',
            INCOME_ANUAL:'investment',
            funds:'funds',
            other_income:'other_income',
        }
        if(values.type == 'nic'){
            server_send.NIC = values.nic;
            validate_find.NIC = 'nic';
        }else if(values.type == 'passport'){
            server_send.PASSPORT = values.nic;
            validate_find.PASSPORT = 'nic';
        }else{
            server_send.OTHER_NO = values.nic;
            validate_find.OTHER_NO = 'nic';
        }
        let status = false;
        let validate = await api.postRequest('application/validate', server_send)
        .then((response) =>{
            setFirstApplicant(server_send);
            //cache data
            let cache = cacheDataSet;
            cache.push({firstApplicant:firstApplicant})
            localStorage.setItem(`senfin_asset_${userId}`, JSON.stringify(cache));
            setCacheDataSet(cache);
            status = true;
        })
        .catch((e) => {
            if(e.response){
                let errors =e?.response?.data?.errors;
                if(errors != undefined && errors != null){
                    Object.keys(errors).map((keyName, i) => {
                        let field = validate_find[keyName];
                        formikFirstApplicant.setFieldError(field, errors[keyName]);
                    })
                }
            }
            status = false;
        })
        return status;
    }

    const unSecondApplicatContactValid = async(values) => {
        let status = false;
        let formData = new FormData();
        formData.append('name', values.name);
        formData.append('mobile', '+'+values.mobile);
        formData.append('email', values.email);
        formData.append('remark', values.remark);
        let validate = await api.postRequest('application/joint/validate', formData)
        .then(() => {
            setSecondApplicant(values);
            let cache = cacheDataSet;
            cache.push({secondApplicant:secondApplicant})
            localStorage.setItem(`senfin_asset_${userId}`, JSON.stringify(cache));
            setCacheDataSet(cache);
            status = true;
        })
        .catch((e) => {
            if(e.response){
                let errors =e?.response?.data?.errors;
                if(errors != undefined && errors != null){
                    Object.keys(errors).map((keyName, i) => {
                        formikSecondApplicantContact.setFieldError(keyName, errors[keyName]);
                    })
                }
            }
            status = false;
        })
        return status;
    }

    const unSeconApplicationValid = async(values) => {
        let server_send = {
            accountType:1,
            instruction:1,
            TITLE:values.title,
            FIRST_NAME:values.firstname,
            type:values.type,
            DATE_OF_BIRTH:values.dob,
            NATIONALITY:values.nationality_type == 1?'Sri Lankan':values.nationality,
            PER_ADD_STREET:values.street,
            PER_ADD_TOWN:values.town,
            COUNTRY:values.country,
            PER_ADD_POSTAL_CODE:'',
            MOBILE_NO:'+'+values.mobile,
            LAND_NO:values.home != ''?'+'+values.home:'',
            EMAIL:values.email,
            OCCUPATION:values.occupation,
            INCOME_SOURCE:values.soi,
            SEC_ADD_STREET:values.corres_street,
            SEC_ADD_TOWN:values.corres_town,
            SEC_ADD_COUNTRY:values.corres_country,
            SEC_ADD_POSTAL_CODE:'',
            CURRENCY_TYPE:values.currency,
            INCOME_ANUAL:values.investment,
            SEC_POSTAL_AREA:'',
            other_income:values.other_income,
        }
       
        let validate_find = {
            TITLE:'title',
            FIRST_NAME:'firstname',
            type:'type',
            DATE_OF_BIRTH:'dob',
            NATIONALITY:'nationality',
            PER_ADD_STREET:'street',
            PER_ADD_TOWN:'town',
            COUNTRY:'country',
            PER_ADD_POSTAL_CODE:'',
            MOBILE_NO:'mobile',
            LAND_NO:'home',
            EMAIL:'email',
            OCCUPATION:'occupation',
            INCOME_SOURCE:'soi',
            INCOME_ANUAL:'investment',
            other_income:'other_income',
            CURRENCY_TYPE:'currency',
        }
        if(values.type == 'nic'){
            server_send.NIC = values.nic;
            validate_find.NIC = 'nic';
        }else if(values.type == 'passport'){
            server_send.PASSPORT = values.nic;
            validate_find.PASSPORT = 'nic';
        }else{
            server_send.OTHER_NO = values.nic;
            validate_find.OTHER_NO = 'nic';
        }
        let validate = await api.postRequest('application/validate', server_send)
        .then((response) =>{
            if(secondApplicantOnly == true){
                submitSecondApplicantData(server_send);
            }else{
                setSecondApplicant(server_send);
                let cache = cacheDataSet;
                cache.push({secondApplicant:secondApplicant})
                localStorage.setItem(`senfin_asset_${userId}`, JSON.stringify(cache));
                setCacheDataSet(cache);
            }
        })
        .catch((e) => {
            if(e.response){
                let errors =e?.response?.data?.errors;
                if(errors != undefined && errors != null){
                    Object.keys(errors).map((keyName, i) => {
                        let field = validate_find[keyName];
                        formikSecondApplicant.setFieldError(field, errors[keyName]);
                    })
                }
            }
        })
    }

    const handleSubmitData = () => {
        
    }

    useEffect(() => {
        changeSideTimeline();
    }, [formikFirstApplicant.values.account_type])

    useEffect(() => {
        changeSideTimeline();
    }, [formikFirstApplicant.values.instruction])

    useEffect(() => {
        let user =  JSON.parse(sessionStorage.getItem('user'));
        if(user?.joint_user != undefined && user?.joint_user != null){
            setStep(2);
            setSecondApplicantOnly(true)
        }else{
            setSecondApplicantOnly(false)
        }
    }, [])

    useEffect(() => {
        setName(formikFirstApplicant.values.firstname);
    }, [formikFirstApplicant.values.firstname])

    const changeSideTimeline = () => {
        let user =  JSON.parse(sessionStorage.getItem('user'));
        let timeline =[];

        const menu_1 = [
            {id:1, isComplete:0, name:'First Applicant Details', status:''},
            {id:3, isComplete:1, name:'Bank Account Details', status:''},
            {id:4, isComplete:2, name:'Nomination Details', status:''},
            {id:5, isComplete:3, name:'Declaration', status:''},
            {id:6, isComplete:4, name:'KYC', status:''},
            {id:7, isComplete:5, name:'Complete', status:''}
        ]

        const menu_2 = [
            {id:1, isComplete:0, name:'First Applicant Details', status:''},
            {id:2, isComplete:1, name:'Second Applicant Details', status:''},
            {id:3, isComplete:2, name:'Bank Account Details', status:''},
            {id:4, isComplete:3, name:'Nomination Details', status:''},
            {id:5, isComplete:4, name:'Declaration', status:''},
            {id:6, isComplete:5, name:'KYC', status:''},
            {id:7, isComplete:6, name:'Complete', status:''}
        ]

        const menu_3 = [
            {id:2, isComplete:0, name:'Second Applicant Details', status:''},
            {id:7, isComplete:1, name:'Complete', status:''}
        ]
        if(user?.joint_user != undefined && user?.joint_user != null){
            timeline = menu_3;
        }else{
            if(formikFirstApplicant.values.account_type == 1){
                timeline = menu_1;
            }else{
                timeline = menu_2;
            }
        }
        setTimeLine(timeline);
    }

    const handleClickableStep = async(value, index) => {
        if(timeLine.filter((val) => val.name == 'Complete')?.[0]?.id != step && value.status == 'complete'){
            if(timeLine.filter((val) => val.id == step)?.[0]?.status == 'complete'){
                let result = await handleStepCheck(step);
                if(Object.keys(result).length == 0){
                    setStep(value.id);
                    setCompleted(value.isComplete)
                }
            }else{
                setStep(value.id);
                setCompleted(value.isComplete)
            } 
        }
    }

    return (
        <>
        <Loader active={loading}>
            <div className="container container-line">
            <TopNav counter={false} sticky={false} downer={true} signOutMobile={false}/>
            <div className='main-wrap' style={{display:'flex', flexDirection:'row'}}>
                <div className='col-md-9'>
                    <div className='card' style={{border:'2px solid #dbfee7'}}>
                        <div class="card-body">
                            {
                                step == 1?
                                <FirstApplicant 
                                    name={name} 
                                    firstDeclare={firstDeclare} 
                                    firstDeclareInvalid={firstDeclareInvalid} 
                                    setFirstDeclare={setFirstDeclare}
                                    setFirstDeclareInvalid={setFirstDeclareInvalid}
                                    scrollref={firstScrollref}
                                    fundError={fundError} 
                                    formik={formikFirstApplicant}/>
                                :
                                (
                                    step == 2?
                                    <SecoundApplicatBrake 
                                        name={name} 
                                        instruction={formikFirstApplicant.values.instruction} 
                                        formikApplication={formikSecondApplicant} 
                                        formikContact={formikSecondApplicantContact}
                                        secondDeclare={secondDeclare}
                                        secondDeclareInvalid={secondDeclareInvalid}
                                        setSecondDeclare={setSecondDeclare}
                                        setSecondDeclareInvalid={setSecondDeclareInvalid}
                                        secondScrollRef={secondScrollRef}

                                        secondContactDeclare={secondContactDeclare}
                                        setSecondContactDeclare={setSecondContactDeclare}
                                        secondContactDeclareInvalid={secondContactDeclareInvalid}
                                        setSecondContactDeclareInvalid={setSecondContactDeclareInvalid}
                                        secondContactRef={secondContactScrollRef}
                                    />
                                    :
                                    (
                                        step == 3?
                                        <BankAccount 
                                            name={name} 
                                            resident={formikFirstApplicant.values.resident} 
                                            nationality_type={formikFirstApplicant.values.nationality_type} 
                                            bankAccount={bankAccount} 
                                            setBankAccount={setBankAccount} 
                                            bankAccountError={bankAccountError}
                                            bankDeclare={bankDeclare}
                                            bankDeclareInvalid={bankDeclareInvalid}
                                            bankScrollRef={bankScrollRef}
                                            setBankDeclare={setBankDeclare}
                                            setBankDeclareInvalid={setBankDeclareInvalid}
                                        />
                                        :
                                        (
                                            step == 4?
                                            <Nomination 
                                                nominees={nominees} 
                                                setNominees={setNominees} 
                                                nomineesError={nomineesError} 
                                                setNomineesError={setNomineesError}
                                                name={name} 
                                                nominationRef={nominationRef}
                                                nominationDeclare={nominationDeclare}
                                                nominationDeclareInvalid={nominationDeclareInvalid}
                                                setNominationDeclare={setNominationDeclare}
                                                setNominationDeclareInvalid={setNominationDeclareInvalid}
                                                ownerData={formikFirstApplicant.values}
                                                nomineeTerms={nomineeTerms}
                                                setNomineeTerms={setNomineeTerms}
                                                nomineeTermsError={nomineeTermsError}
                                                setNomineeTermsError={setNomineeTermsError}
                                            />
                                            :
                                            step == 5?
                                            <TermsCondition checked={checked} setChecked={setChecked} checkedError={checkedError}/>
                                            :
                                            (step == 6?
                                            <Kyc 
                                                formik={formikKyc} 
                                                type={formikFirstApplicant.values.type} 
                                                isAddressPremenent={formikFirstApplicant.values.isAddressPremenent}
                                                name={name} 
                                                kycDeclare={kycDeclare}
                                                kycDeclareInvalid={kycDeclareInvalid}
                                                setKycDeclareInvalid={setKycDeclareInvalid}
                                                setKycDeclare={setKycDeclare}
                                                kycRef={kycRef}
                                            />   
                                            :
                                            <Complete instruction={formikFirstApplicant.values.instruction} accountType={formikFirstApplicant.values.account_type}/>
                                            )
                                        )
                                    )
                                )
                                
                            }
                            
                        </div>
                    </div>
                </div>
                <div className='col-md-3'>
                    <Stepper handleClickableStep={handleClickableStep} customMsg={customError} secondApplicantOnly={secondApplicantOnly} submitting={submitting} completed={completed} handleCompleted={handleCompleted} timeLine={timeLine} step={step}/>
                </div>
            </div>
            </div>
        </Loader>
        </>
    )
}

export default Regcomplete;