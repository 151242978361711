import React, { useEffect, useRef, useState } from 'react'
import { Button, Form, FormControl, InputGroup } from 'react-bootstrap';
import PhoneInput from 'react-phone-input-2';
import api from '../../../services/api';

export default function FirstApplicant({formik,fundError,name,firstDeclare,firstDeclareInvalid,setFirstDeclare,setFirstDeclareInvalid,scrollref}) {

    const [modelFundList, setModelFundList] = useState([]);

    useEffect(() => {
        fundLists();
    }, [])

    const handleClickNational = (id) => {
        if(id ==2){
            formik.setFieldValue('type', 'passport')
        }else{
            formik.setFieldValue('type', 'nic')
        }
        formik.setFieldValue('nationality_type', id);
    }

    const handleClickResident = (id) => {
        formik.setFieldValue('resident', id);
    }

    const handleSoi = (id) => {
        formik.setFieldValue('soi', id);
    }

    const handleClickInvestment = (id) => {
        formik.setFieldValue('investment', id);
    }

    const handleClickAccountType = (type) => {
        formik.setFieldValue('account_type', type);
    }

    const handleClickInstruction = (type) => {
        formik.setFieldValue('instruction', type);
    }

    const handleClickIsAddressPermenent = () => {
        formik.setFieldValue('isAddressPremenent', !formik.values.isAddressPremenent);
    }

    const handleClickPep = (state) => {
        formik.setFieldValue('pep', state);
    }

    const handleCorresAddSame = (e) => {
        let isChecked = e.target.checked;
        if(isChecked == true){
            formik.setFieldValue('corres_street', formik.values.street);
            formik.setFieldValue('corres_town', formik.values.town);
            formik.setFieldValue('corres_country', formik.values.country);
        }else{
            formik.setFieldValue('corres_street', '');
            formik.setFieldValue('corres_town', '');
            formik.setFieldValue('corres_country', '');
        }
    }

    //get all fund list
    const fundLists = async() => {
        try{
            let data = await api.getRequest('data/list');
            if(data.data.status == 'success'){
                setModelFundList([...data.data.fund_list]);
            }
        }catch(error){
        }
    }

    const handleFunds = (e, fundCode) => {
        let arr = formik.values.funds;
        if(e.target.checked == true){
            arr.push(fundCode);
        }else{
            const index = arr.indexOf(fundCode);
            if (index > -1) {
                arr.splice(index, 1);
            }
        }
        formik.setFieldValue('funds', arr);
    }

    const handleDeclareEach = (e) => {
        if(e.target.checked == true){
            setFirstDeclare(true)
        }else{
            setFirstDeclare(false)
        }
    }

  return (
    <div className='col-md-12 animate__animated animate__fadeIn'>
        <p style={{fontWeight:700}}>First Applicant General Details</p>
        <hr/>
        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                <Form.Label className="input-label">Please Select Unit Trust Fund <span class="required-field"></span></Form.Label>
                <div className='row'>
                    {
                        modelFundList.length > 0 && modelFundList.map((fund) => {
                            return(
                                <div className='col-md-4' key={fund.FUND_CODE}>
                                    <Form.Check
                                        size="sm"
                                        style={{fontSize:12}}
                                        inline
                                        label={fund.FUND_NAME}
                                        name="funds"
                                        id={`fund-${fund.FUND_CODE}`}
                                        type={'checkbox'}
                                        onChange={(e) => handleFunds(e, fund.FUND_CODE)}
                                        checked={formik.values.funds.includes(fund.FUND_CODE)}
                                    />
                                </div>
                            )
                         
                        })
                    }
                </div>
                {
                    fundError != null ?
                    <div className="col-md-12">
                    <span className="srv-validation-message"><span className="error-palate">*&nbsp;{fundError}</span></span>
                    </div>
                    :null
                }
            </Form.Group>
        <div className='row'>
        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                <Form.Label className="input-label">Account Type</Form.Label>
                <div key={`inline-radio-type`}>
                    <Form.Check
                        size="sm"
                        style={{fontSize:12}}
                        inline
                        label="Individual Account"
                        name="type"
                        type={'radio'}
                        onClick={() => handleClickAccountType(1)}
                        id={`inline-radio-type-1`}
                        checked={formik.values.account_type==1}
                    />
                    <Form.Check
                        inline
                        size="sm"
                        style={{fontSize:12, marginLeft:40}}
                        label="Joint Account"
                        name="type"
                        type={'radio'}
                        id={`inline-radio-type-2`}
                        onClick={() => handleClickAccountType(2)}
                        checked={formik.values.account_type==2}
                    />
                    <Form.Check
                        inline
                        name="type"
                        size="sm"
                        style={{fontSize:12, marginLeft:40}}
                        label="Minor Account"
                        type={'radio'}
                        onClick={() => handleClickAccountType(3)}
                        id={`inline-radio-type-3`}
                        checked={formik.values.account_type==3}
                    />
                </div>
            </Form.Group>
        </div>
        {
            formik.values.account_type != 1?
            <div className='row animate__animated animate__fadeIn'>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                    <Form.Label className="input-label">Operating Instructions for Joint Account</Form.Label>
                    <div key={`inline-radio-instruction`}>
                        <Form.Check
                            size="sm"
                            style={{fontSize:12}}
                            inline
                            label="Sole/First Applicant"
                            name="instruction"
                            type={'radio'}
                            onClick={() => handleClickInstruction(1)}
                            id={`inline-radio-instruction-1`}
                            checked={formik.values.instruction==1}
                        />
                        <Form.Check
                            inline
                            size="sm"
                            style={{fontSize:12, marginLeft:40}}
                            label="Both Parties"
                            name="instruction"
                            type={'radio'}
                            id={`inline-radio-instruction-2`}
                            onClick={() => handleClickInstruction(2)}
                            checked={formik.values.instruction==2}
                        />
                        <Form.Check
                            inline
                            name="instruction"
                            size="sm"
                            style={{fontSize:12, marginLeft:40}}
                            label="Either Party"
                            type={'radio'}
                            onClick={() => handleClickInstruction(3)}
                            id={`inline-radio-instruction-3`}
                            checked={formik.values.instruction==3}
                        />
                    </div>
                </Form.Group>
            </div>
            :null
        }
        <div className='row'>
            <div className='col-md-2'>
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                    <Form.Label className="input-label">Title</Form.Label>
                        <Form.Select       
                            isInvalid={Boolean(formik.errors.title)}
                            onChange={formik.handleChange}
                            value={formik.values.title}
                            name="title" 
                            autoComplete="off"
                            size="sm" 
                            aria-label="Default select example"
                        >
                            <option value="Mr:">Mr</option>
                            <option value="Mrs:">Mrs</option>
                            <option value="Miss:">Miss</option>
                            <option value="Rev:">Rev</option>
                            <option value="Other:">Other</option>
                        </Form.Select>
                        {formik.errors.title && 
                            <div className="col-md-12">
                            <span className="srv-validation-message"><span className="error-palate">*&nbsp;{formik.errors.title}</span></span>
                            </div>
                        } 
                </Form.Group>
            </div>
            <div className='col-md-7'>
                  <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                      <Form.Label className="input-label">Full Name <span class="required-field"></span></Form.Label>
                      <FormControl
                          size="sm"
                          aria-label="firstname"
                          aria-describedby="basic-addon1"
                          name="firstname"
                          autoComplete="off"
                          isInvalid={Boolean(formik.errors.firstname)}
                          onChange={formik.handleChange}
                          value={formik.values.firstname}
                      />
                      {formik.errors.firstname && 
                          <div className="col-md-12">
                          <span className="srv-validation-message"><span className="error-palate">*&nbsp;{formik.errors.firstname}</span></span>
                          </div>
                      }  
                  </Form.Group>
            </div>
            <div className='col-md-3'>
                  <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                      <Form.Label className="input-label">Date of Birth <span class="required-field"></span></Form.Label>
                      <FormControl
                          size="sm"
                          type='date'
                          aria-label="dob"
                          aria-describedby="basic-addon1"
                          name="dob"
                          autoComplete="off"
                          isInvalid={Boolean(formik.errors.dob)}
                          onChange={formik.handleChange}
                          value={formik.values.dob}
                      />
                      {formik.errors.dob && 
                          <div className="col-md-12">
                          <span className="srv-validation-message"><span className="error-palate">*&nbsp;{formik.errors.dob}</span></span>
                          </div>
                      }  
                  </Form.Group>
            </div>
        </div>
        <div className='row'>
            <div className='col-md-4'>
                  <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                      <Form.Label className="input-label">Nationality</Form.Label>
                      <div className="col-md-12" style={{display:'flex', flexDirection:'row', alignItems:'center'}}>
                            <Form.Check
                                style={{fontSize:12, marginRight:15}}
                                size="sm"
                                label="Sri Lankan"
                                name="group1"
                                type={'radio'}
                                onClick={() => handleClickNational(1)}
                                id={`inline-radio-1`}
                                checked={formik.values.nationality_type==1}
                            />
                            <Form.Check
                                style={{fontSize:12}}
                                size="sm"
                                label="Non-Sri Lankan"
                                name="group1"
                                onClick={() => handleClickNational(2)}
                                type={'radio'}
                                id={`inline-radio-2`}
                                checked={formik.values.nationality_type==2}
                            />
                     </div>
                     {
                        formik.values.nationality_type == 2?
                            <>
                            <FormControl
                                size="sm"
                                className='animate__animated animate__fadeIn'
                                aria-label="nationality"
                                aria-describedby="basic-addon1"
                                name="nationality"
                                autoComplete="off"
                                placeholder='Please state your nationality'
                                isInvalid={Boolean(formik.errors.nationality)}
                                onChange={formik.handleChange}
                                value={formik.values.nationality}
                            />
                            {formik.errors.nationality && 
                                <div className="col-md-12">
                                <span className="srv-validation-message"><span className="error-palate">*&nbsp;{formik.errors.nationality}</span></span>
                                </div>
                            } 
                            </>
                        :null
                     }
                  
                  </Form.Group>
            </div>
            <div className='col-md-3'>
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                    <Form.Label className="input-label">Residency Status</Form.Label>
                    <div className="col-md-12" style={{display:'flex', flexDirection:'row', alignItems:'center'}}>
                        <Form.Check
                            style={{fontSize:12, marginRight:20}}
                            size="sm"
                            label="Resident"
                            name="group1r"
                            type={'radio'}
                            onClick={() => handleClickResident(1)}
                            id={`inline-radio-recident-1`}
                            checked={formik.values.resident==1}
                        />
                        <Form.Check
                            style={{fontSize:12}}
                            size="sm"
                            label="Non-Resident"
                            name="group1r"
                            type={'radio'}
                            onClick={() => handleClickResident(2)}
                            id={`inline-radio-recident-2`}
                            checked={formik.values.resident==2}
                        />
                    </div>
                </Form.Group>
            </div>
            <div className='col-md-5'>
                  <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                      <Form.Label className="input-label">NIC / Passport No. <span class="required-field"></span></Form.Label>
                      <InputGroup>
                        <Form.Select       
                            isInvalid={Boolean(formik.errors.type)}
                            onChange={formik.handleChange}
                            value={formik.values.type}
                            name="type" 
                            style={{maxWidth:'30%'}}
                            autoComplete="off"
                            size="sm" 
                            aria-label="Default select example"
                        >
                            <option value="nic" disabled={formik.values.nationality_type == 2}>NIC</option>
                            <option value="passport">Passport</option>
                            <option value="other_no" disabled={formik.values.nationality_type == 2}>Other</option>
                        </Form.Select>
                      <FormControl
                          size="sm"
                          aria-label="nic"
                          aria-describedby="basic-addon1"
                          name="nic"
                          autoComplete="off"
                          className='text-uppercase'
                          isInvalid={Boolean(formik.errors.nic)}
                          onChange={formik.handleChange}
                          value={formik.values.nic}
                      />
                      </InputGroup>
                      {formik.errors.nic && 
                          <div className="col-md-12">
                          <span className="srv-validation-message"><span className="error-palate">*&nbsp;{formik.errors.nic}</span></span>
                          </div>
                      }  
                  </Form.Group>
            </div>
        </div>
        <div className='row'>
            <Form.Label className="input-label">Permanent Address <span class="required-field"></span></Form.Label>
            <div className='col-md-4'>
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                      <FormControl
                          size="sm"
                          aria-label="street"
                          aria-describedby="basic-addon1"
                          name="street"
                          autoComplete="off"
                          placeholder='Address Line 1'
                          maxLength={100}
                          isInvalid={Boolean(formik.errors.street)}
                          onChange={formik.handleChange}
                          value={formik.values.street}
                      />
                      {formik.errors.street && 
                          <div className="col-md-12">
                          <span className="srv-validation-message"><span className="error-palate">*&nbsp;{formik.errors.street}</span></span>
                          </div>
                      }  
                  </Form.Group>
            </div>
            <div className='col-md-4'>
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                      <FormControl
                          size="sm"
                          aria-label="town"
                          aria-describedby="basic-addon1"
                          name="town"
                          autoComplete="off"
                          placeholder='Address Line 2'
                          maxLength={100}
                          isInvalid={Boolean(formik.errors.town)}
                          onChange={formik.handleChange}
                          value={formik.values.town}
                      />
                      {formik.errors.town && 
                          <div className="col-md-12">
                          <span className="srv-validation-message"><span className="error-palate">*&nbsp;{formik.errors.town}</span></span>
                          </div>
                      }  
                  </Form.Group>
            </div>
            <div className='col-md-4'>
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                      <FormControl
                          size="sm"
                          aria-label="country"
                          aria-describedby="basic-addon1"
                          name="country"
                          autoComplete="off"
                          placeholder='Address Line 3'
                          maxLength={100}
                          isInvalid={Boolean(formik.errors.country)}
                          onChange={formik.handleChange}
                          value={formik.values.country}
                      />
                      {formik.errors.country && 
                          <div className="col-md-12">
                          <span className="srv-validation-message"><span className="error-palate">*&nbsp;{formik.errors.country}</span></span>
                          </div>
                      }  
                  </Form.Group>
            </div>
        </div>
        <div className='row'>
            <div className='col-md-4' style={{display:'flex', flexDirection:'column', justifyContent:'center'}}>
                <Form.Check
                    style={{fontSize:12, marginRight:20}}
                    size="sm"
                    label="Permanent address is same as correspondence address"
                    name="corres_same"
                    type={'checkbox'}
                    onClick={handleCorresAddSame}
                    id={`inline-corres-address-1`}
                />
            </div>
        </div>
        <div className='row'>
            <Form.Label className="input-label">Correspondence Address</Form.Label>
            <div className='col-md-4'>
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                      <FormControl
                          size="sm"
                          aria-label="corres_street"
                          aria-describedby="basic-addon1"
                          name="corres_street"
                          autoComplete="off"
                          placeholder='Address Line 1'
                          maxLength={100}
                          isInvalid={Boolean(formik.errors.corres_street)}
                          onChange={formik.handleChange}
                          value={formik.values.corres_street}
                      />
                      {formik.errors.corres_street && 
                          <div className="col-md-12">
                          <span className="srv-validation-message"><span className="error-palate">*&nbsp;{formik.errors.corres_street}</span></span>
                          </div>
                      }  
                  </Form.Group>
            </div>
            <div className='col-md-4'>
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                      <FormControl
                          size="sm"
                          aria-label="corres_town"
                          aria-describedby="basic-addon1"
                          name="corres_town"
                          autoComplete="off"
                          placeholder='Address Line 2'
                          maxLength={100}
                          isInvalid={Boolean(formik.errors.corres_town)}
                          onChange={formik.handleChange}
                          value={formik.values.corres_town}
                      />
                      {formik.errors.corres_town && 
                          <div className="col-md-12">
                          <span className="srv-validation-message"><span className="error-palate">*&nbsp;{formik.errors.corres_town}</span></span>
                          </div>
                      }  
                  </Form.Group>
            </div>
            <div className='col-md-4'>
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                      <FormControl
                          size="sm"
                          aria-label="corres_country"
                          aria-describedby="basic-addon1"
                          name="corres_country"
                          autoComplete="off"
                          placeholder='Address Line 3'
                          maxLength={100}
                          isInvalid={Boolean(formik.errors.corres_country)}
                          onChange={formik.handleChange}
                          value={formik.values.corres_country}
                      />
                      {formik.errors.corres_country && 
                          <div className="col-md-12">
                          <span className="srv-validation-message"><span className="error-palate">*&nbsp;{formik.errors.corres_country}</span></span>
                          </div>
                      }  
                  </Form.Group>
            </div>
        </div>
        <div className='row'>
            <div className='col-md-4' style={{display:'flex', flexDirection:'column', justifyContent:'center'}}>
                <Form.Check
                    style={{fontSize:12, marginRight:20}}
                    size="sm"
                    label="Click here if your permanent address is different from NIC/Passport address"
                    name="group1r"
                    type={'checkbox'}
                    onClick={() => handleClickIsAddressPermenent()}
                    id={`inline-checkbox-address-1`}
                    checked={formik.values.isAddressPremenent==true}
                />
            </div>
        </div>
        <p style={{fontWeight:700, marginTop:20}}>Contact Details</p>
        <hr/>
        <div className='row'>
            <div className='col-md-4'>
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                      <Form.Label className="input-label">Mobile <span class="required-field"></span></Form.Label>
                        <InputGroup style={{height:'30px', fontSize:'13px'}} className="mb-3">
                                <PhoneInput
                                    country={'lk'}
                                    name="mobile"
                                    className="phone-register-mobile"
                                    placeholder="mobile"
                                    autoComplete="off"
                                    isInvalid={Boolean(formik.errors.mobile)}
                                    onChange={phone => formik.setFieldValue('mobile', phone)}
                                    value={formik.values.mobile}
                                    style={{width:'100%'}}
                                />
                                    {formik.errors.mobile && <div className="col-md-12">
                                    <span className="srv-validation-message"><span className="error-palate">*&nbsp;{formik.errors.mobile}</span></span>
                                </div>}
                        </InputGroup>
                </Form.Group>
            </div>
            <div className='col-md-4'>
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                      <Form.Label className="input-label">Business</Form.Label>
                        <InputGroup style={{height:'30px', fontSize:'13px'}} className="mb-3">
                                <PhoneInput
                                    country={'lk'}
                                    name="business"
                                    className="phone-register-business"
                                    placeholder="business"
                                    autoComplete="off"
                                    isInvalid={Boolean(formik.errors.business)}
                                    onChange={phone => formik.setFieldValue('business', phone)}
                                    value={formik.values.business}
                                    style={{width:'100%'}}
                                />
                                    {formik.errors.business && <div className="col-md-12">
                                    <span className="srv-validation-message"><span className="error-palate">*&nbsp;{formik.errors.business}</span></span>
                                </div>}
                        </InputGroup>
                </Form.Group>
            </div>
            <div className='col-md-4'>
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                      <Form.Label className="input-label">Home</Form.Label>
                        <InputGroup style={{height:'30px', fontSize:'13px'}} className="mb-3">
                                <PhoneInput
                                    country={'lk'}
                                    name="home"
                                    className="phone-register-home"
                                    placeholder="home"
                                    autoComplete="off"
                                    isInvalid={Boolean(formik.errors.home)}
                                    onChange={phone => formik.setFieldValue('home', phone)}
                                    value={formik.values.home}
                                    style={{width:'100%'}}
                                />
                                    {formik.errors.home && <div className="col-md-12">
                                    <span className="srv-validation-message"><span className="error-palate">*&nbsp;{formik.errors.home}</span></span>
                                </div>}
                        </InputGroup>
                </Form.Group>
            </div>
            </div>
            <div className='row'>
            <div className='col-md-4'>
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                      <Form.Label className="input-label">Email <span class="required-field"></span></Form.Label>
                      <FormControl
                          size="sm"
                          aria-label="email"
                          aria-describedby="basic-addon1"
                          name="email"
                          autoComplete="off"
                          isInvalid={Boolean(formik.errors.email)}
                          onChange={formik.handleChange}
                          value={formik.values.email}
                      />
                      {formik.errors.email && 
                          <div className="col-md-12">
                          <span className="srv-validation-message"><span className="error-palate">*&nbsp;{formik.errors.email}</span></span>
                          </div>
                      }  
                </Form.Group>
            </div>             
        </div>
        <p style={{fontWeight:700, marginTop:20}}>Employment Details</p>
        <hr/>
        <div className='row'>
            <div className='col-md-6'>
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                      <Form.Label className="input-label">Occupation/Nature of Business <span class="required-field"></span></Form.Label>
                      <FormControl
                          size="sm"
                          aria-label="occupation"
                          aria-describedby="basic-addon1"
                          name="occupation"
                          autoComplete="off"
                          isInvalid={Boolean(formik.errors.occupation)}
                          onChange={formik.handleChange}
                          value={formik.values.occupation}
                      />
                      {formik.errors.occupation && 
                          <div className="col-md-12">
                          <span className="srv-validation-message"><span className="error-palate">*&nbsp;{formik.errors.occupation}</span></span>
                          </div>
                      }  
                </Form.Group>
            </div>   
            <div className='col-md-6'>
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                      <Form.Label className="input-label">Name of Employer/Business <span class="required-field"></span></Form.Label>
                      <FormControl
                          size="sm"
                          aria-label="employerName"
                          aria-describedby="basic-addon1"
                          name="employerName"
                          autoComplete="off"
                          isInvalid={Boolean(formik.errors.employerName)}
                          onChange={formik.handleChange}
                          value={formik.values.employerName}
                      />
                      {formik.errors.employerName && 
                          <div className="col-md-12">
                          <span className="srv-validation-message"><span className="error-palate">*&nbsp;{formik.errors.employerName}</span></span>
                          </div>
                      }  
                </Form.Group>
            </div>           
        </div>
        <div className='row'>
            <div className='col-md-12'>
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                      <Form.Label className="input-label">Employer's Address/Business Address <span class="required-field"></span></Form.Label>
                      <FormControl
                          size="sm"
                          aria-label="employerAddress"
                          aria-describedby="basic-addon1"
                          name="employerAddress"
                          autoComplete="off"
                          isInvalid={Boolean(formik.errors.employerAddress)}
                          onChange={formik.handleChange}
                          value={formik.values.employerAddress}
                      />
                      {formik.errors.employerAddress && 
                          <div className="col-md-12">
                          <span className="srv-validation-message"><span className="error-palate">*&nbsp;{formik.errors.employerAddress}</span></span>
                          </div>
                      }  
                </Form.Group>
            </div>  
        </div>
        <div className='row'>
            <div className='col-md-7'>
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                      <Form.Label className="input-label">Source of Income <span class="required-field"></span></Form.Label>
                      <div className="col-md-12" style={{display:'flex', flexDirection:'row', alignItems:'center'}}>
                      <Form.Check
                            reverse
                            style={{fontSize:14, marginRight:20}}
                            label="Salary"
                            name="soi"
                            type={'radio'}
                            onClick={(e) => handleSoi('11')}
                            id={`reverse-checkbox-11`}
                            checked={formik.values.soi == '11'}
                        />
                        <Form.Check
                            reverse
                            style={{fontSize:14, marginRight:20}}
                            label="Business profit"
                            name="soi"
                            type={'radio'}
                            onClick={(e) => handleSoi('10')}
                            id={`reverse-checkbox-10`}
                            checked={formik.values.soi == '10'}
                        />
                         <Form.Check
                            reverse
                            style={{fontSize:14, marginRight:20}}
                            label="Investment income"
                            name="soi"
                            type={'radio'}
                            onClick={(e) => handleSoi('12')}
                            id={`reverse-checkbox-12`}
                            checked={formik.values.soi == '12'}
                        />
                        <Form.Check
                            reverse
                            style={{fontSize:14, marginRight:20}}
                            label="Other"
                            name="soi"
                            type={'radio'}
                            onClick={(e) => handleSoi('00')}
                            id={`reverse-checkbox-00`}
                            checked={formik.values.soi == '00'}
                        />
                        </div>
                      {formik.errors.soi && 
                          <div className="col-md-12">
                          <span className="srv-validation-message"><span className="error-palate">*&nbsp;{formik.errors.soi}</span></span>
                          </div>
                      }  
                </Form.Group>
                {
                    formik.values.soi.includes('00')?
                    <>
                    <FormControl
                        size="sm"
                        className='animate__animated animate__fadeIn'
                        aria-label="other_income"
                        aria-describedby="basic-addon1"
                        name="other_income"
                        autoComplete="off"
                        placeholder='Please state other source of income'
                        isInvalid={Boolean(formik.errors.other_income)}
                        onChange={formik.handleChange}
                        value={formik.values.other_income}
                    />
                    {formik.errors.other_income && 
                        <div className="col-md-12">
                        <span className="srv-validation-message"><span className="error-palate">*&nbsp;{formik.errors.other_income}</span></span>
                        </div>
                    } 
                    </>
                    :null
                }
            
            </div>  
            <div className='col-md-5'>
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                      <Form.Label className="input-label">Currency <span class="required-field"></span></Form.Label>
                        <Form.Select       
                            isInvalid={Boolean(formik.errors.currency)}
                            onChange={formik.handleChange}
                            value={formik.values.currency}
                            name="currency" 
                            autoComplete="off"
                            size="sm" 
                            aria-label="Default select example"
                        >
                            <option value="">Select the currency</option>
                            <option value="GB">Great British Pound (GBP)</option>
                            <option value="LKR">Sri Lankan Rupee (LKR)</option>
                            <option value="USD">United States Dollar (USD)</option>
                        </Form.Select>
                      {formik.errors.currency && 
                          <div className="col-md-12">
                          <span className="srv-validation-message"><span className="error-palate">*&nbsp;{formik.errors.currency}</span></span>
                          </div>
                      }  
                </Form.Group>
            </div>
        </div>
        <div className='row'>
            <div className='col-md-12'>
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                    <Form.Label className="input-label">Expected value of investment per annum</Form.Label>
                    <div className="col-md-12" style={{display:'flex', flexDirection:'row', alignItems:'center'}}>
                    <Form.Check
                            reverse
                            style={{fontSize:14, marginRight:20}}
                            label="Up to 1,000,000"
                            name="investment"
                            type={'radio'}
                            id={`reverse-checkbox-4`}
                            onClick={() => handleClickInvestment('10')}
                            checked={formik.values.investment == '10'}
                        />
                        <Form.Check
                            reverse
                            style={{fontSize:14, marginRight:20}}
                            label="1,000,000 to 5,000,000"
                            name="investment"
                            type={'radio'}
                            id={`reverse-checkbox-5`}
                            onClick={() => handleClickInvestment('11')}
                            checked={formik.values.investment == '11'}
                        />
                        <Form.Check
                            reverse
                            style={{fontSize:14, marginRight:20}}
                            label="5,000,000 to 10,000,000"
                            name="investment"
                            type={'radio'}
                            id={`reverse-checkbox-5`}
                            onClick={() => handleClickInvestment('12')}
                            checked={formik.values.investment == '12'}
                        />
                        <Form.Check
                            reverse
                            style={{fontSize:14, marginRight:20}}
                            label="Over 10,000,000"
                            name="investment"
                            type={'radio'}
                            id={`reverse-checkbox-6`}
                            onClick={() => handleClickInvestment('13')}
                            checked={formik.values.investment == '13'}
                        />
                        </div>
                    {formik.errors.investment && 
                        <div className="col-md-12">
                        <span className="srv-validation-message"><span className="error-palate">*&nbsp;{formik.errors.investment}</span></span>
                        </div>
                    }  
                </Form.Group>
            </div> 
            <div className='row'>
                <div className='col-md-6'>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <Form.Label className="input-label">Are you or any member of your family a politically exposed person(PEP)</Form.Label>
                        <div className="col-md-12" style={{display:'flex', flexDirection:'row', alignItems:'center'}}>
                            <Form.Check
                                style={{fontSize:12, marginRight:20}}
                                size="sm"
                                label="No"
                                name="pep"
                                type={'radio'}
                                onClick={() => handleClickPep(0)}
                                id={`inline-radio-pep-1`}
                                checked={formik.values.pep==0}
                            />
                            <Form.Check
                                style={{fontSize:12}}
                                size="sm"
                                label="Yes"
                                name="pep"
                                type={'radio'}
                                onClick={() => handleClickPep(1)}
                                id={`inline-radio-pep-2`}
                                checked={formik.values.pep==1}
                            />
                        </div>
                    </Form.Group>
                </div> 
                {
                    formik.values.pep == 1?
                    <div className='col-md-6 animate__animated animate__fadeIn'>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label className="input-label">Please state the relationship</Form.Label>
                            <FormControl
                                size="sm"
                                aria-label="pep_relationship"
                                aria-describedby="basic-addon1"
                                name="pep_relationship"
                                autoComplete="off"
                                isInvalid={Boolean(formik.errors.pep_relationship)}
                                onChange={formik.handleChange}
                                value={formik.values.pep_relationship}
                            />
                            {formik.errors.pep_relationship && 
                                <div className="col-md-12">
                                <span className="srv-validation-message"><span className="error-palate">*&nbsp;{formik.errors.pep_relationship}</span></span>
                                </div>
                            }  
                        </Form.Group>
                    </div> 
                    :null
                }
            </div>
            <div className='row'>
                <div className='col-md-12' style={{display:'flex', flexDirection:'column', justifyContent:'center'}}>
                    <Form.Check
                        style={{fontSize:13, marginRight:20}}
                        size="md"
                        isInvalid={firstDeclareInvalid}
                        label={`I, ${name} hereby declare that the information given above is true and accurate to the best of my knowledge and confirm submitting same to Senfin Asset Management for the purposes of investing in one or more funds managed by same`}
                        name="declare"
                        type={'checkbox'}
                        onClick={handleDeclareEach}
                        id={`inline-declare`}
                        checked={firstDeclare}
                    />
                </div>
            </div>
            <div ref={scrollref}></div>
        </div>
    </div>
  )
}
