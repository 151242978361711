import React from "react";
import { Button, Modal } from 'react-bootstrap';
import { faGavel, faHandshake } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import 'animate.css';
import moment from "moment";

const TermsAndCon = ({onHide, show, address, handleCancel, handleAgree}) =>  {
    const currentDate = moment();
    return (
      <Modal
        show={show}
        onHide={onHide}
        backdrop="static"
        size="lg"
        style={{fontSize:13}}
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header style={{padding:'0.3rem 0.3rem'}}>
          <Modal.Title style={{fontSize:18}} id="contained-modal-title-vcenter">
            <FontAwesomeIcon icon={faGavel} style={{paddingLeft:'8px'}}/>
            &nbsp;
            Terms and Conditions for Nominees  
          </Modal.Title>
        </Modal.Header>
          <div className="animate__animated animate__fadeIn">
            <Modal.Body style={{ paddingBottom:0, minHeight:250}}>
                <p>To receive all monies as aforesaid lying to the credit of the aforesaid account/s in the event of my death and I understand that such 
                nominations shall take effect upon my death notwithstanding anything to the contrary declared in any Last will or writing of a 
                testamentary nature heretofore or hereinafter made by me and that this nomination shall be subject to the Laws of Sri Lanka.<br/><br/>
                This nomination revokes any previous nominations made by me and shall be in force until I expressly revoke it by notice in writing 
                delivered to you by a subsequent nomination made duly by me.<br/><br/>
                I declare that the payment of the balance in the aforesaid account/s at the date of my death by the Manager to the nominee referred to 
                herein shall be full and complete discharge of the obligation of the Manager in respect of such account/s as provided in section 544(5) of 
                the said Civil Procedure Code. You are entitled without any notice to me to settle my indebtedness to the Manager and/or any third 
                party on any units pledged by me with written notice to you, whether such liabilities be actual contingent, primary or collateral and 
                several or joint on one account by transferring monies from the credit balance of another notwithstanding the balance on such other
                account and the liabilities may not be expressed in the same currency. The Manager’s right will not be affected by my bankruptcy or
                death.<br/><br/>
                
                <span style={{fontWeight:600}}>IN WITNESS WHERE OF</span> I have set my hand hereunto and to one other at {address} on this {currentDate.format("MMMM DD YYYY")}
                </p>

                <p style={{margin:0, fontSize:15, textDecoration:'underline', fontWeight:600, marginBottom:5}}>Conditions</p>
                <p>
                1. Nomination shall have effect notwithstanding anything to the contrary contained in any Last Will of the Unit holder.<br/>
                2. The Nomination will stand revoked under the following circumstances.<br/>
                <div className="row" style={{paddingLeft:20}}>
                    <div style={{width:'30px'}}>
                      a)&nbsp; 
                    </div>
                    <div className="col-md-10">
                      On the death of the nominee in the lifetime of the Unit holder.
                    </div>
                  <br/>
                </div>
                <div className="row" style={{paddingLeft:20}}>
                    <div style={{width:'30px'}}>
                      b)&nbsp;  
                    </div>
                    <div className="col-md-10">
                    By written notice of revocation of nomination by the Unit holder unless a Notice of Pledge/Lien has been 
                registered with the Manager.
                    </div>
                  <br/>
                </div>
                <div className="row" style={{paddingLeft:20}}>
                    <div style={{width:'30px'}}>
                      c)&nbsp;  
                    </div>
                    <div className="col-md-10">
                    By a subsequent nomination duly made by the Unit holder and delivered to the Manager unless a Notice of 
                Pledge/Lien has been registered with the Manager.
                    </div>
                  <br/>
                </div>
                <div className="row" style={{paddingLeft:20}}>
                    <div style={{width:'30px'}}>
                      d)&nbsp;  
                    </div>
                    <div className="col-md-10">
                    Where units have been pledged by the Unit holder in favor of a third party with written Notice to the Manager, 
                by written notice of revocation of such nomination confirmed in writing by the third-party Pledgee.
                    </div>
                  <br/>
                </div>

                3. The monies will be paid to the person legally entitled thereto in the event this nomination cannot be effected.<br/>
                4. Payment will be made to a nominee only on production of proof of identity and the Manager reserves the right to 
                call for any information /documents that maybe required by the Manager to establish such identity.<br/>
                5. Nominations cannot be made in respect of units held by more than one Unit holder jointly.<br/>
                6. The nominator should be a natural person.
                </p>

            </Modal.Body>
              <Modal.Footer style={{padding:'0.3rem 0.3rem', paddingLeft:'8px',paddingRight:'8px', paddingBottom:8, display:'flex', justifyContent:'space-between', borderTop:'none'}}>
                <Button className="btn btn-danger btn-red" style={{width:80}} onClick={handleCancel}>Cancel</Button>
                <Button className="btn btn-success btn-green" style={{width:80}} onClick={handleAgree}>Agree</Button>
            </Modal.Footer>
          </div>
      </Modal>
    );
  }

  export default TermsAndCon;