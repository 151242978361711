import React, {useState} from 'react';
import TopNav from '../../components/common/topnav';
import Loader from '../../components/common/loader';
import Kyc from '../../components/kyc/form';

const KYC = () => {
    document.body.classList.add('body-register')

    const [loading, setLoading] = useState(false);

    const handleLoading = (e) => {
        setLoading(e);
    }

    return (
        <>
          <Loader active={loading}>
          <div className="container">
            <TopNav counter={false} downer={false} signOutMobile={false}/>
            <div className='main-wrap' style={{minHeight:'100vh'}}>
                <div className='row justify-content-center'>
                    <Kyc loading={handleLoading}/>
                </div>
            </div>
            </div>
        </Loader>
        </>
    );
}

export default KYC;