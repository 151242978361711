import React, { useState, useEffect } from "react";
import CountUp from 'react-countup';

const News = ({fixedfund, equityfund, active}) => {
    const [news, setNews] = useState('We Provide customized solutions to your suit your invidual investment needs throgh our welth.');

    useEffect(() => {
        try{
            let news_get = sessionStorage.getItem('news');
            news_get = JSON.parse(news_get);
            let line = '';
            news_get.map((value, index) => {
                line+='* '+value['text']
            })
            setNews(line);
        }catch(error){

        }
    }, [])
    

    return(
        <>
        <div className="news">
            <div className="news-container">
                <p className="news-topic">News</p>

                <div className="form-control news-line">
                    <div className="news-line-text">{news}</div>
                </div>
            </div>
        </div>
        {
            active == 0?
            <div className="mini-fund-counter">
                <h5 className="mobile-counter"><span style={{display:'flex', fontWeight:750, color:'#11974b'}}>FIXED INCOME FUND </span><h4 style={{fontSize:'1rem'}} className="gray">
                    <CountUp 
                        start={0} 
                        end={fixedfund} 
                        separator=","
                        decimals={2}
                        duration={2}
                    /> 
                </h4></h5>
                <h5 className="mobile-counter"><span style={{display:'flex', fontWeight:750, color:'#11974b'}}>EQUITY FUND </span><h4 style={{fontSize:'1rem'}} className="gray">
                    <CountUp 
                        start={0} 
                        end={equityfund} 
                        separator=","
                        decimals={2}
                        duration={2}
                    /> 
                </h4></h5>
            </div>
            :null
        }
        </>
    )
}

export default News;