import React from "react";
import { Form, FormControl } from "react-bootstrap";
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";

const Contact = ({formik}) => {
    return (
    <div className="uploads-content ">
        <div className="col-md-12">
            <div className="row">
     
            </div>
            <div className="row animate__animated animate__fadeIn">
            <div className="col-md-6">
                    <Form.Group 
                        className="mb-3" 
                        controlId="exampleForm.ControlInput1"
                    >
                        <Form.Label className="input-label">Method</Form.Label>
                        <Form.Select 
                            size="sm"
                            name="method"
                            value={formik.values.method}
                            onChange={formik.handleChange}
                            isInvalid={Boolean(formik.errors.method)}
                        >
                            <option value="">Select Method</option>
                            <option value="skype">Skype</option>
                            <option value="whatsapp">Whats App</option>
                            <option value="zoom">Zoom</option>
                            <option value="viber">Viber</option>
                        </Form.Select>
                        {formik.errors.method && 
                        <div className="col-md-12">
                        <span className="srv-validation-message"><span className="error-palate">*&nbsp;{formik.errors.method}</span></span>
                        </div>
                        }  
                    </Form.Group>
                </div>
                <div className="col-md-6">
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <Form.Label className="input-label">Contact ID</Form.Label>
                        <FormControl
                            size="sm"
                            aria-label="conactid"
                            aria-describedby="basic-addon1"
                            autoComplete="off"
                            name="conatctid"
                            value={formik.values.conatctid}
                            onChange={formik.handleChange}
                            isInvalid={Boolean(formik.errors.conatctid)}
                        />
                        {formik.errors.conatctid && 
                        <div className="col-md-12">
                        <span className="srv-validation-message"><span className="error-palate">*&nbsp;{formik.errors.conatctid}</span></span>
                        </div>
                        }  
                    </Form.Group>
                </div>
                <div className="col-md-6">
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <Form.Label className="input-label">Contact At</Form.Label>
                        <DatePicker
                            className="form-control"
                            selected={formik.values.contactat}
                            onChange={(date) => {
                                formik.setFieldValue('contactat', date)
                            }}
                            name="contactat"
                            showTimeSelect
                            autoComplete="off"
                            timeFormat="HH:mm"
                            timeIntervals={1}
                            timeCaption="time"
                            dateFormat="MMMM d, yyyy h:mm aa"
                        />
                        {formik.errors.contactat && 
                        <div className="col-md-12">
                        <span className="srv-validation-message"><span className="error-palate">*&nbsp;{formik.errors.contactat}</span></span>
                        </div>
                        }  
                    </Form.Group>
                </div>
                <div className="col-md-6">
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <Form.Label className="input-label">Remark</Form.Label>
                        <Form.Control as="textarea" 
                        size="sm"
                        rows={3} 
                        autoComplete="off"
                        name="remark"
                        value={formik.values.remark}
                        onChange={formik.handleChange}
                        />
                        {formik.errors.remark && 
                        <div className="col-md-12">
                        <span className="srv-validation-message"><span className="error-palate">*&nbsp;{formik.errors.remark}</span></span>
                        </div>
                        }  
                    </Form.Group>
                </div>
            </div>
        </div>
    </div>
    )
}

export default Contact;