import React, { useEffect, useState } from "react";
import { Form, FormControl, InputGroup } from "react-bootstrap";
import Terms from "../../common/terms/terms";
import PhoneInput from 'react-phone-input-2'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PasswordStrengthBar from 'react-password-strength-bar';
import { faCheckCircle, faEye, faEyeSlash } from "@fortawesome/free-regular-svg-icons";
import { faLock } from "@fortawesome/free-solid-svg-icons";

const eye = <FontAwesomeIcon icon={faEye} />;
const eyeSlash = <FontAwesomeIcon icon={faEyeSlash} />;
const CorporateCustomer = ({ formik, state, setState, msg, setMsg, setStrength, strength }) => {
    const [passvalid, setPassvalid] = useState(false);
    const [showNewPass, setShowNewPass] = useState(false);
    const [showRePass, setShowRePass] = useState(false);
    const [customMsg, setCustomMsg] = useState('');

    //handle re password show icon
    const handleRePasswordShow = (e) => {
        setShowRePass(true);
        setTimeout(() => {
            setShowRePass(false);
        }, 1500);
    }

    //handle password show icon
    const handlePasswordShow = (e) => {
        setShowNewPass(true);
        setTimeout(() => {
            setShowNewPass(false);
        }, 1500);
    }

    //on chnage password strngth
    const  handleScore = (e) => {
        setStrength(e);
    }
    

    useEffect(() => {
        setState(3);
    }, [])

    return(
        <>
        <div className="row">
            <div className="col-md-6">
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                    <Form.Label><strong>Registration Number</strong></Form.Label>
                    <FormControl
                        size="sm"
                        name="registration"
                        aria-label="registration"
                        aria-describedby="basic-addon1"
                        autoComplete="off"
                        isInvalid={Boolean(formik.errors.registration)}
                        onChange={formik.handleChange}
                        value={formik.values.registration}
                    />
                     {formik.errors.registration && 
                        <div className="col-md-12">
                        <span className="srv-validation-message"><span className="error-palate">*&nbsp;{formik.errors.registration}</span></span>
                        </div>
                    }  
                </Form.Group>
            </div>
            <div className="col-md-6">
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                    <Form.Label><strong>Company Name</strong></Form.Label>
                    <FormControl
                        size="sm"
                        name="companyname"
                        aria-label="companyname"
                        autoComplete="off"
                        aria-describedby="basic-addon1"
                        isInvalid={Boolean(formik.errors.companyname)}
                        onChange={formik.handleChange}
                        value={formik.values.companyname}
                    />
                     {formik.errors.companyname && 
                        <div className="col-md-12">
                        <span className="srv-validation-message"><span className="error-palate">*&nbsp;{formik.errors.companyname}</span></span>
                        </div>
                    }  
                </Form.Group>
            </div>
            <div className="col-md-6">
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                    <Form.Label><strong>Company Registration Number</strong></Form.Label>
                    <FormControl
                        size="sm"
                        name="companyregistration"
                        aria-label="companyregistration"
                        autoComplete="off"
                        aria-describedby="basic-addon1"
                        isInvalid={Boolean(formik.errors.companyregistration)}
                        onChange={formik.handleChange}
                        value={formik.values.companyregistration}
                    />
                    {formik.errors.companyregistration && 
                        <div className="col-md-12">
                        <span className="srv-validation-message"><span className="error-palate">*&nbsp;{formik.errors.companyregistration}</span></span>
                        </div>
                    }  
                </Form.Group>
            </div>
            {/* <div className="col-md-6">
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                    <Form.Label><strong>Account Number</strong></Form.Label>
                    <FormControl
                        size="sm"
                        name="accountnumber"
                        autoComplete="off"
                        isInvalid={Boolean(formik.errors.accountnumber)}
                        onChange={formik.handleChange}
                        value={formik.values.accountnumber}
                        aria-label="accountnumber"
                        aria-describedby="basic-addon1"
                    />
                    {formik.errors.accountnumber && 
                        <div className="col-md-12">
                        <span className="srv-validation-message"><span className="error-palate">*&nbsp;{formik.errors.accountnumber}</span></span>
                        </div>
                    }  
                </Form.Group>
            </div> */}
            {/* <div className="col-md-6">
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                    <Form.Label><strong>Contact Person Name</strong></Form.Label>
                    <FormControl
                        size="sm"
                        name="personname"
                        autoComplete="off"
                        aria-label="personname"
                        aria-describedby="basic-addon1"
                        isInvalid={Boolean(formik.errors.personname)}
                        onChange={formik.handleChange}
                        value={formik.values.personname}
                    />
                    {formik.errors.personname && 
                        <div className="col-md-12">
                        <span className="srv-validation-message"><span className="error-palate">*&nbsp;{formik.errors.personname}</span></span>
                        </div>
                    }  
                </Form.Group>
            </div> */}
            {/* <div className="col-md-6">
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                    <Form.Label><strong>Contact Designation</strong></Form.Label>
                    <FormControl
                        size="sm"
                        name="designation"
                        autoComplete="off"
                        aria-label="designation"
                        aria-describedby="basic-addon1"
                        isInvalid={Boolean(formik.errors.designation)}
                        onChange={formik.handleChange}
                        value={formik.values.designation}
                    />
                    {formik.errors.designation && 
                        <div className="col-md-12">
                        <span className="srv-validation-message"><span className="error-palate">*&nbsp;{formik.errors.designation}</span></span>
                        </div>
                    }  
                </Form.Group>
            </div> */}
            {/* <div className="col-md-6">
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                    <Form.Label><strong>Contact Person Email</strong></Form.Label>
                    <FormControl
                        size="sm"
                        aria-label="email"
                        autoComplete="off"
                        name="email"
                        aria-describedby="basic-addon1"
                        isInvalid={Boolean(formik.errors.email)}
                        onChange={formik.handleChange}
                        value={formik.values.email}
                    />
                    {formik.errors.email && 
                        <div className="col-md-12">
                        <span className="srv-validation-message"><span className="error-palate">*&nbsp;{formik.errors.email}</span></span>
                        </div>
                    }  
                </Form.Group>
            </div> */}
            {/* <div className="col-md-6">
                <InputGroup  className="mb-3">
                <Form.Label><strong>Contact Person Mobile</strong></Form.Label>
                        <PhoneInput
                            country={'lk'}
                            name="mobile"
                            autoComplete="off"
                            value={formik.values.mobile}
                            onChange={phone => formik.setFieldValue('mobile', phone)}
                            style={{width:'100%'}}
                        />
                    {formik.errors.mobile && 
                        <div className="col-md-12">
                        <span className="srv-validation-message"><span className="error-palate">*&nbsp;{formik.errors.mobile}</span></span>
                        </div>
                    }  
                </InputGroup>
            </div> */}
        </div>
        <div className="row">
        <div className="col-md-6">
        <Form.Label><strong>Password</strong></Form.Label>
            <InputGroup  className="mb-3">
                <FormControl
                size="sm"
                name="password"
                type={showNewPass == false?"password":"text"}
                autoComplete="off"
                isInvalid={Boolean(formik.errors.password) || passvalid}
                value={formik.values.password}
                onChange={formik.handleChange}
                aria-label="password"
                aria-describedby="basic-addon1"
                />
                <ie style={{cursor:'pointer'}} onClick={handlePasswordShow}>{showNewPass == false?eye:eyeSlash}</ie>
                { formik.values.password.length > 0 && <div className="col-md-12 strength-container" style={{margin:0, padding:0}}>
                    <PasswordStrengthBar  className="strength-bar" onChangeScore={handleScore} password={formik.values.password} />
                </div>}
                {formik.errors.password && 
                    <div className="col-md-12">
                    <span className="srv-validation-message"><span className="error-palate">*&nbsp;{formik.errors.password}</span></span>
                    </div>
                }  

            </InputGroup>
        </div>
        <div className="col-md-6">
        <Form.Label><strong>Confirm Password</strong></Form.Label>
            <InputGroup  className="mb-3">
                <FormControl
                name="repassword"
                size="sm"
                type={showRePass == false?"password":"text"}
                autoComplete="off"
                isInvalid={Boolean(formik.errors.repassword)}
                value={formik.values.repassword}
                onChange={formik.handleChange}
                aria-label="Confirm password"
                // onKeyDown={handleEnterKey}
                aria-describedby="basic-addon1"
                />
                <ie style={{cursor:'pointer'}} onClick={handleRePasswordShow}>{showRePass == false?eye:eyeSlash}</ie>
                {formik.errors.repassword && 
                    <div className="col-md-12">
                    <span className="srv-validation-message"><span className="error-palate">*&nbsp;{formik.errors.repassword}</span></span>
                    </div>
                } 
            </InputGroup>
        </div>
        </div>
        <Terms formik={formik}/>
        </>
    )
}

export default CorporateCustomer;