import React, {useState,useEffect} from "react";
import { Button, Modal, Form, Col, Row } from 'react-bootstrap';
import { faFile } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import 'animate.css';
import OTP from "../../common/contents/otp";
import { useHistory } from "react-router-dom";
import api from '../../../services/api';
import Stepper from 'react-stepper-horizontal';
import Success from '../../common/alerts/success';
import Details from "./details";
import moment from "moment";

const DetailModel = ({onHide, show, formik, fundList, acountList}) =>  {
    const steps = 2; //steps without success window
    const history = useHistory();
    const [step, setStep] = useState(0);
    const [error, setError] = useState('');


    //change step and submit
    const onChangeStep = () => {
      if(steps > step){
        const set = step+1;
        setStep(set);
      }
    }

    useEffect(() => {
        setStep(0);
    }, [show])
    

    //modal previois button action
    const onchnagePrevious = () => {
      if(step > 0){
        const set = step-1;
        setStep(set);
      }
    }

    //close Mode
    const handleClose = () => {
        onHide(true);
    }

    //set Error to Model Content
    const handleSetError = (e) => {
      setError(e);
    }

    const handleContinue = async() =>{
        try{
            
        var FormData={
            from : moment(formik.values.from).format("YYYY/MM/DD"),
            to : moment(formik.values.to).format("YYYY/MM/DD"),
            ACCOUNT_NO : formik.values.account,
            desscription :formik.values.disacription,
            Fund : formik.values.fund == ''?'':fundList.filter((value) => value.FUND_CODE == formik.values.fund)[0].FUND_NAME,       
        }
            const data = await api.postRequest('statement', FormData);
            if(data.data.status == 'success'){
                onChangeStep();
            }else{  
                var erros = data.data.errors;
                var first = Object.keys(erros)[0]
                handleSetError(erros[first][0]);
            }
        }catch(error){
            handleSetError('Soemthing went wrong');
        }
    }

    return (
      <Modal
        show={show}
        onHide={onHide}
        backdrop="static"
        keyboard={false}
        size="md"
        style={{fontSize:13}}
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header style={{padding:'0.3rem 0.3rem'}}>
          <Modal.Title style={{fontSize:18}} id="contained-modal-title-vcenter">
            <FontAwesomeIcon icon={faFile} style={{paddingLeft:'8px'}}/>
            &nbsp;
            STATEMENT REQUEST
          </Modal.Title>
          <div className="steps">
            <Stepper 
              steps={[
                {title: ''}, 
                {title: ''}, 
              ]} 
              activeStep={step}
              size={15}
              circleTop={10}
              circleFontSize={0}
              completeColor='#5cb85c'
              activeColor={1 == 1?'#5096FF':'#d9534f'}
              defaultBorderWidth={2}
              />
          </div>
        </Modal.Header>
        <Modal.Body style={{ paddingBottom:0, minHeight:250}}>
          {
            step == 0?
                <Details formik={formik} acountList={acountList} fundList={fundList} error={error}/>
              :
                <Success closeTime={10} currentStep={step} nextStep={false} message="Statement Requesting Successful!" close={onHide} step={setStep}/>
              
          }
           
        </Modal.Body>
        <Modal.Footer style={{padding:'0.3rem 0.3rem', paddingLeft:'8px',paddingRight:'8px', paddingBottom:8, display:'flex', justifyContent:step == 0?'space-between':'end', borderTop:'none'}}>
          {step == 0?<Button className="btn btn-danger btn-red" style={{width:80}} onClick={onHide}>Cancel</Button>:''}
          <Button className="btn-btn-success btn-green" style={{width:80}} onClick={(e) =>step == 0? handleContinue(): handleClose()}>Continue</Button>
        </Modal.Footer>
      </Modal>
    );
  }

  export default DetailModel;