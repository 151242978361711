import React, {useEffect, useState} from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';

ChartJS.register(ArcElement, Tooltip, Legend);

export const dataset = {
  datasets: [
    {
      label: '# of Votes',
      data: [20, 12, 3, 14],
      backgroundColor: [
        'rgba(222,110,110,255)',
        'rgba(0,118,190,255)',
        'rgba(225,158,70,255)',
        'rgba(72,191,145,255)',
      ],
      borderColor: [
        'rgba(222,110,110,255)',
        'rgba(0,118,190,255)',
        'rgba(225,158,70,255)',
        'rgba(72,191,145,255)',
      ],
      hoverBackgroundColor:[
        'rgba(222,110,110,255)',
        'rgba(0,118,190,255)',
        'rgba(225,158,70,255)',
        'rgba(72,191,145,255)',
      ],
      borderWidth: 1,
    },
  ],
};

export function Chart2({data}) {
  const [dataList, setDataList] = useState(dataset);

  useEffect(() => {
    if(data != null && data.length != 0 && data != undefined){
      setDataList(data);
    }
  }, [data])
  return <Doughnut
        options={{
          cutout: '65%',
        }}
       data={dataList} />;
}
