import React, {useState, useEffect} from "react";
import { Button, Modal, Form, Col, Row } from 'react-bootstrap';
import { faBank } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import 'animate.css';
import { useHistory } from "react-router-dom";
import ModelForm from "../modelForm";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import api from '../../../services/api';

const BankModel = ({bankarray, setBankArray, onHide, show}) =>  {
    const history = useHistory();
    const [customMsg, setCustomMsg] = useState('');
    const [bank, setBank] = useState([]);
    const [branch, setBranch] = useState([]);

        //validation rules
        const validation = Yup.object({
          bank : Yup.string().required("Required"),
          branch : Yup.string().required("Required"),
          account : Yup.string().required("Required"),
          nickname: Yup.string().required('Required'),
        });
    
        //form submission
        const formik = useFormik({
            initialValues: {
              bank: '',
              bankName:'',
              branch: '',
              branchName:'',
              account: '',
              nickname:'',
            },
            validateOnChange:false,
            validateOnBlur:false,
            validationSchema: validation,
            // Login Request
            onSubmit: async(values, { resetForm }) => {
                setCustomMsg('');
                const bankar = [...bankarray];
                var bname = bank.filter((value) => value.id == values.bank)[0].name;
                var brname = branch.filter((value) => value.id == values.branch)[0].name;
                values.bankName = bname;
                values.branchName = brname;
                try{
                  if(isKeyInArray(bankarray, 'editable')){
                    var index = getKeyInArray(bankarray, 'editable');
                    if (index !== -1) {
                      bankarray[index] = values;
                    }
                  }else{
                    bankar.push(values)
                    setBankArray(bankar) 
                  }
                  resetForm();
                  onHide(true);
                }catch(error){
                    setCustomMsg('Something went wrong');
                }
            },
        });

    //handle error Message
    const handleMsg = (e) => {
      setCustomMsg(e)
    }

    const isKeyInArray = (array, key) => { 
      return array.some(obj => obj.hasOwnProperty(key)); 
    }

    const getKeyInArray = (array, key) => {
      let arr = array.filter(obj => obj.hasOwnProperty(key))[0]; 
      return bankarray.indexOf(arr);
    }

    useEffect(async () => {
      try{
          const bank_list = await api.getRequest('bank_list');
          if(bank_list.status == 200){
              if(bank_list.data.status == 'success'){
                  setBank(bank_list.data.bank_list);
                  setBranch(bank_list.data.bank_branch_list);
              }else{
                setCustomMsg('Something went wrong');
              }
          }
      }catch(error){
        setCustomMsg('Something went wrong');
      }
  }, [])

  useEffect(() => {
    if(isKeyInArray(bankarray, 'editable')){
      let key = getKeyInArray(bankarray, 'editable');
      let arr = bankarray[key];
      formik.setFieldValue('bank', arr.bank);
      formik.setFieldValue('bankName', arr.bankName);
      formik.setFieldValue('branch', arr.branch);
      formik.setFieldValue('branchName', arr.branchName);
      formik.setFieldValue('account', arr.account);
      formik.setFieldValue('nickname', arr.nickname);
    }
  }, [bankarray])
  

    return (
      <Modal
        show={show}
        onHide={onHide}
        backdrop="static"
        size="md"
        style={{fontSize:13}}
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header style={{padding:'0.3rem 0.3rem'}}>
          <Modal.Title style={{fontSize:18}} id="contained-modal-title-vcenter">
            <FontAwesomeIcon icon={faBank} style={{paddingLeft:'8px'}}/>
            &nbsp;
            BANK DETAILS 
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ paddingBottom:0}}>
            <ModelForm formik={formik} setMsg={handleMsg} msg={customMsg} bank={bank} branch={branch}/>
        </Modal.Body>
        <Modal.Footer style={{padding:'0.3rem 0.3rem', paddingLeft:'8px',paddingRight:'8px', paddingBottom:8, display:'flex', justifyContent:'space-between', borderTop:'none'}}>
          <Button className="btn btn-danger btn-red" style={{width:80}} onClick={onHide}>Cancel</Button>
          <Button className="btn-btn-success btn-green" style={{width:80}} onClick={formik.handleSubmit}>Continue</Button>
        </Modal.Footer>
      </Modal>
    );
  }

  export default BankModel;