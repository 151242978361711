import { Modal, Button, Form } from "react-bootstrap";
import { useEffect, useState } from "react";
import api from '../../../services/api';

const SupportContent = ({onHide, formik, setError, error}) => {
    const [transList, setTransList] = useState([]);

    useEffect(() => {
        loadTransList();
    }, [])

    const loadTransList = async() => {
        try{
            const data = await api.getRequest('transactions_type_list');
            if(data.data.status == 'success'){
                setTransList(data.data.transaction_type_list);
            }else{
                setTransList('');
            }
        }catch(error){
            setError('Something went wrong');
        }
    }
    

    return(
        <>
        <Modal.Body style={{ paddingBottom:0}}>
            <h6 style={{margin:0}}>Hello, </h6>
            <h6 style={{marginBottom:20}}>How may I help you? </h6>
            <div className="row animate__animated animate__fadeIn">
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                    <div className='row'>
                        <div className='col-md-3'>
                            <Form.Label className="input-label">Type of Question</Form.Label>
                        </div>
                        <div className='col-md-7'>
                        <Form.Select 
                            size="sm" 
                            name="type"
                            isInvalid={Boolean(formik.errors.type)}
                            value={formik.values.type}
                            onChange={formik.handleChange}
                            aria-label="Default select example"
                        >   
                                <option value="">Select type of question</option>
                                <option value="0">General</option>
                            {
                                transList.length > 0 && transList.map((value) =>{
                                    return(
                                        <option value={value.TRANS_CODE}>{value.NAME}</option>
                                    )
                                })
                            }
                        </Form.Select>
                        {formik.errors.type && 
                            <div className="col-md-12">
                            <span className="srv-validation-message"><span className="error-palate">*&nbsp;{formik.errors.type}</span></span>
                            </div>
                        } 
                        </div>
                    </div>
                </Form.Group>
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                    <div className='row'>
                    <div className='col-md-3'>
                        <Form.Label className="input-label">Inquiry</Form.Label>
                    </div>
                    <div className='col-md-9'>
                    <Form.Control 
                        as="textarea" 
                        name="inquiry"
                        isInvalid={Boolean(formik.errors.inquiry)}
                        value={formik.values.inquiry}
                        onChange={formik.handleChange}
                        rows={3}
                    />
                    {formik.errors.inquiry && 
                        <div className="col-md-12">
                        <span className="srv-validation-message"><span className="error-palate">*&nbsp;{formik.errors.inquiry}</span></span>
                        </div>
                    } 
                    </div>
                    </div>
                </Form.Group>
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                    <div className='row'>
                    <div className='col-md-3'>
                        <Form.Label className="input-label">Attachments</Form.Label>
                    </div>
                    <div className='col-md-9'>
                        <Form.Control 
                            type="file" 
                            name="attch"
                            accept="image/jpeg, image/png, image/jpg, image/gif, application/pdf"
                            isInvalid={Boolean(formik.errors.attch)}
                            onChange={(e) => formik.setFieldValue('attch', e.target.files[0])}
                            size="sm"
                        />
                    {formik.errors.attach && 
                        <div className="col-md-12">
                        <span className="srv-validation-message"><span className="error-palate">*&nbsp;{formik.errors.attach}</span></span>
                        </div>
                    } 
                    </div>
                    </div>
                </Form.Group>
                {error != '' && 
                    <div className="col-md-12">
                    <span className="srv-validation-message"><span className="error-palate">*&nbsp;{error}</span></span>
                    </div>
                } 
            </div>
            </Modal.Body>
                <Modal.Footer style={{padding:'0.3rem 0.3rem', paddingLeft:'8px',paddingRight:'8px', paddingBottom:8, display:'flex', justifyContent:'space-between', borderTop:'none'}}>
                <Button className="btn btn-danger btn-red" style={{width:80}} onClick={onHide}>Cancel</Button>
                <Button className="btn-btn-success btn-green" style={{width:80}} onClick={(e) => formik.handleSubmit()}>Submit</Button>
            </Modal.Footer>
        </>
    )
}

export default SupportContent;