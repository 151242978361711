import React, {useState, useEffect} from "react";
import { Button, Modal, Form, Col, Row } from 'react-bootstrap';
import { faLockOpen, faDownload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import 'animate.css';
import OTP from "../contents/otp";
import { useHistory } from "react-router-dom";
import api from '../../../services/api';
import Stepper from 'react-stepper-horizontal';
import Error from "../alerts/error";
import Success from "../alerts/success";

const OtpModelProfile = ({onHide, show, type, sendDetails, referance=null, typeofdata, data}) =>  {
    const steps = 2; //steps without success window
    const history = useHistory();
    const [step, setStep] = useState(0);
    const [Otp, setOtp] = useState('');
    const [error, setError] = useState('');
    const [sendData, setSendData] = useState([]);

    useEffect(() => {
        setSendData(data);
    }, [data])

    const handleSetError = (e) => {
        setError(e);
    }

    const handleOtp = (e) => {
      setOtp(e)
    }

    const onConfirm = async() => {
      var bodyData = {};
      if(step == 1 || step == 2){
          onHide(true);
      }else{
        if(typeofdata == 1){ //mobile
          bodyData = {
            'mobile': sendData.mobile,
            'otp':Otp,
          }
         const datares = await api.postRequest('update/mobile', bodyData);
          if(datares.data.status == 'success'){
            setStep(1);
          }else{
            setStep(2);
          }
        }else if(typeofdata == 2){ //email
          bodyData = {
            'email': sendData.email,
            'otp':Otp,
          }
         const datares = await api.postRequest('update/email', bodyData);
          if(datares.data.status == 'success'){
            setStep(1);
          }else{
            setStep(2);
          }
        }else{ //password

        }
      }
    }
  
    return (
      <Modal
        show={show}
        onHide={onHide}
        backdrop="static"
        keyboard={false}
        size="md"
        style={{fontSize:13}}
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header style={{padding:'0.3rem 0.3rem'}}>
          <Modal.Title style={{fontSize:18}} id="contained-modal-title-vcenter">
            <FontAwesomeIcon icon={faLockOpen} style={{paddingLeft:'8px'}}/>
            &nbsp;
           ONE TIME PASSWORD
          </Modal.Title>
          <div className="steps">
            <Stepper 
              steps={[
                {title: ''}, 
                {title: ''}, 
              ]} 
              activeStep={step}
              size={15}
              circleTop={10}
              circleFontSize={0}
              completeColor='#5cb85c'
              activeColor={1 == 1?'#5096FF':'#d9534f'}
              defaultBorderWidth={2}
              />
          </div>
        </Modal.Header>
        <Modal.Body style={{ paddingBottom:0, minHeight:250}}>
          {
            step == 0?
              <OTP 
                Otp={Otp} 
                setOtp={handleOtp} 
                type={type} 
                typeHide={true}
                sendDetails={sendDetails} 
                error={error}
                setError={handleSetError}
              />
              :(step == 1?
                  <Success closeTime={10} currentStep={step} nextStep={false} message="Profile Update Successful!" close={onHide} step={setStep}/>
                :
                  <Error closeTime={10} currentStep={step} nextStep={false} message="Profile Update Unsuccessful!" close={onHide} step={setStep}/>
                )
          }
           
        </Modal.Body>
        <Modal.Footer style={{padding:'0.3rem 0.3rem', paddingLeft:'8px',paddingRight:'8px', paddingBottom:8, display:'flex', justifyContent:step == 0?'space-between':'end', borderTop:'none'}}>
          {step == 0?<Button className="btn btn-danger btn-red" style={{width:80}} onClick={onHide}>Cancel</Button>:''}
          <Button className="btn-btn-success btn-green" disabled={Otp.length != 5} style={{width:80}} onClick={onConfirm}>Continue</Button>
        </Modal.Footer>
      </Modal>
    );
  }

  export default OtpModelProfile;