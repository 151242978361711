import React, {useEffect,useState} from 'react';
import TopNav from '../../components/common/topnav';
import Loader from '../../components/common/loader';
import Form from '../../components/register/form';
import Image from '../../assets/images/login-front.png';

const Register = () => {
    document.body.classList.add('body-login')

    useEffect(() => {
        document.body.classList.remove('body-register')
        document.body.classList.add('body-login')
    }, [])

    const [loading, setLoading] = useState(false);

    const handleLoading = (e) => {
        setLoading(e);
    }
    
    return (
        <>
           <Loader active={loading}>
            <div className="container">
                    <TopNav counter={false} downer={false} signOutMobile={false}/>
                    <div className='main-wrap' style={{minHeight:'100vh'}}>
                        <div className='row'>
                            <div className='col-md-6 mbrs'>
                                <div className='row justify-content-center'>
                                    <img src={Image} className='login-front-img'/>
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <div className='row justify-content-end'>
                                    <Form loading={handleLoading}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Loader>
        </>
    )
}

export default Register;