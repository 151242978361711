import React, { useEffect } from 'react';
import { Table } from 'react-bootstrap';
import axios from 'axios';
import individual from '../../../assets/applications/individual.pdf';
import investment from '../../../assets/applications/investment.pdf';
import nomination from '../../../assets/applications/nomination.pdf';
import documents from '../../../assets/applications/documents.zip';

const ApplicationDownloads = () => {

    useEffect(() => {
        const link = document.createElement('a');
        link.href = documents;
        link.setAttribute('download', 'documents_all.zip'); //or any other extension
        document.body.appendChild(link);
        link.click();
    }, [])
    
    return (
        <div className='col-md-12'>
            <div className='note-download'>
                <p style={{margin:0}}>This documents are required to complete application process. Therefore all the documents have already been downloaded <b>automatically</b>. If not, download the documents below.</p>
            </div>
            <div className='row' style={{marginTop:10}}>
                <Table responsive="sm">
                    <thead>
                        <tr>
                            <th className='text-left'>Document</th>
                            <th colSpan={2} className='text-right'>
                                <a href={documents} download="documents_all.zip" className="forget-password download-all">Download All</a>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td ><a href={individual} download="application.pdf" className="forget-password">Application Form</a></td>
                            <td className='text-center'><a href={investment} download="investment.pdf" className="forget-password">Investment Form</a></td>
                            <td className='text-right'><a href={nomination} download="nomination.pdf" className="forget-password">Nomination Form</a></td>
                        </tr>                        
                    </tbody>
                </Table>
            </div>
        </div>   
    )
}

export default ApplicationDownloads;