import React, { useState, useEffect} from "react";
import '../../../css/alert.css';
import { useHistory, Redirect } from "react-router-dom";
import Login from "../../../pages/Login";

const Success = ({message="Transaction Successful!", closeTime, close, currentStep=0, step, nextStep=false, redirect=null, leftResponce=null}) => {
    const history = useHistory();
    const [timeLeft, setTimeLeft] = useState(closeTime);

    useEffect(() => {
        if(timeLeft===0){
            setTimeLeft(0)
            if(redirect != null){
                history.push(redirect);
                window.location.reload();
            }

            if(leftResponce != null){
                leftResponce(0)
            }
           if(nextStep == false){
                close(true);
                step(0);
           }else{
                step(currentStep+1);
           }
        }
        if (!timeLeft) return;
        const intervalId = setInterval(() => {
          setTimeLeft(timeLeft - 1);
        }, 1000);
        return () => clearInterval(intervalId);
    }, [timeLeft]);

    return(
    <>
    <div className="swal2-icon swal2-success swal2-animate-success-icon" style={{display:'flex'}}>
        <div className="swal2-success-circular-line-left" style={{backgroundColor: "rgb(255, 255, 255)"}}></div>
        <span className="swal2-success-line-tip"></span>
        <span className="swal2-success-line-long"></span>
        <div className="swal2-success-ring"></div> 
        <div className="swal2-success-fix" style={{backgroundColor: "rgb(255, 255, 255)"}}></div>
        <div className="swal2-success-circular-line-right" style={{backgroundColor: "rgb(255, 255, 255)"}}></div>
    </div>
    <h2 className="swal2-title">{message}</h2>
        {
            nextStep == false?
                <div className="swal2-text">This window will close in {timeLeft} seconds</div>
            :(
                redirect == '/'?
                <div className="swal2-text">This window will redirected to login in {timeLeft} seconds</div>
                :
                <div className="swal2-text">This window will move to next step in {timeLeft} seconds</div>
            )
                
        }
            
    </>
    )
}

export default Success;