import React, {useState,useEffect,useRef} from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-phone-number-input/style.css';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import StepperCom from "../stepper";
import { Form, FormControl } from 'react-bootstrap';
import Document from "./document";
import Bank from "./bank";
import Contact from "./contact";
import { useHistory, useLocation } from "react-router-dom";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Success from '../../common/alerts/success';
import moment from 'moment';
import api from '../../../services/api';
import { data } from "../../home/chart/charts/chart.3";

const Kyc = ({loading}) => {
    const steps = 2;
    const history = useHistory();
    const location = useLocation();
    const btnref = useRef();
    const [active, setActve] = useState(0);
    const [userIdType, setuserIdType] = useState('nic');
    const [userbankList, setUserBankList] = useState([]);
    const [customMsg, setCustomMsg] = useState('');
    const [documents, setDocuments] = useState([]);
    const [banks, setBanks] = useState([]);
    const [contact, setContact] = useState([]);
    const [leftTime, setLeftTime] = useState(10);

    
    const validationDoc = Yup.object({
        application : Yup.string().required("Required"),
        nicf :userIdType == 'nic'?Yup.string().required("Required"):Yup.string().notRequired(),
        nicb :userIdType == 'nic'?Yup.string().required("Required"):Yup.string().notRequired(),
        passport:userIdType == 'passport'? Yup.string().required('Required'):Yup.string().notRequired(),
        verification: Yup.string().required('Required'),
        investment: Yup.string().required('Required'),
    });

    
    const formikDocument = useFormik({
        initialValues: {
          application: '',
          nicf: '',
          nicb: '',
          passport:'',
          verification:'',
          investment:'',
          nomination:'',
        },
        validateOnChange:false,
        validateOnBlur:false,
        validationSchema: validationDoc,
        // Login Request
        onSubmit: async(values) => {
            try{
                setDocuments(values);
                setActve(active+1);
            }catch(error){
                setCustomMsg('Something went wrong');
            }
        },
    });

    useEffect(async() => {
      try{
            setActve(0)
          var logged = sessionStorage.getItem('loggedBy');
            if(logged == 1){
                setuserIdType(location.state.type);
                setUserBankList(location.state.bank);
            }else{
                //from api call
                const data = await api.postRequest('application/details');
                if(data.status == 200){
                    if(data.data.status == 'success'){
                        let application_details = data.data.application_info;
                        if(application_details.application.NIC != null){
                            setuserIdType('nic');
                        }else{
                            setuserIdType('passport');
                        }
                        //bank list
                        let listofbank = [];
                        application_details.bank.length > 0 && application_details.bank.map((value) => {
                            listofbank.push({bankName:value.bank, account:value.account})
                        })
                        setUserBankList(listofbank);
                    }else{
                        setCustomMsg('Something went wrong'); 
                    }
                }else{
                    setCustomMsg('Something went wrong');
                }
            }
      }catch(error){
        setCustomMsg('Something went wrong');
      }
    }, [])

    //create bank feild
    const createBankValidateObject = () => {
        let obj = {};
        let banks = {};
        userbankList.length > 0 && userbankList.map((value, index) => {
            obj['bank'+index]=Yup.string().required("Required");
            banks['bank'+index]='';
        })
        return {validate:obj, formik:banks};
    }
    
    const validationbank = Yup.object(createBankValidateObject()['validate']);

    const formikBank = useFormik({
        initialValues: createBankValidateObject()['formik'],
        enableReinitialize:true,
        validateOnChange:false,
        validateOnBlur:false,
        validationSchema: validationbank,
        // Login Request
        onSubmit: async(values) => {
            try{
                setBanks(values);
                setActve(active+1);
            }catch(error){
                setCustomMsg('Something went wrong');
            }
        },
    });

    const validationContact = Yup.object({
        method : Yup.string().required("Required"),
        conatctid: Yup.string().required('Required'),
        contactat: Yup.string().required('Required'),
    });

    
    const formikContact = useFormik({
        initialValues: {
          method: '',
          conatctid: '',
          contactat: '',
          remark:'',
        },
        validateOnChange:false,
        validateOnBlur:false,
        validationSchema: validationContact,
        // Login Request
        onSubmit: async(values) => {
            loading(true);
            try{

                let formData = new FormData();
                formData.append('file[]', documents.application, 'application');
                if(userIdType == 'nic'){
                    formData.append('file[]', documents.nicf, 'ID_Front');
                    formData.append('file[]', documents.nicb, 'ID_Back');
                }else{
                    formData.append('file[]', documents.passport, 'Passport');
                }
                if(documents.verification != ''){
                  formData.append('file[]', documents.verification, 'Address_verify');
                }
                formData.append('file[]', documents.investment, 'Invesment');
                if(documents.nomination != ''){
                  formData.append('file[]', documents.nomination, 'Nomination');
                }
                if(Object.keys(banks).length > 0){
                    Object.keys(banks).map(function(key, index) {
                        formData.append('file[]', banks[key], 'bank_info');
                    });
                }
                formData.append('method', values.method);
                formData.append('contact_id', values.conatctid);
                formData.append('contact_at', moment(values.contactat).format('YYYY-MM-DD HH:MM'));
                formData.append('remarks', values.remark);
                const data = await api.postRequest('kyc', formData);
                if(data.data.status == 'success'){
                    setActve(active+1);
                }else{
                    setCustomMsg('Something went wrong');
                }
                loading(false);
            }catch(error){
                loading(false);
                setCustomMsg('Something went wrong');
            }
        },
    });
    

    //
    const handleNextButton = () => {
        if(active > steps){
            if(sessionStorage.getItem('loggedBy') == 2){
                history.push('/home');
            }else{
                history.push('/');
            }
           
        }
        if(active == 0){
            formikDocument.handleSubmit();
        }else if(active == 1){
            formikBank.handleSubmit();
        }else{
            formikContact.handleSubmit();
        }
    }

    //got to previous state
    const handlePrevios = () => {
        setActve(active-1)
    }

    const handleleftResponce = (e) => {
        if(e == 0){
            btnref.current.click();
            window.location.reload();
        }
    }

    return(
        <>
        <div className="col-md-7 animate__animated animate__fadeIn">
            <div className="login-card">
                <div className="panel-head">
                    <h4>Uploads</h4>
                </div>
                <hr style={{paddingTop: 0, margin:0}}/>
                {active > steps ?'':<StepperCom active={active}/>} 
                {
                    active == 0?
                        <Document type={userIdType} formik={formikDocument}/>
                    :(
                        active == 1?
                        <Bank bank={userbankList} formik={formikBank}/>
                        :(
                            active == 2?
                            <Contact formik={formikContact}/>
                            :
                            <div style={{marginTop:80}}>
                                <Success message="Documents Uploading Successful!" closeTime={10} leftResponce={handleleftResponce}/>
                            </div>
                           
                        )
                       
                    )
                }
                {customMsg != '' && 
                    <div className="col-md-12">
                    <span className="srv-validation-message"><span className="error-palate">*&nbsp;{customMsg}</span></span>
                    </div>
                }  
                <div style={{display:'flex', flexDirection:'row', justifyContent:active == 0||active == 3?'end':'space-between', alignItems:'center', marginTop:20}}>
                     {
                        active > 0 && active < 3?
                        <button onClick={handlePrevios} className="btn btn-danger login-button-dg">
                            Previous
                        </button>
                        :''
                     }
                    <button ref={btnref} onClick={handleNextButton} style={{position:active == 3?'absolute':'', bottom:active == 3?30:''}} className="btn btn-primary login-button" >
                        {active <= (steps-1)?'Next':(active > steps?(sessionStorage.getItem('loggedBy') == 2?'Home':'Login'):'Complete')}  
                    </button>
                </div>
            </div>
        </div>

        </>
    )
}

export default Kyc;