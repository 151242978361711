import { faAngleDoubleRight, faClock, faLock } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { Button, Modal } from 'react-bootstrap'
import { useHistory } from 'react-router-dom';

export default function RegisterInfo({show, onHide}) {
  const history = useHistory();

  const handleContinue = () => {
    history.push('/complete');
  }

  
  const logOut = () => {
    sessionStorage.removeItem('token');
    history.push('/')
  }

  return (
    <Modal
        show={show}
        onHide={onHide}
        keyboard={false}
        backdrop="static"
        size="md"
        style={{fontSize:13}}
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header style={{padding:'0.3rem 0.3rem'}}>
          <Modal.Title id="contained-modal-title-vcenter">
            <FontAwesomeIcon icon={faClock} style={{paddingLeft:'8px'}}/>
            &nbsp;
            Profile Information
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ paddingBottom:0}}>
          <p style={{fontWeight:600}}>Dear Customer,</p>

          <p>Welcome to your home page. To <span className='text-success' style={{fontWeight:500}}>complete the verification process</span> you will need the below documents.</p>

          <ul className="ul-change">
              <li style={{fontSize:15}}><span className="card-text" style={{fontSize:13}}>National ID Card</span></li>
              <li style={{fontSize:15}}><span className="card-text" style={{fontSize:13}}>Bank Passbook</span></li>
          </ul>
        </Modal.Body>
        <Modal.Footer style={{padding:'0.3rem 0.3rem', paddingLeft:'8px',paddingRight:'8px', paddingBottom:8, display:'flex', justifyContent:'end', borderTop:'none'}}>
            <Button className="btn btn-danger btn-red" style={{width:80}} onClick={logOut}>Sign Out</Button>
            <Button onClick={handleContinue} className="btn btn-success btn-green" style={{width:200}}>Continue Verification <FontAwesomeIcon icon={faAngleDoubleRight}/></Button>
        </Modal.Footer>
      </Modal>
  )
}
