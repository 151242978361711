import React, {useEffect, useState} from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';

ChartJS.register(ArcElement, Tooltip, Legend);

export const dataset = {
  datasets: [
    {
      label: '# of Votes',
      data: [12, 19],
      backgroundColor: [
        'rgba(209,223,69,255)',
        'rgba(42,104,119,255)',
      ],
      borderColor: [
        'rgba(209,223,69,255)',
        'rgba(42,104,119,255)',
      ],
      hoverBackgroundColor:[
        'rgba(209,223,69,255)',
        'rgba(42,104,119,255)',
      ],
      borderWidth: 1,
    },
  ],
};

export function Chart1({data}) {
  const [dataList, setDataList] = useState(dataset);

  useEffect(() => {
    if(data != null && data.length != 0 && data != undefined){
      setDataList(data);
    }
  }, [data])
  

  return <Doughnut 
        options={{
          cutout: '65%',
        }}
        data={dataList} />;
}
