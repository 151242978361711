import React from 'react';
import Stepper from 'react-stepper-horizontal';

const StepperCom = ({active}) => {
    return (
        <div>
            <Stepper 
                size={30} 
                circleTop={18} 
                circleFontSize={10} 
                completeTitleColor="#6FC750" 
                completeColor="#50C773" 
                completeBorderColor="#50C773"
                completeBarColor="#50C773"
                steps={[
                    {title: 'Documents'}, 
                    {title: 'Bank Informations'}, 
                    {title: 'Contact Informations'}
                ]} 
                activeStep={ active } 
                />
        </div> 
    )
}

export default StepperCom;