import React, {useState} from "react";
import { Button, Modal, Form, Col, Row } from 'react-bootstrap';
import { faLockOpen, faDownload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import 'animate.css';
import OTP from "../../common/contents/otp";
import { useHistory } from "react-router-dom";
import api from '../../../services/api';
import Stepper from 'react-stepper-horizontal';
import Success from '../../common/alerts/success';
import ApplicationDownloads from "../../common/downloads/ApplicationDownloads";

const OtpModel = ({onHide, show, password, passwordConfirm, type, sendDetails, referance}) =>  {
    const steps = 2; //steps without success window
    const history = useHistory();
    const [step, setStep] = useState(0);
    const [Otp, setOtp] = useState('');
    const [error, setError] = useState('');
    const [status, setStatus] = useState(1);

    // const setAuthData = (auth) => {
    //   sessionStorage.setItem('loggedBy', 1); //form register
    //   sessionStorage.setItem('token', auth.access_token);
    //   sessionStorage.setItem('user', auth.user);
    //   sessionStorage.setItem('profile', auth.user.profile);
    // } 

    const setAuthData = (auth) => {
      sessionStorage.setItem('loggedBy', 2); //form login
      sessionStorage.setItem('token', auth.access_token);
      sessionStorage.setItem('user',  JSON.stringify(auth.user));
      sessionStorage.setItem('advertisement', JSON.stringify(auth.advertisement));
      sessionStorage.setItem('news', JSON.stringify(auth.news));
      sessionStorage.setItem('profile', auth.user.profile);
      sessionStorage.setItem('maintance', auth.maintenance_notification);
    } 

    //set OTP
    const handleOtp = (e) => {
      setOtp(e);
    }
    
    //change step and submit
    const onChangeStep = () => {
      if(steps > step){
        const set = step+1;
        setStep(set);
      }
    }

    //modal previois button action
    const onchnagePrevious = () => {
      if(step > 0){
        const set = step-1;
        setStep(set);
      }
    }

    //set Error to Model Content
    const handleSetError = (e) => {
      setError(e);
    }

    //on confirm 
    const onConfirm = async() => {
      if(step == 0){
        try{
          var bodyData = {};
          if (type == 1) {
            bodyData = {
              'email': sendDetails,
              'password': password,
              'password_confirmation': passwordConfirm,
              'otp':Otp,
            }
          } else if (type == 2) {
            bodyData = {
              'mobile': sendDetails,
              'password': password,
              'password_confirmation': passwordConfirm,
              'otp':Otp,
            }
          }
          const data = await api.postRequest('register', bodyData);
          if(data.status == 200){
            if(data.data.status == 'success'){
              setAuthData(data.data);
              setStatus(1);
              onChangeStep();
            }else{
                var erros = data.data.errors; //if any otp issue
                var first = Object.keys(erros)[0]
              if(first == 'otp'){
                setError(erros[first][0]);
              }else{
                setStatus(0);
                onChangeStep();
              }
            }
          }
        }catch(error){
          setError('Something went wrong')
        }
      }else if(step == 1){
        history.push('/home');
        onHide(true);
      }
    }

    return (
      <Modal
        show={show}
        onHide={onHide}
        backdrop="static"
        keyboard={false}
        size="md"
        style={{fontSize:13}}
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header style={{padding:'0.3rem 0.3rem'}}>
          <Modal.Title style={{fontSize:18}} id="contained-modal-title-vcenter">
            <FontAwesomeIcon icon={step == 2?faDownload:faLockOpen} style={{paddingLeft:'8px'}}/>
            &nbsp;
           {step == 2?'DOCUMENTS DOWNLOADS':'ONE TIME PASSWORD'} 
          </Modal.Title>
          <div className="steps">
            <Stepper 
              steps={[
                {title: ''}, 
                {title: ''}, 
              ]} 
              activeStep={step}
              size={15}
              circleTop={10}
              circleFontSize={0}
              completeColor='#5cb85c'
              activeColor={1 == 1?'#5096FF':'#d9534f'}
              defaultBorderWidth={2}
              />
          </div>
        </Modal.Header>
        <Modal.Body style={{ paddingBottom:0, minHeight:250}}>
          {
            step == 0?
              <OTP 
                Otp={Otp} 
                setOtp={handleOtp} 
                type={type} 
                sendDetails={sendDetails} 
                referance={(e) => referance()}
                error={error}
                setError={handleSetError}
              />
              :(step == 1?
                  <Success closeTime={10} currentStep={step} nextStep={false} message="Registration Successful!" redirect={"/"}/>
                :
                  <ApplicationDownloads/>
                )
          }
           
        </Modal.Body>
        <Modal.Footer style={{padding:'0.3rem 0.3rem', paddingLeft:'8px',paddingRight:'8px', paddingBottom:8, display:'flex', justifyContent:step == 0?'space-between':'end', borderTop:'none'}}>
          {step == 0?<Button className="btn btn-danger btn-red" style={{width:80}} onClick={onHide}>Cancel</Button>:''}
          <Button className="btn-btn-success btn-green" disabled={Otp.length != 5} style={{width:80}} onClick={onConfirm}>Continue</Button>
        </Modal.Footer>
      </Modal>
    );
  }

  export default OtpModel;