import React, {useState,useEffect} from "react";
import { Button, Modal, Form, Col, Row } from 'react-bootstrap';
import { faExchange } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Stepper from 'react-stepper-horizontal';
import 'animate.css';
import Success from "../../../common/alerts/success";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import api from "../../../../services/api";
import Error from "../../../common/alerts/error";
import OTP from "../../../common/contents/otp";
import TransferForm from "../contents/transferForm";
import TransferMethod from "../contents/transferMethod";
import SwitchForm from "../contents/switchForm";
import SwichMethod from "../contents/swichMethod";


const Transfer = ({onHide, show, fundList, activeAcountList, refreshList, setRefresh, accountList, formType, setFromType}) =>  {
    const steps = 4; //steps without success window
    const [step, setStep] = useState(0);
    const [buyValues, setBuyValues] = useState([]);
    const [file, setFile] = useState([]);
    const [sellsection, setSellSection] = useState([])
    const [details, setDetails] = useState({});
    const [unitError, setUnitError] = useState(false);
    const [valueError, setValueError] = useState(false);
    const [coporate, setCoporate] = useState(false);
    const [customMsg, setCustomMsg] = useState('');
    const [Otp, setOtp] = useState('');
    const [loggedType, setLoggedType] = useState('');
    const [loggedMedia, setloggedMedia] = useState('');
    
    //modal previois button action
    const onchnagePrevious = () => {
      if(step > 0){
        const set = step-1;
        setStep(set);
      }
    }

    //when load page
    useEffect(() => {
        try{
            let user = JSON.parse(sessionStorage.getItem('user'));
            if(user.corporate != null && user.corporate == 1){
                setCoporate(true);
            }else{
                setCoporate(false);
            }

            if (user['mobile'] !== "undefined") {
                setLoggedType(2); // 2 = Mobile
                setloggedMedia(user['mobile']);
            } else {
                setLoggedType(1); // 1 = email 
                setloggedMedia(user['email']);
            }
        }catch(error){
            setCoporate(false);
        }
    }, [])
    


    //validations
  const validationTrans = Yup.object({
      fund : Yup.string().required("Required"),
      account : Yup.string().required("Required"),
      toaccount:Yup.string().required("Required"),
      redeemtype : Yup.string().required("Required"),
      redeemmethod : Yup.string().when(['redeemtype'], (redeemtype, schema) => {
        return redeemtype == 2? schema.required('Required') : schema.notRequired();
      }),
      value:Yup.string().when(['redeemmethod'], (redeemmethod, schema) => {
        return redeemmethod == 1? schema.required('Required'): schema.notRequired();
      }),
      units:Yup.string().min(1, 'Unit must be grater than zero').when(['redeemmethod'], (redeemmethod, schema) => {
        return redeemmethod == 2? schema.required('Required') : schema.notRequired();
      }),
  });

  const formikTrans = useFormik({
    initialValues: {
        fund: '',
        account: '',
        toaccount:'',
        redeemtype: '',
        redeemmethod: '',
        value: '',
        units: '',
        totalunits:0,
        numberofunits:0,
        availabale:0,
      },
      validateOnChange:false,
      validateOnBlur:false,
      validationSchema: validationTrans,
      // Login Request
      onSubmit: async(values) => {
          if(values.totalunits >= values.numberofunits && values.redeemtype == 1){
            setUnitError(true)
            return false;
          }else{
            setUnitError(false)  
          }

          //check avaialble and enterd
          if(values.redeemmethod == 1){
                if(Number(values.value) > Number(values.availabale)){
                    setValueError(true);
                    return false;
                }else{
                    setValueError(false); 
                }
          }else{
                if(Number(values.units) > Number(values.availabale)){
                    setValueError(true);
                    return false;
                }else{
                    setValueError(false); 
                }
          }
            let detail = {
                fund:fundList.filter((value) => value.FUND_CODE == values.fund)[0].FUND_NAME,
                account:values.account,
                toaccount:values.toaccount == '1'?'New':values.toaccount,
                amount:values.redeemmethod == '1'?values.value:values.units,
                redeemtype:values.redeemtype == '1'?'Full':'Partial',
                redeemmethod:values.redeemmethod == '1'?'By Value':'By Units'
            }
          setSellSection(values);
          setDetails(detail);
          setStep(1);
      },
  });

  
  useEffect(() => {
    setValueError('');
  }, [formikTrans.values.redeemmethod])

  //validations
  const validationMethod = Yup.object({
      bankslip : coporate== true?Yup.array().min(1, "Required"):Yup.array().notRequired(),
      terms : Yup.string().required("Required"),
  });

  useEffect(() => {
    setFromType(0);
  }, [])

  const formikMethod = useFormik({
      initialValues: {
        bankslip: [],
        terms: '',
      },
      validateOnChange:false,
      validateOnBlur:false,
      validationSchema: validationMethod,
      // Login Request
      onSubmit: async(values, {resetForm}) => {
        setFile(values.bankslip);
        try{
          let data = await api.postRequest('transaction/otp');
          if(data.status == 200){
            if(data.data.status == 'success'){
              setStep(2);
            }else{
              setStep(4);
            }
          }else{
            setStep(4);
          }
        //   formikTrans.resetForm();
          // resetForm();
        }catch(error){
        // formikTrans.resetForm();
          resetForm();
          setStep(4);
        }
      },
  });

  //handle sell request
  const handleSellRequest = async() => {
    try{
        const fd = new FormData();
        if(sellsection.redeemtype==1){
        let numofunit = activeAcountList.filter((value) => value.FUND_CODE == sellsection.fund)[0];
            fd.append('FUND_CODE', sellsection.fund);
            fd.append('FUND_CODE_2', sellsection.fund);
            fd.append('ACCOUNT_NO', sellsection.account);
            if(sellsection.toaccount != '1'){
                fd.append('ACCOUNT_NO_2', sellsection.toaccount);
            }
            fd.append('redeem_type', sellsection.redeemtype);
            fd.append('redeem_method', 2);
            fd.append('NO_OF_UNITS', numofunit.NO_OF_UNITS);
            fd.append('otp', Otp);
            formikMethod.values.bankslip.length > 0 && Array.from(formikMethod.values.bankslip).forEach(files => {
                fd.append('documents[]', files);
            })
        }else if(sellsection.redeemtype==2 && sellsection.redeemmethod == 1){
            fd.append('FUND_CODE', sellsection.fund);
            fd.append('FUND_CODE_2', sellsection.fund);
            fd.append('ACCOUNT_NO', sellsection.account);
            if(sellsection.toaccount != '1'){
              fd.append('ACCOUNT_NO_2', sellsection.toaccount);
            }
            fd.append('redeem_type', sellsection.redeemtype);
            fd.append('redeem_method', sellsection.redeemmethod);
            fd.append('AMOUNT', sellsection.value);
            fd.append('otp', Otp);  
            formikMethod.values.bankslip.length > 0 && Array.from(formikMethod.values.bankslip).forEach(files => {
                fd.append('documents[]', files);
            }) 
        }else{
            fd.append('FUND_CODE', sellsection.fund);
            fd.append('FUND_CODE_2', sellsection.fund);
            fd.append('ACCOUNT_NO', sellsection.account);
            if(sellsection.toaccount != '1'){
              fd.append('ACCOUNT_NO_2', sellsection.toaccount);
            }
            fd.append('redeem_type', sellsection.redeemtype);
            fd.append('redeem_method', sellsection.redeemmethod);
            fd.append('NO_OF_UNITS', sellsection.units);
            fd.append('otp', Otp);
            formikMethod.values.bankslip.length > 0 && Array.from(formikMethod.values.bankslip).forEach(files => {
                fd.append('documents[]', files);
            })   
        }

        let data = await api.postRequest('transfer', fd);
        if(data.status == 200){
          if(data.data.status == 'success'){
            setStep(3);
          }else{
            setStep(4);
          }
        }else{
          setCustomMsg('Incorrect Otp Enterd!');
        }
        setOtp('');
        formikTrans.resetForm();
        formikMethod.resetForm();
        formikMethod.setFieldValue('bankslip', [])
        setRefresh(!refreshList);
    }catch(error){
      formikTrans.resetForm();
      formikMethod.resetForm();
      formikMethod.setFieldValue('bankslip', [])
      setRefresh(!refreshList);
      setCustomMsg('Incorrect Otp Enterd!');
    }
  }


  //change step and submit
  const onChangeStep = () => {
    if(step == 0){
        if(formType == 1){
          formikSwitch.handleSubmit();
        }else{
          formikTrans.handleSubmit();
        }
    }else if(step == 1){
      if(formType == 1){
        formikSwitchMethod.handleSubmit();
      }else{
        formikMethod.handleSubmit();
      }
    }else if(step == 2){
      if(formType == 1){
        handleSwitchRequest();
      }else{
        handleSellRequest();
      }
    }else{
      setStep(0);
      onHide(true);
    }
  }

    //handle sell request
    const handleSwitchRequest = async() => {
      try{
          const fd = new FormData();
          if(sellsection.redeemtype==1){
          let numofunit = activeAcountList.filter((value) => value.FUND_CODE == sellsection.fund)[0];
              fd.append('FUND_CODE', sellsection.fund);
              fd.append('FUND_CODE_2', sellsection.tofund);
              fd.append('ACCOUNT_NO', sellsection.account);
              if(sellsection.toaccount != '1'){
                  fd.append('ACCOUNT_NO_2', sellsection.toaccount);
              }
              fd.append('redeem_type', sellsection.redeemtype);
              fd.append('redeem_method', 2);
              fd.append('NO_OF_UNITS', numofunit.NO_OF_UNITS);
              fd.append('otp', Otp);
              formikSwitchMethod.values.bankslip.length > 0 && Array.from(formikSwitchMethod.values.bankslip).forEach(files => {
                  fd.append('documents[]', files);
              })
          }else if(sellsection.redeemtype==2 && sellsection.redeemmethod == 1){
              fd.append('FUND_CODE', sellsection.fund);
              fd.append('FUND_CODE_2', sellsection.tofund);
              fd.append('ACCOUNT_NO', sellsection.account);
              if(sellsection.toaccount != '1'){
                fd.append('ACCOUNT_NO_2', sellsection.toaccount);
              }
              fd.append('redeem_type', sellsection.redeemtype);
              fd.append('redeem_method', sellsection.redeemmethod);
              fd.append('AMOUNT', sellsection.value);
              fd.append('otp', Otp);  
              formikSwitchMethod.values.bankslip.length > 0 && Array.from(formikSwitchMethod.values.bankslip).forEach(files => {
                  fd.append('documents[]', files);
              }) 
          }else{
              fd.append('FUND_CODE', sellsection.fund);
              fd.append('FUND_CODE_2', sellsection.tofund);
              fd.append('ACCOUNT_NO', sellsection.account);
              if(sellsection.toaccount != '1'){
                fd.append('ACCOUNT_NO_2', sellsection.toaccount);
              }
              fd.append('redeem_type', sellsection.redeemtype);
              fd.append('redeem_method', sellsection.redeemmethod);
              fd.append('NO_OF_UNITS', sellsection.units);
              fd.append('otp', Otp);
              formikSwitchMethod.values.bankslip.length > 0 && Array.from(formikSwitchMethod.values.bankslip).forEach(files => {
                  fd.append('documents[]', files);
              })   
          }
  
          let data = await api.postRequest('switch', fd);
          if(data.status == 200){
            if(data.data.status == 'success'){
              setStep(3);
            }else{
              setStep(4);
            }
          }else{
            setCustomMsg('Incorrect Otp Enterd!');
          }
          setOtp('');
          formikSwitch.resetForm();
          formikSwitchMethod.resetForm();
          formikSwitchMethod.setFieldValue('bankslip', [])
          setRefresh(!refreshList);
      }catch(error){
        formikSwitch.resetForm();
        formikSwitchMethod.resetForm();
        formikSwitchMethod.setFieldValue('bankslip', [])
        setRefresh(!refreshList);
        setCustomMsg('Incorrect Otp Enterd!');
      }
    }
  

  //error msg set
  const handleSetError = (e) =>{
    setCustomMsg(e)
  }

  const handleOtp = (e) => {
    setOtp(e)
  }


      //validations
      const validationSwitch = Yup.object({
        fund : Yup.string().required("Required"),
        account : Yup.string().required("Required"),
        tofund:Yup.string().required("Required"),
        toaccount:Yup.string().required("Required"),
        redeemtype : Yup.string().required("Required"),
        redeemmethod : Yup.string().when(['redeemtype'], (redeemtype, schema) => {
          return redeemtype == 2? schema.required('Required') : schema.notRequired();
        }),
        value:Yup.string().when(['redeemmethod'], (redeemmethod, schema) => {
          return redeemmethod == 1? schema.required('Required'): schema.notRequired();
        }).test('min-amount', 'The minimum amount required is 1000 LKR.', (value, context) => {
          if(formikSwitch.values.value != '' && formikSwitch.values.redeemmethod == 1 && Number(formikSwitch.values.value) < 1000){
            return false;
          }else{
            return true;
          }
        }),
        units:Yup.string().min(1, 'Unit must be grater than zero').when(['redeemmethod'], (redeemmethod, schema) => {
          return redeemmethod == 2? schema.required('Required') : schema.notRequired();
        }),
    });
  
    const formikSwitch = useFormik({
      initialValues: {
          fund: '',
          tofund:'',
          account: '',
          toaccount:'',
          redeemtype: '',
          redeemmethod: '',
          value: '',
          units: '',
          totalunits:0,
          numberofunits:0,
          availabale:0,
        },
        validateOnChange:false,
        validateOnBlur:false,
        validationSchema: validationSwitch,
        // Login Request
        onSubmit: async(values) => {
            if(values.totalunits >= values.numberofunits && values.redeemtype == 1){
              setUnitError(true)
              return false;
            }else{
              setUnitError(false)  
            }
  
            //check avaialble and enterd
            if(values.redeemmethod == 1){
                  if(Number(values.value) > Number(values.availabale)){
                      setValueError(true);
                      return false;
                  }else{
                      setValueError(false); 
                  }
            }else{
                  if(Number(values.units) > Number(values.availabale)){
                      setValueError(true);
                      return false;
                  }else{
                      setValueError(false); 
                  }
            }
              let detail = {
                  fund:fundList.filter((value) => value.FUND_CODE == values.fund)[0].FUND_NAME,
                  account:values.account,
                  tofund:fundList.filter((value) => value.FUND_CODE == values.tofund)[0].FUND_NAME,
                  toaccount:values.toaccount == '1'?'New':values.toaccount,
                  amount:values.redeemmethod == '1'?values.value:values.units,
                  redeemtype:values.redeemtype == '1'?'Full':'Partial',
                  redeemmethod:values.redeemmethod == '1'?'By Value':'By Units'
              }
            setSellSection(values);
            setDetails(detail);
            setStep(1);
        },
    });
  
    
    useEffect(() => {
      setValueError('');
    }, [formikSwitch.values.redeemmethod])
  
    //validations
    const validationSwitchMethod = Yup.object({
        bankslip : coporate== true?Yup.array().min(1, "Required"):Yup.array().notRequired(),
        terms : Yup.string().required("Required"),
    });
  
    const formikSwitchMethod = useFormik({
        initialValues: {
          bankslip: [],
          terms: '',
        },
        validateOnChange:false,
        validateOnBlur:false,
        validationSchema: validationSwitchMethod,
        // Login Request
        onSubmit: async(values, {resetForm}) => {
          setFile(values.bankslip);
          try{
            let data = await api.postRequest('transaction/otp');
            if(data.status == 200){
              if(data.data.status == 'success'){
                setStep(2);
              }else{
                setStep(4);
              }
            }else{
              setStep(4);
            }
          //   formikSwitch.resetForm();
            // resetForm();
          }catch(error){
          // formikSwitch.resetForm();
            resetForm();
            setStep(4);
          }
        },
    });
  

    return (
      <Modal
        show={show}
        onHide={onHide}
        backdrop={formType == 0?"true":"static"}
        keyboard={false}
        size="md"
        style={{fontSize:13}}
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header style={{padding:'0.3rem 0.3rem'}}>
          <Modal.Title id="contained-modal-title-vcenter">
            <FontAwesomeIcon icon={faExchange} style={{paddingLeft:'8px'}}/>
            &nbsp;
            SWITCH
          </Modal.Title>
          <div className="steps">
            <Stepper 
              steps={[
                {title: ''}, 
                {title: ''}, 
                {title: ''}, 
                {title: ''}, 
              ]} 
              activeStep={step}
              size={15}
              circleFontSize={0}
              completeColor='#5cb85c'
              activeColor={1 == 1?'#5096FF':'#d9534f'}
              defaultBorderWidth={2}
              />
          </div>
        </Modal.Header>
        <Modal.Body style={{ paddingBottom:0}}>
          {
            formType == 0?
            <>
            <div className="overlay-selection" style={{marginTop:50, marginBottom:30}}> 
              <span onClick={(e) => setFromType(1)} className="overlay-link" style={{marginRight:50}}>Fund to Fund</span>
              <span onClick={(e) => setFromType(2)} className="overlay-link" style={{marginLeft:50}}>Within Fund</span>
            </div>
            <div className="animate__animated animate__fadeIn" style={{paddingLeft:20, marginBottom:30}}>
              <p style={{margin:0, padding:0, fontSize:12, fontWeight:600}}>Note:</p>
              <p style={{margin:0, padding:0, fontSize:12, paddingLeft:25}}>1. <span style={{fontWeight:600}}>Fund to Fund</span> - Transfer funds between different Fund types.</p>
              <p style={{margin:0, padding:0, fontSize:12, paddingLeft:25}}>2. <span style={{fontWeight:600}}>Within Fund</span> - Transfer funds between sub accounts in the same Fund.</p>
            </div>
            </>
            :(
            step == 0?
              formType == 1?
              <SwitchForm formik={formikSwitch} fundList={fundList} activeAcountList={activeAcountList} error={unitError} error2={valueError} accountAllList={accountList}/>
              :
              <TransferForm formik={formikTrans} fundList={fundList} activeAcountList={activeAcountList} error={unitError} error2={valueError} accountAllList={accountList}/>
            :(
              step == 1?
              <div className="animate__animated animate__fadeIn">
                {
                   formType == 1?
                   <SwichMethod formik={formikSwitchMethod} details={details} coporate={coporate}/>
                   :
                   <TransferMethod formik={formikMethod} details={details} coporate={coporate}/>
                }
              
              </div>
              : (
                step == 2?
                formType == 1?
                <OTP
                    Otp={Otp} 
                    setOtp={handleOtp} 
                    type={loggedType} 
                    typeHide={true}
                    sendDetails={loggedMedia} 
                    error={customMsg}
                    setError={handleSetError}
                />
                :
                <div className="">
                <OTP
                    Otp={Otp} 
                    setOtp={handleOtp} 
                    type={loggedType} 
                    typeHide={true}
                    sendDetails={loggedMedia} 
                    error={customMsg}
                    setError={handleSetError}
                />
                </div>
                :(
                    step == 3?
                    <div className="animate__animated animate__fadeIn">
                        <Success closeTime={10} close={onHide} step={setStep}/>
                    </div>
                    :
                    <div className="animate__animated animate__fadeIn">
                        <Error closeTime={10} close={onHide} step={setStep}/>
                    </div>
                )
              )
            )
            )
          }
        </Modal.Body>
        <Modal.Footer style={{padding:'0.3rem 0.3rem', paddingLeft:'8px',paddingRight:'8px', paddingBottom:8, display:formType==0?'none':'flex', justifyContent:step > 2?'end':'space-between', borderTop:'none'}}>
          {
            (
            step == 0?
              <Button  className="btn btn-danger btn-red" style={{width:80, display:step > 2?'none':''}} onClick={(e) => {
                onHide();
                formikTrans.resetForm();
                formikMethod.resetForm();
                formikMethod.values.bankslip.splice(0, formikMethod.values.bankslip.length);
              }}>Cancel</Button>
              :
              <Button className="btn btn-danger btn-red" style={{width:80, display:step > 2?'none':''}} onClick={onchnagePrevious}>Previous</Button>
            )
          }
        
          <Button className="btn-btn-success btn-green" style={{width:80}} disabled={step==2?(Otp.length == 5?false:true):false} onClick={onChangeStep}>Continue</Button>
        </Modal.Footer>
      </Modal>
    );
  }

  export default Transfer;