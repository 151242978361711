import React from 'react';
import { useHistory } from 'react-router-dom';

export default function RegistarComplete() {
    const history = useHistory();

    const handleContinue = () => {
        history.push('/complete');
    }
  return (
    <div className="row" style={{paddingLeft:35}}>
        <p style={{ fontSize:15, fontWeight:600}}>Your profile has <span className='text-danger'>not yet been Verified.</span></p>
        <p style={{ fontSize:14}}>To complete the verification process you must have below documents.</p>

        <ul className="ul-change" style={{paddingLeft:50}}>
            <li style={{fontSize:14}}><span className="card-text" style={{fontSize:13}}>National ID Card</span></li>
            <li style={{fontSize:14}}><span className="card-text" style={{fontSize:13}}>Bank Passbook</span></li>
        </ul>
        <div className='row' style={{padding:20}}>
            <button type='button' onClick={handleContinue} className='btn btn-sm btn-success'>Verify Profile Now</button>
        </div>
    </div>
  )
}
