import React, { useState, useEffect } from "react";
import { InputGroup, FormControl, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import 'bootstrap/dist/css/bootstrap.min.css';
import { faUser, faLock, faEnvelope, faMobileAlt } from "@fortawesome/free-solid-svg-icons";
import 'react-phone-input-2/lib/style.css'
import PhoneInput from 'react-phone-input-2'
import { useHistory } from "react-router-dom";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import api from '../../../services/api';
import OtpModel from "../model";

const Form = () => {
    const history = useHistory();
    const [DivSwift, setDivSwift] = useState(1);
    const [customMsg, setCustomMsg] = useState('');
    const [modelShow, setModelShow] = useState(false);

    const validation = Yup.object({
        email : DivSwift == 1?Yup.string().required("Email required").email('Invalid email address'):Yup.string().notRequired(),
        mobile : DivSwift == 2?Yup.string().required("Mobile required"):Yup.string().notRequired(),
    });

    
    const setAuthData = (auth) => {
        sessionStorage.setItem('loggedBy', 2); //form register
        sessionStorage.setItem('token', auth.access_token);
        sessionStorage.setItem('user', '');
        sessionStorage.setItem('profile', '');
    } 

    const formik = useFormik({
        initialValues: {
          email: '',
          mobile: '',
        },
        validateOnChange:false,
        validateOnBlur:false,
        validationSchema: validation,
        // Login Request
        onSubmit: async(values) => {
            setCustomMsg('');
            if(DivSwift == 1){
               var body = {
                    'email':values.email,
                }
            }else{
                var body = {
                    'mobile':'+'+values.mobile,
                }
            }
            try{
                const data = await api.postRequest('password/reset', body)
                if(data.status == 200){
                    if(data.data.status == 'success'){
                        if(data.data.status == 'success'){
                            setAuthData(data.data);
                            setModelShow(true);
                        }
                    }else{
                        setCustomMsg('Forget password request could not be sent!');
                    }
                }else{
                    setCustomMsg('Something went wrong!');
                }
            }catch(error){
                setCustomMsg('Something went wrong!');
            }
        },
    });

    const handleModelHide = () => {
        setModelShow(false);
    }

    const handleCreateNew = () => {
        history.push('/register');
    }

    const handleCreateLogin = () => {
        history.push('/');
    }

    //handle enter key login
    const handleEnterKey = (e) => {
        if(e.key == 'Enter'){
            formik.handleSubmit();
        }
    }


    return ( 
        <>
        <div className="col-md-8 animate__animated animate__fadeIn">
            <div className="login-card">
                <h3>Reset Password</h3>
                <p className="card-text">Please enter your email address or mobile phone number <br/>
                to reset your current password
                </p>
                {/* <hr/> */}
        
                <div className="panel-head">
                <ul className="animated_tab">
                    <li className={DivSwift == 1?'active':''} onClick={(e) => setDivSwift(1)}><a className="tab-item" data-toggle="tab"><FontAwesomeIcon className="login-icon" icon={faEnvelope}/>Email</a></li>
                    <li className={DivSwift == 2?'active':''} onClick={(e) => setDivSwift(2)}><a className="tab-item" data-toggle="tab"><FontAwesomeIcon className="login-icon" icon={faMobileAlt}/>Mobile</a></li>
                </ul>
                </div>
                {
                    DivSwift == 1?
                    <InputGroup  className="mb-3" style={{marginTop:10}}>
                        <InputGroup.Text id="basic-addon1"><FontAwesomeIcon icon={faUser}/></InputGroup.Text>
                        <FormControl
                        placeholder="Email"
                        aria-label="Email"
                        name="email"
                        autoComplete="off"
                        isInvalid={Boolean(formik.errors.email)}
                        onChange={formik.handleChange}
                        aria-describedby="basic-addon1"
                        value={formik.values.email}
                        onKeyDown={handleEnterKey}
                        />
                        {formik.errors.email && 
                        <div className="col-md-12">
                        <span className="srv-validation-message"><span className="error-palate">*&nbsp;{formik.errors.email}</span></span>
                        </div>
                        }   
                        
                    </InputGroup>
                    :
                    <InputGroup  className="mb-3">
                            <PhoneInput
                                country={'lk'}
                                name="mobile"
                                placeholder="Mobile"
                                autoComplete="off"
                                isInvalid={Boolean(formik.errors.mobile)}
                                onChange={phone => formik.setFieldValue('mobile', phone)}
                                value={formik.values.mobile}
                                // onChange={phone => setMobile(phone)}
                                style={{width:'100%',marginTop:10}}
                                onKeyDown={handleEnterKey}
                            />
                             {formik.errors.mobile && <div className="col-md-12">
                                <span className="srv-validation-message"><span className="error-palate">*&nbsp;{formik.errors.mobile}</span></span>
                            </div>}
                    </InputGroup>
                }
              
                {
                    customMsg != ''?
                    <span className="srv-validation-message"><span className="error-palate">*&nbsp;{customMsg}</span></span>
                    :''
                }
                <div className="text-right" style={{display:'flex', flexDirection:'row', justifyContent:'end', alignItems:'center', marginTop:20}}>
                    <button onClick={(e) => formik.handleSubmit()} style={{width:150}} className="btn btn-primary login-button">
                        Change Password
                    </button>
                </div>
                <div className="pt-2">
                    <p className="line-separator"><span>or</span></p>
                </div>
                <div className="pt-1" style={{display:'flex', flexDirection:'row', justifyContent:"space-between", alignItems:'center'}}>
                    <span className="card-text underline" onClick={handleCreateNew}>Create new account</span>
                    <span className="card-text underline" onClick={handleCreateLogin}>Login</span>
                </div>
            </div>
        </div>
        <div className="col-md-1">
            
        </div>
        <OtpModel show={modelShow} onHide={handleModelHide} type={DivSwift} sendDetails={DivSwift == 1?formik.values.email:formik.values.mobile}/>
        </>
    );
}

export default Form;