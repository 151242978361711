import React, {useEffect, useState} from "react";
import CountUp from 'react-countup';

const FundCount = ({fixedfund, equityfund}) => {
    const [fixed, setFixed] = useState(3345711900.24);
    const [equity, setEquity] = useState(3345711900.24);

    useEffect(() => {
      const user = sessionStorage.getItem('user');
      if(user.profile != null){

      }
    }, [])

    useEffect(() => {
        setFixed(fixedfund);
    }, [fixedfund])
    
    useEffect(() => {
        setEquity(equityfund);
    }, [equityfund])

    return(
        <div className="col-md-5 center">
            <div className="row">
                <div className="col-md-6 text-right">
                    <h6 className="dark-blue">FIXED INCOME FUND</h6>
                    <h4 className="gray">
                            <CountUp 
                                start={0} 
                                end={fixed} 
                                separator=","
                                decimals={2}
                                duration={2}
                            /> 
                    </h4>
                </div>
                <div className="col-md-1">
                    <div class="vertical-line line-center"></div>
                </div>
                <div className="col-md-5">
                    <h6 className="dark-blue">EQUITY FUND</h6>
                    <h4 className="gray">
                            <CountUp 
                                start={0} 
                                end={equity} 
                                separator=","
                                decimals={2}
                                duration={2}
                            /> 
                    </h4>
                </div>
            </div>
        </div>
    )
}

export default FundCount;