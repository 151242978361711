import React, { useEffect, useState } from "react";
import { Form, FormControl, InputGroup } from "react-bootstrap";
import Terms from "../../common/terms/terms";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PasswordStrengthBar from 'react-password-strength-bar';
import { faCheckCircle, faEye, faEyeSlash } from "@fortawesome/free-regular-svg-icons";
import { faLock } from "@fortawesome/free-solid-svg-icons";

const eye = <FontAwesomeIcon icon={faEye} />;
const eyeSlash = <FontAwesomeIcon icon={faEyeSlash} />;
const IndividualCustomer = ({ formik, state, setState, msg, setMsg, setStrength, strength }) => {
    const [passvalid, setPassvalid] = useState(false);
    const [showNewPass, setShowNewPass] = useState(false);
    const [showRePass, setShowRePass] = useState(false);
    const [customMsg, setCustomMsg] = useState('');

    useEffect(() => {
        setState(2);
    }, [])

    //handle re password show icon
    const handleRePasswordShow = (e) => {
        setShowRePass(true);
        setTimeout(() => {
            setShowRePass(false);
        }, 1500);
    }

    //handle password show icon
    const handlePasswordShow = (e) => {
        setShowNewPass(true);
        setTimeout(() => {
            setShowNewPass(false);
        }, 1500);
    }

    //on chnage password strngth
    const  handleScore = (e) => {
        setStrength(e);
    }

    return (
        <>
        <div className="row">
            <div className="col-md-6">
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                    <Form.Label><strong>Full Name</strong></Form.Label>
                    <FormControl
                        size="sm"
                        aria-label="fullname"
                        aria-describedby="basic-addon1"
                        name="fullname"
                        autoComplete="off"
                        isInvalid={Boolean(formik.errors.fullname)}
                        onChange={formik.handleChange}
                        value={formik.values.fullname}
                    />
                    {formik.errors.fullname && 
                        <div className="col-md-12">
                        <span className="srv-validation-message"><span className="error-palate">*&nbsp;{formik.errors.fullname}</span></span>
                        </div>
                    }  
                </Form.Group>
            </div>
            <div className="col-md-6">
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                    <Form.Label><strong>Type</strong></Form.Label>
                    <Form.Select 
                        size="sm" 
                        isInvalid={Boolean(formik.errors.type)}
                        onChange={formik.handleChange}
                        value={formik.values.type}
                        autoComplete="off"
                        name="type"
                        aria-label="Default select example">
                        <option value="nic">NIC</option>
                        <option value="passport">Passport</option>
                        <option value="other">Other</option>
                    </Form.Select>
                    {formik.errors.type && 
                        <div className="col-md-12">
                        <span className="srv-validation-message"><span className="error-palate">*&nbsp;{formik.errors.type}</span></span>
                        </div>
                    }  
                </Form.Group>
            </div>

        </div>
        <div className="row">
            <div className="col-md-6">
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                    <Form.Label><strong>Identification Number</strong></Form.Label>
                    <FormControl
                        size="sm"
                        aria-label="idnumber"
                        name="idnumber"
                        autoComplete="off"
                        isInvalid={Boolean(formik.errors.idnumber)}
                        onChange={formik.handleChange}
                        value={formik.values.idnumber}
                        aria-describedby="basic-addon1"
                    />
                    {formik.errors.idnumber && 
                        <div className="col-md-12">
                        <span className="srv-validation-message"><span className="error-palate">*&nbsp;{formik.errors.idnumber}</span></span>
                        </div>
                    }  
                </Form.Group>
            </div>
            <div className="col-md-6">
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                    <Form.Label><strong>Registration Number</strong></Form.Label>
                    <FormControl
                        size="sm"
                        name="registration"
                        isInvalid={Boolean(formik.errors.registration)}
                        onChange={formik.handleChange}
                        autoComplete="off"
                        value={formik.values.registration}
                        aria-label="registration"
                        aria-describedby="basic-addon1"
                    />
                    {formik.errors.registration && 
                        <div className="col-md-12">
                        <span className="srv-validation-message"><span className="error-palate">*&nbsp;{formik.errors.registration}</span></span>
                        </div>
                    }  
                </Form.Group>
            </div>
        </div>
        <div className="row">
        <div className="col-md-6">
        <Form.Label><strong>Password</strong></Form.Label>
            <InputGroup  className="mb-3">
                <FormControl
                size="sm"
                name="password"
                type={showNewPass == false?"password":"text"}
                autoComplete="off"
                isInvalid={Boolean(formik.errors.password) || passvalid}
                value={formik.values.password}
                onChange={formik.handleChange}
                aria-label="password"
                aria-describedby="basic-addon1"
                />
                <ie style={{cursor:'pointer'}} onClick={handlePasswordShow}>{showNewPass == false?eye:eyeSlash}</ie>
                { formik.values.password.length > 0 && <div className="col-md-12 strength-container" style={{margin:0, padding:0}}>
                    <PasswordStrengthBar  className="strength-bar" onChangeScore={handleScore} password={formik.values.password} />
                </div>}
                {formik.errors.password && 
                    <div className="col-md-12">
                    <span className="srv-validation-message"><span className="error-palate">*&nbsp;{formik.errors.password}</span></span>
                    </div>
                }  

            </InputGroup>
        </div>
        <div className="col-md-6">
        <Form.Label><strong>Confirm Password</strong></Form.Label>
            <InputGroup  className="mb-3">
                <FormControl
                name="repassword"
                size="sm"
                type={showRePass == false?"password":"text"}
                autoComplete="off"
                isInvalid={Boolean(formik.errors.repassword)}
                value={formik.values.repassword}
                onChange={formik.handleChange}
                aria-label="Confirm password"
                // onKeyDown={handleEnterKey}
                aria-describedby="basic-addon1"
                />
                <ie style={{cursor:'pointer'}} onClick={handleRePasswordShow}>{showRePass == false?eye:eyeSlash}</ie>
                {formik.errors.repassword && 
                    <div className="col-md-12">
                    <span className="srv-validation-message"><span className="error-palate">*&nbsp;{formik.errors.repassword}</span></span>
                    </div>
                } 
            </InputGroup>
        </div>
        </div>
        {
            customMsg != ''?
            <div className="col-md-12">
                <span className="srv-validation-message"><span className="error-palate">*&nbsp;{customMsg}
                </span></span>
            </div>
            :''
        }
        <Terms formik={formik}/>
        </>
    )
}

export default IndividualCustomer;