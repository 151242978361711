import React from "react";
import LoadingOverlay from 'react-loading-overlay-ts';
import  BarLoader  from 'react-spinners/HashLoader';

const Loader = (props) => {
    return (
        <LoadingOverlay
            active={props.active}
            spinner={<BarLoader color="#2d9957"/>}
        >
            {props.children}
      </LoadingOverlay>
    )
}
export default Loader;