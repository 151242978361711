import React, { useState, useEffect } from "react";
import { InputGroup, FormControl, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import 'bootstrap/dist/css/bootstrap.min.css';
import { faUser, faLock, faEnvelope, faMobileAlt, faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import 'react-phone-input-2/lib/style.css'
import PhoneInput from 'react-phone-input-2'
import { useHistory } from "react-router-dom";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import api from '../../../services/api';


const eye = <FontAwesomeIcon icon={faEye} />;
const eyeSlash = <FontAwesomeIcon icon={faEyeSlash} />;
const Form = ({loading}) => {
    const history = useHistory();
    const [DivSwift, setDivSwift] = useState(1);
    const [customMsg, setCustomMsg] = useState('');
    const [showPass, setShowPass] = useState(false);

    useEffect(() => {
        setCustomMsg('');
        sessionStorage.setItem('loggedBy', ''); //form login
        sessionStorage.setItem('token','');
        sessionStorage.setItem('user', '');
        sessionStorage.setItem('advertisement', '');
        sessionStorage.setItem('news', '');
        sessionStorage.setItem('profile', '');
        sessionStorage.setItem('loggedBy', '');
        sessionStorage.setItem('maintance', 0);
    }, [DivSwift])

    const setAuthData = (auth) => {
        sessionStorage.setItem('loggedBy', 2); //form login
        sessionStorage.setItem('token', auth.access_token);
        sessionStorage.setItem('user',  JSON.stringify(auth.user));
        sessionStorage.setItem('advertisement', JSON.stringify(auth.advertisement));
        sessionStorage.setItem('news', JSON.stringify(auth.news));
        sessionStorage.setItem('profile', auth.user.profile);
        sessionStorage.setItem('maintance', auth.maintenance_notification);
    } 
    

    const validation = Yup.object({
        email : DivSwift == 1?Yup.string().required("Email required").email('Invalid email address'):Yup.string().notRequired(),
        mobile : DivSwift == 2?Yup.string().required("Mobile required"):Yup.string().notRequired(),
        password : Yup.string().required("Password required"),
    });

    const formik = useFormik({
        initialValues: {
          email: '',
          mobile: '',
          password: '',
        },
        validateOnChange:false,
        validateOnBlur:false,
        validationSchema: validation,
        // Login Request
        onSubmit: async(values) => {
            loading(true);
            setCustomMsg('');
            if(DivSwift == 1){
               var body = {
                    'email':values.email,
                    'password':values.password
                }
            }else{
                var body = {
                    'mobile':'+'+values.mobile,
                    'password':values.password
                }
            }
            try{
                const data = await api.postRequestNoToken('login', body)
                if(data.status == 200){
                    if(data.data.status == 'success'){
                        setAuthData(data.data);
                        // if(data.data.user.application == null){
                        //     history.push('/application');
                        //     return false;
                        // }

                        // if(data.data.user.kyc == null){
                        //     history.push('/uploads');
                        //     return false;
                        // }
                        history.push('/home');
                    }else{
                        setCustomMsg('Email / Mobile or Password is invalid');
                    }
                }else{
                    setCustomMsg('Email / Mobile or Password is invalid');
                }
                loading(false);
            }catch(error){
                setCustomMsg('Email / Mobile or Password is invalid');
                loading(false);
            }
        },
    });

    const handleCreateNew = () => {
        history.push('/checkpoint');
    }

    //handle enter key login
    const handleEnterKey = (e) => {
        if(e.key == 'Enter'){
            formik.handleSubmit();
        }
    }

    //handle re password show icon
    const handlePasswordShow = (e) => {
        setShowPass(true);
        setTimeout(() => {
            setShowPass(false);
        }, 1500);
    }

    //forget password
    const handleForgetPassword = () => {
        history.push('/reset');
    }
    

    return ( 
        <>
        <div className="col-md-8 animate__animated animate__fadeIn">
            <div className="login-card">
                <h3>Welcome Back</h3>
                <p className="card-text">To keep connected with us please login with your personal<br/>
                
                information by email address or mobile
                </p>
                {/* <hr/> */}
                <div className="panel-head">
                <ul className="animated_tab">
                    <li className={DivSwift == 1?'active':''} onClick={(e) => setDivSwift(1)}><a className="tab-item" data-toggle="tab"><FontAwesomeIcon className="login-icon" icon={faEnvelope}/>Email</a></li>
                    <li className={DivSwift == 2?'active':''} onClick={(e) => setDivSwift(2)}><a className="tab-item" data-toggle="tab"><FontAwesomeIcon className="login-icon" icon={faMobileAlt}/>Mobile</a></li>
                </ul>
                </div>
                {
                    DivSwift == 1?
                    <InputGroup  className="mb-3" style={{marginTop:10}}>
                        <InputGroup.Text id="basic-addon1"><FontAwesomeIcon icon={faUser}/></InputGroup.Text>
                        <FormControl
                        placeholder="Email"
                        aria-label="Email"
                        name="email"
                        autoComplete="off"
                        isInvalid={Boolean(formik.errors.email)}
                        onChange={formik.handleChange}
                        aria-describedby="basic-addon1"
                        value={formik.values.email}
                        />
                        {formik.errors.email && 
                        <div className="col-md-12">
                        <span className="srv-validation-message"><span className="error-palate">*&nbsp;{formik.errors.email}</span></span>
                        </div>
                        }   
                        
                    </InputGroup>
                    :
                    <InputGroup  className="mb-3">
                            <PhoneInput
                                country={'lk'}
                                name="mobile"
                                placeholder="Mobile"
                                autoComplete="off"
                                isInvalid={Boolean(formik.errors.mobile)}
                                onChange={phone => formik.setFieldValue('mobile', phone)}
                                value={formik.values.mobile}
                                // onChange={phone => setMobile(phone)}
                                style={{width:'100%',marginTop:10}}
                            />
                             {formik.errors.mobile && <div className="col-md-12">
                                <span className="srv-validation-message"><span className="error-palate">*&nbsp;{formik.errors.mobile}</span></span>
                            </div>}
                    </InputGroup>
                }
              
                <InputGroup  className="mb-3">
                    <InputGroup.Text id="basic-addon1"><FontAwesomeIcon icon={faLock}/></InputGroup.Text>
                    <FormControl
                    placeholder="Password"
                    aria-label="password"
                    type={showPass == false?`password`:`text`}
                    autoComplete="off"
                    name="password"
                    onKeyDown={handleEnterKey}
                    isInvalid={Boolean(formik.errors.password)}
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    // onChange={(e) => setPassword(e.target.value)}
                    aria-describedby="basic-addon1"
                    />
                    <ie style={{cursor:'pointer'}} onClick={handlePasswordShow}>{showPass == false?eye:eyeSlash}</ie>
                    { formik.errors.password && <div className="col-md-12">
                        <span className="srv-validation-message"><span className="error-palate">*&nbsp;{formik.errors.password}</span></span>
                    </div>
                    }
                </InputGroup>
                {/* {
                    Object.keys(formik.errors).length > 0 && Object.values(formik.errors).map((el) => {
                        return(
                            <span className="srv-validation-message"><span className="error-palate">*&nbsp;{el}</span></span>
                        )
                    })
                } */
                
                    customMsg != ''?
                    <span className="srv-validation-message"><span className="error-palate">*&nbsp;{customMsg}</span></span>
                    :''
                }
                <div className="text-right" style={{display:'flex', flexDirection:'row', justifyContent:'space-between', alignItems:'center', marginTop:20}}>
                    <span className="forget-password" onClick={handleForgetPassword}>Forgot Password ?</span>
                    <button onClick={(e) => formik.handleSubmit()} className="btn btn-primary login-button">
                        Login
                    </button>
                </div>
                <div className="pt-2">
                    <p className="line-separator"><span>or</span></p>
                </div>
                <div className="pt-1">
                    <span className="card-text underline" onClick={handleCreateNew}>Create new account</span>
                </div>
            </div>
        </div>
        <div className="col-md-1">
            
        </div>
        </>
    );
}

export default Form;