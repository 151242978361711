import React, { useState, useEffect } from 'react';
import FundCount from '../../components/home/fund_count';
import TopNav from '../../components/common/topnav';
import Downer from '../../components/common/downer';
import News from '../../components/common/news';
import Navbar from '../../components/common/navbar';
import Portfolio from '../../components/home/portfolio';
import Actions from '../../components/home/actions';
import Banner from '../../components/home/banner';
import Footer from '../../components/common/footer';
import Chart from '../../components/home/chart';
import '../../css/custom.css';
import TransactionForm from '../../components/transaction/form';
import SupportModel from '../../components/support/model';
import api from '../../services/api';
import InProgress from '../../components/home/progress';
import Loader from '../../components/common/loader';
import UserFundsTable from '../../components/home/user_funds';
import { Logout } from '../../helpers';
import { useHistory } from 'react-router-dom';

import { useDispatch } from 'react-redux';
import { profileUpdater } from '../../store/actions';
import axios from "axios";
import Maintance from '../../components/home/maintance';
import SiteNotify from '../../components/home/site_notify';
import RegisterInfo from '../../components/home/actions/models/registerInfo';

function Home() {
    const history  = useHistory();
    const [navActive, setNavActive] = useState(0);
    const [support, setSupport] = useState(false);
    const [fundlist, setFundlist] = useState([]);
    const [userFund, setUserFund] = useState([]);
    const [tableFund, setTableFund] = useState([]);
    const [fundall, setFundall] = useState(false);
    const [inprogress, setInProgress] = useState(false);
    const [maintance, setMaintance] = useState(false);
    const [maintanceReaded, setMaintanceReaded] = useState(false);
    const [siteNotification, setSiteNotification] = useState(false);
    const [siteNotificationReaded, setSiteNotificationReaded] = useState(false);

    //fund list for models
    const [modelFundList, setModelFundList] = useState([]);
    const [modelActiveAccount, setModelActiveAccount] = useState([]);
    const [modelAccount, setModelAccount] = useState([]);
    const [refreshListFund, setRefreshListFund] = useState(false);
    const [fixedfund, setFixedFund] = useState(0);
    const [equityfund, setEquityFund] = useState(0);
    const [fundCost, setFundCost] = useState([]);
    const [loading, setLoading] = useState(false);

    //Chart data list
    const [fixedFundChart, setFixedFundChart] = useState([]);
    const [equityFundChart, setEquityFundChart] = useState([]);
    const [allFundChart, setAllFundChart] = useState([]);
    const [tableActive, setTableActive] = useState(2);
    const [requireVerify, setRequireVerify] = useState(false);
    const [userUpdate, setUserUpdate] = useState(null);

    document.body.classList.remove('body-register');
    document.body.classList.remove('body-login');

    const dispatch = useDispatch();

    const noInterceptAxios = axios.create();
    axios.interceptors.response.use(
        function async(response) {
        if(response?.data?.maintenance_notification 
            && response?.data?.maintenance_notification == true && maintanceReaded == false){
                setMaintance(true);
        }

        if(response?.data?.site_notification 
            && response?.data?.site_notification == 1 && siteNotificationReaded == false){
                setSiteNotification(true);
        }
        return response;
        }
    );
    
    const handleLoading = (e) => {
        setLoading(e);
    }

    //handleTable Activation
    const handleTableActive = (e) => {
        setTableActive(e);
    }

    const LogoutHome = () => {
        if(sessionStorage.getItem('token') == undefined || sessionStorage.getItem('token') == '' || sessionStorage.getItem('token') == null){
            history.push('/');
        }
    }

    useEffect(async() => {
        let response = await api.postRequest('info').catch(() => {
        });
        if(response.status == 200){
            sessionStorage.setItem('user', JSON.stringify(response.data));
            setUserUpdate(JSON.stringify(response.data));
        }
    }, [])

    useEffect(async() => {
        LogoutHome();
        loadFundList();
        try{
            let user = JSON.parse(userUpdate);
            if(user != undefined && user != null){
                if(user?.kyc != null && user?.application != null){
                    setRequireVerify(false);
                    if(user?.profile != null){
                        setInProgress(false);
                    }else{
                        setInProgress(true);
                    }
                }else{
                    setRequireVerify(true);
                }
            }
            dispatch(profileUpdater('asdasdasdasda'))
        }catch(error){
            setInProgress(true);
        }
    }, [userUpdate])

    useEffect(() => {
        fundLists();
    }, [refreshListFund])

    
    const loadFundList = async() => {
        try{
            const data = await api.getRequest('funds');
            if(data.data.status == 'success'){

                setFundlist(data.data.funds);
                setUserFund(data.data.user_funds);

                if(data.data.user_funds.length > 0){
                    let fixedAmount = Object.values(data.data.user_funds).reduce((r, { REDIM_PRICE, type, NO_OF_UNITS }) => r + (type == 1?(Number(REDIM_PRICE) * Number(NO_OF_UNITS)):0), 0);
                    let EquiryAmount = Object.values(data.data.user_funds).reduce((r, { REDIM_PRICE, type, NO_OF_UNITS }) => r + (type == 2?(Number(REDIM_PRICE) * Number(NO_OF_UNITS)):0), 0);

                    setFixedFund(fixedAmount);
                    setEquityFund(EquiryAmount);

                    let fixAr = [];
                    let eqiAr = [];
                    let fulAr = [];
                    data.data.user_funds.map((value) => {
                        let  amt = Number(value.REDIM_PRICE) * Number(value.NO_OF_UNITS);
                        if(value.type == 1){
                            let precentage = (Number(amt)/Number(fixedAmount)) * 100;
                            fixAr.push({name:value.FUND_NAME, market:amt, precen: precentage, color:value.color});
                        }else{
                            let precentage = (Number(amt)/Number(EquiryAmount)) * 100;
                            eqiAr.push({name:value.FUND_NAME, market:amt, precen: precentage, color:value.color});
                        }
                        let fullAmount = Number(fixedAmount)+Number(EquiryAmount);
                        let fullPrecentage = (Number(amt)/Number(fullAmount)) * 100;
                        fulAr.push({name:value.FUND_NAME, market:amt, precen: fullPrecentage, color:value.color});
                    })

                    setFixedFundChart(fixAr);
                    setEquityFundChart(eqiAr);
                    setAllFundChart(fulAr);
                }

                const arr = [];
                if(data.data.funds.length == data.data.user_funds.length){
                    setFundall(true);
                    arr.push(data.data.funds[0]);
                    arr.push(data.data.funds[1]);
                }else{
                    setFundall(false);
                    let num = 1;
                    data.data.funds.length > 0 && data.data.funds.map((value, index) =>{
                        if(data.data.user_funds.filter((values) => values.FUND_CODE == value.FUND_CODE).length == 0 && num < 3){
                            arr.push(value)
                            num++;
                        }
                    })
                }
                setTableFund(arr);
            }
        }catch(error) {

        }
    }

    //get all fund list
    const fundLists = async() => {
        try{
            let data = await api.getRequest('data/list');
            if(data.data.status == 'success'){
                setModelFundList([...data.data.fund_list]);
                setModelActiveAccount([...data.data.active_account_list]);
                setModelAccount([...data.data.account_list]);
                setFundCost([...data.data.fund_cost])
            }
        }catch(error){
        }
    }

    //set Navigation activity
    const setActive = (e) => {
        setNavActive(e);
    }

    //handle refresh fundlist
    const handleRefreshFund = (e) => {
        setRefreshListFund(e);
    }

    const supportHide = () => {
        setSupport(false);
    }

    //set support model open
    const setSupportModel = (e) => {
        setSupport(e);
    }

    //on hide inprogress model
    const handleOnHideModel = () => {
        setInProgress(false);
    }

    const handleOnHideMaintance = () => {
        setMaintanceReaded(true);
        setMaintance(false);
    }

    const handleCloseTost = () => {
        setSiteNotificationReaded(true);
        setSiteNotification(false)
    }

    const handleRequireVerify = () => {
        setRequireVerify(false);
    }

    return(
    <>
    <Loader active={loading}>
    <div className="container container-line">
    <TopNav counter={true} sticky={false} downer={true} fixedfund={fixedfund} equityfund={equityfund}/>
    <div className="row nav-responce" style={{alignItems:'center', justifyContent:navActive == 0?'space-between':'end'}}>
        <News active={navActive} fixedfund={fixedfund} equityfund={equityfund}/>
        <Navbar active={navActive} setActive={setActive} support={support} setSupportModel={setSupportModel}/>
    </div>
    {
        navActive == 0?
        <div className='animate__animated animate__fadeIn'>
            <div className='main-wrap'>
                <div className='row flip-mb'>
                    <Portfolio fundlist={fundlist} userFunds={userFund} modelFundList={modelFundList} tableList={tableFund} all={fundall} activeList={modelActiveAccount} setActiveList={setModelActiveAccount} acountList={modelAccount}refreshList={refreshListFund} setRefresh={handleRefreshFund} fundCost={fundCost}/>

                    <Actions userUpdate={userUpdate} tableList={tableFund} all={fundall} userFund={userFund} fundList={modelFundList} activeList={modelActiveAccount} acountList={modelAccount} refreshList={refreshListFund} setRefresh={handleRefreshFund} fixedFundChart={fixedFundChart} equityFundChart={equityFundChart} allFundChart={allFundChart} setTableActive={handleTableActive}/>
                </div>
            </div>
            {/* {
                  userFund.length > 1 ?
                  <div className='main-wrap'>
                    <div className='row'>
                        <div className="col-md-7 banner-container">
                            <Banner/>
                        </div>
                        <div className="col-md-7 mobile-chart">
                            <Chart userFund={userFund} allFundChart={allFundChart} fixedFundChart={fixedFundChart} equityFundChart={equityFundChart} setTableActive={handleTableActive}/>
                        </div>
                    <div className='col-md-5 user-fund-detail animate__animated animate__fadeIn center'>
                        <UserFundsTable funds={tableActive == 1?fixedFundChart:(tableActive == 2?allFundChart:equityFundChart)}/>
                    </div>
                    </div>
                </div>
                  :''
            } */}
        </div>
        :
            <div className='main-wrap'>
                <div className='row animate__animated animate__fadeIn'>
                    <TransactionForm loading={handleLoading} fundList={modelFundList} acountList={modelAccount}/>
                </div>
            </div>     
      
    }
    {
        support == true?
            <SupportModel show={support} onHide={supportHide}/>
        :''
    }

    {
        inprogress == true?
            <InProgress show={inprogress} onHide={handleOnHideModel}/>
        :''
    }

    {
        maintance == true && maintanceReaded == false?
            <Maintance show={maintance} onHide={handleOnHideMaintance}/>
        :null
    }

    {
        siteNotification == true && siteNotificationReaded == false?
            <SiteNotify open={siteNotification} handleClose={handleCloseTost} />
        :null
    }

    {
        requireVerify == true ?
        <RegisterInfo show={requireVerify} onHide={handleRequireVerify}/>
        :null
    }
    
    </div>
    <Footer/>
    </Loader>
    </>
    )
}

export default Home;