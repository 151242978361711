import React, {useEffect, useState} from "react";
import { Form, Col, Row } from "react-bootstrap";


//buy form
const CreateNicnameForm = ({formik, state, account, nickname}) => {

    return(
        <Form>
        <Form.Group as={Row} className="mb-2" controlId="fund">
        <Form.Label column sm="4">
            Account Number
        </Form.Label>
        <Col sm="6">
           <Form.Label column>
           {account}
        </Form.Label>
        </Col>
        </Form.Group>
        {
            state == 2?
            <Form.Group as={Row} className="mb-2" controlId="fund">
            <Form.Label column sm="4">
                Current Nickname
            </Form.Label>
            <Col sm="6">
               <Form.Label column>
               {nickname}
            </Form.Label>
            </Col>
            </Form.Group>
            :null
        }
        <Form.Group as={Row} className="mb-3" controlId="fund">
        <Form.Label column sm="4">
            Nickname
        </Form.Label>
        <Col sm="6">
            <Form.Control 
                name="nickname" 
                className="text-small"
                autoComplete="off"
                isInvalid={Boolean(formik.errors.nickname)}
                onChange={(e) => formik.setFieldValue('nickname', e.target.value)}
                value={formik.values.nickname}  
                size="sm" 
                type="text" 
                />

        {formik.errors.nickname && 
        <div className="col-md-12">
        <span className="srv-validation-message"><span className="error-palate">*&nbsp;{formik.errors.nickname}</span></span>
        </div>
        } 
        </Col>
        </Form.Group>
        </Form>
    )
}

export default CreateNicnameForm;
