import React, {useState} from "react";
import { Button, Modal, Form, Col, Row } from 'react-bootstrap';
import { faHandshake } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import 'animate.css';
import { useHistory } from "react-router-dom";
import SupportContent from "../content";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import api from "../../../services/api";
import Success from "../../common/alerts/success";

const SupportModel = ({onHide, show}) =>  {
    const history = useHistory();
    const [customMsg, setCustomMsg] = useState('');
    const [step, setStep] = useState(1);

    //on confirm 
    const onConfirm = () => {
        onHide(true);
    }

    const validation = Yup.object({
      type :Yup.string().required("Required"),
      inquiry : Yup.string().required("Required"),
  });
  

    const formik = useFormik({
      initialValues: {
        type: '',
        inquiry: '',
        attch: '',
      },
      validateOnChange:false,
      validateOnBlur:false,
      validationSchema: validation,
      // Login Request
      onSubmit: async(values) => {
          try{
              const fd = new FormData();
              if(values.attch){
                fd.append('description', values.inquiry);           
                fd.append('TRANS_CODE', values.type);           
                fd.append('document', values.attch);           
              }else{
                fd.append('description', values.inquiry);           
                fd.append('TRANS_CODE', values.type);           
              }
              const data = await api.postRequest('support', fd)
              if(data.status == 200){
                  if(data.data.status == 'fail'){
                      var erros = data.data.errors;
                      var first = Object.keys(erros)[0]
                      setCustomMsg(erros[first][0]);
                  }else{
                    setStep(2);  
                  }
              }else{
                  setCustomMsg('Something went wrong');
              }
          }catch(error){
              setCustomMsg('Something went wrong');
          }
      },
  });

  const handleCloseSuccess = (e) => {
    onHide(e);
  }

    return (
      <Modal
        show={show}
        onHide={onHide}
        backdrop="static"
        size="md"
        style={{fontSize:13}}
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header style={{padding:'0.3rem 0.3rem'}}>
          <Modal.Title style={{fontSize:18}} id="contained-modal-title-vcenter">
            <FontAwesomeIcon icon={faHandshake} style={{paddingLeft:'8px'}}/>
            &nbsp;
            Support 
          </Modal.Title>
        </Modal.Header>
        {
          step == 1?
            <SupportContent onHide={onHide} formik={formik} error={customMsg} setError={setCustomMsg}/>
          :
          <div className="animate__animated animate__fadeIn">
            <Modal.Body style={{ paddingBottom:0, minHeight:250}}>
              <Success closeTime={10} currentStep={step} close={handleCloseSuccess} nextStep={false} message="Support Requesting Successful!" step={setStep}/>
            </Modal.Body>
              <Modal.Footer style={{padding:'0.3rem 0.3rem', paddingLeft:'8px',paddingRight:'8px', paddingBottom:8, display:'flex', justifyContent:'end', borderTop:'none'}}>
                <Button className="btn-btn-success btn-green" style={{width:80}} onClick={(e) => onHide(true)}>Confirm</Button>
            </Modal.Footer>
          </div>
        }

      </Modal>
    );
  }

  export default SupportModel;