import React from "react";
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import { Button } from "react-bootstrap";
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import moment from "moment";

const PendingTable = ({pending}) => {
    return(
        <Table className="pending-table-style">
        <Thead style={{fontSize:14}}>
            <Tr>
            <Th>Fund Details</Th>
            <Th className="text-right">Amount / Units</Th>
            <Th className="text-center">Status</Th>
            </Tr>
        </Thead>
        <Tbody style={{fontSize:14}}>
            {
                pending.length > 0 && pending.map((value) => {
                    return(
                        <Tr>
                            <Td className="fund-details">
                                <span className="pending-fund">{value.FUND_NAME} <span className="pending-date">[{moment(value.TRANSACTION_DATE).format("DD/MM/YYYY")}]</span></span>
                                <span className="pending-account">{value.ACCOUNT_NO}</span>
                                <span className="pending-type">{value.NAME}</span>
                            </Td>
                            <Td className="text-right">
                                <span>{value.NO_OF_UNITS?parseFloat(value.NO_OF_UNITS).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","):parseFloat(value.AMOUNT).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
                                <br/>
                                <span>{value.NO_OF_UNITS?'Units':'LKR'}</span>
                            </Td>
                            <Td className="text-center">
                                <Button style={{fontSize:12}} className="label-button" variant="outline-default">Pending</Button>
                            </Td>
                        </Tr>
                    )
                })
            }
        </Tbody>
        </Table>
    )
}

export default PendingTable;