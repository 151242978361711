import React, { useEffect, useState } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-phone-input-2/lib/style.css';
import { useHistory } from "react-router-dom";
import { useFormik } from 'formik';
import api from '../../../services/api';
import * as Yup from 'yup';
import OTPInput from "otp-input-react";
import { FormControl, InputGroup } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faEye, faEyeSlash, faLock } from "@fortawesome/free-solid-svg-icons";
import PasswordStrengthBar from 'react-password-strength-bar';
import SuccessPanelMsg from "../../common/alerts/successPanelMsg";

const eye = <FontAwesomeIcon icon={faEye} />;
const eyeSlash = <FontAwesomeIcon icon={faEyeSlash} />;
const Form = ({loading}) => {
    const history = useHistory();
    const [customMsg, setCustomMsg] = useState('');
    const [strength, setStrength] = useState(0);
    const [passvalid, setPassvalid] = useState(false);
    const [showNewPass, setShowNewPass] = useState(false);
    const [showRePass, setShowRePass] = useState(false);
    const [successMsg, setSuccessMsg] = useState(false);
    const [userData, setUserData] = useState(null);

    const setAuthData = (auth) => {
        sessionStorage.setItem('loggedBy', 2); //form login
        sessionStorage.setItem('token', auth.access_token);
        sessionStorage.setItem('user',  JSON.stringify(auth.user));
        sessionStorage.setItem('advertisement', JSON.stringify(auth.advertisement));
        sessionStorage.setItem('news', JSON.stringify(auth.news));
        sessionStorage.setItem('profile', auth.user.profile);
        sessionStorage.setItem('maintance', auth.maintenance_notification);
    } 

    //back to login page
    const handleToLogin = () => {
        history.push('/');
    }

    const validation = Yup.object({
        password : Yup.string().required("Required"),
        password_confirmation: Yup.string().required('Required').oneOf([Yup.ref('password'), null], 'Passwords must match')
    });
    
    const formik = useFormik({
        initialValues: {
          mobile:'',
          otp: '',
          password:'',
          password_confirmation:'',
        },
        validateOnChange:false,
        validateOnBlur:false,
        validationSchema: validation,
        // Login Request
        onSubmit: async(values) => {
            if(strength < 1){
                setPassvalid(true);
            }
            if(values.otp == ''){
                setCustomMsg('OTP is required');
                return false;
            }else if(values.otp.length < 5){
                setCustomMsg('Invalid OTP'); 
                return false;
            }
            loading(true);
            try{
                const data = await api.postRequest('register', values)
                if(data.status == 200){
                    if(data.data.status == 'fail'){
                        var erros = data.data.errors;
                        var first = Object.keys(erros)[0]
                        setCustomMsg(erros[first][0]);
                    }else{
                        setAuthData(data.data);
                        setSuccessMsg(true);
                    }
                }else{
                    setCustomMsg('Something went wrong');
                }
                loading(false);
            }catch(error){
                loading(false);
                setCustomMsg('Something went wrong');
            }
        },
    });

    //on chnage password strngth
    const  handleScore = (e) => {
        setStrength(e);
    }

    //handle password show icon
    const handlePasswordShow = (e) => {
        setShowNewPass(true);
        setTimeout(() => {
            setShowNewPass(false);
        }, 1500);
    }

     //handle re password show icon
     const handleRePasswordShow = (e) => {
        setShowRePass(true);
        setTimeout(() => {
            setShowRePass(false);
        }, 1500);
    }

    //handle enter key
    const handleEnterKey = (e) => {
        if(e.key == 'Enter'){
            formik.handleSubmit();
        }
    }

    useEffect(() => {
        userDetails();
    }, [])

    const userDetails = async() => {
        let url_str = window.location.href;
        const pieces = url_str.split('/')
        const last = pieces[pieces.length - 1]
        const data = await api.postRequestPassToken('register/holder', last)
        if(data.status == 200){
            if(data.data.status == 'fail'){
                var erros = data.data.errors;
                var first = Object.keys(erros)[0]
                setCustomMsg(erros[first][0]);
            }else{
                setUserData(data.data.holder);
                formik.setFieldValue('mobile', data.data.holder?.mobile);
            }
        }else{
            setCustomMsg('Something went wrong');
        }
    }

    return ( 
        <>
            <div className="col-md-8 animate__animated animate__fadeIn">
                <div className="otp-card">
                {
                successMsg == false?
                <>
                    <h3>Welcome</h3>
                    <p className="card-text">Please provide the password for future login.</p>
                    <div style={{marginTop:20}}>
                    <div className="row" style={{marginBottom:10, paddingLeft:10, paddingRight:10}}>
                        <div className="col-md-4" style={{fontSize:14, fontWeight:500, paddingTop:5, paddingBottom:5, background:'#e9ecef'}}>
                             Mobile No:
                        </div>
                        <div className="col-md-8" style={{fontSize:14, fontWeight:500, background:'#e9ecef', color:'#212529', paddingTop:5, paddingBottom:5}}>
                            {userData?.mobile}
                        </div>
                    </div>
                    <div className="row" style={{marginBottom:10 , paddingLeft:10, paddingRight:10}}>
                        <div className="col-md-4" style={{fontSize:14, fontWeight:500, paddingTop:5, paddingBottom:5, background:'#e9ecef'}}>
                             Email:
                        </div>
                        <div className="col-md-8" style={{fontSize:14, fontWeight:500, background:'#e9ecef', color:'#212529', paddingTop:5, paddingBottom:5}}>
                            {userData?.email}
                        </div>
                    </div>
                    <InputGroup  className="mb-3">
                        <InputGroup.Text id="basic-addon1"><FontAwesomeIcon icon={faLock}/></InputGroup.Text>
                        <FormControl
                        placeholder="Password"
                        name="password"
                        type={showNewPass == false?"password":"text"}
                        autoComplete="off"
                        isInvalid={Boolean(formik.errors.password) || passvalid}
                        value={formik.values.password}
                        onChange={formik.handleChange}
                        aria-label="password"
                        aria-describedby="basic-addon1"
                        />
                        <ie style={{cursor:'pointer'}} onClick={handlePasswordShow}>{showNewPass == false?eye:eyeSlash}</ie>
                        { formik.values.password.length > 0 && <div className="col-md-12 strength-container" style={{margin:0, padding:0}}>
                            <PasswordStrengthBar  className="strength-bar" onChangeScore={handleScore} password={formik.values.password} />
                        </div>}
                        {formik.errors.password && 
                            <div className="col-md-12">
                            <span className="srv-validation-message"><span className="error-palate">*&nbsp;{formik.errors.password}</span></span>
                            </div>
                        }  
            
                    </InputGroup>
                    <InputGroup  className="mb-3">
                        <InputGroup.Text id="basic-addon1"><FontAwesomeIcon icon={faCheckCircle}/></InputGroup.Text>
                        <FormControl
                        placeholder="Confirm Password"
                        name="password_confirmation"
                        type={showRePass == false?"password":"text"}
                        autoComplete="off"
                        isInvalid={Boolean(formik.errors.password_confirmation)}
                        value={formik.values.password_confirmation}
                        onChange={formik.handleChange}
                        aria-label="Confirm password"
                        onKeyDown={handleEnterKey}
                        aria-describedby="basic-addon1"
                        />
                        <ie style={{cursor:'pointer'}} onClick={handleRePasswordShow}>{showRePass == false?eye:eyeSlash}</ie>
                        {formik.errors.password_confirmation && 
                            <div className="col-md-12">
                            <span className="srv-validation-message"><span className="error-palate">*&nbsp;{formik.errors.password_confirmation}</span></span>
                            </div>
                        } 
                    </InputGroup>
                    </div>
                    <p className="card-text">Enter the OTP that we have already sent to your mobile.</p>
                    <div style={{marginTop:20}}>
                    <OTPInput
                        style={{ display: "block" }}
                        value={formik.values.otp}
                        onChange={(e) =>formik.setFieldValue('otp', e)}
                        autoFocus
                        OTPLength={5}
                        otpType="number"
                        disabled={false}
                    />
                    </div>
                    {
                        customMsg != ''?
                        <div className="col-md-12" style={{marginTop:25}}>
                            <span className="srv-validation-message"><span className="error-palate">*&nbsp;{customMsg}
                            </span></span>
                        </div>
                        :''
                    }
                    <div className="text-right" style={{display:'flex', flexDirection:'row', justifyContent:'end', alignItems:'center', marginTop:25}}>
                        <button disabled={successMsg} onClick={formik.handleSubmit} className="btn btn-primary login-button">
                            Submit
                        </button>
                    </div>
                    <div className="pt-2">
                        <p className="line-separator"><span>or</span></p>
                    </div>
                    <div className="pt-1">
                        <span className="card-text underline" onClick={handleToLogin}>Login</span>
                    </div>
                    </>
                    :
                    <>
                    <SuccessPanelMsg closeTime={10} nextStep={false} message="Registration Successful!" redirect={"/complete"}/>
                    <div className="text-right" style={{display:'flex', flexDirection:'row', justifyContent:'end', alignItems:'center', marginTop:25}}>
                    <button onClick={(e) => history.push('/complete')} className="btn btn-primary login-button" style={{width:150}}>
                        Go to Application
                    </button>
                    </div>
                    </>
                }
                </div>
            </div>
        <div className="col-md-1">
            
        </div>
        </>
    );
}

export default Form;