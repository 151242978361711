import React, {useState, useEffect} from "react";
import { Button, Modal, Form, Col, Row } from 'react-bootstrap';
import { faLockOpen, faRecycle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import 'animate.css';
import OTP from "../../common/contents/otp";
import { useHistory } from "react-router-dom";
import api from '../../../services/api';
import Stepper from 'react-stepper-horizontal';
import Success from '../../common/alerts/success';
import PasswordResetForm from "./form";
import { useFormik } from 'formik';
import * as Yup from 'yup';

const OtpModel = ({onHide, show, type, sendDetails}) =>  {
    const steps = 2; //steps without success window
    const history = useHistory();
    const [step, setStep] = useState(0);
    const [Otp, setOtp] = useState('');
    const [error, setError] = useState('');
    const [status, setStatus] = useState(1);
    const [passvalid, setPassvalid] = useState(false);
    const [strength, setStrength] = useState(0);

    //on chnage password strngth
    const  handleScore = (e) => {
        setStrength(e);
    }

    const setAuthData = (auth) => {
        sessionStorage.setItem('loggedBy', 1); //form register
        sessionStorage.setItem('token', auth.access_token);
        sessionStorage.setItem('user', '');
        sessionStorage.setItem('profile', '');
    } 
    

    const validation = Yup.object({
        password : Yup.string().required("Required"),
        repassword : Yup.string().required("Required").oneOf([Yup.ref('password'), null], 'Passwords must match'),
    });

    const formik = useFormik({
        initialValues: {
          password: '',
          repassword: '',
        },
        validateOnChange:false,
        validateOnBlur:false,
        validationSchema: validation,
        // Login Request
        onSubmit: async(values) => {
            if(strength < 1){
                setPassvalid(true);
            }

            let body ={
                password:values.password,
                password_confirmation:values.repassword
            }

            try{
                const data = await api.postRequest('password/update', body)
                if(data.status == 201){
                    if(data.data.status == 'success'){
                        setStep(2);
                    }else{
                        var erros = data.data.errors;
                        var first = Object.keys(erros)[0]
                        setError(erros[first][0]);
                    }
                }else{
                    setError('Something went wrong!');
                }
            }catch(error){
                setError('Something went wrong!');
            }
        },
    });

    //open
    useEffect(() => {
        setStep(0);
        formik.resetForm();
    }, [])

    //set OTP
    const handleOtp = (e) => {
      setOtp(e);
    }
    
    //change step and submit
    const onChangeStep = async() => {
        try{
            const data = await api.postRequest('otp/validate', {otp:Otp})
            if(data.data.status == 'success'){
                    setAuthData(data.data);
                    setStep(1);
            }else{
                var erros = data.data.errors;
                var first = Object.keys(erros)[0]
                setError(erros[first][0]);
            }
        }catch(error){
            setError('Something went wrong')
        }
    }

    //modal previois button action
    const onchnagePrevious = () => {
      if(step > 0){
        const set = step-1;
        setStep(set);
      }
    }

    //set Error to Model Content
    const handleSetError = (e) => {
      setError(e);
    }

    const onClode = () =>{
        history.push('/')
    }
  

    return (
      <Modal
        show={show}
        onHide={onHide}
        backdrop="static"
        keyboard={false}
        size="md"
        style={{fontSize:13}}
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header style={{padding:'0.3rem 0.3rem'}}>
          <Modal.Title style={{fontSize:18}} id="contained-modal-title-vcenter">
            <FontAwesomeIcon icon={step == 0?faLockOpen:faRecycle} style={{paddingLeft:'8px'}}/>
            &nbsp;
            {step == 0?'ONE TIME PASSWORD':'CHANGE PASSWORD'}
          </Modal.Title>
          <div className="steps">
            <Stepper 
              steps={[
                {title: ''}, 
                {title: ''}, 
                {title: ''}, 
              ]} 
              activeStep={step}
              size={15}
              circleTop={10}
              circleFontSize={0}
              completeColor='#5cb85c'
              activeColor={1 == 1?'#5096FF':'#d9534f'}
              defaultBorderWidth={2}
              />
          </div>
        </Modal.Header>
        <Modal.Body style={{ paddingBottom:0, minHeight:250}}>
          {
            step == 0?
              <OTP 
                Otp={Otp} 
                setOtp={handleOtp} 
                type={type} 
                sendDetails={sendDetails} 
                typeHide={true}
                error={error}
                setError={handleSetError}
              />
              :(step == 1?
                    <PasswordResetForm formik={formik} passvalid={passvalid} handleScore={handleScore}/>
                :
                  <Success closeTime={10} currentStep={step} nextStep={true} redirect="/" message="Password Reset Successful!" close={onClode} step={setStep}/>
                )
          }
           
        </Modal.Body>
        <Modal.Footer style={{padding:'0.3rem 0.3rem', paddingLeft:'8px',paddingRight:'8px', paddingBottom:8, display:'flex', justifyContent:step == 0?'space-between':'end', borderTop:'none'}}>
          {step == 0?<Button className="btn btn-danger btn-red" style={{width:80}} onClick={onHide}>Cancel</Button>:''}
          <Button className="btn-btn-success btn-green" disabled={Otp.length != 5} style={{width:80}} onClick={(e) => step == 0?onChangeStep():(step == 1?formik.handleSubmit():onClode())}>Continue</Button>
        </Modal.Footer>
      </Modal>
    );
  }

  export default OtpModel;