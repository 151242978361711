import { faAngleDoubleRight, faChartLine, faCheckCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, {CSSProperties} from 'react'
import { Button } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import {ClipLoader} from "react-spinners";

const override = {
    display: "block",
    margin: "0 auto",
    borderColor: "red",
  };

export default function Stepper({completed, handleCompleted, timeLine, submitting, customMsg, handleClickableStep, step}) {
    const history = useHistory();

    const handleGoDashboard = () => {
        history.push('/home')
    }

  return (
    <>
    <ol className="stepper">
        {
            timeLine.length > 0 && timeLine.map((value, index) => {
                return(
                    <li className={`stepper-item ${value.status}`} key={index}>
                        <span className="stepper-counter"></span>
                        <span onClick={() => handleClickableStep(value, index)} className={`stepper-link ${value.status == 'complete'?'stepper-clickable':''} `}>
                            <span>{value.name}</span>
                        </span>
                        <span className="stepper-line"></span>
                    </li>
                )
            })
        }
{/*       

        <li className={`stepper-item ${completed >= 2?'complete':''}`}>
            <span className="stepper-counter"></span>
            <span className="stepper-link">
                <span>Second Applicant Details</span>
            </span>
            <span className="stepper-line"></span>
        </li>
        <li className={`stepper-item ${completed >= 3?'complete':''}`}>
            <span className="stepper-counter"></span>
            <span className="stepper-link">
                <span>Bank Account Details</span>
            </span>
            <span className="stepper-line"></span>
        </li>
        <li className={`stepper-item ${completed >= 4?'complete':''}`}>
            <span className="stepper-counter"></span>
            <span className="stepper-link">
                <span>Declaration and Signatures</span>
            </span>
            <span className="stepper-line"></span>
        </li>
        <li className={`stepper-item ${completed >= 5?'complete':''}`}>
            <span className="stepper-counter"></span>
            <span className="stepper-link">
                <span>Video KYC</span>
            </span>
            <span className="stepper-line"></span>
        </li>
        <li className={`stepper-item ${completed >= 6?'complete':''}`}>
            <span className="stepper-counter"></span>
            <span className="stepper-link">
                <span>Complete</span>
            </span>
            <span className="stepper-line"></span>
        </li> */}
    </ol>
    <div style={{padding:2}}>
        {
            timeLine.filter((val) => val.name == 'Complete')?.[0]?.id == step?
            <Button variant="success" onClick={handleGoDashboard} size="sm" style={{position:'', top:20, right:120, width:'100%'}}>
               <FontAwesomeIcon icon={faChartLine}/>&nbsp; Go to Dashboard
            </Button>
            :
            <Button disabled={submitting} variant="success" onClick={handleCompleted} size="sm" style={{position:'', top:20, right:120, width:'100%'}}>
                {
                    submitting == true?
                    <>
                    <ClipLoader size={12}/>&nbsp;<span>Submitting... </span>
                    </>
                    :
                    <>
                    Submit to next step &nbsp; <FontAwesomeIcon icon={faAngleDoubleRight}/>
                    </>
                }
                
            </Button>
        }
        {
            customMsg != null?
            <div className="col-md-12">
                <span className="srv-validation-message"><span className="error-palate">*&nbsp;{customMsg}
                </span></span>
            </div>
            :null
        }
    </div>
    </>
  )
}
