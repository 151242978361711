import React, { useState, useEffect } from "react";
import src from '../../../assets/images/avatar.jpg';
import { InputGroup, FormControl, Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { useFormik } from "formik";
import * as Yup from 'yup';
import PhoneInput from "react-phone-input-2";
import OtpModelProfile from "./otpModel";
import api from "../../../services/api";
import Message from "./message";
import { faArrowAltCircleLeft } from "@fortawesome/free-solid-svg-icons";
import { useHistory, useLocation } from "react-router-dom";
import Avatar from 'react-avatar';
import UploadModel from "./uploadModel";

import { useDispatch } from 'react-redux';
import { profileUpdater } from "../../../store/actions";
import { assetUrl } from "../../../Config";

const eye = <FontAwesomeIcon icon={faEye} />;
const eyeSlash = <FontAwesomeIcon icon={faEyeSlash} />;
const Profile = ({loading}) => {
	const history = useHistory();
	const location = useLocation();
	const [otp, setOtp] = useState();
	const [profile, setProfile] =useState([]);
	const [userType, setUserType] = useState(1);
	const [error, setError] = useState('');
	const [typeofdata, setTypeOfData] = useState(null);
	const [data, setData] = useState([]);
	const [step, setStep] = useState(0);
	const [message, setMessage] = useState(false);
	const [messageState, setMessageState] = useState(1);
	const [showPass, setShowPass] = useState(false);
	const [showRePass, setShowRePass] = useState(false);
	const [imageUpload, setImageUpload] = useState(false);
	const [profileImage, setProfileImage] = useState('');

	const dispatch = useDispatch();

	useEffect(() => {
		loading(true);
		loadProfile();
		setStep(0);

		let user = sessionStorage.getItem('user');
        let image = (JSON.parse(user).image != null? JSON.parse(user).image:null);
		let full_path = assetUrl+image;
        dispatch(profileUpdater(full_path))
		setProfileImage(image);
	}, [])

	const loadProfile = async() => {
		try{
			let data = null;
			if(location.pathname == '/profile'){
				data = await api.getRequest('profile');
			}else{
				data = await api.getRequest('pre/profile');
			}
			setProfile(data.data.profile)
			if(data.data.profile.mobile == undefined){
				setUserType(1); //email
			}else{
				setUserType(2); //mobile
			}
			loading(false);
		}catch(error){
			loading(false);
		}
	}

	//handle otp hide
	const handleOtpHide = () => {
		loadProfile();
		setOtp(false);
	}
	

	const validation = Yup.object({
        mobile : Yup.string().required("Required"),
    });

    const formik = useFormik({
        initialValues: {
		mobile: '',
        },
        validateOnChange:false,
        validateOnBlur:false,
        validationSchema: validation,
        // Login Request
        onSubmit: async(values) => {
            try{
				let mobile_number = '+'+values.mobile;
                const data = await api.postRequest('update/mobile/request', {mobile:mobile_number})
                if(data.status == 200){
                    if(data.data.status == 'success'){
						setData({mobile:mobile_number})
						setStep(0);
						setOtp(true);
						setError('');
						setUserType(2);
                    }else{
						var erros = data.data.errors;
                        var first = Object.keys(erros)[0]
                        setError(erros[first][0]);
                    }
                }else{
					setError('Somethin went wrong')
                }
            }catch(error){
				setError('Somethin went wrong')
            }
        },
    });

	const validationEmail = Yup.object({
        email : Yup.string().required("Required"),
    });

    const formikEmail = useFormik({
        initialValues: {
			email: '',
        },
        validateOnChange:false,
        validateOnBlur:false,
        validationSchema: validationEmail,
        // Login Request
        onSubmit: async(values) => {
            try{
                const data = await api.postRequest('update/email/request', {email:values.email})
                if(data.status == 200){
                    if(data.data.status == 'success'){
						setData({email:values.email})
						setStep(0);
						setOtp(true)
						setError('');
						setUserType(1);
                    }else{
						var erros = data.data.errors;
                        var first = Object.keys(erros)[0]
                        setError(erros[first][0]);
                    }
                }else{
					setError('Somethin went wrong')
                }
            }catch(error){
				setError('Somethin went wrong')
            }
        },
    });

	const validationPassword = Yup.object({
    	newpass : Yup.string().required("Required"),
		conpass : Yup.string().required("Required").oneOf([Yup.ref('newpass'), null], 'Passwords must match'),
    });

    const formikPassword = useFormik({
        initialValues: {
			newpass: '',
			conpass: '',
        },
        validateOnChange:false,
        validateOnBlur:false,
        validationSchema: validationPassword,
        // Login Request
        onSubmit: async(values) => {
            try{
                const data = await api.postRequest('password/update', {password:values.newpass, password_confirmation:values.conpass})
                if(data.status == 201){
                    if(data.data.status == 'success'){
						setMessageState(2);
						setMessage(true);
						setError('');
                    }else{
						var erros = data.data.errors;
                        var first = Object.keys(erros)[0]
                        setError(erros[first][0]);
                    }
                }else{
					setMessageState(1);
					setMessage(true);
					setError(''); 
                }
            }catch(error){
				setMessageState(1);
				setMessage(true);
				setError(''); 
            }
        },
    });

	const onChangeMobile = () => {
		setTypeOfData(1);
		formik.handleSubmit();
	}

	const onChangeEmail = () => {
		setTypeOfData(2);
		formikEmail.handleSubmit();
	}

	const onChangePassword = () => {
		setTypeOfData(3);
		formikPassword.handleSubmit();
	}

	const handleMessage = () => {
		setMessage(false);
	}

	const handlePasswordShow = () => {
		setShowPass(true);
        setTimeout(() => {
            setShowPass(false);
        }, 1500);
	}

	const handleRePasswordShow = () => {
		setShowRePass(true);
        setTimeout(() => {
            setShowRePass(false);
        }, 1500);
	}

	const handleBacktohome = () => {
		history.push('/home');
	}

	const handleImageModel = () => {
		setImageUpload(false);
	}

	const handleImageUploadModel = () => {
		setImageUpload(true);
	}

	useEffect(() => {
		let user = JSON.parse(sessionStorage.getItem('user'));
		user.image = profileImage;
		sessionStorage.removeItem('user');
		sessionStorage.setItem('user', JSON.stringify(user));
		let full_path = assetUrl+profileImage;
		dispatch(profileUpdater(full_path))
	}, [profileImage])

    return(
        <div className="container" >
		<div className="main-body" style={{padding:30}}>
			<div className="row" style={{display:'flex', justifyContent:'center'}}>
				<div className="col-lg-4 animate__animated animate__fadeIn">
					<div className="login-card" style={{minHeight:250}}>
					<FontAwesomeIcon onClick={handleBacktohome} style={{fontSize:20, cursor:'pointer'}} title="Back to home" icon={faArrowAltCircleLeft}/>
						<div className="card-body">
							<div className="d-flex flex-column align-items-center text-center">
								{/* <img src={src} alt="Admin" className="rounded-circle p-1 bg-success" width="110"/> */}
								<Avatar src={assetUrl+profileImage} onClick={handleImageUploadModel} name={profile.INITIALS+' '+profile.SURNAME} style={{cursor:'pointer'}} size="110"  round={true} maxInitials={2} />
								<div className="mt-3">
									{
										profile?.TITLE !=  undefined || profile?.INITIALS != undefined || profile?.SURNAME != undefined?
										<h5>{profile?.TITLE+' '+profile?.INITIALS+' '+profile?.SURNAME}</h5>
										:null
									}
									{
										profile?.FIRST_NAME != undefined?
										<h6>{profile?.FIRST_NAME}</h6>
										:null
									}
									<p className="text-secondary mb-1">{userType == 1?profile.email:profile.mobile}</p>
									{
										userType == 1?
										<p className="text-secondary mb-1">{(profile.mobile != null && profile.mobile != undefined)?profile.mobile:''}</p>
										:
										<p className="text-secondary mb-1">{(profile.email != null && profile.email != undefined)?profile.email:''}</p>
									}
									<p className="text-muted font-size-sm">{profile.nic}</p>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="col-md-6 animate__animated animate__fadeIn">
           			 <div className="login-card" style={{minHeight:350, padding:30}}>
						<Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
							<Form.Label className="input-label">Mobile</Form.Label>
							<div className="row" style={{alignItems:'baseline', textAlign:'center'}}>
								<div className="col-md-8">
									<PhoneInput
										country={'lk'}
										name="mobile"
										placeholder="Mobile"
										autoComplete="off"
										isInvalid={Boolean(formik.errors.mobile)}
										onChange={phone => formik.setFieldValue('mobile', phone)}
										value={formik.values.mobile}
										// onChange={phone => setMobile(phone)}
										style={{width:'100%',marginTop:10}}
									/>
								</div>
								<div className="col-md-4">
								<button onClick={(e) => onChangeMobile()} className="btn btn-primary login-button">
									Change
								</button>
								</div>
							</div>
							
							{formik.errors.mobile && 
								<div className="col-md-12">
								<span className="srv-validation-message"><span className="error-palate">*&nbsp;{formik.errors.mobile}</span></span>
								</div>
							}   
						</Form.Group>
						<Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
							<Form.Label className="input-label">Email</Form.Label>
							<div className="row" style={{alignItems:'baseline', textAlign:'center'}}>
								<div className="col-md-8">
									<FormControl
										name="email"
										size="sm"
										autoComplete="off"
										isInvalid={Boolean(formikEmail.errors.email)}
										onChange={formikEmail.handleChange}
										value={formikEmail.values.email}
										aria-label="email"
										aria-describedby="basic-addon1"
									/>
								</div>
								<div className="col-md-4">
								<button onClick={(e) => onChangeEmail()} className="btn btn-primary login-button">
									Change
								</button>
								</div>
							</div>
							
							{formikEmail.errors.email && 
								<div className="col-md-12">
								<span className="srv-validation-message"><span className="error-palate">*&nbsp;{formikEmail.errors.email}</span></span>
								</div>
							}   
						</Form.Group>
						<hr style={{marginTop:40}}/>
						<Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
							<Form.Label className="input-label">New Password</Form.Label>
							<div className="row" style={{alignItems:'baseline', textAlign:'center'}}>
								<div className="col-md-8">
									<FormControl
										name="newpass"
										size="sm"
										autoComplete="off"
										isInvalid={Boolean(formikPassword.errors.newpass)}
										onChange={formikPassword.handleChange}
										value={formikPassword.values.newpass}
										aria-label="newpass"
										aria-describedby="basic-addon1"
										type="password"
									/>
								
								</div>
								<div className="col-md-4">
								</div>
							</div>
							
							{formikPassword.errors.newpass && 
								<div className="col-md-12">
								<span className="srv-validation-message"><span className="error-palate">*&nbsp;{formikPassword.errors.newpass}</span></span>
								</div>
							}   
						</Form.Group>
						<Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
							<Form.Label className="input-label">Confirm Password</Form.Label>
							<div className="row" style={{alignItems:'baseline', textAlign:'center'}}>
								<div className="col-md-8">
									<FormControl
										name="conpass"
										size="sm"
										autoComplete="off"
										isInvalid={Boolean(formikPassword.errors.conpass)}
										onChange={formikPassword.handleChange}
										value={formikPassword.values.conpass}
										aria-label="conpass"
										aria-describedby="basic-addon1"
										type="password"
									/>
							
								</div>
								<div className="col-md-4">
								<button onClick={(e) => onChangePassword()} className="btn btn-primary login-button">
									Change
								</button>
								</div>
							</div>
							
							{formikPassword.errors.conpass && 
								<div className="col-md-12">
								<span className="srv-validation-message"><span className="error-palate">*&nbsp;{formikPassword.errors.conpass}</span></span>
								</div>
							}   
						</Form.Group>
							
						{error != '' && 
							<div className="col-md-12">
							<span className="srv-validation-message"><span className="error-palate">*&nbsp;{error}</span></span>
							</div>
						}  
						<OtpModelProfile show={otp} onHide={handleOtpHide} type={userType} sendDetails={userType == 1?formikEmail.values.email:'+'+formik.values.mobile} typeofdata={typeofdata} data={data}/>
						<Message show={message} onHide={handleMessage} state={messageState}/>
						<UploadModel show={imageUpload} onHide={handleImageModel} setImage={setProfileImage} image={profileImage}/>
					</div>
				</div>
			</div>
		</div>
	</div>
    )
}

export default Profile;