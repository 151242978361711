import React, { useState, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import 'bootstrap/dist/css/bootstrap.min.css';
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import 'react-phone-input-2/lib/style.css';
import { useHistory } from "react-router-dom";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import api from '../../../services/api';

const eye = <FontAwesomeIcon icon={faEye} />;
const eyeSlash = <FontAwesomeIcon icon={faEyeSlash} />;
const Form = ({loading}) => {
    const history = useHistory();
    const [DivSwift, setDivSwift] = useState(1);
    const [countryId, setCountryId] = useState('');
    const [otp, setOtp] = useState(false);
    const [customMsg, setCustomMsg] = useState('');
    const [strength, setStrength] = useState(0);
    const [passvalid, setPassvalid] = useState(false);
    const [showNewPass, setShowNewPass] = useState(false);
    const [showRePass, setShowRePass] = useState(false);

    //Ref's
    const emailInput = useRef();
    const mobileInput = useRef();

    //back to login page
    const handleToLogin = () => {
        history.push('/');
    }
    
    const setAuthData = (auth) => {
        sessionStorage.setItem('loggedBy', 1); //form register
        sessionStorage.setItem('token', auth.token);
        sessionStorage.setItem('user', '');
        sessionStorage.setItem('profile', '');
    } 

    const validation = Yup.object({
        email : DivSwift == 1?Yup.string().required("Required").email('Invalid email address'):Yup.string().notRequired(),
        mobile : DivSwift == 2?Yup.string().required("Required"):Yup.string().notRequired(),
        password : Yup.string().required("Required"),
        repassword: Yup.string().required('Required').oneOf([Yup.ref('password'), null], 'Passwords must match')
    });

    //focus Email / Mobile
    const setFocusRefFunc = () => {
        if(DivSwift == 1){
            emailInput.current.focus();
            emailInput.current.select();
            setOtp(false);
        }else{
            setOtp(false);
            // mobileInput.current.focus();
        }
    }

    const formik = useFormik({
        initialValues: {
          email: '',
          mobile: '',
          password: '',
          repassword:'',
        },
        validateOnChange:false,
        validateOnBlur:false,
        validationSchema: validation,
        // Login Request
        onSubmit: async(values) => {
            loading(true);
            setCustomMsg('');
            if(strength < 1){
                setPassvalid(true);
            }
            if(DivSwift == 1){
               var body = {
                    'email':values.email,
                }
            }else{
                var body = {
                    'mobile':'+'+values.mobile,
                }
            }
            try{
                const data = await api.postRequestNoToken('register/request', body)
                if(data.status == 200){
                    if(data.data.status == 'fail'){
                        var erros = data.data.errors;
                        var first = Object.keys(erros)[0]
                        setCustomMsg(erros[first][0]);
                    }else{
                        setAuthData(data.data);
                        setOtp(true);
                    }
                }else{
                    setCustomMsg('Something went wrong');
                }
                loading(false);
            }catch(error){
                loading(false);
                setCustomMsg('Something went wrong');
            }
        },
    });

    //on chnage password strngth
    const  handleScore = (e) => {
        setStrength(e);
    }

    //handle password show icon
    const handlePasswordShow = (e) => {
        setShowNewPass(true);
        setTimeout(() => {
            setShowNewPass(false);
        }, 1500);
    }

     //handle re password show icon
     const handleRePasswordShow = (e) => {
        setShowRePass(true);
        setTimeout(() => {
            setShowRePass(false);
        }, 1500);
    }

    //handle enter key
    const handleEnterKey = (e) => {
        if(e.key == 'Enter'){
            formik.handleSubmit();
        }
    }

    const handleNoAccount = () => {
        history.push('/register');
    }

    const handleHaveAccount = () => {
        history.push('/existing');
    }

    return ( 
        <>
        <div className="col-md-8 animate__animated animate__fadeIn">
            <div className="login-card">
                <h3>Let's Create an Account</h3>

                <p style={{marginTop:20, fontSize:13, fontWeight:600}} className="card-text">Do you have a registration number issued by the Senfin Asset Management?
                </p>
                {/* <hr/> */}
                    <ul className="ul-change">
                        <li><span className="card-text">The registration number must contain 10 - 13 charactors.</span></li>
                        <li><span className="card-text" style={{padding:0, margin:0, marginTop:1}}>The registration number must contain letters, numbers or both.</span></li>
                    </ul>

                <button type="button" title="Press this if you are existing customer" onClick={handleHaveAccount} className="btn btn-success btn-small" style={{width:'100%', marginTop:20}}>Yes. I have an account Number</button>
                <button type="button" title="Press this if you are new customer" onClick={handleNoAccount} className="btn btn-secondary btn-small" style={{width:'100%', marginTop:10}}>No. I don't have an account Number</button>
                {
                    customMsg != ''?
                    <div className="col-md-12">
                        <span className="srv-validation-message"><span className="error-palate">*&nbsp;{customMsg}
                        </span></span>
                    </div>
                    :''
                }
                <div className="pt-3">
                    <p className="line-separator"><span>or</span></p>
                </div>
                <div className="pt-1">
                    <span className="card-text underline" onClick={handleToLogin}>Login</span>
                </div>
            </div>
            {
        }
        </div>
        <div className="col-md-1">
            
        </div>
        </>
    );
}

export default Form;