import { faWarning } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react'
import Toast from 'react-bootstrap/Toast';
import ToastContainer from 'react-bootstrap/ToastContainer';

export default function SiteNotify({open, handleClose}) {
  return (
    <ToastContainer
    className="p-3"
    position="bottom-end"
    style={{ zIndex: 1 }}
  >
    <Toast bg='light' show={open} onClose={handleClose}>
        <Toast.Header>
        <strong className="me-auto"><FontAwesomeIcon icon={faWarning}/>&nbsp;Update In Progress</strong>
        </Toast.Header>
        <Toast.Body>Update is currently in progress.</Toast.Body>
    </Toast>
    </ToastContainer>
  )
}
