export const baseUrl = process.env.NODE_ENV === 'development' ? 'http://192.168.200.50:8002/api/' : 'https://admin.senfinassetmanagement.com/api/';
export const assetUrl = process.env.NODE_ENV === 'development' ? 'http://192.168.200.50:8002' : 'https://admin.senfinassetmanagement.com';

// export const baseUrl = process.env.NODE_ENV === 'development' ? 'http://10.1.200.253:8002/api/' : 'http://10.1.200.253:8002/api/';
// export const assetUrl = process.env.NODE_ENV === 'development' ? 'http://10.1.200.253:8002' : 'http://10.1.200.253:8002';

// export const baseUrl = process.env.NODE_ENV === 'development' ? 'http://assetmgt.local/api/' : 'http://assetmgt.local/api/';
// export const assetUrl = process.env.NODE_ENV === 'development' ? 'http://assetmgt.local' : 'http://assetmgt.local';


/**
 * 
 * Build Note: if it is live server build url must be (https://admin.senfinassetmanagement.com/api/) that's all
 *              if it is test build url must be (http://10.1.200.253:8002/api/) or (http://assetmgt.local/api/)
 */