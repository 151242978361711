import React from 'react'
import SecondApplication from './secoundApplication'
import SecoundApplicantContact from './secoundApplicantContact'

export default function SecoundApplicatBrake({instruction, formikApplication, formikContact, name, secondDeclare, secondDeclareInvalid, setSecondDeclare, setSecondDeclareInvalid,secondScrollRef, secondContactRef, secondContactDeclare, setSecondContactDeclare, secondContactDeclareInvalid, setSecondContactDeclareInvalid}) {
  if(instruction == 1){
    return (
        <SecondApplication 
          formik={formikApplication} 
          name={name} 
          secondDeclare={secondDeclare} 
          secondDeclareInvalid={secondDeclareInvalid}
          setSecondDeclare={setSecondDeclare}
          setSecondDeclareInvalid={setSecondDeclareInvalid}
          scrollref={secondScrollRef}
        />
    )
  }else{
    return (
        <SecoundApplicantContact 
          formik={formikContact}
          name={name} 
          secondContactDeclare={secondContactDeclare}
          setSecondContactDeclare={setSecondContactDeclare}
          secondContactDeclareInvalid={secondContactDeclareInvalid}
          setSecondContactDeclareInvalid={setSecondContactDeclareInvalid}
          scrollref={secondContactRef}
        /> 
    )
  }
}
