import React, { useEffect, useState } from "react";
import { Nav, NavDropdown, NavItem, NavLink } from "react-bootstrap";
import src from '../../../assets/images/avatar.jpg';
import { useHistory } from "react-router-dom";
import Avatar from 'react-avatar';
import { useSelector } from "react-redux";

import { useDispatch } from 'react-redux';
import { profileUpdater } from "../../../store/actions";
import { assetUrl } from "../../../Config";
import api from "../../../services/api";

const Downer = () => {
    const history = useHistory();
    const [name, setName] = useState([]);
    const dispatch = useDispatch();
    const [image, setImage] = useState(null);
    const [manualRender, setManualRender] = useState(false);
    const profile = useSelector(state => state.profile);

    useEffect(async() => {
        let user = sessionStorage.getItem('user');
        setName(JSON.parse(user).name == null?(JSON.parse(user).mobile == null?JSON.parse(user).email:JSON.parse(user).mobile):JSON.parse(user).name);
        let image = assetUrl+'/'+(JSON.parse(user).image != null? JSON.parse(user).image:null);
        setImage(image);
        dispatch(profileUpdater(image))
        setManualRender(!manualRender);
    }, [])
    
    const handleLogout = () => {
        sessionStorage.removeItem('user')
        sessionStorage.removeItem('token')
        history.push('/');
    }

    const handleProfile = () => {
        history.push('/profile');
    }


    useEffect(() => {
        let user = JSON.parse(sessionStorage.getItem('user'));
        setImage(assetUrl+user.image);
    }, [profile])

    return(
        <>
        <div className="col-md-4 user-downer">
            {/* <button type="button" className="btn btn-success btn-sm" style={{marginRight:'10px'}}>Complete Registration</button> */}
            <div className="user-info">
                <p className="user-name">{name}</p>
                <p style={{marginBottom:0, paddingBottom:0}} onClick={handleLogout} className="account-number"><strong>Sign out</strong></p>
            </div>
            <Nav onClick={handleProfile}>
                <div>
                    <Avatar src={image} id="user-profile" className="user-profile" name={name} style={{cursor:'pointer'}} size="40"  round={true} maxInitials={2} />
                </div>
            </Nav>
        </div>
        </>
    )
}

export default Downer;