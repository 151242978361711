import React, { useState, useEffect } from "react";
import OTPInput from "otp-input-react";
import { Button } from "react-bootstrap";
import api from "../../../services/api";
import "animate.css";

const OTP = ({
  setOtp,
  Otp,
  type,
  sendDetails,
  referance = null,
  error,
  setError,
  typeHide = false,
}) => {
  const [timeLeft, setTimeLeft] = useState(10);
  const [sendInfo, setSendinfo] = useState("");

  //0 counter
  useEffect(() => {
    setOtp("");
    setError("");
    setTimeLeft(10);
  }, []);

  //counter
  useEffect(() => {
    if (timeLeft === 0) {
      setTimeLeft(0);
    }
    if (!timeLeft) return;
    const intervalId = setInterval(() => {
      setTimeLeft(timeLeft - 1);
    }, 1000);
    return () => clearInterval(intervalId);
  }, [timeLeft]);

  //resend otp
  const handleResend = async () => {
    try {
      setOtp("");
      setError("");
      let loggedBy = sessionStorage.getItem("loggedBy");
      let data = [];
      if (loggedBy == 1) {
        data = await api.postRequest("register/resend");
      } else {
        data = await api.postRequest("resend/otp");
      }
      if (data.status == 200) {
        if (data.data.status == "success") {
          setTimeLeft(10);
        } else {
          setError("Failed to Resend Password");
        }
      } else {
        setError("Something went wrong");
      }
    } catch (error) {
      setError("Something went wrong");
    }
  };

  //did't recived otp
  const handleReEnter = () => {
    setError("");
    referance();
  };

  return (
    <div className="container animate__animated animate__fadeIn">
      <div className="otp-body">
        <h6>Please enter the One-Time Password to verify your account</h6>
        {timeLeft == 0 ? (
          <p>A One-Time Password has been sent to {sendDetails}</p>
        ) : (
          <p>One-Time Password will receive in {timeLeft} seconds</p>
        )}
        <OTPInput
          style={{ display: "block" }}
          value={Otp}
          onChange={setOtp}
          autoFocus
          OTPLength={5}
          otpType="number"
          disabled={false}
        />
        {error != "" ? (
          <div className="col-md-12">
            <span className="srv-validation-message">
              <span
                className="error-palate"
                style={{
                  flexDirection: "row",
                  justifyContent: "center",
                  marginTop: 10,
                }}
              >
                {error}
              </span>
            </span>
          </div>
        ) : (
          ""
        )}
        <div className="resend-button mb-2">
          <Button
            variant="secondary"
            onClick={handleResend}
            disabled={timeLeft > 0}
          >
            Resend OTP
          </Button>
        </div>
        {typeHide == false ? (
          <span
            onClick={handleReEnter}
            className="forget-password pt-2 pb-0 mb-0 "
          >
            Change {type == 1 ? "email address" : "mobile number"}
          </span>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default OTP;
