import React from "react";
import { Form } from "react-bootstrap";
import Conditions from '../../../assets/applications/document_conditions.pdf'


const Terms = ({ formik }) => {
    return (
        <>
        <Form.Group className=" terms-condition mt-1" controlId="formBasicCheckbox">
            <Form.Check onChange={(e) => formik.setFieldValue('terms', formik.values.terms == ''?1:'')} isInvalid={Boolean(formik.errors.terms)} style={{fontSize:13, marginRight:4}} type="checkbox" label="I agree with" checked={formik.values.terms}/>
            <a href={Conditions} target="_blank" className="conditions">terms and conditions</a>
        </Form.Group>
        {formik.errors.terms && 
            <div className="col-md-12">
            <span className="srv-validation-message"><span className="error-palate">*&nbsp;{formik.errors.terms}</span></span>
            </div>
        } 
        </>
    )
}

export default Terms;