import React, {useState, useEffect} from "react";
import { Form, FormControl, Button } from 'react-bootstrap';

const TransSearch = ({formik, fundList, acountList, handleSearch}) => {
    const [fundlist, setFundList] = useState([]);
    const [accountList, setAccountList] = useState([]);

    useEffect(() => {
        setFundList(fundList);
    }, [fundList])

    const handleFund = (e) => {
        let fund_code = e.target.value;
        formik.setFieldValue('fund', fund_code);
        let accounts = acountList.filter((value) => value.FUND_CODE == fund_code);
        setAccountList(accounts);
    }

    const handleClick = () => {
        formik.resetForm();
    }

    return (
        <div className='trans-form'>
        <div className='row'>
            <div className='col-md-6'>
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                    <div className='row'>
                    <div className='col-md-4'>
                        <Form.Label className="input-label">Select Fund</Form.Label>
                    </div>
                    <div className='col-md-8'>
                    <Form.Select size="sm" 
                        onChange={handleFund} 
                        name="fund"
                        aria-label="Default select example"
                        value={formik.values.fund}
                        isInvalid={Boolean(formik.errors.fund)}
                    >
                            <option value={''}>All</option>
                            {
                                fundlist.length > 0 && fundlist.map((value) => {
                                    return(
                                        <option value={value.FUND_CODE}>{value.FUND_NAME}</option> 
                                    )
                                })
                            }
                    </Form.Select>
                    {formik.errors.fund && 
                        <div className="col-md-12">
                        <span className="srv-validation-message"><span className="error-palate">*&nbsp;{formik.errors.fund}</span></span>
                        </div>
                    }  
                    </div>
                    </div>
                </Form.Group>
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                    <div className='row'>
                    <div className='col-md-4'>
                        <Form.Label className="input-label">Select Account</Form.Label>
                    </div>
                    <div className='col-md-8'>
                    <Form.Select 
                        size="sm" 
                        name="account"
                        aria-label="Default select example"
                        value={formik.values.account}
                        isInvalid={Boolean(formik.errors.account)}
                        onChange={(e) =>  formik.setFieldValue('account', e.target.value)}
                    >
                        <option value={''}>All</option>
                            {
                                accountList.length > 0 && accountList.map((value) => {
                                    return(
                                        <option value={value.ACCOUNT_NO}>{value.ACCOUNT_NO}</option> 
                                    )
                                })
                            }
                    </Form.Select>
                    {formik.errors.account && 
                        <div className="col-md-12">
                        <span className="srv-validation-message"><span className="error-palate">*&nbsp;{formik.errors.account}</span></span>
                        </div>
                    }  
                    </div>
                    </div>
                </Form.Group>
            </div>
            <div className='col-md-6'>
                <div className='row' style={{alignItems:'center'}}>
                <div className='col-md-4' >
                    <Form.Label className="input-label">Select Dates</Form.Label>
                </div>
                <div className='col-md-8'>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <div className='col-md-12'>
                                <FormControl
                                    size="sm"
                                    type="date"
                                    name="from"
                                    aria-label="from"
                                    aria-describedby="basic-addon1"
                                    value={formik.values.from}
                                    isInvalid={Boolean(formik.errors.from)}
                                    onChange={(e) =>  formik.setFieldValue('from', e.target.value)}
                                />
                            </div>
                            {formik.errors.from && 
                                <div className="col-md-12">
                                <span className="srv-validation-message"><span className="error-palate">*&nbsp;{formik.errors.from}</span></span>
                                </div>
                            }  
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <div className='col-md-12'>
                                <FormControl
                                    size="sm"
                                    type="date"
                                    name="to"
                                    aria-label="dateofbirth"
                                    aria-describedby="basic-addon1"
                                    value={formik.values.to}
                                    isInvalid={Boolean(formik.errors.to)}
                                    onChange={(e) =>  formik.setFieldValue('to', e.target.value)}
                                />
                            </div>
                            {formik.errors.to && 
                                <div className="col-md-12">
                                <span className="srv-validation-message"><span className="error-palate">*&nbsp;{formik.errors.to}</span></span>
                                </div>
                            }  
                    </Form.Group>
                </div>
                </div>
 
            </div>
        </div>
        <div className='row' >
            <div className='col-md-12' style={{marginTop:10, display:'flex', flexDirection:'row', justifyContent:'end'}}>
                <Button className="btn btn-success btn-green" style={{width:80, marginRight:30}} onClick={handleSearch}>Search</Button>
                <Button className="btn btn-default btn-default" onClick={handleClick} style={{width:80}}>Clear</Button>
            </div>
        </div>
    </div>
    )
}

export default TransSearch;